import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import MUIDataTable from "mui-datatables";
import Modal from '@mui/material/Modal';
import axios from 'axios';
import Button from '@mui/material/Button';
import { Container } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import { Grid, Divider } from '@mui/material';
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Typography } from '@mui/material';
import { useRef, useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';

function Production_mach_edit() {
    const location = useLocation();
    const navigate = useNavigate();

    const [EditID, setEditID] = useState();
    const [DepValues, setDepValues] = useState([]);
    const [DepType, setDepType] = useState(null);
    const [MachineName, setMachineName] = useState();

    const [inputValues, setInputValues] = useState([]);

    const [shift, setshift] = useState([]);
    const [categoryData, setcategoryData] = useState([]);
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [actionMessage, setActionMessage] = useState('');

    const lastContentRef = useRef(null);
    useLayoutEffect(() => {
        if (lastContentRef.current) {
            lastContentRef.current.style.marginBottom = '85px';
        }
    }, []);

    const fetchShiftData = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const masterid = userSchema?.masterid;
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/production/shiftListGET`,
                {
                    params: {
                        masterid,
                        compid: JSON.parse(companyIdString),
                        divid: JSON.parse(divIdString),
                    },
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            setshift(response.data.shiftData)
            setcategoryData(response.data.CatData)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleCancel = () => {
        fetchShiftData()
        setMachineName('')
        setDepType(null)
    }

    const handleSave = async () => {
        try {
            if (!MachineName) {
                setActionMessage('Machine Name is required.')
                setErrorModalOpen(true)
            }
            if (!DepType) {
                setActionMessage('Department Name is required.')
                setErrorModalOpen(true)
            }
            if (MachineName && DepType) {
                const userSchemaString = localStorage.getItem('userSchema');
                const userSchema = JSON.parse(userSchemaString);
                const companyIdString = localStorage.getItem('companyId');
                const divIdString = localStorage.getItem('divId');

                const data = {
                    machine_name: MachineName,
                    department: DepType?._id,
                    machgroup: inputValues,
                    _id: EditID,
                    user: userSchema?.usrnm,
                    compid: JSON.parse(companyIdString),
                    divid: JSON.parse(divIdString),
                    masterid: userSchema?.masterid
                };
                const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/production/MachineMasterUpdate`, data, {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                })
                    .then(response => {
                        if (response.data.status) {
                            setActionMessage(response.data.message)
                            setSuccessModalOpen(true);
                            // handleCancel();

                        } else {
                            setActionMessage(response.data.message)
                            // handleCancel();
                            setErrorModalOpen(true)
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        setActionMessage(response.data.message)
                        setErrorModalOpen(true)
                    });
            }

        } catch (error) {
            console.error('Error saving data:', error);
            setActionMessage('Oops... an error occured while saving data.')
            setErrorModalOpen(true)
        }
    };

    const handleAddMore = () => {
        setInputValues([...inputValues, {
            shift: '',
            shiftName: '',
            hours: '',
            category: '',
            discription: '',
            nos: 0
        }]);
    };

    const handleInputChange = (index, field, newValue) => {
        setInputValues(prevValues => {
            const updatedValues = [...prevValues];

            // If the field being updated is 'shiftName'
            if (field === 'shiftName') {
                updatedValues[index] = {
                    ...updatedValues[index],
                    shiftName: newValue, // update the shiftName
                    hours: newValue ? newValue.total_hour : '', // update hours based on newValue (shift object)
                    shift: newValue ? newValue._id : '', // update shift with newValue._id
                };
            }
            else if (field === 'discription') {
                updatedValues[index] = {
                    ...updatedValues[index],
                    discription: newValue, // update the shiftName
                    category: newValue ? newValue._id : '', // update shift with newValue._id
                };
            }
            else {
                // For other fields, just update the field normally
                updatedValues[index] = {
                    ...updatedValues[index],
                    [field]: newValue
                };
            }

            return updatedValues;
        });
    };

    const handleDeleteRow = (index) => {
        const newRows = [...inputValues];
        newRows.splice(index, 1);
        setInputValues(newRows);
    };

    // const handleInputChange = (e, shift, category) => {
    //     const newValue = e.target.value;
    //     setInputValues(prevValues => {
    //         const updatedValues = prevValues.map(item => {
    //             if (item.shift === shift && item.category === category) {
    //                 return { ...item, nos: newValue };
    //             }
    //             return item;
    //         });
    //         return updatedValues;
    //     });
    // };

    // useEffect(() => {
    //     const initialInputValues = shift.flatMap(shiftData =>
    //         categoryData.map(cat => ({
    //             shift: shiftData._id,
    //             shiftName: shiftData,
    //             hours: shiftData.total_hour,
    //             category: cat._id,
    //             discription: cat,
    //             nos: 0
    //         }))
    //     );
    //     setInputValues(initialInputValues);
    // }, [shift, categoryData]);

    const DepTypeGET = async () => {
        const userSchemaString = localStorage.getItem('userSchema');
        const userSchema = JSON.parse(userSchemaString);
        const companyIdString = localStorage.getItem('companyId');
        const divIdString = localStorage.getItem('divId');

        const data = {
            user: userSchema?.usrnm,
            compid: JSON.parse(companyIdString),
            divid: JSON.parse(divIdString),
            masterid: userSchema?.masterid
        };

        try {
            const token = localStorage.getItem('user-token');
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/production/DepartmentTypeGET`,
                data,
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            setDepValues(response.data.lastEntryNo);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        DepTypeGET();
        fetchShiftData();
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            await DepTypeGET();
            const data = location?.state?.data;
            setMachineName(data?.MacName);
            setDepType(data?.department);
            setEditID(data?.EditID);

            if (location.state && location.state?.data?.machgroup.length > 0) {
                console.log(data?.machgroup);
                setInputValues(data?.machgroup);
            } else {
                fetchShiftData();
            }
        };

        fetchData();
    }, [location]);

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        border: '2px solid',
        boxShadow: 24,
        padding: 16,
        minWidth: 300,
        maxWidth: 500,
        textAlign: 'center'
    };
    const handleSuccessModalClose = () => {
        setSuccessModalOpen(false);
    };

    const handleErrorModalClose = () => {
        setErrorModalOpen(false);
    };

    return (
        <>
            <Container ref={lastContentRef} maxWidth="xl">
                <Paper elevation={3} style={{ padding: '20px', marginBottom: '10px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
                                Production Machine Master
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: '10px' }}>
                            <Grid container alignItems="center">
                                <Grid item xs={6}>
                                    <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: '20px', marginLeft: '10px', align: 'center' }}>
                                        Machine Name
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <input
                                            required
                                            type="text"
                                            value={MachineName}
                                            onChange={(e) => setMachineName(e.target.value)}
                                            style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', }} // Adjusted flex to take half of the container width
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} >
                            <Divider /> {/* Divider */}
                        </Grid>
                        <Grid item xs={12} >
                            <Grid container alignItems="center">
                                <Grid item xs={6}>
                                    <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: '20px', marginLeft: '10px', align: 'center' }}>
                                        Department
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <Autocomplete
                                            value={DepType}
                                            onChange={(event, newValue) => {
                                                setDepType(newValue);
                                            }}
                                            options={DepValues}
                                            getOptionLabel={(option) => option.discription || option.description}
                                            getOptionSelected={(option, value) => option._id === value}
                                            style={{ height: '38px', fontSize: '14px' }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    inputProps={{ ...params.inputProps, 'aria-label': 'Select Department Type', style: { paddingTop: '0px' } }}
                                                    sx={{ "& .MuiInputBase-root": { height: "38px" } }}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>

                <TableContainer component={Paper}>
                    <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', padding: '20px' }}>
                        Machine Requirment
                    </Typography>
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell align='center'>Shift Name</TableCell>
                                <TableCell align='center'>Total Hours</TableCell>
                                <TableCell align='center'>Employee Category</TableCell>
                                <TableCell align='center'>Nos</TableCell>
                                <TableCell align='center'>Delete</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {inputValues.map((item, index) => (
                                <TableRow key={index}>
                                    {/* <TableCell align='center'>{item.shiftName}</TableCell> */}
                                    <TableCell align='center'>
                                        <Autocomplete
                                            value={item.shiftName}
                                            // onInputChange={(event, value) => {
                                            //     if (value && Type !== 'Contra') fetchOptions(value); // Fetch options as user types
                                            // }}
                                            onChange={(event, newValue) => handleInputChange(index, 'shiftName', newValue)}
                                            options={shift}
                                            getOptionLabel={(option) => option.shift_name || ''}
                                            renderInput={(params) => <TextField {...params} variant="outlined" size="small" />}
                                            // onFocus={() => handleFocus(index)}
                                            // sx={{ "& .MuiInputBase-input": { fontSize: 14, height: 15, padding: 1 } }}
                                            fullWidth
                                        />
                                    </TableCell>
                                    <TableCell align='center'>{item.hours}</TableCell>
                                    {/* <TableCell align='center'>{item.discription}</TableCell> */}
                                    <TableCell align='center'>
                                        <Autocomplete
                                            value={item.discription}
                                            // onInputChange={(event, value) => {
                                            //     if (value && Type !== 'Contra') fetchOptions(value); // Fetch options as user types
                                            // }}
                                            onChange={(event, newValue) => handleInputChange(index, 'discription', newValue)}
                                            options={categoryData}
                                            getOptionLabel={(option) => option.discription || ''}
                                            renderInput={(params) => <TextField {...params} variant="outlined" size="small" />}
                                            // onFocus={() => handleFocus(index)}
                                            // sx={{ "& .MuiInputBase-input": { fontSize: 14, height: 15, padding: 1 } }}
                                            fullWidth
                                        />
                                    </TableCell>
                                    <TableCell align='center'>
                                        <TextField
                                            value={item.nos}
                                            // onChange={(e) => handleInputChange(e, item.shift, item.category)}
                                            onChange={(e) => handleInputChange(index, 'nos', e.target.value)}
                                            variant="outlined"
                                            size="small"
                                            type='number'
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleDeleteRow(index)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>

                    </Table>
                </TableContainer>
                <Grid item xs={12}>
                    <Button onClick={handleAddMore}>Add More</Button>
                </Grid>
                <Paper style={{ padding: '16px', position: 'fixed', bottom: '16px', zIndex: 1000, width: '60%', left: '0', right: '0', margin: '0 auto' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }}>
                        <Button style={{ marginRight: '8px' }} variant="contained" color="error" onClick={handleCancel} >
                            Cancel
                        </Button>
                        <Button style={{ marginLeft: '8px' }} variant="contained" color="primary" onClick={handleSave}>
                            Save
                        </Button>
                    </div>
                </Paper>
                <Modal
                    open={successModalOpen}
                    onClose={handleSuccessModalClose}
                    aria-labelledby="success-modal-title"
                    aria-describedby="success-modal-description"
                >
                    <div style={{ ...modalStyle, borderColor: '#007bff' }}>
                        <h2 id="success-modal-title" style={{ color: '#007bff' }}>Success!</h2>
                        <p id="success-modal-description">{actionMessage}</p>
                        <Button variant="contained" onClick={handleSuccessModalClose} style={{ backgroundColor: '#007bff', color: 'white' }}>Close</Button>
                    </div>
                </Modal>
                <Modal
                    open={errorModalOpen}
                    onClose={handleErrorModalClose}
                    aria-labelledby="error-modal-title"
                    aria-describedby="error-modal-description"
                >
                    <div style={{ ...modalStyle, borderColor: '#ff0000' }}>
                        <h2 id="error-modal-title" style={{ color: '#ff0000' }}>Error!</h2>
                        <p id="error-modal-description">{actionMessage}.</p>
                        <Button variant="contained" onClick={handleErrorModalClose} style={{ backgroundColor: '#ff0000', color: 'white' }}>Close</Button>
                    </div>
                </Modal>
            </Container>
        </>
    );
}

export default Production_mach_edit;
