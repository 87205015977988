import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import PortalNavbar from "./portal/navbar/PortalNavbar";
import { ProSidebarProvider } from 'react-pro-sidebar';
import { Box } from '@mui/material';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const location = useLocation(); // Get the current location
  const [userDetail, setUserDetail] = useState(null);
  const [userRights, setUserRights] = useState(null);
  const [administrator, setAdministrator] = useState(false);
  const [EmpoyeeIID, setEmpoyeeIID] = useState(null);
  const [divname, setdivname] = useState(null);
  const [coname, setconame] = useState(null);

  useEffect(() => {
    const userSchemaString = localStorage.getItem('userSchema');
    setEmpoyeeIID(localStorage.getItem('EmployeeIDD'));
    setdivname(JSON.parse(localStorage.getItem('div_mast')));
    setconame(JSON.parse(localStorage.getItem('com_name')));

    const userSchema = JSON.parse(userSchemaString);
    setUserDetail(userSchema);
    setAdministrator(userSchema?.administrator === 'Yes');
    setUserRights(userSchema?.admin ? userSchema.admin.split(/,\s*/) : []);
  }, [localStorage.getItem('div_mast')]);

  const checkUserToken = () => {
    const userToken = localStorage.getItem('user-token');
    if (!userToken || userToken === 'undefined') {
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
    }
  };

  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn]);
  const isDevRoute = location.pathname === '/devision';
const style ={ 'min-height': 'auto' };
  return (
    <>
<style>
  {`
    .css-hyum1k-MuiToolbar-root {
      min-height: auto;
    }
    
    .tss-gm6zfk-MUIDataTableHeadCell-fixedHeader {
      background: linear-gradient(#FFFFFF, #60a5f2);
    }

    .css-1ygcj2i-MuiTableCell-root {
      padding: 8px;
    }

    .tss-1fbujeu-MUIDataTableHeadCell-toolButton {
      font-weight: bold;
      font-size: 12px; 
    }
    .css-1j9bukl-MuiDataGrid-root .MuiDataGrid-container--top [role=row], .css-1j9bukl-MuiDataGrid-root .MuiDataGrid-container--bottom [role=row]{
      background: linear-gradient(#FFFFFF, #60a5f2);
    }
    .css-t89xny-MuiDataGrid-columnHeaderTitle{      
      font-weight: 600;
    }

    .app-container {
      display: flex;
      flex-direction: row;
      height: 100vh;
      overflow: hidden;
    }

    .main-content {
      flex: 1;
      overflow-y: auto;
    }

    .sidebar {
      height: 100vh;
      overflow-y: auto;
    }

    .login-notice {
      background: linear-gradient(90deg, #ffffff 0%, #ffffff 50%, #ffffff 100%); /* Softer blue to white gradient */
      color: #333; /* Darker text for better readability */
      padding: 12px 12px; /* Increased padding for better spacing */
      text-align: center;
      font-weight: bold;
      font-size: 14px; /* Larger font for better visibility */
      border-radius: 10px; /* Slightly more rounded corners */
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Stronger shadow for better depth */
      position: sticky;
      top: 0;
      z-index: 10;
      display: flex; /* Flexbox for horizontal alignment */
      justify-content: center;
      align-items: center;
      gap: 10px; /* Spacing between text elements */
    }
  `}
</style>

    {/* <div>
      {isLoggedIn && !isDevRoute && <PortalNavbar />}
      <div>
        <Outlet />
      </div>
    </div> */}
     {(isLoggedIn && !isDevRoute) && (
        <>
          <Box className="login-notice">
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
              <span>{userDetail?.usrnm || ''}</span>
              <span>{divname}</span>
              <span>{coname}</span>
            </div>
          </Box>

        </>
      )}
    <ProSidebarProvider>
    <div className="app-container">
    {isLoggedIn && !isDevRoute && <div className="sidebar"><PortalNavbar /></div>}
        <div className="main-content">
          <Outlet />
        </div>
      </div>
      </ProSidebarProvider>
      
    </>
    );
}

export default App;
