import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Menu, MenuItem, SubMenu, Sidebar, useProSidebar, sidebarClasses } from 'react-pro-sidebar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { ProSidebarProvider } from 'react-pro-sidebar';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import GroupIcon from '@mui/icons-material/Group';
import ReportIcon from '@mui/icons-material/Report';
import TaskIcon from '@mui/icons-material/Task';
import SecurityIcon from '@mui/icons-material/Security';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountBalanceTwoToneIcon from '@mui/icons-material/AccountBalanceTwoTone';
import Diversity2 from '@mui/icons-material/Diversity2';

import axios from 'axios';

const settings = ['Logout'];

function PortalSidebar() {
  const { collapseSidebar } = useProSidebar();

  useEffect(()=>{
    collapseSidebar(true);
  },[]);

  const [userDetail, setUserDetail] = useState(null);
  const [userRights, setUserRights] = useState(null);
  const [administrator, setAdministrator] = useState(false);
  const [EmpoyeeIID, setEmpoyeeIID] = useState(null);
  const [divname, setdivname] = useState(null);
  const [coname, setconame] = useState(null);
  const [divisionDetails, setDivisionDetails] = useState([]); // State to store division details

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const userSchemaString = localStorage.getItem('userSchema');
    setEmpoyeeIID(localStorage.getItem('EmployeeIDD'));
    setdivname(JSON.parse(localStorage.getItem('div_code')));
    setconame(JSON.parse(localStorage.getItem('com_name')));

    const userSchema = JSON.parse(userSchemaString);
    setUserDetail(userSchema);
    setAdministrator(userSchema?.administrator === 'Yes');
    setUserRights(userSchema?.admin ? userSchema.admin.split(/,\s*/) : []);
  }, []);

  const handleCompanyDivSelect = (divId) => {
    localStorage.setItem('divId', JSON.stringify(divId?._id));
    localStorage.setItem('masterid', JSON.stringify(divId?.masterid));
    localStorage.setItem('div_code', JSON.stringify(divId?.div_code));
    localStorage.setItem('div_mast', JSON.stringify(divId?.div_mast));
    // navigate('/dash')
    window.location.href = '/dash'; // Use window.location.href to redirect
  }

  const handleCompanySelect = () => {

    const userSchemaString = localStorage.getItem('userSchema');
    const userSchema = JSON.parse(userSchemaString);
    const token = localStorage.getItem('user-token'); // Get JWT token from localStorage

    axios.post(`${process.env.REACT_APP_API_URL}/company/div_co`, { div_code: userSchema.div_code }, {
      headers: {
        authorization: `Bearer ${token}`
      }
    }).then(response => {
      setDivisionDetails(response.data.division);
    })
      .catch(error => {
        console.error('Error fetching company details:', error);
      });
  };

  useEffect(() => {
    handleCompanySelect();
  }, [])

  const handleLogout = () => {
    localStorage.clear();
    navigate('/auth/login');
  };

  return (
    <Sidebar
      rootStyles={{
        [`.${sidebarClasses.container}`]: {
          backgroundColor: 'white',
          // background: 'linear-gradient(45deg, #001f3f 30%, #004080 90%)', // Yellow Gradient
          fontSize: '14px',
          padding: '0px',
        },
      }}
    >
      {/* <main>
        <button onClick={() => collapseSidebar()}>Collapse</button>
      </main> */}
      <Menu iconShape="square">
        <MenuItem onClick={() => collapseSidebar()}>
          <img src={"/logo.jpeg"} alt="Profile" style={{ width: '50px', height: '50px' }} />
        </MenuItem>

        {(userRights?.includes('Dashboard-V') || administrator) && (
          <MenuItem
            // onClick={() => navigate('/dash')}
            onClick={(e) => {
              if (!e.metaKey && !e.ctrlKey) { // Check if Ctrl/Cmd key is not pressed
                navigate('/dash');
                e.preventDefault(); // Prevent default navigation
              }
            }}
            component="a"
            href="/dash"
            icon={<DashboardCustomizeIcon sx={{ color: '#2196F4' }} />}
            active={location.pathname.includes('/dash')}
            style={{ height: '40px' }} 
          >
            Dashboard
          </MenuItem>
        )}

        {(userRights?.includes('HR Master-V') || administrator || EmpoyeeIID) && (
          <SubMenu style={{ height: '40px' }} label="HR Master" icon={<GroupIcon sx={{ color: '#2196F4' }} />} active={location.pathname.includes('/HrMaster') || location.pathname.includes('/staffDetail')}>

            {(userRights?.includes('Salary Master-V') || administrator) && (
              <SubMenu style={{ height: '35px' }} label="Salary Master">
                {(userRights?.includes("Add Salary Head-V") || administrator) && (
                  <MenuItem 
                  style={{ height: '35px' }}
                  // onClick={() => navigate('/HrMaster/salaryMaster/SalaryHead')}
                  onClick={(e) => {
                    if (!e.metaKey && !e.ctrlKey) { // Check if Ctrl/Cmd key is not pressed
                      navigate('/HrMaster/salaryMaster/SalaryHead');
                      e.preventDefault(); // Prevent default navigation
                    }
                  }}
                  component="a"
                  href="/HrMaster/salaryMaster/SalaryHead"
                  >Add Salary Head</MenuItem>
                )}

                {(userRights?.includes("Add Employee-V") || administrator) && (
                  <MenuItem 
                  style={{ height: '35px' }}
                  // onClick={() => navigate('/HrMaster/salaryMaster/shiftRole')}
                  onClick={(e) => {
                    if (!e.metaKey && !e.ctrlKey) { // Check if Ctrl/Cmd key is not pressed
                      navigate('/HrMaster/salaryMaster/shiftRole');
                      e.preventDefault(); // Prevent default navigation
                    }
                  }}
                  component="a"
                  href="/HrMaster/salaryMaster/shiftRole"
                  >Add Employee</MenuItem>
                )}

                {(userRights?.includes("Salary Structure List-V") || administrator) && (
                  <MenuItem 
                  style={{ height: '35px' }}
                  // onClick={() => navigate('/HrMaster/salaryMaster/Salary_StrucList')}
                  onClick={(e) => {
                    if (!e.metaKey && !e.ctrlKey) { // Check if Ctrl/Cmd key is not pressed
                      navigate('/HrMaster/salaryMaster/Salary_StrucList');
                      e.preventDefault(); // Prevent default navigation
                    }
                  }}
                  component="a"
                  href="/HrMaster/salaryMaster/Salary_StrucList"
                  >Salary Structure List</MenuItem>
                )}
                {(userRights?.includes("Shift Master-V") || administrator) && (
                  <MenuItem style={{ height: '35px' }} onClick={() => navigate('/ShiftMaster/AddShiftMaster')}>Shift Master</MenuItem>
                )}
              </SubMenu>
            )}

            {(userRights?.includes('Employee Info Master-V') || administrator) && (
              <SubMenu  style={{ height: '35px' }} label="Employee Info Master">
                {(userRights?.includes("Employee Grade Master-V") || administrator) && (
                  <MenuItem 
                  style={{ height: '35px' }}
                  // onClick={() => navigate('/HrMaster/Emp_Grade_Master')}
                  onClick={(e) => {
                    if (!e.metaKey && !e.ctrlKey) { // Check if Ctrl/Cmd key is not pressed
                      navigate('/HrMaster/Emp_Grade_Master');
                      e.preventDefault(); // Prevent default navigation
                    }
                  }}
                  component="a"
                  href="/HrMaster/Emp_Grade_Master"
                  >Employee Grade Master</MenuItem>
                )}

                {(userRights?.includes("Employee Type-V") || administrator) && (
                  <MenuItem 
                  style={{ height: '35px' }}
                  // onClick={() => navigate('/HrMaster/Emp_Type')}
                  onClick={(e) => {
                    if (!e.metaKey && !e.ctrlKey) { // Check if Ctrl/Cmd key is not pressed
                      navigate('/HrMaster/Emp_Type');
                      e.preventDefault(); // Prevent default navigation
                    }
                  }}
                  component="a"
                  href="/HrMaster/Emp_Type"
                  >Employee Type</MenuItem>
                )}

                {(userRights?.includes("Document Master-V") || administrator) && (
                  <MenuItem 
                  style={{ height: '35px' }}
                  // onClick={() => navigate('/HrMaster/Emp_Document')}
                  onClick={(e) => {
                    if (!e.metaKey && !e.ctrlKey) { // Check if Ctrl/Cmd key is not pressed
                      navigate('/HrMaster/Emp_Document');
                      e.preventDefault(); // Prevent default navigation
                    }
                  }}
                  component="a"
                  href="/HrMaster/Emp_Document"
                  >Document Master</MenuItem>
                )}

                {(userRights?.includes("Department-V") || administrator) && (
                  <MenuItem 
                  style={{ height: '35px' }}
                  // onClick={() => navigate('/HrMaster/Department')}
                  onClick={(e) => {
                    if (!e.metaKey && !e.ctrlKey) { // Check if Ctrl/Cmd key is not pressed
                      navigate('/HrMaster/Department');
                      e.preventDefault(); // Prevent default navigation
                    }
                  }}
                  component="a"
                  href="/HrMaster/Department"
                  >Department</MenuItem>
                )}

                {(userRights?.includes("Designation-V") || administrator) && (
                  <MenuItem 
                  style={{ height: '35px' }}
                  // onClick={() => navigate('/HrMaster/Designation')}
                  onClick={(e) => {
                    if (!e.metaKey && !e.ctrlKey) { // Check if Ctrl/Cmd key is not pressed
                      navigate('/HrMaster/Designation');
                      e.preventDefault(); // Prevent default navigation
                    }
                  }}
                  component="a"
                  href="/HrMaster/Designation"
                  >Designation</MenuItem>
                )}

                {(userRights?.includes("Attendance Type-V") || administrator) && (
                  <MenuItem 
                  style={{ height: '35px' }}
                  // onClick={() => navigate('/HrMaster/Emp_AttendanceType')}
                  onClick={(e) => {
                    if (!e.metaKey && !e.ctrlKey) { // Check if Ctrl/Cmd key is not pressed
                      navigate('/HrMaster/Emp_AttendanceType');
                      e.preventDefault(); // Prevent default navigation
                    }
                  }}
                  component="a"
                  href="/HrMaster/Emp_AttendanceType"
                  >Attendance Type</MenuItem>
                )}

                {(userRights?.includes("Leave Reason Master-V") || administrator) && (
                  <MenuItem 
                  style={{ height: '35px' }}
                  // onClick={() => navigate('/HrMaster/leave_reason_master')}
                  onClick={(e) => {
                    if (!e.metaKey && !e.ctrlKey) { // Check if Ctrl/Cmd key is not pressed
                      navigate('/HrMaster/leave_reason_master');
                      e.preventDefault(); // Prevent default navigation
                    }
                  }}
                  component="a"
                  href="/HrMaster/leave_reason_master"
                  >Leave Reason Master</MenuItem>
                )}

                {(userRights?.includes("Language Master-V") || administrator) && (
                  <MenuItem 
                  style={{ height: '35px' }}
                  // onClick={() => navigate('/HrMaster/language_master')}
                  onClick={(e) => {
                    if (!e.metaKey && !e.ctrlKey) { // Check if Ctrl/Cmd key is not pressed
                      navigate('/HrMaster/language_master');
                      e.preventDefault(); // Prevent default navigation
                    }
                  }}
                  component="a"
                  href="/HrMaster/language_master"
                  >Language Master</MenuItem>
                )}

                {(userRights?.includes("Holiday Master-V") || administrator) && (
                  <MenuItem 
                  style={{ height: '35px' }}
                  // onClick={() => navigate('/HrMaster/holiday_master')}
                  onClick={(e) => {
                    if (!e.metaKey && !e.ctrlKey) { // Check if Ctrl/Cmd key is not pressed
                      navigate('/HrMaster/holiday_master');
                      e.preventDefault(); // Prevent default navigation
                    }
                  }}
                  component="a"
                  href="/HrMaster/holiday_master"
                  >Holiday Master</MenuItem>
                )}

                {(userRights?.includes("Bank Master-V") || administrator) && (
                  <MenuItem 
                  style={{ height: '35px' }}
                  // onClick={() => navigate('/HrMaster/bank_master')}
                  onClick={(e) => {
                    if (!e.metaKey && !e.ctrlKey) { // Check if Ctrl/Cmd key is not pressed
                      navigate('/HrMaster/bank_master');
                      e.preventDefault(); // Prevent default navigation
                    }
                  }}
                  component="a"
                  href="/HrMaster/bank_master"
                  >Bank Master</MenuItem>
                )}
              </SubMenu>
            )}

            {(userRights?.includes("Production-V") || administrator) && (
              <SubMenu style={{ height: '35px' }} label="Production">
                {(userRights?.includes("Production Machine List-V") || administrator) && (
                  <MenuItem 
                  style={{ height: '35px' }}
                  // onClick={() => navigate('/HrMaster/production/ProMachList')}
                  onClick={(e) => {
                    if (!e.metaKey && !e.ctrlKey) { // Check if Ctrl/Cmd key is not pressed
                      navigate('/HrMaster/production/ProMachList');
                      e.preventDefault(); // Prevent default navigation
                    }
                  }}
                  component="a"
                  href="/HrMaster/production/ProMachList"
                  >Production Machine List</MenuItem>
                )}
              </SubMenu>
            )}

            {(userRights?.includes('Employee Master-V') || administrator || EmpoyeeIID) && (
              <MenuItem 
              style={{ height: '35px' }}
              // onClick={() => navigate('/HrMaster/EmpMaster/StaffDetails')}
              onClick={(e) => {
                if (!e.metaKey && !e.ctrlKey) { // Check if Ctrl/Cmd key is not pressed
                  navigate('/HrMaster/EmpMaster/StaffDetails');
                  e.preventDefault(); // Prevent default navigation
                }
              }}
              component="a"
              href="/HrMaster/EmpMaster/StaffDetails"
              >
                Employee Master
              </MenuItem>
            )}
          </SubMenu>
        )}

        {(userRights?.includes("Salary-V") || administrator || EmpoyeeIID) && (
          <SubMenu style={{ height: '40px' }} icon={<AccountBalanceTwoToneIcon sx={{ color: '#2196F4' }} />} label="Salary" active={location.pathname.includes('/transaction')}>

            {(userRights?.includes("Advance-V") || administrator) && (
              <MenuItem 
              style={{ height: '35px' }}
              // onClick={() => navigate('/transaction/advance')}
              onClick={(e) => {
                if (!e.metaKey && !e.ctrlKey) { // Check if Ctrl/Cmd key is not pressed
                  navigate('/transaction/advance');
                  e.preventDefault(); // Prevent default navigation
                }
              }}
              component="a"
              href="/transaction/advance"
              >
                Advance
              </MenuItem>
            )}

            {(userRights?.includes("Salary-V") || administrator) && (
              <MenuItem 
              style={{ height: '35px' }}
              // onClick={() => navigate('/transaction/salary')}
              onClick={(e) => {
                if (!e.metaKey && !e.ctrlKey) { // Check if Ctrl/Cmd key is not pressed
                  navigate('/transaction/salary');
                  e.preventDefault(); // Prevent default navigation
                }
              }}
              component="a"
              href="/transaction/salary"
              >
                Salary
              </MenuItem>
            )}

            {(userRights?.includes("Salary List-V") || administrator) && (
              <MenuItem 
              style={{ height: '35px' }}
              // onClick={() => navigate('/transaction/salaryList')}
              onClick={(e) => {
                if (!e.metaKey && !e.ctrlKey) { // Check if Ctrl/Cmd key is not pressed
                  navigate('/transaction/salaryList');
                  e.preventDefault(); // Prevent default navigation
                }
              }}
              component="a"
              href="/transaction/salaryList"
              >
                Salary List
              </MenuItem>
            )}

            {(userRights?.includes("Bank Salary List-V") || administrator) && (
              <MenuItem 
              style={{ height: '35px' }}
              // onClick={() => navigate('/transaction/banksalaryList')}
              onClick={(e) => {
                if (!e.metaKey && !e.ctrlKey) { // Check if Ctrl/Cmd key is not pressed
                  navigate('/transaction/banksalaryList');
                  e.preventDefault(); // Prevent default navigation
                }
              }}
              component="a"
              href="/transaction/banksalaryList"
              >
                Bank Salary List
              </MenuItem>
            )}

            {(userRights?.includes("ESIC Report-V") || administrator) && (
              <MenuItem 
              style={{ height: '35px' }}
              // onClick={() => navigate('/transaction/esic_report')}
              onClick={(e) => {
                if (!e.metaKey && !e.ctrlKey) { // Check if Ctrl/Cmd key is not pressed
                  navigate('/transaction/esic_report');
                  e.preventDefault(); // Prevent default navigation
                }
              }}
              component="a"
              href="/transaction/esic_report"
              >
                ESIC Report
              </MenuItem>
            )}

            {(userRights?.includes("PF Report-V") || administrator) && (
              <MenuItem 
              style={{ height: '35px' }}
              // onClick={() => navigate('/transaction/pf_report')}
              onClick={(e) => {
                if (!e.metaKey && !e.ctrlKey) { // Check if Ctrl/Cmd key is not pressed
                  navigate('/transaction/pf_report');
                  e.preventDefault(); // Prevent default navigation
                }
              }}
              component="a"
              href="/transaction/pf_report"
              >
                PF Report
              </MenuItem>
            )}

            {(userRights?.includes("Attendance List-V") || administrator) && (
              <MenuItem 
              style={{ height: '35px' }}
              // onClick={() => navigate('/transaction/attendanceList')}
              onClick={(e) => {
                if (!e.metaKey && !e.ctrlKey) { // Check if Ctrl/Cmd key is not pressed
                  navigate('/transaction/attendanceList');
                  e.preventDefault(); // Prevent default navigation
                }
              }}
              component="a"
              href="/transaction/attendanceList"
              >
                Attendance List
              </MenuItem>
            )}

            {(userRights?.includes("Leave Request List-V") || administrator || EmpoyeeIID) && (
              <MenuItem 
              style={{ height: '35px' }}
              // onClick={() => navigate('/transaction/LeaveReqList')}
              onClick={(e) => {
                if (!e.metaKey && !e.ctrlKey) { // Check if Ctrl/Cmd key is not pressed
                  navigate('/transaction/LeaveReqList');
                  e.preventDefault(); // Prevent default navigation
                }
              }}
              component="a"
              href="/transaction/LeaveReqList"
              >
                Leave Request List
              </MenuItem>
            )}

            {(userRights?.includes("Loan Request List-V") || administrator || EmpoyeeIID) && (
              <MenuItem 
              style={{ height: '35px' }}
              // onClick={() => navigate('/transaction/LoanReqList')}
              onClick={(e) => {
                if (!e.metaKey && !e.ctrlKey) { // Check if Ctrl/Cmd key is not pressed
                  navigate('/transaction/LoanReqList');
                  e.preventDefault(); // Prevent default navigation
                }
              }}
              component="a"
              href="/transaction/LoanReqList"
              >
                Loan Request List
              </MenuItem>
            )}

            {(userRights?.includes("Add Leaves -V") || administrator ) && (
              <MenuItem 
              style={{ height: '35px' }}
              // onClick={() => navigate('/transaction/add_leaves')}
              onClick={(e) => {
                if (!e.metaKey && !e.ctrlKey) { // Check if Ctrl/Cmd key is not pressed
                  navigate('/transaction/add_leaves');
                  e.preventDefault(); // Prevent default navigation
                }
              }}
              component="a"
              href="/transaction/add_leaves"
              >
                Add Leaves
              </MenuItem>
            )}
          </SubMenu>
        )}

        {(userRights?.includes("Reports-V") || administrator) && (
          <SubMenu style={{ height: '40px' }} icon={<ReportIcon sx={{ color: '#2196F4' }} />} label="Reports" active={location.pathname.includes('/reports')}>

            {(userRights?.includes("Machine Wise Requirement-V") || administrator) && (
              <MenuItem 
              style={{ height: '35px' }}
              // onClick={() => navigate('/reports/MachineWiseRequirement')}
              onClick={(e) => {
                if (!e.metaKey && !e.ctrlKey) { // Check if Ctrl/Cmd key is not pressed
                  navigate('/reports/MachineWiseRequirement');
                  e.preventDefault(); // Prevent default navigation
                }
              }}
              component="a"
              href="/reports/MachineWiseRequirement"
              >
                Machine Wise Requirement
              </MenuItem>
            )}

            {(userRights?.includes("Absent Report-V") || administrator) && (
              <MenuItem 
              style={{ height: '35px' }}
              // onClick={() => navigate('/reports/AbsentReport')}
              onClick={(e) => {
                if (!e.metaKey && !e.ctrlKey) { // Check if Ctrl/Cmd key is not pressed
                  navigate('/reports/AbsentReport');
                  e.preventDefault(); // Prevent default navigation
                }
              }}
              component="a"
              href="/reports/AbsentReport"
              >
                Absent Report
              </MenuItem>
            )}

            {(userRights?.includes("Loan Report-V") || administrator) && (
              <MenuItem 
              style={{ height: '35px' }}
              // onClick={() => navigate('/reports/LoanReport')}
              onClick={(e) => {
                if (!e.metaKey && !e.ctrlKey) { // Check if Ctrl/Cmd key is not pressed
                  navigate('/reports/LoanReport');
                  e.preventDefault(); // Prevent default navigation
                }
              }}
              component="a"
              href="/reports/LoanReport"
              >
                Loan Report
              </MenuItem>
            )}

            {(userRights?.includes("Detailed Loan Report-V") || administrator) && (
              <MenuItem 
              style={{ height: '35px' }}
              // onClick={() => navigate('/reports/DetailedLoanReport')}
              onClick={(e) => {
                if (!e.metaKey && !e.ctrlKey) { // Check if Ctrl/Cmd key is not pressed
                  navigate('/reports/DetailedLoanReport');
                  e.preventDefault(); // Prevent default navigation
                }
              }}
              component="a"
              href="/reports/DetailedLoanReport"
              >
                Detailed Loan Report
              </MenuItem>
            )}

            {(userRights?.includes("Leaving Report-V") || administrator) && (
              <MenuItem 
              style={{ height: '35px' }}
              // onClick={() => navigate('/reports/leaving_report')}
              onClick={(e) => {
                if (!e.metaKey && !e.ctrlKey) { // Check if Ctrl/Cmd key is not pressed
                  navigate('/reports/leaving_report');
                  e.preventDefault(); // Prevent default navigation
                }
              }}
              component="a"
              href="/reports/leaving_report"
              >
                Leaving Report
              </MenuItem>
            )}

            {(userRights?.includes("Joining Report-V") || administrator) && (
              <MenuItem 
              style={{ height: '35px' }}
              // onClick={() => navigate('/reports/joining_report')}
              onClick={(e) => {
                if (!e.metaKey && !e.ctrlKey) { // Check if Ctrl/Cmd key is not pressed
                  navigate('/reports/joining_report');
                  e.preventDefault(); // Prevent default navigation
                }
              }}
              component="a"
              href="/reports/joining_report"
              >
                Joining Report
              </MenuItem>
            )}
            
            {(userRights?.includes("OT Report-V") || administrator) && (
              <MenuItem 
              style={{ height: '35px' }}
              // onClick={() => navigate('/reports/ot_report')}
              onClick={(e) => {
                if (!e.metaKey && !e.ctrlKey) { // Check if Ctrl/Cmd key is not pressed
                  navigate('/reports/ot_report');
                  e.preventDefault(); // Prevent default navigation
                }
              }}
              component="a"
              href="/reports/ot_report"
              >
                OT Report
              </MenuItem>
            )}

            {(userRights?.includes("Late Coming Report-V") || administrator) && (
              <MenuItem 
              style={{ height: '35px' }}
              // onClick={() => navigate('/reports/latecoming')}
              onClick={(e) => {
                if (!e.metaKey && !e.ctrlKey) { // Check if Ctrl/Cmd key is not pressed
                  navigate('/reports/latecoming');
                  e.preventDefault(); // Prevent default navigation
                }
              }}
              component="a"
              href="/reports/latecoming"
              >
                Late Coming Report
              </MenuItem>
            )}

            {(userRights?.includes("Miss Punch Report-V") || administrator) && (
              <MenuItem 
              style={{ height: '35px' }}
              // onClick={() => navigate('/reports/miss_punch_report')}
              onClick={(e) => {
                if (!e.metaKey && !e.ctrlKey) { // Check if Ctrl/Cmd key is not pressed
                  navigate('/reports/miss_punch_report');
                  e.preventDefault(); // Prevent default navigation
                }
              }}
              component="a"
              href="/reports/miss_punch_report"
              >
                Miss Punch Report
              </MenuItem>
            )}
          </SubMenu>
        )}

        {(userRights?.includes("Task-V") || administrator || EmpoyeeIID) && (
          <SubMenu style={{ height: '40px' }} icon={<TaskIcon sx={{ color: '#2196F4' }} />} label="Tasks" active={location.pathname.includes('/task')}>

            {(userRights?.includes("Task Management-V") || administrator) && (
              <MenuItem 
              style={{ height: '35px' }}
              // onClick={() => navigate('/task/task_management')}
              onClick={(e) => {
                if (!e.metaKey && !e.ctrlKey) { // Check if Ctrl/Cmd key is not pressed
                  navigate('/task/task_management');
                  e.preventDefault(); // Prevent default navigation
                }
              }}
              component="a"
              href="/task/task_management"
              >
                Task Management
              </MenuItem>
            )}

            {(userRights?.includes("Task Plan-V") || administrator || EmpoyeeIID) && (
              <MenuItem 
              style={{ height: '35px' }}
              // onClick={() => navigate('/task/task_plan')}
              onClick={(e) => {
                if (!e.metaKey && !e.ctrlKey) { // Check if Ctrl/Cmd key is not pressed
                  navigate('/task/task_plan');
                  e.preventDefault(); // Prevent default navigation
                }
              }}
              component="a"
              href="/task/task_plan"
              >
                Task Plan
              </MenuItem>
            )}
          </SubMenu>
        )}

        {(userRights?.includes("Visitor-V") || administrator ) && (
          <SubMenu style={{ height: '40px' }} icon={<Diversity2 sx={{ color: '#2196F4' }} />} label="Visitor" active={location.pathname.includes('/visitor')}>

            {(userRights?.includes("Visitors List-V") || administrator ) && (
              <MenuItem 
              style={{ height: '35px' }}
              // onClick={() => navigate('/visitor/visitors_list')}
              onClick={(e) => {
                if (!e.metaKey && !e.ctrlKey) { // Check if Ctrl/Cmd key is not pressed
                  navigate('/visitor/visitors_list');
                  e.preventDefault(); // Prevent default navigation
                }
              }}
              component="a"
              href="/visitor/visitors_list"
              >
                Visitors List
              </MenuItem>
            )}

            {(userRights?.includes("Attendant Master-V") || administrator) && (
              <MenuItem 
              style={{ height: '35px' }}
              // onClick={() => navigate('/visitor/attendant_master')}
              onClick={(e) => {
                if (!e.metaKey && !e.ctrlKey) { // Check if Ctrl/Cmd key is not pressed
                  navigate('/visitor/attendant_master');
                  e.preventDefault(); // Prevent default navigation
                }
              }}
              component="a"
              href="/visitor/attendant_master"
              >
                Attendant Master
              </MenuItem>
            )}
          </SubMenu>
        )}

        {(userRights?.includes('Admin Setup-V') || administrator) && (
          <SubMenu style={{ height: '40px' }} icon={<SecurityIcon sx={{ color: '#2196F4' }} />} label="Admin Setup" active={location.pathname.includes('/user_rights')}>
            {(userRights?.includes('User List-V') || administrator) && (
              <MenuItem 
              style={{ height: '35px' }}
              // onClick={() => navigate('/user_rights/users_list')}
              onClick={(e) => {
                if (!e.metaKey && !e.ctrlKey) { // Check if Ctrl/Cmd key is not pressed
                  navigate('/user_rights/users_list');
                  e.preventDefault(); // Prevent default navigation
                }
              }}
              component="a"
              href="/user_rights/users_list"
              >
                User List
              </MenuItem>
            )}

            {(userRights?.includes('Security-V') || administrator) && (
              <MenuItem 
              style={{ height: '35px' }}
              // onClick={() => navigate('/user_rights/security')}
              onClick={(e) => {
                if (!e.metaKey && !e.ctrlKey) { // Check if Ctrl/Cmd key is not pressed
                  navigate('/user_rights/security');
                  e.preventDefault(); // Prevent default navigation
                }
              }}
              component="a"
              href="/user_rights/security"
              >
                Security
              </MenuItem>
            )}

            {(userRights?.includes('Authorization Setup-V') || administrator) && (
              <MenuItem 
              style={{ height: '35px' }}
              // onClick={() => navigate('/user_rights/authorization_setup')}
              onClick={(e) => {
                if (!e.metaKey && !e.ctrlKey) { // Check if Ctrl/Cmd key is not pressed
                  navigate('/user_rights/authorization_setup');
                  e.preventDefault(); // Prevent default navigation
                }
              }}
              component="a"
              href="/user_rights/authorization_setup"
              >
                Authorization Setup
              </MenuItem>
            )}

            {(userRights?.includes('Division Master-V') || administrator) && (
              <MenuItem 
              style={{ height: '35px' }}
              // onClick={() => navigate('/user_rights/division_master')}
              onClick={(e) => {
                if (!e.metaKey && !e.ctrlKey) { // Check if Ctrl/Cmd key is not pressed
                  navigate('/user_rights/division_master');
                  e.preventDefault(); // Prevent default navigation
                }
              }}
              component="a"
              href="/user_rights/division_master"
              >
                Division Master
              </MenuItem>
            )}

            {(userRights?.includes('Company Master-V') || administrator) && (
              <MenuItem 
              style={{ height: '35px' }}
              // onClick={() => navigate('/user_rights/company_master')}
              onClick={(e) => {
                if (!e.metaKey && !e.ctrlKey) { // Check if Ctrl/Cmd key is not pressed
                  navigate('/user_rights/company_master');
                  e.preventDefault(); // Prevent default navigation
                }
              }}
              component="a"
              href="/user_rights/company_master"
              >
                Company Master
              </MenuItem>
            )}

          </SubMenu>
        )}

        {/* <MenuItem> */}
        {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ mr: 3 }}>{userDetail?.usrnm || ''}</Typography>
            <Typography sx={{ mr: 3 }}>{divname || ''}</Typography>
            <Typography sx={{ mr: 3 }}>{coname || ''}</Typography>
          </Box> */}
        {/* <Menu iconShape="circle">
              <MenuItem icon={<LogoutIcon sx={{ color: '#2196F4' }}/>} onClick={ handleLogout }>
      Logout
              </MenuItem>
          </Menu>
        </MenuItem> */}

        <SubMenu label={<IconButton sx={{ p: 0 }}>
          <Avatar alt={userDetail?.first_name} src="/static/images/avatar/2.jpg" />
        </IconButton>}>

          {(userRights?.includes('Switch Division-V') || administrator) && (
            <SubMenu style={{ height: '40px' }} label="Switch Division">
              {divisionDetails.map((division, index) => (
                <MenuItem style={{ height: '40px' }} onClick={() => { handleCompanyDivSelect(division) }}>{division?.div_mast}</MenuItem>
              ))}
            </SubMenu>
          )}

          <MenuItem
          style={{ height: '40px' }}
            onClick={() => handleLogout()}
            icon={<LogoutIcon sx={{ color: '#eb4034' }} />}
          >
            Logout
          </MenuItem>

          <MenuItem style={{ height: '40px' }}>
            {userDetail?.usrnm || ''}
          </MenuItem>

          <MenuItem style={{ height: '40px' }}>
            {divname}
          </MenuItem >

          <MenuItem style={{ height: '40px' }}>
            {coname}
          </MenuItem>


        </SubMenu>
      </Menu>
    </Sidebar>
  );
}

export default PortalSidebar;