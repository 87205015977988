import React, { useState, useEffect, useRef } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TextField, TableFooter, TablePagination, Button, Autocomplete, Grid, Typography, Modal,
    Container
} from '@mui/material';
import axios from 'axios';

const EditableTable = () => {
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [actionMessage, setActionMessage] = useState('');
    const [rows, setRows] = useState([]);
    const [editedRows, setEditedRows] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [month, setMonth] = useState('');
    const [availableMonths, setAvailableMonths] = useState([
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ]);

    const [DepValues, setDepValues] = useState([]);
    const [DepType, setDepType] = useState(null);
    const [searchTerm, setSearchTerm] = useState(''); // State for search term

    const inputRefs = useRef([]); // Refs array to store input field refs

    const calculateSundays = (year, monthStr) => {
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        const month = monthNames.indexOf(monthStr);
        if (month === -1) {
            throw new Error("Invalid month name");
        }

        let sundays = 0;
        const daysInMonth = new Date(year, month + 1, 0).getDate();

        for (let day = 1; day <= daysInMonth; day++) {
            const date = new Date(year, month, day);
            if (date.getDay() === 0) {
                sundays++;
            }
        }

        return sundays;
    };



    const EmpListGET = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');

            const data = {
                user: userSchema?.usrnm,
                compid: JSON.parse(companyIdString),
                divid: JSON.parse(divIdString),
                masterid: userSchema?.masterid,
                month: month,
                department: DepType?._id
            }
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/salart_handleRouter/SalarySchemaLeaveGroupData`, data,
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );
            const currentYear = new Date().getFullYear();
            const sundays = calculateSundays(currentYear, month);

            const updatedRows = response?.data?.result.map(row => {
                const leaveOrGroup = row.leave_or_group.map(leave => {
                    if (leave.leave_name.discription === 'Weekly Off') {
                        return { ...leave, nos: leave.nos || sundays.toString() };
                    }
                    return leave;
                });
                return { ...row, leave_or_group: leaveOrGroup };
            });

            setRows(updatedRows);
            setPage(0);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const DepTypeGET = async () => {
        const userSchemaString = localStorage.getItem('userSchema');
        const userSchema = JSON.parse(userSchemaString);
        const companyIdString = localStorage.getItem('companyId');
        const divIdString = localStorage.getItem('divId');

        const data = {
            user: userSchema?.usrnm,
            compid: JSON.parse(companyIdString),
            divid: JSON.parse(divIdString),
            masterid: userSchema?.masterid
        };

        try {
            const token = localStorage.getItem('user-token');
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/employee/DepartmentTypeGET`,
                data,
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            setDepValues(response.data.lastEntryNo);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        const currentDate = new Date();
        const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
        setMonth(currentMonth);
    }, []);

    useEffect(() => {
        EmpListGET();
    }, [month, DepType])

    useEffect(() => {
        DepTypeGET();
    }, [])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const leaveTypes = [...new Set(rows.flatMap(emp => emp.leave_or_group.map(leave => leave.leave_name.discription)))];

    const handleSave = async () => {
        try {
            const invalidRows = rows.filter(row => {
                const totalNos = row.leave_or_group.reduce((sum, leaveEntry) => {
                    return sum + parseInt(leaveEntry.nos, 10);
                }, 0);
                const daysInMonth = new Date(new Date().getFullYear(), availableMonths.indexOf(month) + 1, 0).getDate(); // Get number of days in selected month

                return totalNos > daysInMonth;
            });

            if (invalidRows.length > 0) {
                const employeeNames = invalidRows.map(row => row.employee.full_name).join(', ');
                setActionMessage(`Total 'nos' for ${employeeNames} exceeds ${month} days.`);
                setErrorModalOpen(true);
                return;
            }

            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');

            const updatedRows = editedRows.map(index => {
                return rows[index];
            });

            const updatedData = updatedRows?.map(entry => {
                const { employee, leave_or_group, _id, net_salary } = entry;

                const updatedEmployee = {
                    _id: employee._id,
                    full_name: employee.full_name
                };

                // Transforming leave_or_group to have leave_name as _id
                const updatedLeaveOrGroup = leave_or_group?.map(leaveEntry => {
                    const { leave_name, nos, hrs, _id } = leaveEntry;
                    let obj = {
                        leave_name: leave_name._id, // Changing leave_name to _id
                        nos,
                        hrs
                    };
                    
                    if (_id) {
                        obj._id = _id;
                    }
                    
                    return obj;
                    
                });

                return {
                    employee: updatedEmployee._id,
                    leave_or_group: updatedLeaveOrGroup,
                    _id,
                    net_salary
                };
            });


            const data = {
                user: userSchema?.usrnm,
                compid: JSON.parse(companyIdString),
                divid: JSON.parse(divIdString),
                masterid: userSchema?.masterid,
                rows: updatedData,
                month,
            };

            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/salart_handleRouter/SalarySchemaAddLeaveGroupData`, data, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            })
                .then(response => {
                    if (response.data.status) {
                        setActionMessage(response.data.message)
                        setSuccessModalOpen(true);
                        setEditedRows([]);
                        setRows([]);
                        EmpListGET();
                    } else {
                        setEditedRows([]);
                        setActionMessage(response.data.message)
                        setErrorModalOpen(true)
                    }
                })
                .catch(error => {
                    setEditedRows([]);
                    console.log(error)
                    setActionMessage("Ooops an error occured.")
                    setErrorModalOpen(true)
                });
        } catch (error) {
            setEditedRows([]);
            console.error('Error saving data:', error);
        }
    };


    // const handleChange = (e, rowIdx, leaveType) => {
    //     const { name, value } = e.target;
    //     const pageIndex = page * rowsPerPage + rowIdx; // Calculate the actual index of the row in the full data

    //     const updatedRows = rows.map((row, idx) => {
    //         if (idx === pageIndex) {
    //             return {
    //                 ...row,
    //                 leave_or_group: row.leave_or_group.map(leave =>
    //                     leave.leave_name.discription === leaveType ? { ...leave, [name]: value } : leave
    //                 )
    //             };
    //         }
    //         return row;
    //     });

    //     setRows(updatedRows);

    //     if (!editedRows.includes(pageIndex)) {
    //         setEditedRows([...editedRows, pageIndex]);
    //     }
    // };

    const handleChange = (e, rowIdx, leaveType) => {
        const { name, value } = e.target;
        const pageIndex = page * rowsPerPage + rowIdx; // Calculate the actual index of the row in the full data

        const updatedRows = rows.map((row, idx) => {
            if (idx === pageIndex) {
                return {
                    ...row,
                    leave_or_group: row.leave_or_group.map(leave =>
                        leave.leave_name.discription === leaveType ? { ...leave, [name]: value } : leave
                    )
                };
            }
            return row;
        });

        setRows(updatedRows);

        if (!editedRows.includes(pageIndex)) {
            setEditedRows([...editedRows, pageIndex]);
        }

        if (leaveType !== 'Absent') {
            updateAbsentDays(updatedRows, pageIndex);
        }
    };

    const updateAbsentDays = (updatedRows, rowIndex) => {
        const daysInMonth = new Date(new Date().getFullYear(), availableMonths.indexOf(month) + 1, 0).getDate();

        // Update only the specific row at rowIndex
        const updatedRowsCopy = [...updatedRows];
        const rowToUpdate = updatedRowsCopy[rowIndex];

        let totalLeaveDays = 0;

        rowToUpdate.leave_or_group.forEach(leaveEntry => {
            if (leaveEntry.leave_name.discription !== 'Absent') {
                const leaveDays = leaveEntry.leave_name.discription === 'Half Day' ? parseFloat(leaveEntry.nos) * 0.5 : parseFloat(leaveEntry.nos);
                totalLeaveDays += leaveDays;
            }
        });

        const absentDays = daysInMonth - totalLeaveDays;

        // Find the "Absent" entry and update its 'nos' value
        const updatedLeaveOrGroup = rowToUpdate.leave_or_group.map(leaveEntry => {
            if (leaveEntry.leave_name.discription === 'Absent') {
                return { ...leaveEntry, nos: absentDays > 0 ? absentDays.toString() : '0' };
            } else {
                return leaveEntry;
            }
        });

        // Update the rowToUpdate with the updatedLeaveOrGroup
        rowToUpdate.leave_or_group = updatedLeaveOrGroup;

        // Update the specific row in updatedRowsCopy
        updatedRowsCopy[rowIndex] = rowToUpdate;

        // Update state with the modified rows
        setRows(updatedRowsCopy);
    };



    const handleKeyDown = (e, rowIndex, cellIndex) => {
        if (e.key === 'ArrowDown') {
            e.preventDefault(); // Prevent default scrolling behavior
            const nextRowIndex = rowIndex + 1;
            if (nextRowIndex < rows.length && nextRowIndex !== 10) {
                console.log(nextRowIndex);
                // Focus on the next row's input field
                inputRefs?.current[nextRowIndex][cellIndex]?.focus();
            }
        }
    };

    const handleKeyUp = (e, rowIndex, cellIndex) => {
        if (e.key === 'ArrowUp') {
            e.preventDefault(); // Prevent default scrolling behavior
            const nextRowIndex = rowIndex - 1;
            if (nextRowIndex < rows.length && nextRowIndex >= 0) {
                console.log(nextRowIndex);
                // Focus on the next row's input field
                inputRefs?.current[nextRowIndex][cellIndex]?.focus();
            }
        }
    };

    const handleTotalChange = (rowIdx) => {
        const totalNos = rows[rowIdx].leave_or_group.reduce((sum, leaveEntry) => {
            // Assume 'halfDay' is the identifier for a half-day leave
            console.log("*************",leaveEntry)
            const count = leaveEntry?.leave_name?.discription === 'Half Day' ? 0.5*leaveEntry?.nos : parseFloat(leaveEntry.nos, 10);
            return sum + count;
        }, 0);
    
        return totalNos;
    };
    

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        border: '2px solid',
        boxShadow: 24,
        padding: 16,
        minWidth: 300,
        maxWidth: 500,
        textAlign: 'center'
    };
    const handleSuccessModalClose = () => {
        setSuccessModalOpen(false);
    };

    const handleErrorModalClose = () => {
        setErrorModalOpen(false);
    };

    return (
        <>
            <Container maxWidth="xl" >
                <Paper sx={{ padding: '5px' }}>
                    <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                        <Grid item xs={2}>
                            <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: '10px', fontSize: '20px' }}>
                                Add Salary
                            </Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
                                <Grid item xs={6}>
                                    <Autocomplete
                                        value={DepType}
                                        onChange={(event, newValue) => {
                                            setDepType(newValue);
                                        }}
                                        options={DepValues}
                                        getOptionLabel={(option) => option.description}
                                        getOptionSelected={(option, value) => option._id === value}
                                        style={{ height: '35px', fontSize: '14px' }}
                                        renderInput={(params) => <TextField {...params} variant="outlined" size='small' label='Department'

                                            inputProps={{ ...params.inputProps, 'aria-label': 'Select Department Type', style: { paddingTop: '0px' } }}
                                            sx={{
                                                "& .MuiInputBase-root": { height: "35px" },
                                            }}
                                        />}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Autocomplete
                                        value={month}
                                        onChange={(event, newValue) => setMonth(newValue)}
                                        options={availableMonths}
                                        style={{ height: '35px', fontSize: '14px' }}
                                        renderInput={(params) => <TextField {...params} variant="outlined" size='small'

                                            inputProps={{ ...params.inputProps, 'aria-label': 'Select Department Type', style: { paddingTop: '0px' } }}
                                            sx={{
                                                "& .MuiInputBase-root": { height: "35px" },
                                            }}
                                        />}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>

                <TableContainer component={Paper}>
                    <Table size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 'bold', width: '15%', }}>Name</TableCell>
                                {/* <TableCell>{handleTotalChange(pageIndex)}</TableCell> */}
                                {leaveTypes.map(type => (
                                    <TableCell sx={{ fontWeight: 'bold' }} key={type}>{type}</TableCell>
                                ))}
                                <TableCell sx={{ fontWeight: 'bold', }}>Total</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIdx) => {
                                const pageIndex = page * rowsPerPage + rowIdx;

                                return (
                                    <TableRow key={row.employee._id} sx={{ padding: 0, backgroundColor: row.net_salary === "Y" ? '#f77281' : 'inherit', }}>
                                        {/* Render each row */}
                                        <TableCell sx={{ fontSize: '12px',  }}>{row.employee.full_name}</TableCell>
                                        {/* Render each cell */}
                                        {row.leave_or_group.map((leave, cellIndex) => (
                                            <TableCell key={cellIndex} sx={{ padding: 0 }}>
                                                <TextField
                                                    name="nos"
                                                    value={leave.nos}
                                                    onChange={(e) => handleChange(e, rowIdx, leave.leave_name.discription)}
                                                    onFocus={(e) => e.target.select()}
                                                    onKeyDown={(e) => handleKeyDown(e, rowIdx, cellIndex)}
                                                    onKeyUp={(e) => handleKeyUp(e, rowIdx, cellIndex)}
                                                    inputRef={el => {
                                                        if (!inputRefs.current[rowIdx]) {
                                                            inputRefs.current[rowIdx] = [];
                                                        }
                                                        inputRefs.current[rowIdx][cellIndex] = el;
                                                    }}
                                                    size='small'
                                                    InputProps={{ sx: { textAlign: 'center', height: '25px' }, readOnly: row.net_salary === "Y" }}
                                                    sx={{ backgroundColor: row.net_salary === "Y" ? '#f77281' : 'inherit', }}
                                                />
                                            </TableCell>
                                        ))}
                                        <TableCell sx={{ backgroundColor: row.net_salary === "Y" ? '#f77281' : 'yellowgreen' }}>{handleTotalChange(pageIndex)}</TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    count={rows.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    rowsPerPageOptions={[]} // Empty array means no options to choose from
                                />


                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
                {editedRows.length > 0 && (
                    <Button variant="contained" color="primary" onClick={handleSave}>
                        Save All
                    </Button>
                )}
                <Modal
                    open={successModalOpen}
                    onClose={handleSuccessModalClose}
                    aria-labelledby="success-modal-title"
                    aria-describedby="success-modal-description"
                >
                    <div style={{ ...modalStyle, borderColor: '#007bff' }}>
                        <h2 id="success-modal-title" style={{ color: '#007bff' }}>Success!</h2>
                        <p id="success-modal-description">{actionMessage}</p>
                        <Button variant="contained" onClick={handleSuccessModalClose} style={{ backgroundColor: '#007bff', color: 'white' }}>Close</Button>
                    </div>
                </Modal>
                <Modal
                    open={errorModalOpen}
                    onClose={handleErrorModalClose}
                    aria-labelledby="error-modal-title"
                    aria-describedby="error-modal-description"
                >
                    <div style={{ ...modalStyle, borderColor: '#ff0000' }}>
                        <h2 id="error-modal-title" style={{ color: '#ff0000' }}>Error!</h2>
                        <p id="error-modal-description">{actionMessage}.</p>
                        <Button variant="contained" onClick={handleErrorModalClose} style={{ backgroundColor: '#ff0000', color: 'white' }}>Close</Button>
                    </div>
                </Modal>
            </Container>
        </>
    );
};

export default EditableTable;


