import React, { useState, useEffect } from 'react';
import { Select, MenuItem, Accordion, AccordionDetails, AccordionSummary, Grid, Chip, Box, Typography, Paper, Container, Divider, Button } from '@mui/material';
import MUIDataTable from "mui-datatables";
import Modal from '@mui/material/Modal';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress for the loader
import axios from 'axios';
import profileImage from './3fb3ce89059bd129352cbee229d68952.jpg';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import FileDownloadIcon from '@mui/icons-material/FileDownload'; // Import the FileDownloadIcon
import { useRef, useLayoutEffect } from 'react';

function StaffDetails() {
    const [StaffData, setStaffData] = useState({});
    const [isLoading, setIsLoading] = useState(true); // State variable to manage loading state
    const [activeStatus, setActiveStatus] = useState('Active'); // Initial state for active status
    const [searchTerm, setSearchTerm] = useState(''); // Initial state for active status
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [actionMessage, setActionMessage] = useState('');
    const [file, setfile] = useState(null);

    const navigate = useNavigate();

    const lastContentRef = useRef(null);
    useLayoutEffect(() => {
        if (lastContentRef.current) {
            lastContentRef.current.style.marginBottom = '70px';
        }
    }, []);


    const handleClick = (_id, pp, full_name, machine_code, e) => {
        // Data to send to TargetComponent
        const dataToSend = { id: _id };
        // localStorage.removeItem('StaffDetailEmpID');
        localStorage.setItem('StaffDetailEmpID', _id);
        localStorage.setItem('EPP', pp);
        localStorage.setItem('StaffDetailEmpName', full_name);
        localStorage.setItem('StaffDetailEmpMid', machine_code);
        localStorage.setItem('StaffDetailEmpStatus', 'Android');

        if (e.metaKey || e.ctrlKey) {
            const dataString = encodeURIComponent(JSON.stringify(dataToSend));
            const url = `/staffDetail/profile?data=${dataString}`;
            window.open(url, '_blank');
        } else {
            navigate('/staffDetail/profile', { state: dataToSend });
        }
        // navigate('/staffDetail/profile', { state: dataToSend });
    };

    const groupDataByDepartment = (data) => {
        const groupedData = data.reduce((acc, obj) => {
            console.log(obj)
            const description = obj?.department?.discription || 'Unknown Department';
            if (!acc[description]) {
                acc[description] = [];
            }
            acc[description].push(obj);
            return acc;
        }, {});

        // if (groupedData['Unknown department']) {
        //     const unknownDept = groupedData['Unknown department'];
        //     delete groupedData['Unknown department'];
        //     groupedData['Unknown department'] = unknownDept;
        // }

        return groupedData;
    };

    function getImageUrlFromPath(imagePath) {
        // Remove leading './public' from the image path
        const relativeImagePath = imagePath.replace('./public', '');

        // Return the dynamically generated image URL
        return relativeImagePath;
    }

    const fetchData = async () => {
        try {
            setIsLoading(true); // Set loading state to true
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const employeeIDD = JSON.parse(localStorage.getItem('EmployeeIDD'))

            const dataToSend = {
                usrnm: userSchema?.usrnm,
                compid: JSON.parse(companyIdString),
                divid: JSON.parse(divIdString),
                masterid: userSchema?.masterid,
                employeeIDD: employeeIDD ? employeeIDD : null
            };
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/attendance/GetattendanceDetailsold?active=${activeStatus}&search=${searchTerm}`, dataToSend, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            });
            if (response) {
                setIsLoading(false); // Set loading state to true
            }
            setStaffData(groupDataByDepartment(response.data.data))
            console.log(groupDataByDepartment(response.data.data));
        } catch (error) {
            setIsLoading(false); // Set loading state to true
            console.error('Error fetching data:', error);
        }
    };

    const handleFileChangeDB = (event) => {
        try {
            setIsLoading(true)
            const file = event.target.files[0];
            console.log('Selected file:', file);
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');

            // Get JWT token from localStorage
            const token = localStorage.getItem('user-token');

            // Create a FormData object
            const formData = new FormData();
            formData.append('ImportEmpDB', event.target.files[0]); // Append the file to FormData with key 'file'
            formData.append('user', userSchema?.usrnm);
            formData.append('compid', JSON.parse(companyIdString));
            formData.append('divid', JSON.parse(divIdString));
            formData.append('masterid', userSchema?.masterid);

            // Make a POST request to your API endpoint
            axios.post(`${process.env.REACT_APP_API_URL}/employee/ImportEmpDB`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Set the Content-Type header to multipart/form-data
                    authorization: `Bearer ${token}`
                },
            })
                .then((response) => {
                    // Handle success response
                    if (response.data.status) {
                        setActionMessage(response.data.message)
                        setSuccessModalOpen(true);
                    } else {
                        setActionMessage(response.data.message)
                        // handleCancel();
                        setErrorModalOpen(true)

                    }
                })
                .catch((error) => {
                    setActionMessage(error.message)
                    setErrorModalOpen(true)
                    console.error('Error uploading file:', error);
                });
        } catch (error) {
            setActionMessage(error.message)
            setErrorModalOpen(true)
            console.error('Error uploading file:', error);
        }
        finally {
            event.target.value = null; // Reset file input
            setIsLoading(false); // Set loading state to false regardless of success or failure
        }
    };

    const handleFileChange = (event) => {
        try {
            setIsLoading(true)
            const file = event.target.files[0];
            console.log('Selected file:', file);
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');

            // Get JWT token from localStorage
            const token = localStorage.getItem('user-token');

            // Create a FormData object
            const formData = new FormData();
            formData.append('employee_mast_import', event.target.files[0]); // Append the file to FormData with key 'file'
            formData.append('user', userSchema?.usrnm);
            formData.append('compid', JSON.parse(companyIdString));
            formData.append('divid', JSON.parse(divIdString));
            formData.append('masterid', userSchema?.masterid);

            // Make a POST request to your API endpoint
            axios.post(`${process.env.REACT_APP_API_URL}/employeegrade_master/ImportFile`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Set the Content-Type header to multipart/form-data
                    authorization: `Bearer ${token}`
                },
            })
                .then((response) => {
                    // Handle success response
                    if (response.data.status) {
                        setActionMessage(response.data.message)
                        setSuccessModalOpen(true);
                    } else {
                        setActionMessage(response.data.message)
                        // handleCancel();
                        setErrorModalOpen(true)

                    }
                })
                .catch((error) => {
                    setActionMessage(error.message)
                    setErrorModalOpen(true)
                    console.error('Error uploading file:', error);
                });
        } catch (error) {
            setActionMessage(error.message)
            setErrorModalOpen(true)
            console.error('Error uploading file:', error);
        }
        finally {
            event.target.value = null; // Reset file input
            setIsLoading(false); // Set loading state to false regardless of success or failure
        }
    };
    const handleSalStruFileChange = (event) => {
        try {
            setIsLoading(true)
            const file = event.target.files[0];
            console.log('Selected file:', file);
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');

            // Get JWT token from localStorage
            const token = localStorage.getItem('user-token');

            // Create a FormData object
            const formData = new FormData();
            formData.append('salary_structure_import', event.target.files[0]); // Append the file to FormData with key 'file'
            formData.append('user', userSchema?.usrnm);
            formData.append('compid', JSON.parse(companyIdString));
            formData.append('divid', JSON.parse(divIdString));
            formData.append('masterid', userSchema?.masterid);

            // Make a POST request to your API endpoint
            axios.post(`${process.env.REACT_APP_API_URL}/employeegrade_master/ImportSalStruc`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Set the Content-Type header to multipart/form-data
                    authorization: `Bearer ${token}`
                },
            })
                .then((response) => {
                    // Handle success response
                    if (response.data.status) {
                        setActionMessage(response.data.message)
                        setSuccessModalOpen(true);
                    } else {
                        setActionMessage(response.data.message)
                        // handleCancel();
                        setErrorModalOpen(true)

                    }
                })
                .catch((error) => {
                    setActionMessage(error.message)
                    setErrorModalOpen(true)
                    console.error('Error uploading file:', error);
                });
        } catch (error) {
            setActionMessage(error.message)
            setErrorModalOpen(true)
            console.error('Error uploading file:', error);
        }
        finally {
            event.target.value = null; // Reset file input
            setIsLoading(false); // Set loading state to false regardless of success or failure
        }
    };

    const handleEmpoyeeFileDownload = async () => {
        const token = localStorage.getItem('user-token');
        const config = {
            url: `${process.env.REACT_APP_API_URL}/employee/EmpMasterFileDownload`,
            method: 'GET',
            responseType: 'blob',
            headers: {
                Authorization: `Bearer ${token}`, // Send the token in the Authorization header
            },
        };

        axios(config)
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Employee_Master_Sheet.xls');
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => {
                console.error('Error downloading file:', error);
                // Handle error
            });
    };


    useEffect(() => {
        fetchData();
    }, [activeStatus, searchTerm]);

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        border: '2px solid',
        boxShadow: 24,
        padding: 16,
        minWidth: 300,
        maxWidth: 500,
        textAlign: 'center'
    };
    const handleSuccessModalClose = () => {
        setSuccessModalOpen(false);
    };

    const handleErrorModalClose = () => {
        setErrorModalOpen(false);
    };

    return (
        <>
            <Container maxWidth="xl" ref={lastContentRef}>
                <Paper elevation={3} style={{ padding: '20px', marginBottom: '5px', background: 'linear-gradient(135deg, #FFFFFF,#60a5f2,#FFFFFF)', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                        <div>
                            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: 'black' }}>
                                Staff Details
                            </Typography>
                        </div>
                        {!localStorage.getItem('EmployeeIDD') ? (
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                                <input
                                    type="text"
                                    placeholder="Search..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    style={{ marginLeft: '10px', marginRight: '10px', padding: '8px', borderRadius: '14px', border: '1px solid #ccc', width: '200px', height: '22px' }}
                                />
                                <Select
                                    value={activeStatus} // Use state value for the selected value
                                    onChange={(e) => { setActiveStatus(e.target.value) }} // Call handleChange function when the value changes
                                    sx={{ width: '120px', borderRadius: '14px', borderColor: 'red', marginLeft: '10px', height: '40px' }} // Apply some styling
                                >
                                    <MenuItem value="Active">Active</MenuItem>
                                    <MenuItem value="InActive">InActive</MenuItem>
                                    <MenuItem value="All">All</MenuItem>
                                </Select>
                            </div>
                        ) : null}
                    </div>
                </Paper>
                <Paper elevation={3} sx={{ padding: 1, marginBottom: 1 }}>
                    {isLoading ? (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        // height="100vh" // Adjust height as needed
                        >
                            <CircularProgress size={80} /> {/* Adjust size as needed */}
                        </Box>
                    ) : (
                        <Box>
                            {Object.entries(StaffData).length === 0 ? (
                                <Typography variant="body1">No data found</Typography>
                            ) : (
                                Object.entries(StaffData).map(([department, employees], index) => (
                                    <Accordion key={department} sx={{ marginBottom: 0 }} defaultExpanded={index === 0}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ height: '17px' }}>
                                            <Typography variant="subtitle1" sx={{ marginRight: 1, }}>{department}</Typography>
                                            <Chip label={`${employees.length}`} color="default" />
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Box sx={{ width: '100%' }}>
                                                {employees.map(employee => (
                                                    <Grid container key={employee._id} alignItems="center" sx={{ marginBottom: 0 }}>
                                                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                            {/* <img src={getImageUrlFromPath("./public/uploads/employees/1709040696427656ad0d80ccd247bb8cdcc86.png")} alt="Profile" style={{ width: '50px', height: '50px', borderRadius: '50%', marginRight: '10px', border: '2px solid white' }} /> */}
                                                            <img src={getImageUrlFromPath(`${employee.filename[0]}`)} alt="Profile" style={{ width: '50px', height: '50px', borderRadius: '50%', marginRight: '10px', border: '2px solid white' }} />

                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                <Typography variant="subtitle1" sx={{ marginRight: 2 }}>{employee.full_name}</Typography>
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Typography variant="subtitle1" color="textSecondary">{employee.machine_code}</Typography>
                                                        </Grid>
                                                        <Grid item xs={3} sx={{ textAlign: 'right' }}>
                                                            <Button variant="contained" color="primary" size='small' onClick={(e) => handleClick(employee._id, employee.filename[0], employee?.full_name, employee?.machine_code, e)}
                                                                sx={{ backgroundColor: '#2196f3', color: '#fff', borderRadius: '20px', padding: '7px 10px', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', '&:hover': { backgroundColor: '#0d8bf5' } }}
                                                            >
                                                                Profile
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                ))
                            )}
                        </Box>

                    )}
                </Paper>
                <Modal
                    open={successModalOpen}
                    onClose={handleSuccessModalClose}
                    aria-labelledby="success-modal-title"
                    aria-describedby="success-modal-description"
                >
                    <div style={{ ...modalStyle, borderColor: '#007bff' }}>
                        <h2 id="success-modal-title" style={{ color: '#007bff' }}>Success!</h2>
                        <p id="success-modal-description">{actionMessage}</p>
                        <Button variant="contained" onClick={handleSuccessModalClose} style={{ backgroundColor: '#007bff', color: 'white' }}>Close</Button>
                    </div>
                </Modal>
                <Modal
                    open={errorModalOpen}
                    onClose={handleErrorModalClose}
                    aria-labelledby="error-modal-title"
                    aria-describedby="error-modal-description"
                >
                    <div style={{ ...modalStyle, borderColor: '#ff0000' }}>
                        <h2 id="error-modal-title" style={{ color: '#ff0000' }}>Error!</h2>
                        <p id="error-modal-description">{actionMessage}.</p>
                        <Button variant="contained" onClick={handleErrorModalClose} style={{ backgroundColor: '#ff0000', color: 'white' }}>Close</Button>
                    </div>
                </Modal>
                <Paper style={{ padding: '16px', position: 'fixed', bottom: '16px', zIndex: 1000, width: '60%', left: '0', right: '0', margin: '0 auto' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }}>
                        <Tooltip title="Download Employee File" arrow>
                            <Button variant='contained' color='success' onClick={() => { handleEmpoyeeFileDownload() }} startIcon={<FileDownloadIcon />} sx={{ marginRight: '10px', borderRadius: '14px', borderColor: 'red', }}>D</Button>
                        </Tooltip>

                        {/* <input
                            accept=".xlsx, .xls, .mdb, .accdb,"
                            id="import-db"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={handleFileChangeDB}
                        />
                        <label htmlFor="import-db">
                            <Button
                                variant="contained"
                                component="span"
                                sx={{
                                    marginRight: '10px',
                                    borderRadius: '14px',
                                    borderColor: 'red',
                                }}
                            >
                                Import DB
                            </Button>
                        </label> */}

                        <input
                            accept=".xlsx, .xls"
                            // accept=".xlsx, .xls, .mdb, .accdb,"
                            id="import-profile"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                        <label htmlFor="import-profile">
                            <Tooltip title="Import Employee Master" arrow>
                                <Button
                                    variant="contained"
                                    component="span"
                                    sx={{
                                        marginRight: '10px',
                                        borderRadius: '14px',
                                        borderColor: 'red',
                                    }}
                                >
                                    Import Profile
                                </Button>
                            </Tooltip>
                        </label>

                        <input
                            accept=".xlsx, .xls"
                            id="import-sal-struc"
                            type="file"
                            style={{ display: 'none' }}
                            onChange={handleSalStruFileChange}
                        />
                        <label htmlFor="import-sal-struc">
                            <Tooltip title="Import Salary Structure" arrow>
                                <Button
                                    variant="contained"
                                    component="span"
                                    sx={{
                                        marginRight: '10px',
                                        borderRadius: '14px',
                                        borderColor: 'red',
                                    }}
                                >
                                    Import Salary Structure
                                </Button>
                            </Tooltip>
                        </label>
                        <Button variant='contained' sx={{ mr: 1, borderRadius: '14px' }}
                            // onClick={() => { navigate('/staffDetail/EmployeesList') }}
                            onClick={(e) => {
                                if (e.metaKey || e.ctrlKey) {
                                    const url = `/staffDetail/EmployeesList`;
                                    window.open(url, '_blank');
                                } else {
                                    navigate('/staffDetail/EmployeesList')
                                }
                            }}
                        >
                            Employees List
                        </Button>
                        <Button variant='contained' sx={{ mr: 1, borderRadius: '14px' }}
                            // onClick={() => { navigate('/HrMaster/salaryMaster/shiftRole') }}
                            onClick={(e) => {
                                if (e.metaKey || e.ctrlKey) {
                                    const url = `/HrMaster/salaryMaster/shiftRole`;
                                    window.open(url, '_blank');
                                } else {
                                    navigate('/HrMaster/salaryMaster/shiftRole')
                                }
                            }}
                        >
                            Add Employees
                        </Button>
                    </div>
                </Paper>
            </Container>
        </>
    );
}

export default StaffDetails;
