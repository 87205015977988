import React, { useState, useEffect } from 'react';
import { Modal, Typography, Grid, Box, Button, Paper, TextField, Divider, CircularProgress, Select, MenuItem, Container } from '@mui/material';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import axios from 'axios';
import MUIDataTable from "mui-datatables";
import Autocomplete from '@mui/material/Autocomplete';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid'; // Import DataGrid from MUI

import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useRef, useLayoutEffect } from 'react';

import moment from 'moment';

const DetailedLoanReport = () => {
    const [selectionRange, setSelectionRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });

    const [isOpen, setIsOpen] = useState(false);
    const dateRangePickerRef = useRef(null);

    const handleSelect = (ranges) => {
        setSelectionRange(ranges.selection);
        setIsOpen(false); // Close the date picker when a selection is made
    };

    const handleClickOutside = (event) => {
        if (dateRangePickerRef.current && !dateRangePickerRef.current.contains(event.target)) {
            setIsOpen(false); // Close the date picker when clicked outside
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDateRangePicker = () => {
        setIsOpen(!isOpen);
    };

    const formatDate = (date) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };


    const [searchText, setSearchText] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0); // Initialize currentPage to 0
    const [totalCount, setTotalCount] = useState(0); // State to hold total count of rows
    const [sumdata, setSumData] = useState([]);
    const [CatVal, setCatVal] = useState([]);
    const [startDate, setstartDate] = React.useState(dayjs());
    const [endDate, setendDate] = React.useState(dayjs());
    const [Type, setType] = useState('Balance');

    const fetchSummaryData = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const masterid = userSchema?.masterid;
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const token = localStorage.getItem('user-token');
            console.log(startDate)
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/loan_reqs/DetailedLoanReport`,
                {
                    compid: JSON.parse(companyIdString),
                    divid: JSON.parse(divIdString),
                    masterid,
                    // startDate,
                    // endDate,
                    Type,
                    startDate: selectionRange.startDate,
                    endDate: selectionRange.endDate,
                },
                {
                    params: {
                        searchText,
                        rowsPerPage,
                        currentPage,
                    },
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );
            setSumData(response.data.transformedData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    useEffect(() => {
        fetchSummaryData()
        // }, [startDate, endDate, SelectedDay])
    }, [Type])

    const columns = [
        { field: "full_name", headerName: "Employee", flex: 1, },
        { field: "loan_date", headerName: "Date", flex: 1 },
        { field: "loandc", headerName: "Loan", flex: 1 },
        { field: "loanamount", headerName: "Loan Amount", flex: 1 },
        { field: "loaninstallment", headerName: "Installment", flex: 1 },
        { field: "paidbalance", headerName: "Paid Amount", flex: 1 },
        { field: "loanbalance", headerName: "Remaining Amount", flex: 1 },
    ];

    function convertToIndianTime(dateString) {
        const padZero = (num) => (num < 10 ? '0' : '') + num;

        // Convert the string to a Date object
        let date = new Date(dateString);

        // Add 5.5 hours to the date
        date.setHours(date.getHours() + 5, date.getMinutes() + 30);

        // Format the date to "dd-mm-yyyy hh:mm:ss"
        const day = padZero(date.getUTCDate());
        const month = padZero(date.getUTCMonth() + 1); // Months are zero-based
        const year = date.getUTCFullYear();

        const hours = padZero(date.getUTCHours());
        const minutes = padZero(date.getUTCMinutes());
        const seconds = padZero(date.getUTCSeconds());

        return `${day}-${month}-${year}`;
    }

    const Newsumdata = sumdata.map((item, index) => ({
        ...item,
        id: index,
        paidbalance: (parseFloat(item?.loanamount || 0) - parseFloat(item?.loanbalance || 0)) !== 0 ? parseFloat(item?.loanamount || 0) - parseFloat(item?.loanbalance || 0) : '',        
        loan_date: convertToIndianTime(item?.loan_date),
        loanamount: item?.loanamount>0?item?.loanamount:''
    }));
    return (
        <>
            <Container maxWidth="xl" >

                <Grid container spacing={2} sx={{display:'flex', justifyContent:'space-between'}}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h5" sx={{ color: 'black', fontWeight: 'bold', padding: '10px' }}>
                        Detailed Loan Report
                        </Typography>
                    </Grid>
                    {/* <Grid item xs={12} md={2}>
                        <Autocomplete
                            value={Type}
                            onChange={(e, newValue) => setType(newValue)}
                            options={["All", "Balance"]}
                            disableClearable
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    inputProps={{ ...params.inputProps, 'aria-label': 'Select Day' }}
                                    variant="outlined"
                                    size="small"
                                // fullWidth
                                />
                            )}
                        />
                    </Grid> */}
                </Grid>

                <DataGrid
                    rows={Newsumdata}
                    columns={columns}
                    initialState={{
                        ...Newsumdata.initialState,
                        pagination: { paginationModel: { pageSize: 10 } },

                    }}
                    pageSizeOptions={[10, 25, 100]}
                    pagination
                    autoHeight
                    checkboxSelection={false}
                    disableSelectionOnClick
                    density="compact"
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                        },
                    }}
                />
                {/* </Box> */}
            </Container>
        </>
    );
};

export default DetailedLoanReport;
