import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import MUIDataTable from "mui-datatables";
import Modal from '@mui/material/Modal';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid'; // Import DataGrid from MUI
import { Grid, Paper, Typography, FormControl, TextField, Divider, Autocomplete } from '@mui/material';
import { useRef, useLayoutEffect } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useLocation, useNavigate } from 'react-router-dom';

function VisitorList() {
    const navigate = useNavigate();

    const [name, setname] = useState('');
    const [mobile_no, setmobile_no] = useState('');
    const [email, setemail] = useState('');
    const [remark, setremark] = useState('');
    const [DesignationType, setDesignationType] = useState([]);
    const [DesignationTypeValue, setDesignationTypeValue] = useState('');

    const [error, setError] = useState('');
    const [type, settype] = useState('All');
    const [data, setdata] = useState([]);
    const [openModal, setOpenModal] = useState(false); // State to control the modal visibility
    const [deleteIndex, setDeleteIndex] = useState(null); // State to store the index of item to be deleted
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [ActionMessage, setActionMessage] = useState('');
    const [editID, setEditID] = useState();
    const [selectionRange, setSelectionRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });

    const [tempStartDate, setTempStartDate] = useState(new Date());
    const [tempEndDate, setTempEndDate] = useState(new Date());
    const dateRangePickerRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleSelect = (ranges) => {
        // Update the tempStartDate and tempEndDate independently
        const { startDate, endDate } = ranges.selection;
        if (startDate && endDate) {
            if (startDate <= tempEndDate) {
                setTempStartDate(startDate);
            }
            if (endDate >= tempStartDate) {
                setTempEndDate(endDate);
            }
        }
    };

    const applyDateRange = () => {
        setSelectionRange({
            startDate: tempStartDate,
            endDate: tempEndDate,
            key: 'selection',
        });
        setIsOpen(false);
    };

    const handleClickOutside = (event) => {
        if (dateRangePickerRef.current && !dateRangePickerRef.current.contains(event.target)) {
            setIsOpen(false); // Close the date picker when clicked outside
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDateRangePicker = () => {
        setIsOpen(!isOpen);
    };

    const formatDates = (date) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const lastContentRef = useRef(null);
    useLayoutEffect(() => {
        if (lastContentRef.current) {
            lastContentRef.current.style.marginBottom = '80px';
        }
    }, []);

    const handleErrorModalClose = () => {
        setErrorModalOpen(false);
    };

    const handleSuccessModalClose = () => {
        setSuccessModalOpen(false);
    };

    const handleDelete = (INFO) => {
        setDeleteIndex(INFO._id);
        setOpenModal(true);
    };

    const handleModalDelete = async () => {
        try {
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/visitor/VisitorDELETE/${deleteIndex}`, {}, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            });
            handleCloseModal();
            fetchData();
        } catch (error) {
            console.error('Error deleting item:', error);
        }
    };


    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const fetchData = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const masterid = userSchema?.masterid;
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/visitor/VisitorsListGET`,
                {
                    params: {
                        masterid,
                        compid: JSON.parse(companyIdString),
                        divid: JSON.parse(divIdString),
                        startDate: selectionRange.startDate,
                        endDate: selectionRange.endDate,
                        type
                    },
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            console.log('API Response:', response.data.lastEntryNo);
            setdata(response.data.lastEntryNo)

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData(); // Call fetchData when component mounts or when searchText, rowsPerPage, or currentPage changes
        // }, [searchText, rowsPerPage, currentPage]);
    }, [selectionRange, type]);

    function convertToIndianTime(dateString) {
        const padZero = (num) => (num < 10 ? '0' : '') + num;

        // Convert the string to a Date object
        let date = new Date(dateString);

        // Add 5.5 hours to the date
        date.setHours(date.getHours() + 5, date.getMinutes() + 30);

        // Format the date to "dd-mm-yyyy hh:mm:ss"
        const day = padZero(date.getUTCDate());
        const month = padZero(date.getUTCMonth() + 1); // Months are zero-based
        const year = date.getUTCFullYear();

        const hours = padZero(date.getUTCHours());
        const minutes = padZero(date.getUTCMinutes());
        const seconds = padZero(date.getUTCSeconds());

        return `${day}-${month}-${year}`;
    }

    const dataWithSerialNumbers = data.map((item, index) => ({
        ...item,
        serialNumber: index + 1, // Serial number starts from 1
        id: index + 1, // Serial number starts from 1
        want_to_meet: item?.want_to_meet?.attendant_name,
        date: convertToIndianTime(item?.date),
        refNo: `${item?.c_j_s_p}/${item?.vouc_code}`,
        filepath: item?.filepath[0]
    }));

    function getImageUrlFromPath(imagePath) {
        // Remove leading './public' from the image path
        const relativeImagePath = imagePath.replace('./public', '');

        // Return the dynamically generated image URL
        return relativeImagePath;
    }

    const columns = [
        { field: "refNo", headerName: "Ref. No.", flex: 1, }, // Serial number column
        { field: "date", headerName: "Date", flex: 1, },
        { field: "name", headerName: "Name", flex: 1, },
        {
            field: "filepath",
            headerName: "Image",
            flex: 1,
            renderCell: (params) => (
                <img src={getImageUrlFromPath(`${params.value}`)} alt="Profile" style={{ width: 50, height: 50, borderRadius: '50%' }} />
            )
        },
        { field: "mobile", headerName: "Mobile", flex: 1, },
        { field: "company", headerName: "Company", flex: 1, },
        // { field: "want_to_meet", headerName: "Name", flex: 1, },
        { field: "time_in", headerName: "Time In", flex: 1, },
        { field: "out", headerName: "Out Time", flex: 1, },
        { field: "purpose", headerName: "Purpose", flex: 1, },
        {
            field: "edit",
            headerName: "Edit",
            flex: 1,
            renderCell: (params) => (
                <Button variant="text" color="primary" size='small'
                    onClick={(e) => handleEdit(params, e)}><EditIcon fontSize="small" /></Button>
            )

        },
        {
            field: "delete",
            headerName: "Delete",
            flex: 1,
            renderCell: (params) => (
                <Button variant="text" color="error" size='small' onClick={() => handleDelete(params.row)}><DeleteIcon fontSize="small" /></Button>
            )
        }
    ];

    const handleEdit = (params, e) => {
        const rowIndex = params.api.getAllRowIds().indexOf(params.id); // Using getAllRowIds to get the index of the row by id
        const dataToSend = { data: data[rowIndex] };
        // navigate('/visitor/visitors_edit', { state: dataToSend });
        if (e.metaKey || e.ctrlKey) {
            const dataString = encodeURIComponent(JSON.stringify(dataToSend));
            const url = `/visitor/visitors_edit?data=${dataString}`;
            window.open(url, '_blank');
        } else {
            navigate('/visitor/visitors_edit', { state: dataToSend });
        }
    };
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        border: '2px solid',
        boxShadow: 24,
        padding: 16,
        minWidth: 300,
        maxWidth: 500,
        textAlign: 'center'
    };

    const generateFileName = () => {
        const now = new Date();
        const date = now.toLocaleDateString().replace(/\//g, '-'); // Format date as YYYY-MM-DD
        const time = now.toLocaleTimeString().replace(/:/g, '-');  // Format time as HH-MM-SS

        return `VISITORS LIST_${date}_${time}`;
    };

    return (
        <>
            <Container maxWidth="xl" style={{ marginTop: '10px' }} ref={lastContentRef}>
                {/* <MUIDataTable title={""} data={dataWithSerialNumbers} columns={columns} options={options} /> */}
                <div style={{ marginTop: '10px' }}>
                    <Grid container spacing={2} alignItems="center" justifyContent="flex-start" style={{ width: '100%' }}>

                        <Grid item xs={2} sx={{ marginBlockStart: '0px' }}>
                            <Typography variant="h5" sx={{ color: 'black', fontWeight: 'bold' }}>
                                Visitors List
                            </Typography>
                        </Grid>
                        <Grid item xs={3} sx={{ marginBlockStart: '0px' }} >
                            <div style={{ display: 'flex', justifyContent: 'center', position: 'relative', padding: '0px' }}>
                                <TextField
                                    type="text"
                                    size="small"
                                    label="Select Range"
                                    value={`${formatDates(selectionRange.startDate)} - ${formatDates(selectionRange.endDate)}`}
                                    onFocus={toggleDateRangePicker}
                                    readOnly
                                    fullWidth
                                    InputProps={{
                                        disableUnderline: true, // Remove default underline
                                        style: { cursor: 'pointer', textAlign: 'center' } // Center-align text and change cursor to pointer when hovering
                                    }}
                                    InputLabelProps={{
                                        shrink: true, // Ensure the label doesn't float above the input when value is present
                                    }}
                                    onClick={toggleDateRangePicker} // Open DateRangePicker on click
                                />
                                {isOpen && (
                                    <div style={{ position: 'absolute', zIndex: 999, marginRight: '0px' }} ref={dateRangePickerRef}>
                                        <DateRangePicker
                                            ranges={[{ startDate: tempStartDate, endDate: tempEndDate, key: 'selection' }]}
                                            onChange={handleSelect}
                                        />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={applyDateRange}
                                            fullWidth
                                            style={{ marginTop: '10px' }}
                                        >
                                            Apply
                                        </Button>
                                    </div>
                                )}

                            </div>
                        </Grid>
                        <Grid item xs={2} sx={{ marginBlockStart: '0px' }} >
                            <FormControl style={{ width: '100%' }}>
                                <Autocomplete
                                    value={type}
                                    onChange={(event, newValue) => {
                                        settype(newValue);
                                    }}
                                    options={['In', 'Out', 'All']}
                                    style={{ height: '40px', fontSize: '14px' }}
                                    renderInput={(params) => <TextField {...params} variant="outlined"

                                        inputProps={{ ...params.inputProps, 'aria-label': 'Select Department Type', style: { paddingTop: '0px' } }}
                                        sx={{
                                            "& .MuiInputBase-root": { height: "40px" },
                                        }}
                                    />}
                                />
                            </FormControl>
                        </Grid>

                    </Grid>


                    <DataGrid
                        rows={dataWithSerialNumbers}
                        columns={columns}
                        initialState={{
                            ...data.initialState,
                            pagination: { paginationModel: { pageSize: 10 } },

                        }}
                        pageSizeOptions={[10, 25, 100]}
                        pagination
                        autoHeight
                        checkboxSelection={false}
                        disableSelectionOnClick
                        density="compact"
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                csvOptions: { fileName: generateFileName() },
                            },
                        }}
                    />
                </div>
            </Container >
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, width: 400 }}>
                    <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
                        Confirm Deletion
                    </Typography>
                    <Typography id="modal-description" sx={{ mt: 2 }}>
                        Are you sure you want to delete this item?
                    </Typography>
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={handleCloseModal} color="primary" sx={{ mr: 2 }}>
                            Cancel
                        </Button>
                        <Button onClick={handleModalDelete} variant="contained" color="error">
                            Delete
                        </Button>
                    </Box>
                </Box>
            </Modal>


            <Modal
                open={successModalOpen}
                onClose={handleSuccessModalClose}
                aria-labelledby="success-modal-title"
                aria-describedby="success-modal-description"
            >
                <div style={{ ...modalStyle, borderColor: '#007bff' }}>
                    <h2 id="success-modal-title" style={{ color: '#007bff' }}>Success!</h2>
                    <p id="success-modal-description">{ActionMessage}</p>
                    <Button variant="contained" onClick={handleSuccessModalClose} style={{ backgroundColor: '#007bff', color: 'white' }}>Close</Button>
                </div>
            </Modal>

            <Modal
                open={errorModalOpen}
                onClose={handleErrorModalClose}
                aria-labelledby="error-modal-title"
                aria-describedby="error-modal-description"
            >
                <div style={{ ...modalStyle, borderColor: '#ff0000' }}>
                    <h2 id="error-modal-title" style={{ color: '#ff0000' }}>Error!</h2>
                    <p id="error-modal-description">{ActionMessage}.</p>
                    <Button variant="contained" onClick={handleErrorModalClose} style={{ backgroundColor: '#ff0000', color: 'white' }}>Close</Button>
                </div>
            </Modal>



        </>
    );
}

export default VisitorList;
