import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { Select, MenuItem } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import Divider from '@mui/material/Divider';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress for the loader
import { useLocation } from 'react-router-dom';
import ProfileNav from './profileNav';
import ProfileHeader from './profileheader';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import useEnhancedEffect from '@mui/material/utils/useEnhancedEffect';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';

function ProfileAttendance() {
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [isLoading, setIsLoading] = useState(true); // State variable to manage loading state
  const [AttendanceData, setAttendanceData] = useState([]);
  const [AttendanceCount, setAttendanceCount] = useState({});
  const currentMonth = new Date().toLocaleString('default', { month: 'long' });
  const [selectedCalenderMonth, setSelectedCalenderMonth] = useState(currentMonth);
  const [view, setView] = useState(Views.MONTH); // Default view is 'Month'
  const [data, setData] = useState([]);

  const [startDate, setstartDate] = React.useState(dayjs());
  const [open, setOpen] = useState(false);
  const [AddAttendanceOpen, setAddAttendanceOpen] = useState(false);
  const [shiftType, setShifTtype] = useState([]);
  const [LeaveType, setLeaveType] = useState([]);

  const [InTime, setInTime] = useState();
  const [OutTime, setOutTime] = useState();
  const [LeaveValue, setLeaveValue] = useState();
  const [ShiftValue, setShiftValue] = useState();
  const [month, setMonth] = useState(startDate.format('MMMM'));

  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [ActionMessage, setActionMessage] = useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddAttendanceClose = async() => {
    // handleClose()
    setAddAttendanceOpen(false);
    // setstartDate(dayjs());
    await fetchAttenData()
    setInTime('');
    setOutTime('');
    setLeaveValue('');
    setShiftValue('');
  };

  const shiftTypeGET = async () => {
    try {
      const userSchemaString = localStorage.getItem('userSchema');
      const userSchema = JSON.parse(userSchemaString);
      const companyIdString = localStorage.getItem('companyId');
      const divIdString = localStorage.getItem('divId');

      const data = {
        user: userSchema?.usrnm,
        compid: JSON.parse(companyIdString),
        divid: JSON.parse(divIdString),
        masterid: userSchema?.masterid
      }
      const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/attendance/shiftTypeGET`, data,
        {
          headers: {
            authorization: `Bearer ${token}`
          }
        }
      );
      setShifTtype(response.data.lastEntryNo);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchLeaveType = async () => {
    try {
      const userSchemaString = localStorage.getItem('userSchema');
      const userSchema = JSON.parse(userSchemaString);
      const companyIdString = localStorage.getItem('companyId');
      const divIdString = localStorage.getItem('divId');

      const data = {
        user: userSchema?.usrnm,
        compid: JSON.parse(companyIdString),
        divid: JSON.parse(divIdString),
        masterid: userSchema?.masterid
      };
      const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/attendance/LeaveTypeGET`, data,
        {
          headers: {
            authorization: `Bearer ${token}`
          }
        }
      );
      setLeaveType(response.data.lastEntryNo);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    shiftTypeGET();
    fetchLeaveType();
  }, [])

  const handleDateChange = (newValue) => {
    setstartDate(newValue);
    setMonth(newValue.format('MMMM'));
  };

  function extractData(data) {
    return data.map(item => ({
      atdid: item._id.atdid,
      lvname: Array.isArray(item._id.lvname) ? item._id.lvname[0] : item._id.lvname,
      shiftname: Array.isArray(item._id.shiftname) ? item._id.shiftname[0] : item._id.shiftname,
      dalatten_intime: item._id.dalatten_intime,
      dalatten_outtime: item._id.dalatten_outtime
    }));
  }

  function updateDataWithIDs(data, LeaveType, shiftType) {
    return data.map(item => {
      const matchedLeave = LeaveType.find(leaveItem => leaveItem.discription === item.lvname);
      const matchedShift = shiftType.find(shiftItem => shiftItem.shift_name === item.shiftname);

      if (matchedLeave && matchedShift) {
        return {
          ...item,
          lvname: matchedLeave._id,
          shiftname: matchedShift._id
        };
      } else {
        return item;
      }
    });
  }

  const fetchAttenData = async () => {
    try {
      const userSchemaString = localStorage.getItem('userSchema');
      const userSchema = JSON.parse(userSchemaString);
      const masterid = userSchema?.masterid;
      const companyIdString = localStorage.getItem('companyId');
      const divIdString = localStorage.getItem('divId');
      const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/attendance/GetattendanceDetails`,
        {
          compid: JSON.parse(companyIdString),
          divid: JSON.parse(divIdString),
          masterid,
          startDate,
          endDate: startDate,
          employee: localStorage.getItem('StaffDetailEmpID'),
        },
        {
          headers: {
            authorization: `Bearer ${token}`
          }
        }
      );
      const extractedData = await extractData(response.data.summary);
      // console.log(await updateDataWithIDs(extractedData, LeaveType, shiftType))
      setData(await updateDataWithIDs(extractedData, LeaveType, shiftType));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchAttenData();
  }, [startDate])

  const handleInputChange = (index, field, value) => {
    const newData = [...data];
    newData[index][field] = value;
    setData(newData);
  };

  const handleUpdate = async () => {
    try {
      const userSchemaString = localStorage.getItem('userSchema');
      const userSchema = JSON.parse(userSchemaString);
      const companyIdString = localStorage.getItem('companyId');
      const divIdString = localStorage.getItem('divId');
      const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/attendance/UpdateAttendanceByEmployee`,
        {
          data,
          usrnm: userSchema?.usrnm,
          compid: JSON.parse(companyIdString),
          divid: JSON.parse(divIdString),
          masterid: userSchema?.masterid,
          month,
        },
        {
          headers: {
            authorization: `Bearer ${token}`
          }
        }
      );

      if (response.data.status) {
        setActionMessage(response.data.message)
        // handleClose()
        // fetchData()
        setSuccessModalOpen(true)
      }
      else {
        setActionMessage(response.data.message)
        // handleClose()
        setErrorModalOpen(true)
      }
    } catch (error) {
      // fetchData()
      setActionMessage('Oops an error caught')
      setErrorModalOpen()
      console.error('Error:', error);
    }
  };

  function getTimeDifferenceInHours(intime, outtime) {
    const [inHours, inMinutes] = intime.split(':').map(Number);
    const [outHours, outMinutes] = outtime.split(':').map(Number);

    const inDate = new Date();
    inDate.setHours(inHours, inMinutes, 0, 0);

    const outDate = new Date();
    outDate.setHours(outHours, outMinutes, 0, 0);

    if (outDate <= inDate) {
        outDate.setDate(outDate.getDate() + 1);
    }

    const diffMs = outDate - inDate;

    const diffHrs = diffMs / (1000 * 60 * 60);
    return diffHrs.toFixed(2);
}

  const handleAddAttend = async () => {
    try {
      const TimeDifference = await getTimeDifferenceInHours(InTime, OutTime)
      const userSchemaString = localStorage.getItem('userSchema');
      const userSchema = JSON.parse(userSchemaString);
      const companyIdString = localStorage.getItem('companyId');
      const divIdString = localStorage.getItem('divId');
      const dataToSend = {
        dalatten_shiftnm: ShiftValue,
        dalatten_atdtype: LeaveValue,
        dalatten_intime: InTime,
        dalatten_outtime: OutTime,
        dalatten_total_hour: TimeDifference,
        month: month,
        dalatten_date: startDate,
        employee_name: localStorage.getItem('StaffDetailEmpID'),
        usrnm: userSchema?.usrnm,
        compid: JSON.parse(companyIdString),
        divid: JSON.parse(divIdString),
        masterid: userSchema?.masterid,
      };
      const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/attendance/AddAttendanceByEmployee`, dataToSend, {
        headers: {
          authorization: `Bearer ${token}`
        }
      });
      if (response.data.status) {
        setActionMessage(response.data.message)
        handleAddAttendanceClose()
        fetchAttenData()
        setSuccessModalOpen(true)
      }
      else {
        setActionMessage(response.data.message)
        handleAddAttendanceClose()
        fetchAttenData()
        setErrorModalOpen(true)
      }
    } catch (error) {
      fetchAttenData()
      handleAddAttendanceClose()
      setActionMessage('Oops an error caught')
      setErrorModalOpen()
      console.error('Error:', error);
    }
  }

  const handleErrorModalClose = () => {
    setErrorModalOpen(false);
  };
  const handleSuccessModalClose = () => {
    setSuccessModalOpen(false);
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    border: '2px solid',
    boxShadow: 24,
    padding: 16,
    minWidth: 300,
    maxWidth: 500,
    textAlign: 'center'
  };
  const localizer = momentLocalizer(moment);

  const empName = localStorage.getItem('StaffDetailEmpName');
  const empMid = localStorage.getItem('StaffDetailEmpMid');
  const empID = localStorage.getItem('StaffDetailEmpID');
  const empStatus = localStorage.getItem('StaffDetailEmpStatus');

  const handlePrevMonth = () => {
    setSelectedMonth(prevMonth => new Date(prevMonth.getFullYear(), prevMonth.getMonth() - 1, 1));
  };

  const handleNextMonth = () => {
    setSelectedMonth(prevMonth => new Date(prevMonth.getFullYear(), prevMonth.getMonth() + 1, 1));
  };

  const monthYearString = selectedMonth.toLocaleDateString('en-IN', { year: 'numeric', month: 'long' });

  function getLvnameCounts(data) {
    const lvnameCounts = { TotalDays: data.length };

    data.forEach(item => {
      const lvname = item._id.lvname[0]; // Extract lvname from each item

      // If lvname already exists in counts, increment count, else initialize with 1
      lvnameCounts[lvname] = (lvnameCounts[lvname] || 0) + 1;
    });

    return lvnameCounts;
  }

  const fetchData = async () => {
    try {
      const userSchemaString = localStorage.getItem('userSchema');
      const userSchema = JSON.parse(userSchemaString);
      const companyIdString = localStorage.getItem('companyId');
      const divIdString = localStorage.getItem('divId');
      const dataToSend = {
        usrnm: userSchema?.usrnm,
        compid: JSON.parse(companyIdString),
        divid: JSON.parse(divIdString),
        masterid: userSchema?.masterid,
        month: selectedMonth.toLocaleDateString('en-IN', { month: 'long' }),
        // id: state.id ? state.id : null,
        employee: localStorage.getItem('StaffDetailEmpID')
      };
      const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/attendance/GetEmpAttendanceDetail`,
        dataToSend,
        {
          headers: {
            authorization: `Bearer ${token}`
          }
        }
      );
      // setAttendanceData(response.data.summary)
      setAttendanceCount(getLvnameCounts(response.data.summary))
      // console.log(getLvnameCounts(response.data.summary))
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData()
  }, [selectedMonth])

  const fetchCalenderData = async () => {
    try {
      const userSchemaString = localStorage.getItem('userSchema');
      const userSchema = JSON.parse(userSchemaString);
      const companyIdString = localStorage.getItem('companyId');
      const divIdString = localStorage.getItem('divId');
      const dataToSend = {
        usrnm: userSchema?.usrnm,
        compid: JSON.parse(companyIdString),
        divid: JSON.parse(divIdString),
        masterid: userSchema?.masterid,
        month: selectedCalenderMonth,
        // id: state.id ? state.id : null,
        employee: localStorage.getItem('StaffDetailEmpID')
      };
      const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/attendance/GetEmpAttendanceDetail`,
        dataToSend,
        {
          headers: {
            authorization: `Bearer ${token}`
          }
        }
      );

      setAttendanceData(response.data.summary)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchCalenderData()
  }, [selectedCalenderMonth])

  const events = AttendanceData.map(item => ([
    {
      title: `${item._id.lvname[0]} ${item._id.dalatten_intime && item._id.dalatten_intime !== "0" ? `(${item._id.dalatten_intime} - ${item._id.dalatten_outtime})` : ''}`,
      start: new Date(item._id.dalatten_datemilisecond),
      end: new Date(item._id.dalatten_datemilisecond),
      style: {
        backgroundColor: 'red'
      }
    }
  ])).flat();

  const eventStyleGetter = (event, start, end, isSelected) => {
    let style = {
      backgroundColor: event.title.startsWith('A') ? '#c7545f' : '#5480c7',
      borderRadius: '5px',
      opacity: 0.8,
      color: 'white',
      border: '0px',
      display: 'block',
      textAlign: 'center', // Center the text
      lineHeight: '15px', // Adjust line height as needed
    };
    return {
      style: style
    };
  };

  const handleViewChange = newView => {
    setView(newView);
  };

  const getMonthIndex = (monthName) => {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const lowercaseMonth = monthName.toLowerCase(); // Convert to lowercase
    return months.findIndex(month => month.toLowerCase() === lowercaseMonth);
  };

  function handleCalenderNavigate(newDate, view) {
    const month = new Date(newDate).toLocaleString('default', { month: 'long' });
    setSelectedCalenderMonth(month);
    // console.log('Navigated to:', month);
  }

  const CustomToolbar = (onViewChange) => {
    return (
      <div style={{ padding: '5px', display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
        {/* Render the views */}
        <Button variant='contained' style={{ marginRight: '10px' }} onClick={() => handleViewChange(Views.MONTH)}>Month</Button>
        <Button variant='contained' onClick={() => handleViewChange(Views.AGENDA)}>Agenda</Button>
      </div>
    );
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ProfileHeader name={empName} id={empMid} status={empStatus} EmpID={empID} />
        </Grid>
        {/* Side Navbar */}

        <Grid item xs={2}>
          <ProfileNav />
        </Grid>

        {/* Content Section */}
        <Grid item xs={10}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button onClick={handlePrevMonth}><ArrowBackIosIcon /></Button>
              <div style={{ textAlign: 'center' }}>
                <Typography variant="h6">{monthYearString}</Typography>
              </div>
              <Button onClick={handleNextMonth}><ArrowForwardIosIcon /></Button>
            </div>
            <Button variant='contained' color='info' size='small' onClick={() => { setOpen(true) }}>Attendance Regularize</Button>
          </Box>

          <Paper sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '8px', marginTop: '10px' }}>
            <div style={{ marginRight: '10px', width: '120px', }}>
              <Typography variant="body1">Days</Typography>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{AttendanceCount.TotalDays || 0}</Typography>
            </div>
            <div style={{ marginRight: '10px', width: '120px', }}>
              <Typography variant="body1">Present</Typography>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{AttendanceCount.P || 0}</Typography>
            </div>
            <div style={{ marginRight: '10px', width: '120px', }}>
              <Typography variant="body1">Absent</Typography>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{AttendanceCount.A || 0}</Typography>
            </div>
            <div style={{ marginRight: '10px', width: '120px', }}>
              <Typography variant="body1">Weekly Off</Typography>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{AttendanceCount['Weekly Off'] || 0}</Typography>
            </div>
            <div style={{ marginRight: '10px', width: '120px', }}>
              <Typography variant="body1">Paid Leave</Typography>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{AttendanceCount.PD || 0}</Typography>
            </div>
            <div style={{ marginRight: '10px', width: '120px', }}>
              <Typography variant="body1">Half Day</Typography>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{AttendanceCount['Half Day'] || 0}</Typography>
            </div>
          </Paper>
          <Paper sx={{ alignItems: 'center', padding: '10px', marginTop: '10px', height: '600px' }}>
            <Calendar
              localizer={localizer}
              events={events}
              startAccessor="start"
              endAccessor="end"
              style={{ maxWidth: '100%', margin: '0 auto', }}
              views={['month', 'agenda',]}
              onNavigate={handleCalenderNavigate}
              eventPropGetter={eventStyleGetter}
            // views={[Views.MONTH, Views.AGENDA]} // Define available views
            // defaultView={Views.MONTH} // Set default view
            // onView={handleViewChange} // Update view when changed by the calendar itself
            // view={view} // Controlled view
            // components={{
            //   toolbar: CustomToolbar,
            // }}
            // defaultDate={getMonthIndex(selectedMonth.toLocaleDateString('en-IN', { month: 'long' }))} // Set default month to January
            />
          </Paper>
        </Grid>
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
          <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 450, bgcolor: 'background.paper', boxShadow: 24, p: 4, maxHeight: '80vh', overflow: 'auto', }}>
            <Typography id="modal-title" variant="h6" component="h2" sx={{ textAlign: 'center', paddingBottom: '20px', fontWeight: 'bold' }}>Attendance Regularize</Typography>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '10px' }}>
              <div style={{ marginRight: '20px', width: '100px' }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Select Date</Typography>
              </div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={startDate}
                  onChange={(newValue) => handleDateChange(newValue)}
                  fullWidth
                  // label='Date'
                  inputFormat="dd-MM-yyyy"
                  format="DD/MM/YYYY" // Set the desired date format here
                  sx={{ height: '30px', '& input': { height: '5px' } }} // Adjust height of both input and picker
                />
              </LocalizationProvider>
              <Button size='small' onClick={() => { setAddAttendanceOpen(true) }}><LibraryAddIcon color="primary" fontSize="large" /></Button>
            </div>
            {data.map((item, index) => (
              <div key={index} style={{ marginBottom: '15px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '8px' }}>
                  <div style={{ marginRight: '20px', width: '100px' }}>
                    <Typography variant="subtitle1">In Time</Typography>
                  </div>
                  <input type='time' name="field1" value={item.dalatten_intime} onChange={(e) => { handleInputChange(index, 'dalatten_intime', e.target.value) }} style={{ fontFamily: 'Arial, sans-serif', flex: 1, padding: '10px', border: '1px solid #ccc', borderRadius: '4px' }} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '8px' }}>
                  <div style={{ marginRight: '20px', width: '100px' }}>
                    <Typography variant="subtitle1">Out Time</Typography>
                  </div>
                  <input type='time' name="field1" value={item.dalatten_outtime} onChange={(e) => { handleInputChange(index, 'dalatten_outtime', e.target.value) }} style={{ fontFamily: 'Arial, sans-serif', flex: 1, padding: '10px', border: '1px solid #ccc', borderRadius: '4px' }} />
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '8px' }}>
                  <div style={{ marginRight: '20px', width: '100px' }}>
                    <Typography variant="subtitle1" >Shift Type</Typography>
                  </div>
                  <Select
                    value={item.shiftname}
                    onChange={(e) => { handleInputChange(index, 'shiftname', e.target.value) }}
                    style={{ flex: 1, height: '40px', borderRadius: '4px', }}
                  >
                    {shiftType.map((option, index) => (
                      <MenuItem key={index} value={option._id}>{option.shift_name}</MenuItem>
                    ))}
                  </Select>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '8px' }}>
                  <div style={{ marginRight: '20px', width: '100px' }}>
                    <Typography variant="subtitle1" >Leave Type</Typography>
                  </div>
                  <Select
                    value={item.lvname}
                    onChange={(e) => { handleInputChange(index, 'lvname', e.target.value) }}
                    style={{ flex: 1, height: '40px', borderRadius: '4px', }}
                  >
                    {LeaveType.map((option, index) => (
                      <MenuItem key={index} value={option._id}>{option.discription}</MenuItem>
                    ))}
                  </Select>
                </div>
                <Divider />
              </div>
            ))}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button onClick={handleClose} style={{ marginRight: '8px' }}>Close</Button>
              <Button variant="contained" color="primary" onClick={() => { handleUpdate(); }}>Update</Button>
            </div>
          </Box>
        </Modal>
        <Modal
          open={successModalOpen}
          onClose={handleSuccessModalClose}
          aria-labelledby="success-modal-title"
          aria-describedby="success-modal-description"
        >
          <div style={{ ...modalStyle, borderColor: '#007bff' }}>
            <h2 id="success-modal-title" style={{ color: '#007bff' }}>Success!</h2>
            <p id="success-modal-description">{ActionMessage}</p>
            <Button variant="contained" onClick={handleSuccessModalClose} style={{ backgroundColor: '#007bff', color: 'white' }}>Close</Button>
          </div>
        </Modal>

        <Modal
          open={errorModalOpen}
          onClose={handleErrorModalClose}
          aria-labelledby="error-modal-title"
          aria-describedby="error-modal-description"
        >
          <div style={{ ...modalStyle, borderColor: '#ff0000' }}>
            <h2 id="error-modal-title" style={{ color: '#ff0000' }}>Error!</h2>
            <p id="error-modal-description">{ActionMessage}.</p>
            <Button variant="contained" onClick={handleErrorModalClose} style={{ backgroundColor: '#ff0000', color: 'white' }}>Close</Button>
          </div>
        </Modal>

        <Modal open={AddAttendanceOpen} onClose={setAddAttendanceOpen} aria-labelledby="modal-title" aria-describedby="modal-description">
          <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 450, bgcolor: 'background.paper', boxShadow: 24, p: 4, }}>
            <Typography id="modal-title" variant="h6" component="h2" sx={{ textAlign: 'center', paddingBottom: '20px', fontWeight: 'bold' }}>Add Attendance Request</Typography>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '20px' }}>
              <div style={{ marginRight: '20px', width: '100px' }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Select Date</Typography>
              </div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={startDate}
                  onChange={(newValue) => handleDateChange(newValue)}
                  fullWidth
                  // label='Date'
                  inputFormat="dd-MM-yyyy"
                  format="DD/MM/YYYY" // Set the desired date format here
                  sx={{ height: '30px', '& input': { height: '5px' } }} // Adjust height of both input and picker
                />
              </LocalizationProvider>
            </div>
            {/* {data.map((item, index) => ( */}
            <div style={{ marginBottom: '15px' }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '8px' }}>
                <div style={{ marginRight: '20px', width: '100px' }}>
                  <Typography variant="subtitle1">In Time</Typography>
                </div>
                <input type='time' name="field1" value={InTime} onChange={(e) => { setInTime(e.target.value) }} style={{ fontFamily: 'Arial, sans-serif', flex: 1, padding: '10px', border: '1px solid #ccc', borderRadius: '4px' }} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '8px' }}>
                <div style={{ marginRight: '20px', width: '100px' }}>
                  <Typography variant="subtitle1">Out Time</Typography>
                </div>
                <input type='time' name="field1" value={OutTime} onChange={(e) => { setOutTime(e.target.value) }} style={{ fontFamily: 'Arial, sans-serif', flex: 1, padding: '10px', border: '1px solid #ccc', borderRadius: '4px' }} />
              </div>

              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '8px' }}>
                <div style={{ marginRight: '20px', width: '100px' }}>
                  <Typography variant="subtitle1" >Shift Type</Typography>
                </div>
                <Select
                  value={ShiftValue}
                  onChange={(e) => { setShiftValue(e.target.value) }}
                  style={{ flex: 1, height: '40px', borderRadius: '4px', }}
                >
                  {shiftType.map((option, index) => (
                    <MenuItem key={index} value={option._id}>{option.shift_name}</MenuItem>
                  ))}
                </Select>
              </div>

              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '8px' }}>
                <div style={{ marginRight: '20px', width: '100px' }}>
                  <Typography variant="subtitle1" >Leave Type</Typography>
                </div>
                <Select
                  value={LeaveValue}
                  onChange={(e) => { setLeaveValue(e.target.value) }}
                  style={{ flex: 1, height: '40px', borderRadius: '4px', }}
                >
                  {LeaveType.map((option, index) => (
                    <MenuItem key={index} value={option._id}>{option.discription}</MenuItem>
                  ))}
                </Select>
              </div>
              <Divider />
            </div>
            {/* ))} */}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button onClick={handleAddAttendanceClose} style={{ marginRight: '8px' }}>Close</Button>
              <Button variant="contained" color="primary" onClick={() => { handleAddAttend(); }}>Add</Button>
            </div>
          </Box>
        </Modal>
      </Grid>
    </>
  );
}

export default ProfileAttendance;
