import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import MUIDataTable from "mui-datatables";
import Modal from '@mui/material/Modal';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid'; // Import DataGrid from MUI
import { Grid, Paper, Typography, FormControl, TextField, Divider, Autocomplete, Checkbox, FormControlLabel } from '@mui/material';
import { useRef, useLayoutEffect } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';

function VisitorEdit() {
    const location = useLocation();
    const navigate = useNavigate();

    const [auditerChecked, setAuditerChecked] = useState(false);
    const [InTime, setInTime] = useState();
    const [company, setcompany] = useState('');
    const [purpose, setpurpose] = useState('');
    const [Date, setDate] = useState(dayjs().startOf('day'));
    const [name, setname] = useState('');
    const [mobile_no, setmobile_no] = useState('');
    const [email, setemail] = useState('');
    const [remark, setremark] = useState('');
    const [DesignationType, setDesignationType] = useState([]);
    const [DesignationTypeValue, setDesignationTypeValue] = useState('');
    const [vcode, setvcode] = useState('');
    const [cjsp, setcjsp] = useState('');

    const [error, setError] = useState('');
    const [data, setdata] = useState([]);
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [ActionMessage, setActionMessage] = useState('');
    const [editID, setEditID] = useState();

    const lastContentRef = useRef(null);
    useLayoutEffect(() => {
        if (lastContentRef.current) {
            lastContentRef.current.style.marginBottom = '80px';
        }
    }, []);

    const handleSubmit = (event) => {
        // event.preventDefault();
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');

            const dataToSend = {
                name: name,
                mobile_no: mobile_no,
                InTime,
                company,
                DesignationTypeValue: DesignationTypeValue?._id,
                purpose,
                Date,
                auditerChecked:auditerChecked?'on':'off',
                vcode,
                usrnm: userSchema?.usrnm,
                compid: JSON.parse(companyIdString),
                divid: JSON.parse(divIdString),
                masterid: userSchema?.masterid,
                _id: editID ? editID : null
            };
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorages
            axios.post(`${process.env.REACT_APP_API_URL}/visitor/Visitore_Update`, dataToSend, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            })
                .then(response => {
                    console.log(response)
                    if (response.data.status) {
                        setActionMessage(response.data.message)
                        setSuccessModalOpen(true);
                        navigate('/visitor/visitors_list');
                    } else {
                        setActionMessage(response.data.message)
                        setErrorModalOpen(true)
                    }
                })
                .catch(error => {
                    console.log(error)
                    setActionMessage("Ooops an error occured.")
                    setErrorModalOpen(true)
                });
    };

    const AttendantTypeGET = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');

            const data = {
                user: userSchema?.usrnm,
                compid: JSON.parse(companyIdString),
                divid: JSON.parse(divIdString),
                masterid: userSchema?.masterid
            }
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/visitor/AttendantTypeGET`, data,
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            setDesignationType(response.data.lastEntryNo);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        AttendantTypeGET();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const queryParams = new URLSearchParams(location.search);
            if (location.state && location.state.data) {
                const data = location?.state?.data;
                setInTime(data?.time_in);
                setcompany(data.company);
                setpurpose(data.purpose);
                setDate(dayjs(data.date));
                setname(data.name);
                setmobile_no(data.mobile);
                setDesignationTypeValue(data.want_to_meet);
                setAuditerChecked(data.auditers === 'on'? true : false)
                setvcode(data?.vouc_code)
                setcjsp(data?.c_j_s_p)
                setEditID(data?._id);
            }
            else{
                console.log("Still have location?")
                const urlParams = new URLSearchParams(window.location.search);
                const dataParam = urlParams.get('data');
                if (dataParam) {
                    const data = JSON.parse(decodeURIComponent(dataParam));
                    setInTime(data?.data?.time_in);
                    setcompany(data?.data.company);
                    setpurpose(data?.data.purpose);
                    setDate(dayjs(data?.data.date));
                    setname(data?.data.name);
                    setmobile_no(data?.data.mobile);
                    setDesignationTypeValue(data?.data.want_to_meet);
                    setAuditerChecked(data?.data.auditers === 'on'? true : false)
                    setvcode(data?.data?.vouc_code)
                    setcjsp(data?.data?.c_j_s_p)
                    setEditID(data?.data?._id);
                }
            }
        };
        fetchData(); // Call the asynchronous function
    }, [location.state]);

    const handleErrorModalClose = () => {
        setErrorModalOpen(false);
    };

    const handleSuccessModalClose = () => {
        setSuccessModalOpen(false);
    };

    const handleCancel = () => {
        navigate('/visitor/visitors_list');
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        border: '2px solid',
        boxShadow: 24,
        padding: 16,
        minWidth: 300,
        maxWidth: 500,
        textAlign: 'center'
    };
    return (
        <>
            <Container maxWidth="xl" style={{ marginTop: '10px' }} ref={lastContentRef}>
                <div>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: '10px', fontSize: '20px' }}>
                        Edit Visitor
                    </Typography>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={12}>
                            <Paper elevation={3} style={{ padding: '15px' }}>
                            <Divider />
                                <div style={{ marginBottom: '10px', marginTop: '10px' }}>
                                    <Grid container alignItems="center" spacing={2}>
                                        <Grid item xs={2}>
                                            <Typography variant="body1">Ref No.</Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    value={cjsp}
                                                    // onChange={(e) => setcjsp(e.target.value)}
                                                    InputProps={{
                                                        readOnly: true,
                                                      }}
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                />
                                            </FormControl>
                                        </Grid>
                                    
                                        <Grid item xs={3}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    value={vcode}
                                                    onChange={(e) => setvcode(e.target.value)}
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    type='number'
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div style={{ marginBottom: '10px', marginTop: '10px' }}>
                                    <Grid container alignItems="center" spacing={2}>
                                        <Grid item xs={2}>
                                            <Typography variant="body1">Date</Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControl fullWidth>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        value={Date}
                                                        onChange={(date) => setDate(date)}
                                                        // label={'Request Date'}
                                                        format="DD/MM/YYYY" // Set the desired date format here
                                                        renderInput={(params) => <TextField {...params} />}
                                                        slotProps={{ textField: { size: 'small' } }}
                                                    />
                                                </LocalizationProvider>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={2}>
                                            <Typography variant="body1">With whom you want to meet</Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControl style={{ width: '100%' }}>
                                                <Autocomplete
                                                    value={DesignationTypeValue}
                                                    onChange={(event, newValue) => {
                                                        setDesignationTypeValue(newValue);
                                                    }}
                                                    options={DesignationType}
                                                    getOptionLabel={(option) => option?.attendant_name || ""}
                                                    getOptionSelected={(option, value) => option._id === value}
                                                    style={{ height: '38px', fontSize: '14px' }}
                                                    renderInput={(params) => <TextField {...params} variant="outlined"

                                                        inputProps={{ ...params.inputProps, 'aria-label': 'Select Department Type', style: { paddingTop: '0px' } }}
                                                        sx={{
                                                            "& .MuiInputBase-root": { height: "38px" },
                                                        }}
                                                    />}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </div>
                                <Divider />
                                <div style={{ marginBottom: '10px', marginTop: '10px' }}>
                                    <Grid container alignItems="center" spacing={2}>
                                        <Grid item xs={2}>
                                            <Typography variant="body1">Mobile No.</Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    value={mobile_no}
                                                    onChange={(e) => setmobile_no(e.target.value)}
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    type='number'
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={2}>
                                            <Typography variant="body1">Name</Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    value={name}
                                                    onChange={(e) => setname(e.target.value)}
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                    type='email'
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </div>
                                <Divider />
                                <div style={{ marginBottom: '10px', marginTop: '10px' }}>
                                    <Grid container alignItems="center" spacing={2}>
                                        <Grid item xs={2}>
                                            <Typography variant="body1">Company</Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    value={company}
                                                    onChange={(e) => setcompany(e.target.value)}
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={2}>
                                            <Typography variant="body1">In Time</Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControl fullWidth>
                                                {/* <TextField
                                                    value={remark}
                                                    onChange={(e) => setremark(e.target.value)}
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                /> */}
                                                <input
                                                    type='time'
                                                    name="field1"
                                                    value={InTime}
                                                    onChange={(e) => setInTime(e.target.value)}
                                                    style={{
                                                        fontFamily: 'Arial, sans-serif',
                                                        padding: '10px',
                                                        border: '1px solid #ccc',
                                                        borderRadius: '4px'
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </div>
                                <Divider />
                                <div style={{ marginBottom: '10px', marginTop: '10px' }}>
                                    <Grid container alignItems="center" spacing={2}>
                                        <Grid item xs={2}>
                                            <Typography variant="body1">Purpose</Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    value={purpose}
                                                    onChange={(e) => setpurpose(e.target.value)}
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={2}>
                                            <Typography variant="body1">Auditers</Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControl fullWidth>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={auditerChecked}
                                                            onChange={(e) => { setAuditerChecked(e.target.checked) }}
                                                            color="primary"
                                                        />
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>

                <Paper style={{ padding: '16px', position: 'fixed', bottom: '10px', zIndex: 1000, width: '60%', left: '0', right: '0', margin: '0 auto' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }}>
                        <Button style={{ marginRight: '8px' }} variant="contained" color="error" onClick={() => { handleCancel() }}>
                            Cancel
                        </Button>
                        <Button style={{ marginLeft: '8px' }} variant="contained" color="primary" onClick={() => { handleSubmit() }} >
                            Save
                        </Button>
                    </div>
                </Paper>
            </Container>

            <Modal
                open={successModalOpen}
                onClose={handleSuccessModalClose}
                aria-labelledby="success-modal-title"
                aria-describedby="success-modal-description"
            >
                <div style={{ ...modalStyle, borderColor: '#007bff' }}>
                    <h2 id="success-modal-title" style={{ color: '#007bff' }}>Success!</h2>
                    <p id="success-modal-description">{ActionMessage}</p>
                    <Button variant="contained" onClick={handleSuccessModalClose} style={{ backgroundColor: '#007bff', color: 'white' }}>Close</Button>
                </div>
            </Modal>

            <Modal
                open={errorModalOpen}
                onClose={handleErrorModalClose}
                aria-labelledby="error-modal-title"
                aria-describedby="error-modal-description"
            >
                <div style={{ ...modalStyle, borderColor: '#ff0000' }}>
                    <h2 id="error-modal-title" style={{ color: '#ff0000' }}>Error!</h2>
                    <p id="error-modal-description">{ActionMessage}.</p>
                    <Button variant="contained" onClick={handleErrorModalClose} style={{ backgroundColor: '#ff0000', color: 'white' }}>Close</Button>
                </div>
            </Modal>

        </>
    );
}

export default VisitorEdit;
