import React, { useState, useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import MUIDataTable from "mui-datatables";
import Modal from '@mui/material/Modal';
import axios from 'axios';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import { Paper, Divider, FormControl, Grid, Autocomplete } from '@mui/material';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Collapse } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CancelIcon from '@mui/icons-material/Cancel';
import Chip from '@mui/material/Chip';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

function TaskPlan() {

    const [selectionRange, setSelectionRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      });
    
      const [isOpen, setIsOpen] = useState(false);
      const dateRangePickerRef = useRef(null);
    
      const handleSelect = (ranges) => {
        setSelectionRange(ranges.selection);
        setIsOpen(false); // Close the date picker when a selection is made
      };
    
      const handleClickOutside = (event) => {
        if (dateRangePickerRef.current && !dateRangePickerRef.current.contains(event.target)) {
          setIsOpen(false); // Close the date picker when clicked outside
        }
      };
    
      useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);
    
      const toggleDateRangePicker = () => {
        setIsOpen(!isOpen);
      };
    
      const handleStartDateChange = (event) => {
        const newStartDate = new Date(event.target.value);
        setSelectionRange((prevRange) => ({
          ...prevRange,
          startDate: isNaN(newStartDate.getTime()) ? prevRange.startDate : newStartDate,
        }));
      };
    
      const handleEndDateChange = (event) => {
        const newEndDate = new Date(event.target.value);
        setSelectionRange((prevRange) => ({
          ...prevRange,
          endDate: isNaN(newEndDate.getTime()) ? prevRange.endDate : newEndDate,
        }));
      };

    const [Remark, setRemark] = useState();
    const [planName, setplanName] = useState();
    const [startDate, setstartDate] = useState(dayjs());
    const [targetDate, settargetDate] = useState(dayjs());
    const [priority, setpriority] = useState();
    const [status, setstatus] = useState("Pending");
    const [priorityList, setpriorityList] = useState([]);


    const [AssisgnplanName, setAssisgnplanName] = useState();
    const [AssisgnVouCode, setAssisgnVouCode] = useState();
    const [AssisgntaskDesc, setAssisgntaskDesc] = useState();
    const [AssisgnstartDate, setAssisgnstartDate] = useState(dayjs());
    const [AssisgntargetDate, setAssisgntargetDate] = useState(dayjs());
    const [Assisgnpriority, setAssisgnpriority] = useState();
    const [AssisgnstepOrder, setAssisgnstepOrder] = useState();
    const [Assisgnstatus, setAssisgnstatus] = useState("Pending");

    const [employeeName, setemployeeName] = useState();
    const [EmpList, setEmpList] = useState([]);

    const [openAddModal, setOpenAddModal] = useState(false); // State to control the modal visibility
    const [openAssignModal, setOpenAssignModal] = useState(false); // State to control the modal visibility

    const [data, setdata] = useState([]);
    const [openModal, setOpenModal] = useState(false); // State to control the modal visibility
    const [deleteIndex, setDeleteIndex] = useState(null); // State to store the index of item to be deleted
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [ActionMessage, setActionMessage] = useState('');
    const [selectedImages, setSelectedImages] = useState([]);

    const [expandedRow, setExpandedRow] = React.useState(null);

    const [showSlider, setShowSlider] = useState(false);
    const [sliderImages, setSliderImages] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    function concatenateUrls(filenames, baseUrl) {
        return filenames.map(filename => baseUrl + filename);
    }

    const handleImageClick = async (imageUrls, imageIndex) => {
        setCurrentIndex(imageIndex);
        setSliderImages(await concatenateUrls(imageUrls, `${process.env.REACT_APP_API_URL}/uploads/`));
        console.log(imageUrls)
        setShowSlider(true);
    };

    const handleCloseSlider = () => {
        setShowSlider(false);
    };

    const settings = {
        customPaging: function (i) {
            return (
                <div>
                    <img
                        src={sliderImages[i]}
                        alt=""
                        style={{
                            height: "50px",
                            width: "50px",
                            objectFit: "cover",
                            borderRadius: "10px"
                        }}
                    />
                </div>
            );
        },
        dots: true,
        cssEase: "linear",
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: currentIndex, // Start with the third image
        beforeChange: (oldIndex, newIndex) => setCurrentIndex(newIndex)
    };

    const handleRowClick = (rowKey) => {
        if (expandedRow === rowKey) {
            setExpandedRow(null);
        } else {
            setExpandedRow(rowKey);
        }
    };

    const handleSubmit = () => {

        const userSchemaString = localStorage.getItem('userSchema');
        const userSchema = JSON.parse(userSchemaString);
        const companyIdString = localStorage.getItem('companyId');
        const divIdString = localStorage.getItem('divId');
        const dataToSend = {
            startdate: startDate,
            planName: planName,
            targetdate: targetDate,
            remarks: Remark,
            status: status,
            priority: priority?._id,

            usrnm: userSchema?.usrnm || userSchema?.mobile_no,
            compid: JSON.parse(companyIdString),
            divid: JSON.parse(divIdString),
            masterid: userSchema?.masterid,
        };
        const token = localStorage.getItem('user-token'); // Get JWT token from localStorages
        axios.post(`${process.env.REACT_APP_API_URL}/task/TaskPlanAdd`, dataToSend, {
            headers: {
                authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                fetchData();
                if (response.data.status) {
                    setActionMessage(response.data.message)
                    handleCloseAddModal()
                    setSuccessModalOpen(true);
                } else {
                    setActionMessage(response.data.message)
                    handleCloseAddModal()
                    setErrorModalOpen(true)
                }
            })
            .catch(error => {
                console.log(error)
                setActionMessage("Ooops an error occured.")
                handleCloseAddModal()
                setErrorModalOpen(true)
            });
    };

    function convertAndFormatDate(dateStr) {
        const dateObj = new Date(dateStr);
        const formattedDate = formatDate(dateObj);
        return formattedDate;
    }

    function formatDate(date) {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear()).slice(-2);
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${day}/${month}/${year} ${hours}:${minutes}`;
    }

    function groupTaskAssignsByTaskPlan(data) {
        const taskPlans = data.filter(item => item._id.c_j_s_p === 'TaskPlan');
        const taskAssigns = data.filter(item => item._id.c_j_s_p === 'TaskAssign');

        return taskPlans.map(taskPlan => {
            const correspondingAssigns = taskAssigns.filter(assign => assign._id.vouc_code === taskPlan._id.vouc_code);
            return { ...taskPlan, TaskAssigns: correspondingAssigns };
        });
    }

    const fetchData = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const masterid = userSchema?.masterid;
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const dataToSend = {
                usrnm: userSchema?.usrnm || userSchema?.mobile_no.toString(),
                compid: JSON.parse(companyIdString),
                divid: JSON.parse(divIdString),
                masterid: userSchema?.masterid,
            };
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/task/GettasksDetails`, dataToSend, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            })
            const groupByTask = await groupTaskAssignsByTaskPlan(response.data.summary);
            setdata(groupByTask);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [])
    const handleErrorModalClose = () => {
        setErrorModalOpen(false);
    };

    const handleSuccessModalClose = () => {
        setSuccessModalOpen(false);
    };

    const handleModalDelete = async () => {
        try {
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/task/TaskDELETE/${deleteIndex}`, {}, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            });
            console.log('Delete API Response:', response.data);
            handleCloseModal();
        } catch (error) {
            console.error('Error deleting item:', error);
        }
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const PriorityGET = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');

            const data = {
                user: userSchema?.usrnm,
                compid: JSON.parse(companyIdString),
                divid: JSON.parse(divIdString),
                masterid: userSchema?.masterid
            }
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/task/PriorityGET`, data,
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            setpriorityList(response.data.lastEntryNo);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const EmpListGET = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');

            const data = {
                user: userSchema?.usrnm,
                compid: JSON.parse(companyIdString),
                divid: JSON.parse(divIdString),
                masterid: userSchema?.masterid
            }
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/attendance/EmployeeNamesGET`, data,
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            setEmpList(response.data.lastEntryNo);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        PriorityGET()
        EmpListGET()
    }, []);

    const handleOpenAddModal = () => {
        setOpenAddModal(true);
    };

    const handleCloseAddModal = async () => {
        setOpenAddModal(false);
        setRemark('');
        setpriority('');
        setstartDate(dayjs());
        settargetDate(dayjs());
        setplanName('');
    };

    const handleOpenAssignModal = (vouc, plan_name) => {
        setAssisgnVouCode(vouc);
        setAssisgnplanName(plan_name);
        setOpenAssignModal(true);
    };

    const handleCloseAssignModal = async () => {
        setOpenAssignModal(false);
        setSelectedImages([]);
        setAssisgnpriority('');
        setAssisgnstartDate(dayjs());
        setAssisgntargetDate(dayjs());
        setAssisgnplanName('');
        setAssisgntaskDesc('');
        setemployeeName('');
        setAssisgnstepOrder('');
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        border: '2px solid',
        boxShadow: 24,
        padding: 16,
        minWidth: 300,
        maxWidth: 500,
        textAlign: 'center'
    };

    const handleFileChange = (event) => {
        const files = event.target.files;
        setSelectedImages([...files]);
        console.log('Selected files:', files);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        setSelectedImages([...files]);
        console.log('Dropped files:', files);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleClick = () => {
        document.getElementById('import-profile').click();
    };

    const handleAssignSave = () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const token = localStorage.getItem('user-token');

            const formData = new FormData();
            formData.append('usrnm', userSchema?.usrnm || userSchema?.mobile_no);
            formData.append('compid', JSON.parse(companyIdString));
            formData.append('divid', JSON.parse(divIdString));
            formData.append('masterid', userSchema?.masterid);
            formData.append('planName', AssisgnplanName);
            formData.append('VouCode', AssisgnVouCode);
            formData.append('taskDesc', AssisgntaskDesc);
            formData.append('startDate', AssisgnstartDate);
            formData.append('targetDate', AssisgntargetDate);
            formData.append('priority', Assisgnpriority?._id);
            formData.append('stepOrder', AssisgnstepOrder);
            formData.append('task_employee', employeeName?._id);

            selectedImages.forEach(image => {
                formData.append('images', image);
            });
            axios.post(`${process.env.REACT_APP_API_URL}/task/TaskAssigneAdd`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'authorization': `Bearer ${token}`
                }
            })
                .then((response) => {
                    if (response.data.status) {
                        setActionMessage(response.data.message)
                        handleCloseAssignModal();
                        fetchData();
                        setSuccessModalOpen(true);
                    } else {
                        setActionMessage(response.data.message)
                        handleCloseAssignModal();
                        fetchData();
                        setErrorModalOpen(true)
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setActionMessage("Ooops an error occured.")
                    handleCloseAssignModal();
                    fetchData();
                    setErrorModalOpen(true)
                });
        } catch (error) {
            console.error('Error:', error);
            setActionMessage("Ooops an error occured.")
            handleCloseAssignModal();
            fetchData();
            setErrorModalOpen(true)
        }
    };

    return (
        <>
           {/* <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
      <input
        type="text"
        value={selectionRange.startDate.toDateString()}
        onChange={handleStartDateChange}
        onFocus={toggleDateRangePicker}
        readOnly={!isOpen}
      />
      <span> - </span>
      <input
        type="text"
        value={selectionRange.endDate.toDateString()}
        onChange={handleEndDateChange}
        onFocus={toggleDateRangePicker}
        readOnly={!isOpen}
      />
      {isOpen && (
        <div style={{ position: 'absolute', zIndex: 999 }} ref={dateRangePickerRef}>
          <DateRangePicker
            ranges={[selectionRange]}
            onChange={handleSelect}
          />
        </div>
      )}
    </div> */}
            {/* <Container maxWidth="lg" style={{ marginTop: '25px', minHeight: '400px' }}> */}
            <div>
                <Paper sx={{ border: '1px solid #ccc', padding: '10px', marginTop: '25px', position: 'relative' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold' }}>
                            Task Plan List
                        </Typography>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Button variant='contained' color='success' onClick={() => { handleOpenAddModal() }} style={{ marginLeft: '8px', color: 'white', borderColor: 'white' }}>Add Task</Button>
                        </div>
                    </div>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead style={{ background: 'linear-gradient(#FFFFFF, #60a5f2)' }}>
                                <TableRow>
                                    <TableCell style={{ fontSize: '18px' }}>Plan Name</TableCell>
                                    <TableCell align="center" style={{ fontSize: '18px' }}>Start Date</TableCell>
                                    <TableCell align="center" style={{ fontSize: '18px' }}>Remarks</TableCell>
                                    <TableCell align="center" style={{ fontSize: '18px' }}>Priority</TableCell>
                                    <TableCell align="center" style={{ fontSize: '18px' }}>Due Date</TableCell>
                                    <TableCell align="center" style={{ fontSize: '18px' }}>Status</TableCell>
                                    <TableCell align="center" style={{ fontSize: '18px' }}>Assign</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.length > 0 ? (
                                    data.map((row, index) => (
                                        <React.Fragment key={index}>
                                            <TableRow onClick={() => handleRowClick(index)}>
                                                <TableCell component="th" scope="row" style={{ fontSize: '16px', padding: '8px' }}>
                                                    <IconButton size="small">
                                                        {expandedRow === index ? <ExpandMoreIcon /> : <ExpandMoreIcon />}
                                                    </IconButton>
                                                    <Chip
                                                        label={row?.TaskAssigns && row.TaskAssigns.length > 0 ? `${row.TaskAssigns.length}` : '0'}
                                                        color={row?.TaskAssigns && row.TaskAssigns.length > 0 ? 'success' : 'error'}
                                                        sx={{ marginRight: '10px' }}
                                                    />
                                                    {row?._id?.task_name}
                                                </TableCell>
                                                <TableCell align="center" style={{ fontSize: '16px', padding: '8px' }}>{convertAndFormatDate(row?._id?.startdate)}</TableCell>
                                                <TableCell align="center" style={{ fontSize: '16px', padding: '8px' }}>{row?._id?.task_description}</TableCell>
                                                <TableCell align="center" style={{ fontSize: '16px', padding: '8px' }}>{row?._id?.task_priority[0]}</TableCell>
                                                <TableCell align="center" style={{ fontSize: '16px', padding: '8px' }}>{convertAndFormatDate(row?._id?.duedate)}</TableCell>
                                                <TableCell align="center" style={{ fontSize: '16px', padding: '8px' }}>{row?._id?.task_status}</TableCell>
                                                <TableCell align="center" style={{ fontSize: '16px', padding: '8px' }}>
                                                    <Button
                                                        variant="text"
                                                        color="primary"
                                                        size="small"
                                                        sx={{ padding: '0px' }}
                                                        onClick={() => { handleOpenAssignModal(row?._id.vouc_code, row?._id?.task_name) }}                                            >
                                                        <PersonAddAlt1Icon fontSize="small" />
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                                                    <Collapse in={expandedRow === index} timeout="auto" unmountOnExit>
                                                        <Box margin={1}>
                                                            <Typography variant="h6" gutterBottom component="div">
                                                                Task Assignees
                                                            </Typography>
                                                            <Table size="small" aria-label="purchases">
                                                                <TableHead>
                                                                    <TableRow fontWeight='bold'>
                                                                        <TableCell sx={{ fontWeight: 'bold' }}>Employee</TableCell>
                                                                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>Task Description</TableCell>
                                                                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>Date Time Allotted</TableCell>
                                                                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>Images</TableCell>
                                                                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>Priority</TableCell>
                                                                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>Status</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {row?.TaskAssigns?.map((Assignees) => (
                                                                        <TableRow key={index} sx={{
                                                                            backgroundColor: Assignees?._id?.task_status === 'Done' ? '#97c991' : 'inherit'
                                                                          }}>
                                                                            <TableCell >{Assignees?._id?.empName[0]}</TableCell>
                                                                            <TableCell align="center">{Assignees?._id?.task_description}</TableCell>
                                                                            <TableCell align="center">{`${convertAndFormatDate(Assignees?._id?.startdate)} - ${convertAndFormatDate(Assignees?._id?.duedate)}`}</TableCell>
                                                                            <TableCell align="center" >
                                                                                {Assignees?._id?.filenames?.map((imageName, imageIndex) => (
                                                                                    <img key={imageIndex} src={`${process.env.REACT_APP_API_URL}/uploads/${imageName}`} alt="" style={{ width: '50px', height: '30px', padding: '5px' }} onClick={() => handleImageClick(Assignees?._id?.filenames, imageIndex)} />
                                                                                ))}
                                                                            </TableCell>
                                                                            <TableCell align="center">{Assignees?._id?.task_priority}</TableCell>
                                                                            <TableCell align="center">{Assignees?._id?.task_status}</TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </Box>
                                                    </Collapse>
                                                </TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    ))
                                ) :
                                    <TableRow>
                                        <TableCell colSpan={7} align="center" style={{ fontSize: '16px', padding: '8px' }}>
                                            No Tasks
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
                {/* </Container> */}
                <div style={{ position: 'fixed', top: '100px', left: 0, right: 0, zIndex: '999', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                    {showSlider && (
                        <>
                            {sliderImages.length === 1 ? (
                                <div style={{ textAlign: "center" }}>
                                    <button onClick={handleCloseSlider}>Close</button>
                                    <img
                                        src={sliderImages[0]}
                                        alt=""
                                        style={{ width: "100%", maxWidth: "500px", height: "50vh" }}
                                    />
                                </div>
                            ) : (
                                <div style={{ maxWidth: "500px", margin: "0 auto" }}>
                                    <button onClick={handleCloseSlider}>Close</button>
                                    <Slider {...settings}>
                                        {sliderImages.map((item, index) => (
                                            <div key={index}>
                                                <img
                                                    src={item}
                                                    alt=""
                                                    style={{ width: "100%", height: "50vh" }}
                                                />
                                            </div>
                                        ))}
                                    </Slider>
                                    <div style={{ textAlign: "center" }}>
                                        {sliderImages.map((_, index) => (
                                            <span
                                                key={index}
                                                style={{
                                                    display: "inline-block",
                                                    width: "10px",
                                                    height: "10px",
                                                    borderRadius: "50%",
                                                    backgroundColor: currentIndex === index ? "#000" : "#ccc",
                                                    margin: "0 5px",
                                                    cursor: "pointer"
                                                }}
                                                onClick={() => setCurrentIndex(index)}
                                            />
                                        ))}
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>

                <Modal
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, width: 400 }}>
                        <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
                            Confirm Deletion
                        </Typography>
                        <Typography id="modal-description" sx={{ mt: 2 }}>
                            Are you sure you want to delete this item?
                        </Typography>
                        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={handleCloseModal} color="primary" sx={{ mr: 2 }}>
                                Cancel
                            </Button>
                            <Button onClick={handleModalDelete} variant="contained" color="error">
                                Delete
                            </Button>
                        </Box>
                    </Box>
                </Modal>

                <Modal
                    open={successModalOpen}
                    onClose={handleSuccessModalClose}
                    aria-labelledby="success-modal-title"
                    aria-describedby="success-modal-description"
                >
                    <div style={{ ...modalStyle, borderColor: '#007bff' }}>
                        <h2 id="success-modal-title" style={{ color: '#007bff' }}>Success!</h2>
                        <p id="success-modal-description">{ActionMessage}</p>
                        <Button variant="contained" onClick={handleSuccessModalClose} style={{ backgroundColor: '#007bff', color: 'white' }}>Close</Button>
                    </div>
                </Modal>

                <Modal
                    open={errorModalOpen}
                    onClose={handleErrorModalClose}
                    aria-labelledby="error-modal-title"
                    aria-describedby="error-modal-description"
                >
                    <div style={{ ...modalStyle, borderColor: '#ff0000' }}>
                        <h2 id="error-modal-title" style={{ color: '#ff0000' }}>Error!</h2>
                        <p id="error-modal-description">{ActionMessage}.</p>
                        <Button variant="contained" onClick={handleErrorModalClose} style={{ backgroundColor: '#ff0000', color: 'white' }}>Close</Button>
                    </div>
                </Modal>

                <Dialog open={openAddModal} onClose={handleCloseAddModal} fullWidth maxWidth="sm">
                    <DialogTitle>
                        <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: '10px', fontSize: '20px' }}>
                            Add Plan
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <div >
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={12}>
                                    {/* <Paper elevation={3} style={{ padding: '15px' }}> */}
                                    <div style={{ marginBottom: '15px', marginTop: '15px' }}>
                                        <Grid container alignItems="center" spacing={1}>
                                            <Grid item xs={2}>
                                                <Typography variant="body1">Plan</Typography>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <FormControl style={{ width: '100%' }}>
                                                    <input
                                                        type="text"
                                                        value={planName}
                                                        onChange={(e) => setplanName(e.target.value)}
                                                        style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    {/* </Paper> */}
                                </Grid>
                            </Grid>
                        </div>
                        <Divider /> {/* Divider */}
                        <div >
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={12}>
                                    {/* <Paper elevation={3} style={{ padding: '15px' }}> */}
                                    <div style={{ marginBottom: '15px', marginTop: '15px' }}>
                                        <Grid container alignItems="center" spacing={1}>
                                            <Grid item xs={2}>
                                                <Typography variant="body1">Priority</Typography>
                                            </Grid>
                                            <Grid item xs={10}>
                                                {/* <FormControl fullWidth>
                                                <input
                                                    type="text"
                                                    value={priority}
                                                    onChange={(e) => setpriority(e.target.value)}
                                                    style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', width: '60%' }}
                                                />
                                            </FormControl> */}
                                                <FormControl style={{ width: '100%' }}>
                                                    <Autocomplete
                                                        value={priority}
                                                        onChange={(event, newValue) => {
                                                            setpriority(newValue);
                                                        }}
                                                        options={priorityList}
                                                        getOptionLabel={(option) => option?.discription || ""}
                                                        getOptionSelected={(option, value) => option._id === value}
                                                        style={{ height: '38px', fontSize: '14px' }}
                                                        renderInput={(params) => <TextField {...params} variant="outlined"

                                                            inputProps={{ ...params.inputProps, 'aria-label': 'Select Department Type', style: { paddingTop: '0px' } }}
                                                            sx={{
                                                                "& .MuiInputBase-root": { height: "38px" },
                                                            }}
                                                        />}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    {/* </Paper> */}
                                </Grid>
                            </Grid>
                        </div>
                        <Divider /> {/* Divider */}
                        <div >
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={12}>
                                    {/* <Paper elevation={3} style={{ padding: '15px' }}> */}
                                    <div style={{ marginBottom: '15px', marginTop: '15px' }}>
                                        <Grid container alignItems="center" spacing={1}>
                                            <Grid item xs={2}>
                                                <Typography variant="body1">Date Time</Typography>
                                            </Grid>
                                            <Grid item xs={5} style={{ height: 'fit-content' }}>
                                                <FormControl >
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            value={startDate}
                                                            onChange={(date) => setstartDate(date)}
                                                            label={'Start Date Time'}
                                                            format="DD/MM/YYYY HH:mm:ss" // Set the desired date and time format here
                                                            sx={{ height: '30px', '& input': { height: '5px' } }} // Adjust height of both input and picker
                                                        />
                                                    </LocalizationProvider>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={5} style={{ height: 'fit-content' }}>
                                                <FormControl >
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            value={targetDate}
                                                            onChange={(date) => settargetDate(date)}
                                                            label={'Target Date Time'}
                                                            format="DD/MM/YYYY HH:mm:ss" // Set the desired date and time format here
                                                            sx={{ height: '30px', '& input': { height: '5px' } }} // Adjust height of both input and picker
                                                        />
                                                    </LocalizationProvider>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    {/* </Paper> */}
                                </Grid>
                            </Grid>
                        </div>
                        <Divider /> {/* Divider */}
                        <div >
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={12}>
                                    {/* <Paper elevation={3} style={{ padding: '15px' }}> */}
                                    <div style={{ marginBottom: '15px', marginTop: '15px' }}>
                                        <Grid container alignItems="center" spacing={1}>
                                            <Grid item xs={2}>
                                                <Typography variant="body1">Remarks</Typography>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <FormControl fullWidth>
                                                    <input
                                                        type="text"
                                                        value={Remark}
                                                        onChange={(e) => setRemark(e.target.value)}
                                                        style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    {/* </Paper> */}
                                </Grid>
                            </Grid>
                        </div>
                        <Divider /> {/* Divider */}
                        <div >
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={12}>
                                    {/* <Paper elevation={3} style={{ padding: '15px' }}> */}
                                    <div style={{ marginBottom: '15px', marginTop: '15px' }}>
                                        <Grid container alignItems="center" spacing={1}>
                                            <Grid item xs={2}>
                                                <Typography variant="body1">Status</Typography>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <FormControl fullWidth>
                                                    <input
                                                        type="text"
                                                        value={status}
                                                        readOnly
                                                        style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', fontWeight: 'bold' }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    {/* </Paper> */}
                                </Grid>
                            </Grid>
                        </div>
                        <Divider /> {/* Divider */}

                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleCloseAddModal} color="primary">Cancel</Button>
                        <Button onClick={() => { handleSubmit() }} color="primary">Save</Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={openAssignModal} onClose={handleCloseAssignModal} fullWidth maxWidth="md">
                    <DialogTitle>
                        <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: '10px', fontSize: '20px' }}>
                            Assign Plan
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <div >
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={12}>
                                    {/* <Paper elevation={3} style={{ padding: '15px' }}> */}
                                    <div style={{ marginBottom: '15px', marginTop: '15px' }}>
                                        <Grid container alignItems="center" spacing={2}>
                                            <Grid item xs={2}>
                                                <Typography variant="body1">Employee</Typography>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <FormControl style={{ width: '100%' }}>
                                                    <Autocomplete
                                                        value={employeeName}
                                                        onChange={(event, newValue) => {
                                                            setemployeeName(newValue);
                                                        }}
                                                        options={EmpList}
                                                        getOptionLabel={(option) => option?.full_name || ""}
                                                        getOptionSelected={(option, value) => option._id === value}
                                                        style={{ height: '38px', fontSize: '14px' }}
                                                        renderInput={(params) => <TextField {...params} variant="outlined"

                                                            inputProps={{ ...params.inputProps, 'aria-label': 'Select Department Type', style: { paddingTop: '0px' } }}
                                                            sx={{
                                                                "& .MuiInputBase-root": { height: "38px" },
                                                            }}
                                                        />}
                                                    />
                                                </FormControl>
                                            </Grid>

                                        </Grid>
                                    </div>
                                    <Divider /> {/* Divider */}
                                    <div style={{ marginBottom: '15px', marginTop: '15px' }}>
                                        <Grid container alignItems="center" spacing={1}>
                                            <Grid item xs={2}>
                                                <Typography variant="body1">Task Description</Typography>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <FormControl style={{ width: '100%' }}>
                                                    <input
                                                        type="text"
                                                        value={AssisgntaskDesc}
                                                        onChange={(e) => setAssisgntaskDesc(e.target.value)}
                                                        style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    {/* </Paper> */}
                                </Grid>
                            </Grid>
                        </div>
                        <Divider /> {/* Divider */}
                        <div >
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={12}>
                                    {/* <Paper elevation={3} style={{ padding: '15px' }}> */}
                                    <div style={{ marginBottom: '15px', marginTop: '15px' }}>
                                        <Grid container alignItems="center" spacing={1}>
                                            <Grid item xs={2}>
                                                <Typography variant="body1">Images</Typography>
                                            </Grid>
                                            <Grid item xs={10}>
                                                <FormControl fullWidth>
                                                    <div style={{ display: 'flex', alignItems: 'center', }}>
                                                        <div
                                                            onDrop={handleDrop}
                                                            onDragOver={handleDragOver}
                                                            onClick={handleClick}
                                                            style={{ cursor: 'pointer', width: '200px', height: '70px', border: '2px dashed #aaa', borderRadius: '10px', textAlign: 'center', marginRight: '20px' }}
                                                        >
                                                            <input
                                                                accept="image/*"
                                                                id="import-profile"
                                                                type="file"
                                                                multiple
                                                                style={{ display: 'none' }}
                                                                onChange={handleFileChange}
                                                            />
                                                            <p><h5>Click or drag images here</h5></p>
                                                        </div>
                                                        <div>
                                                            {selectedImages.map((image, index) => (
                                                                <img key={index} src={URL.createObjectURL(image)} alt={`Selected ${index}`} style={{ maxWidth: '90px', maxHeight: '70px', marginRight: '10px' }} />
                                                            ))}
                                                        </div>
                                                    </div>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    {/* </Paper> */}
                                </Grid>
                            </Grid>
                        </div>
                        <Divider /> {/* Divider */}
                        <div >
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={12}>
                                    {/* <Paper elevation={3} style={{ padding: '15px' }}> */}
                                    <div style={{ marginBottom: '15px', marginTop: '15px' }}>
                                        <Grid container alignItems="center" spacing={1}>
                                            <Grid item xs={2}>
                                                <Typography variant="body1">Priority</Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                {/* <FormControl fullWidth>
                                                <input
                                                    type="text"
                                                    value={priority}
                                                    onChange={(e) => setpriority(e.target.value)}
                                                    style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', width: '60%' }}
                                                />
                                            </FormControl> */}
                                                <FormControl style={{ width: '100%' }}>
                                                    <Autocomplete
                                                        value={Assisgnpriority}
                                                        onChange={(event, newValue) => {
                                                            setAssisgnpriority(newValue);
                                                        }}
                                                        options={priorityList}
                                                        getOptionLabel={(option) => option?.discription || ""}
                                                        getOptionSelected={(option, value) => option._id === value}
                                                        style={{ height: '38px', fontSize: '14px' }}
                                                        renderInput={(params) => <TextField {...params} variant="outlined"

                                                            inputProps={{ ...params.inputProps, 'aria-label': 'Select Department Type', style: { paddingTop: '0px' } }}
                                                            sx={{
                                                                "& .MuiInputBase-root": { height: "38px" },
                                                            }}
                                                        />}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={1}>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Typography variant="body1">Step Order</Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControl fullWidth>
                                                    <input
                                                        type="number"
                                                        value={AssisgnstepOrder}
                                                        onChange={(e) => setAssisgnstepOrder(e.target.value)}
                                                        style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    {/* </Paper> */}
                                </Grid>
                            </Grid>
                        </div>
                        <Divider /> {/* Divider */}
                        <div >
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={12}>
                                    {/* <Paper elevation={3} style={{ padding: '15px' }}> */}
                                    <div style={{ marginBottom: '15px', marginTop: '15px' }}>
                                        <Grid container alignItems="center" spacing={1}>
                                            <Grid item xs={2}>
                                                <Typography variant="body1">Date Time</Typography>
                                            </Grid>
                                            <Grid item xs={5} style={{ height: 'fit-content' }}>
                                                <FormControl >
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            value={AssisgnstartDate}
                                                            onChange={(date) => setAssisgnstartDate(date)}
                                                            label={'Start Date Time'}
                                                            format="DD/MM/YYYY HH:mm:ss" // Set the desired date and time format here
                                                            sx={{ height: '30px', '& input': { height: '5px' } }} // Adjust height of both input and picker
                                                        />
                                                    </LocalizationProvider>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={5} style={{ height: 'fit-content' }}>
                                                <FormControl >
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            value={AssisgntargetDate}
                                                            onChange={(date) => setAssisgntargetDate(date)}
                                                            label={'Target Date Time'}
                                                            format="DD/MM/YYYY HH:mm:ss" // Set the desired date and time format here
                                                            sx={{ height: '30px', '& input': { height: '5px' } }} // Adjust height of both input and picker
                                                        />
                                                    </LocalizationProvider>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    {/* </Paper> */}
                                </Grid>
                            </Grid>
                        </div>
                        <Divider /> {/* Divider */}
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={() => { handleCloseAssignModal() }} color="primary">Cancel</Button>
                        <Button onClick={() => { handleAssignSave() }} color="primary">Save</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
}

export default TaskPlan;






