import React from 'react';
import Paper from '@mui/material/Paper';
import profileImage from './3fb3ce89059bd129352cbee229d68952.jpg'; // Replace 'profileImage.jpg' with the path to your image
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

function ProfileHeader(props) {
    const { name, id, status, EmpID } = props;
    const PP = localStorage.getItem('EPP');

    function getImageUrlFromPath(imagePath) {
        // Remove leading './public' from the image path
        const relativeImagePath = imagePath.replace('./public', '');

        // Return the dynamically generated image URL
        return relativeImagePath;
    }
    return (
        <Paper elevation={3} style={{ padding: '10px', marginBottom: '10px', borderRadius: '10px', background: 'linear-gradient(135deg, #FFFFFF,#60a5f2,#FFFFFF)', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* <img src={profileImage} alt="Profile" style={{ width: '70px', height: '70px', borderRadius: '50%', marginRight: '10px',border: '2px solid white' }} /> */}
                <img src={getImageUrlFromPath(`${PP}`)} alt="Profile" style={{ width: '75px', height: '75px', borderRadius: '50%', marginRight: '10px', border: '2px solid white' }} />

                <div>
                    <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: 'black' }}>
                        {name}
                    </Typography>
                    <Typography variant="" gutterBottom sx={{ fontWeight: 'normal', color: 'black' }}>
                        ID {id} | {status}
                    </Typography>
                </div>
            </div>
            <div>
                <Button variant="contained" color="primary" style={{ marginRight: '10px' }}>Active</Button>
                <Button variant="outlined" color="primary">Invite</Button>
            </div>
        </Paper>
    );
}

export default ProfileHeader;
