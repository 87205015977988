import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Button, Grid, Paper, Select, MenuItem } from '@mui/material';
import axios from 'axios';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid'; // Import DataGrid from MUI
import FormControl from '@mui/material/FormControl';
import { useNavigate } from 'react-router-dom';

const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

function Nosal() {
    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [Month, setMonth] = useState(new Date().toLocaleString('default', { month: 'long' }));
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [openModal, setOpenModal] = useState(false); // State to control the modal visibility
    const [NoSalArr, setNoSalArr] = useState([]);
    
    useEffect(() => {
        fetchAdvanceData();
    }, [Month]);

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const fetchAdvanceData = async () => {
        setLoading(true);
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const masterid = userSchema?.masterid;
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/salart_handleRouter/GetNoSal?search=${search}`,
                {
                    user: userSchema?.usrnm,
                    compid: JSON.parse(companyIdString),
                    divid: JSON.parse(divIdString),
                    masterid: userSchema?.masterid,
                    month: Month
                },
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );
            setData(response?.data?.NoSalRecords || '');
            setNoSalArr(response?.data?.NoSalRecords?.NoSalArr || []);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const NewreqData = NoSalArr.map((item, index) => ({
        ...item,
        id: index,
        Sno: index+1,
        full_name: item?.full_name,
        reason: item?.reason,
    }));

    const columns = [
        { field: 'Sno', headerName: 'Sno', width: 90},
        { field: 'full_name', headerName: 'Employee', flex: 1 },
        { field: 'reason', headerName: 'Reason', flex: 1 },
    ];

    const generateFileName = () => {
        const now = new Date();
        const date = now.toLocaleDateString().replace(/\//g, '-'); // Format date as YYYY-MM-DD
        const time = now.toLocaleTimeString().replace(/:/g, '-');  // Format time as HH-MM-SS

        const prefix = 'Unprocessed Salary Records SHEET';
        return `${prefix}_${Month}_${date}_${time}`;

        // return `AbsentReport_${date}_${time}`;
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper elevation={3} style={{ padding: '10px', }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
                            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
                            Unprocessed Salary Records
                            </Typography>

                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>

                                <FormControl sx={{ m: 1, width: '100%' }} size="small">

                                    <Select
                                        value={Month}
                                        onChange={(e) => setMonth(e.target.value)}
                                        sx={{ borderRadius: '14px' }}
                                    >
                                        {months.map(month => (
                                            <MenuItem key={month} value={month}>{month}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        <DataGrid
                            rows={NewreqData}
                            columns={columns}
                            initialState={{
                                ...NewreqData.initialState,
                                pagination: { paginationModel: { pageSize: 10 } },
                            }}
                            pageSizeOptions={[10, 25, 100]}
                            pagination
                            autoHeight
                            checkboxSelection={false}
                            disableSelectionOnClick
                            density="compact"
                            loading={loading}
                            slots={{ toolbar: GridToolbar }}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    csvOptions: { fileName: generateFileName() }, 
                                },
                            }}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}

export default Nosal;
