import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import ProfileNav from './navbar';
import ProfileHeader from './header';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Box from '@mui/material/Box';
import { useRef, useLayoutEffect } from 'react';


function UserRightEdit() {
    const [Allrights, setAllrights] = useState();
    const [selectedRights, setSelectedRights] = useState([]);
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [ActionMessage, setActionMessage] = useState('');
    const [loading, setLoading] = useState(true); // Add loading state

    const lastContentRef = useRef(null);
    useLayoutEffect(() => {
        if (lastContentRef.current) {
            lastContentRef.current.style.marginBottom = '75px';
        }
    }, []);

    const UserData = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const masterid = userSchema?.masterid;
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const token = localStorage.getItem('user-token');
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/user_rights/UserDataGET`,
                {
                    params: {
                        masterid,
                        compid: JSON.parse(companyIdString),
                        divid: JSON.parse(divIdString),
                        user: localStorage.getItem('UserRightsID')
                    },
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );
            const data = await response.data.user;
            setSelectedRights(data && data.admin ? data.admin.split(/,\s*/) : []);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        finally {
            setLoading(false)
        }
    };

    const handleRightCheckboxChange = (rightName, action) => {
        let rightKey = `${rightName}-${action}`;
        setSelectedRights(prevRights => {
            if (prevRights.includes(rightKey)) {
                return prevRights.filter(right => right !== rightKey);
            } else {
                return [...prevRights, rightKey];
            }
        });
    };

    const AllrightsGET = async () => {
        try {
            setLoading(true)
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const masterid = userSchema?.masterid;
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const token = localStorage.getItem('user-token');
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/user_rights/AllrightsGET`,
                {
                    params: {
                        masterid,
                        compid: JSON.parse(companyIdString),
                        divid: JSON.parse(divIdString),
                        user: localStorage.getItem('UserRightsID')
                    },
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );
            return response.data;

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const UpdateUser = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const masterid = userSchema?.masterid;
            const singleRightsString = selectedRights.join(', ');

            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage

            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/user_rights/UpdateUserDetails`,
                {
                    admin: singleRightsString,
                    _id: localStorage.getItem('UserRightsID'),
                    key: "UserRights_ListUpdate"
                },
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );
            if (response.data.status) {
                fetchData();
                setActionMessage(response.data.message)
                setSuccessModalOpen(true)
            }
            else {
                setActionMessage(response.data.message)
                setErrorModalOpen(true)
            }

        } catch (error) {
            setActionMessage('Some error occured.')
            setErrorModalOpen(true)
            console.error('Error fetching data:', error);
        }
    }

    const handleErrorModalClose = () => {
        setErrorModalOpen(false);
    };

    const handleSuccessModalClose = () => {
        setSuccessModalOpen(false);
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        border: '2px solid',
        boxShadow: 24,
        padding: 16,
        minWidth: 300,
        maxWidth: 500,
        textAlign: 'center'
    };

    const fetchData = async () => {
        try {
            const allrightsData = await AllrightsGET();
            setAllrights(allrightsData);
            await UserData(allrightsData);
        } catch (error) {
            console.error('Error fetching Allrights:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handlecancel = () => { 
        setLoading(true)
        fetchData();
    }
    return (
        <>
            <Grid container spacing={2} ref={lastContentRef}>
                <Grid item xs={12}>
                    <ProfileHeader />
                </Grid>
                <Grid item xs={2} sm={3} md={2}>
                    <ProfileNav />
                </Grid>
                <Grid item xs={10} sm={9} md={10}>
                    <Paper elevation={3} sx={{ border: '1px solid #ccc' }}>
                        <Box sx={{ padding: '20px' }}>
                            <Paper elevation={0} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '10px', }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>

                                    </Grid>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow sx={{
                                                    '& .MuiTableCell-root': {
                                                        textAlign: 'center',
                                                        fontWeight: 'bold',
                                                    }
                                                }}>
                                                    <TableCell>S.No</TableCell>
                                                    <TableCell>Right Name</TableCell>
                                                    <TableCell>Add</TableCell>
                                                    <TableCell>View</TableCell>
                                                    <TableCell>Edit</TableCell>
                                                    <TableCell>Delete</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {loading ? (
                                                <TableRow>
                                                    <TableCell colSpan={6} align="center">
                                                        <Box display="flex" padding='20px' alignItems="center" justifyContent="center" height="350px">
                                                            <CircularProgress />
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            ) : (
                                                <TableBody>
                                                    {Allrights?.SecurityList.map((security, index) => (
                                                        <TableRow key={security._id} sx={{
                                                            '& .MuiTableCell-root': {
                                                                height: '10px',
                                                                padding: '0',
                                                                textAlign: 'center',
                                                            },
                                                            '&:last-child td, &:last-child th': {
                                                                border: 0
                                                            }
                                                        }}>
                                                            <TableCell>{index + 1}</TableCell>
                                                            <TableCell>{security.right_name}</TableCell>
                                                            <TableCell>
                                                                <Checkbox
                                                                    color="primary"
                                                                    checked={selectedRights.includes(`${security.right_name}-A`)}
                                                                    onChange={(e) => handleRightCheckboxChange(security.right_name, 'A')}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Checkbox
                                                                    color="primary"
                                                                    checked={selectedRights.includes(`${security.right_name}-V`)}
                                                                    onChange={(e) => handleRightCheckboxChange(security.right_name, 'V')}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Checkbox
                                                                    color="primary"
                                                                    checked={selectedRights.includes(`${security.right_name}-E`)}
                                                                    onChange={(e) => handleRightCheckboxChange(security.right_name, 'E')}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Checkbox
                                                                    color="primary"
                                                                    checked={selectedRights.includes(`${security.right_name}-D`)}
                                                                    onChange={(e) => handleRightCheckboxChange(security.right_name, 'D')}
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            )}
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Paper>
                        </Box>
                    </Paper>
                </Grid>

                <Paper style={{ padding: '16px', position: 'fixed', bottom: '16px', zIndex: 1000, width: '60%', left: '0', right: '0', margin: '0 auto' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }}>
                        <Button style={{ marginRight: '8px' }} variant="contained" color="error" onClick={()=>{handlecancel()}}>
                            Cancel
                        </Button>
                        <Button style={{ marginLeft: '8px' }} variant="contained" color="primary" onClick={() => { UpdateUser() }} >
                            Save
                        </Button>
                    </div>
                </Paper>

                <Modal
                    open={successModalOpen}
                    onClose={handleSuccessModalClose}
                    aria-labelledby="success-modal-title"
                    aria-describedby="success-modal-description"
                >
                    <div style={{ ...modalStyle, borderColor: '#007bff' }}>
                        <h2 id="success-modal-title" style={{ color: '#007bff' }}>Success!</h2>
                        <p id="success-modal-description">{ActionMessage}</p>
                        <Button variant="contained" onClick={handleSuccessModalClose} style={{ backgroundColor: '#007bff', color: 'white' }}>Close</Button>
                    </div>
                </Modal>

                <Modal
                    open={errorModalOpen}
                    onClose={handleErrorModalClose}
                    aria-labelledby="error-modal-title"
                    aria-describedby="error-modal-description"
                >
                    <div style={{ ...modalStyle, borderColor: '#ff0000' }}>
                        <h2 id="error-modal-title" style={{ color: '#ff0000' }}>Error!</h2>
                        <p id="error-modal-description">{ActionMessage}.</p>
                        <Button variant="contained" onClick={handleErrorModalClose} style={{ backgroundColor: '#ff0000', color: 'white' }}>Close</Button>
                    </div>
                </Modal>
            </Grid>
        </>
    );
}

export default UserRightEdit;
