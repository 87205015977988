import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import { Button, Grid, Paper, Typography, TextField, Select, MenuItem, InputLabel } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { Container, Box } from '@mui/material';
import { useRef, useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function UserRightEdit() {
    const [userData, setUserData] = useState({});
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const [moreDetails, setMoreDetails] = useState('');
    const [admin, setAdmin] = useState('');
    const [jobWork, setJobWork] = useState('');
    const [co_codes, setco_codes] = useState();
    const [div_codes, setdiv_codes] = useState();
    const [checkedDivisions, setCheckedDivisions] = useState([]);
    const [checkedComps, setCheckedComps] = useState([]);

    const [data, setdata] = useState('');
    const [master, setMaster] = useState('');
    const [masterList, setmasterList] = useState([]);
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [ActionMessage, setActionMessage] = useState('');

    const navigate = useNavigate();

    const lastContentRef = useRef(null);
    useLayoutEffect(() => {
        if (lastContentRef.current) {
            lastContentRef.current.style.marginBottom = '80px';
        }
    }, []);

    const handleCheckboxChange = (event) => {
        const divisionId = event.target.value;
        const isChecked = event.target.checked;

        if (isChecked) {
            // If checked, add divisionId to the array
            setCheckedDivisions(prevCheckedDivisions => [...prevCheckedDivisions, divisionId]);
        } else {
            // If unchecked, remove divisionId from the array
            setCheckedDivisions(prevCheckedDivisions => prevCheckedDivisions.filter(id => id !== divisionId));
        }
    };

    const handleCheckboxCompChange = (event) => {
        const CompId = event.target.value;
        const isChecked = event.target.checked;

        if (isChecked) {
            // If checked, add CompId to the array
            setCheckedComps(prevcheckedComps => [...prevcheckedComps, CompId]);
        } else {
            // If unchecked, remove CompId from the array
            setCheckedComps(prevcheckedComps => prevcheckedComps.filter(id => id !== CompId));
        }
    };


    const MasterGET = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const masterid = userSchema?.masterid;

            const data = {
                user: userSchema?.usrnm,
                compid: JSON.parse(companyIdString),
                divid: JSON.parse(divIdString),
                masterid: userSchema?.masterid
            }
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/user_rights/MasterGET`, data,
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            setmasterList(response.data.lastEntryNo);
            const datas = response.data.lastEntryNo;
            const matchedObject = datas.find(item => item._id === masterid);
            setMaster(matchedObject);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const CompanyGET = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');

            const data = {
                user: userSchema?.usrnm,
                compid: JSON.parse(companyIdString),
                divid: JSON.parse(divIdString),
                masterid: userSchema?.masterid
            }
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/user_rights/CompanyGET`, data,
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            setco_codes(response.data.lastEntryNo);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const DivSchemaGET = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');

            const data = {
                user: userSchema?.usrnm,
                compid: JSON.parse(companyIdString),
                divid: JSON.parse(divIdString),
                masterid: userSchema?.masterid
            }
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/user_rights/DivSchemaGET`, data,
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            setdiv_codes(response.data.lastEntryNo);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    useEffect(() => {
        MasterGET()
        DivSchemaGET()
        CompanyGET()
    }, []);
    const AddUser = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const masterid = userSchema?.masterid;
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage

            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/user_rights/AddUser`,
                {
                    firstName: firstName,
                    lastName: lastName,
                    userName: userName,
                    password: password,
                    checkedComps,
                    checkedDivisions,
                    email: email,
                    phoneNo: phoneNo,
                    moreDetails: moreDetails,
                    master: master?._id,
                    admin: admin,
                    moreDetails: moreDetails,
                    user: userSchema?.usrnm,
                    compid: JSON.parse(companyIdString),
                    divid: JSON.parse(divIdString),
                    masterid: userSchema?.masterid,
                },
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );
            if (response.data.status) {
                setActionMessage(response.data.message)
                setSuccessModalOpen(true)
                navigate('/user_rights/users_list')
            }
            else {
                setActionMessage(response.data.message)
                setErrorModalOpen(true)

            }

        } catch (error) {
            setActionMessage('Some error occured.')
            setErrorModalOpen(true)
            console.error('Error fetching data:', error);
        }
    }

    const handleCancel = () => {
        setFirstName('');
        setLastName('');
        setUserName('');
        setPassword('');
        setEmail('');
        setPhoneNo('');
        setMoreDetails('');
        setMaster('');
        setAdmin('');
        setJobWork('');
        setCheckedDivisions([]);
        setCheckedComps([]);
    }

    const handleErrorModalClose = () => {
        setErrorModalOpen(false);
    };

    const handleSuccessModalClose = () => {
        setSuccessModalOpen(false);
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        border: '2px solid',
        boxShadow: 24,
        padding: 16,
        minWidth: 300,
        maxWidth: 500,
        textAlign: 'center'
    };

    return (
        <>
            <Container ref={lastContentRef}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Paper elevation={3} style={{ padding: '20px', marginBottom: '10px', borderRadius: '10px', background: 'linear-gradient(135deg, #FFFFFF,#60a5f2,#FFFFFF)', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {/* <img src={profileImage} alt="Profile" style={{ width: '70px', height: '70px', borderRadius: '50%', marginRight: '10px',border: '2px solid white' }} /> */}
                                {/* <img src={getImageUrlFromPath(`${PP}`)} alt="Profile" style={{ width: '95px', height: '95px', borderRadius: '50%', marginRight: '10px', border: '2px solid white' }} /> */}

                                <div>
                                    <Typography variant="h5" gutterBottom sx={{ fontWeight: '10px', color: 'black' }}>
                                        {"Add User"}
                                    </Typography>
                                </div>
                            </div>
                            <div>
                                <Button variant="contained" color="primary" style={{ marginRight: '10px' }}>Active</Button>
                                <Button variant="outlined" color="primary">Invite</Button>
                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                        <Paper elevation={3} sx={{ border: '1px solid #ccc' }}>

                            <Box sx={{ padding: '20px' }}>
                                <Paper elevation={0} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '10px', marginTop: '10px' }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                type="text"
                                                value={firstName}
                                                label='First Name'
                                                onChange={(e) => setFirstName(e.target.value)}
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                type="text"
                                                value={lastName}
                                                label='Last Name'
                                                onChange={(e) => setLastName(e.target.value)}
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                type="text"
                                                value={userName}
                                                label='User Name'
                                                onChange={(e) => setUserName(e.target.value)}
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                                <Paper elevation={0} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '10px', }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                type="password"
                                                value={password}
                                                label='Password'
                                                onChange={(e) => setPassword(e.target.value)}
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                type="email"
                                                value={email}
                                                label='Email'
                                                onChange={(e) => setEmail(e.target.value)}
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                type="number"
                                                value={phoneNo}
                                                label='Phone No'
                                                onChange={(e) => setPhoneNo(e.target.value)}
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                                <Divider sx={{ padding: '10px' }} />
                                <Paper elevation={0} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '10px', }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                type="text"
                                                value={moreDetails}
                                                label='More Details'
                                                onChange={(e) => setMoreDetails(e.target.value)}
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Autocomplete
                                                value={master}
                                                readOnly
                                                onChange={(e, newValue) => setMaster(newValue)}
                                                label='Master'
                                                options={masterList}
                                                getOptionLabel={(option) => option?.custname || ''}
                                                renderInput={(params) => <TextField {...params} label="Master"
                                                    inputProps={{ ...params.inputProps, 'aria-label': 'Select Department Type', style: { paddingTop: '0px' } }}
                                                    // sx={{ "& .MuiInputBase-root": { height: '30px', marginTop: "10px" } }}    
                                                    variant="outlined"
                                                    size="small" />}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>

                                <Divider sx={{ padding: '10px' }} />
                                <Paper elevation={0} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '10px', }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: '10px', marginBottom: '5px' }}>
                                                Company Name:
                                            </Typography>
                                            {co_codes?.map((company, index) => (
                                                <FormControlLabel
                                                    key={index}
                                                    control={
                                                        <Checkbox
                                                            checked={checkedComps.includes(company._id)}
                                                            onChange={handleCheckboxCompChange}
                                                            value={company._id}
                                                        />
                                                    }
                                                    label={company.com_name}
                                                    sx={{ marginRight: '50px' }}
                                                />
                                            ))}
                                        </Grid>
                                    </Grid>
                                </Paper>
                                <Divider sx={{ padding: '10px' }} />
                                <Paper elevation={0} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '10px', }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: '10px', marginBottom: '5px' }}>
                                                Division Name:
                                            </Typography>
                                            {div_codes?.map((division, index) => (
                                                <FormControlLabel
                                                    key={index}
                                                    control={
                                                        <Checkbox
                                                            checked={checkedDivisions.includes(division._id)} // Check if division is checked
                                                            onChange={handleCheckboxChange}
                                                            value={division._id}
                                                        />
                                                    }
                                                    label={division.div_mast}
                                                    sx={{ marginRight: '50px' }}
                                                />
                                            ))}
                                        </Grid>
                                    </Grid>
                                </Paper>
                                <Divider sx={{ padding: '10px' }} />
                                <Paper elevation={0} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '10px', }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Autocomplete
                                                value={admin}
                                                onChange={(e, newValue) => setAdmin(newValue)}
                                                options={['Yes', 'No']}
                                                renderInput={(params) => <TextField {...params} label="Admin"
                                                    inputProps={{ ...params.inputProps, 'aria-label': 'Select Department Type', style: { paddingTop: '0px' } }}
                                                    // sx={{ "& .MuiInputBase-root": { height: '30px', marginTop: "10px" } }}    
                                                    variant="outlined" size="small" />}
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Box>
                        </Paper>
                    </Grid>
                    <Paper style={{ padding: '16px', position: 'fixed', bottom: '16px', zIndex: 1000, width: '60%', left: '0', right: '0', margin: '0 auto' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }}>
                            <Button style={{ marginRight: '8px', color: 'white', backgroundColor: '#c91e32' }} variant="contained" onClick={(() => { handleCancel() })}>
                                Cancel
                            </Button>
                            <Button style={{ marginLeft: '8px', color: 'white', backgroundColor: '#2656ab' }} variant="contained" onClick={(() => { AddUser() })} >
                                Save
                            </Button>
                        </div>
                    </Paper>
                    <Modal
                        open={successModalOpen}
                        onClose={handleSuccessModalClose}
                        aria-labelledby="success-modal-title"
                        aria-describedby="success-modal-description"
                    >
                        <div style={{ ...modalStyle, borderColor: '#007bff' }}>
                            <h2 id="success-modal-title" style={{ color: '#007bff' }}>Success!</h2>
                            <p id="success-modal-description">{ActionMessage}</p>
                            <Button variant="contained" onClick={handleSuccessModalClose} style={{ backgroundColor: '#007bff', color: 'white' }}>Close</Button>
                        </div>
                    </Modal>

                    <Modal
                        open={errorModalOpen}
                        onClose={handleErrorModalClose}
                        aria-labelledby="error-modal-title"
                        aria-describedby="error-modal-description"
                    >
                        <div style={{ ...modalStyle, borderColor: '#ff0000' }}>
                            <h2 id="error-modal-title" style={{ color: '#ff0000' }}>Error!</h2>
                            <p id="error-modal-description">{ActionMessage}.</p>
                            <Button variant="contained" onClick={handleErrorModalClose} style={{ backgroundColor: '#ff0000', color: 'white' }}>Close</Button>
                        </div>
                    </Modal>
                </Grid>
            </Container>
        </>
    );
}

export default UserRightEdit;
