import React, { useState, useEffect, } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Container, Paper } from '@mui/material';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { useRef, useLayoutEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import Modal from '@mui/material/Modal';

const steps = ['Basic Details', 'Salary Structure'];
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#2196f3',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export default function StepperForm() {
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const [earnings, setearnings] = useState([]);
  const [deductions, setdeductions] = useState([]);
  const [EC, setEC] = useState([]);
  const [staff_type_values, setStaff_type_values] = useState([])
  const [staff_type, setStaff_Type] = useState(null);
  const [shiftType, setShifTtype] = useState([]);
  const [shiftTypeValue, setShifTtypeValue] = useState(null);
  const [SalryStucValues, setSalryStucValues] = useState([]);
  const [SalryStucType, setSalryStucType] = useState(null);
  const [DepValues, setDepValues] = useState([]);
  const [DepType, setDepType] = useState(null);
  const [Firstname, setFirstname] = useState('');
  const [Lastname, setLastname] = useState('');
  const [Fullname, setFullname] = useState('');
  const [PhoneNumber, setPhoneNumber] = useState('');
  const [MobileNumber, setMobileNumber] = useState('');
  const [GrossSal, setGrossSal] = useState(0);

  const [machineCode, setmachineCode] = useState('');
  const [SalaryCycle, setSalaryCycle] = useState(1);
  const [salaryDetails, setSalaryDetails] = React.useState(false);
  const [cycleSalaryAccess, setCycleSalaryAccess] = React.useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [actionMessage, setActionMessage] = useState('');

  const [nextMachineCode, setnextMachineCode] = useState('');

  const lastContentRef = useRef(null);
  useLayoutEffect(() => {
    if (lastContentRef.current) {
      lastContentRef.current.style.marginBottom = '60px';
    }
  }, []);
  const isStepOptional = (step) => {
    // return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleSave = async () => {
    try {
      if (MobileNumber && PhoneNumber) {
        if (MobileNumber === PhoneNumber) {
          setActionMessage('Contact Numbers Should Not Be Same');
          // handleCancel();
          setErrorModalOpen(true)
          return;
        }
      }
      if(!GrossSal){
        setActionMessage('Please Enter Gross Salary');
        setErrorModalOpen(true)
        return;
      }
      if(!DepType?._id){
        setActionMessage('Please choose a department to continue');
        setErrorModalOpen(true)
        return;
      }
      if(!staff_type?._id){
        setActionMessage('Please choose a staff type to continue');
        setErrorModalOpen(true)
        return;
      }
      if(!SalaryCycle){
        setActionMessage('Please Enter Salary Cycle');
        setErrorModalOpen(true)
        return;
      }
      if (!shiftTypeValue?._id) {
        setActionMessage('Please choose a shift to continue');
        // handleCancel();
        setErrorModalOpen(true)
        return;
      }
      const userSchemaString = localStorage.getItem('userSchema');
      const userSchema = JSON.parse(userSchemaString);
      const companyIdString = localStorage.getItem('companyId');
      const divIdString = localStorage.getItem('divId');
      // const salryhd_or_group = [...earnings, ...deductions, ...EC].map(obj => {
      //   const { _id, ...rest } = obj;
      //   return rest;
      // });
      const salryhd_or_group = [...earnings, ...deductions, ...EC]

      const newArray = salryhd_or_group.map(item => ({
        ...item,
        salaryhead_name: item.salaryhead_name || item._id,
        salryhd_odr: item.salryhd_odr || item.order,
        salryhd_sign: item.salryhd_sign || item.head_type,
        salryhd_calcb: item.salryhd_calcb || item.calculation_basis,
        salryhd_round: item.salryhd_round || item.round_of_zero,
        salryhd_vlu: item.salryhd_vlu || item.value
      }));

      const Newsalryhd_or_group = newArray.map(obj => {
        const { _id, ...rest } = obj;
        return rest;
      });
      const data = {
        first_name: Firstname,
        last_name: Lastname,
        full_name: Firstname + ' ' + Lastname,
        salaryCycle: SalaryCycle,
        mobile_no: PhoneNumber,
        emergencyContact: MobileNumber,
        default_shift: shiftTypeValue?._id,
        salary_stru: SalryStucType?._id,
        category: staff_type?._id,
        salryhd_or_group: Newsalryhd_or_group,
        salaryDetails: salaryDetails,
        cycleSalaryAccess: cycleSalaryAccess,
        department: DepType?._id,
        GrossSal:GrossSal,
        machine_code: machineCode,
        user: userSchema?.usrnm,
        compid: JSON.parse(companyIdString),
        divid: JSON.parse(divIdString),
        masterid: userSchema?.masterid
      };
      const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/employee/AddEmployee`, data, {
        headers: {
          authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          console.log(response)
          fetchData()
          if (response.data.status) {
            setActionMessage(response.data.message)
            setSuccessModalOpen(true);
            navigate(0);
            // handleCancel();

          } else {
            setActionMessage(response.data.message)
            // handleCancel();
            setErrorModalOpen(true)
          }
        })
        .catch(error => {
          console.log(error)
          setActionMessage("Ooops an error occured.")
          setErrorModalOpen(true)
        });
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  function calculateValue(data, salt, count) {
    const sal = salt * 12;
    const range = data?.find(item => sal >= parseInt(item.Limit) && sal <= parseInt(item.MaxLimit));

    if (!range) {
        return 0;
    }
    const valuePerMonth = parseInt(range.Value) / 12;
    const integerPart = Math.floor(valuePerMonth);
    const fractionalPart = valuePerMonth - integerPart;
    // console.log(fractionalPart.toFixed(2) )
    if (count < 12) {
        return integerPart;
    } else {
        return Math.ceil(fractionalPart?.toFixed(2) * 12) + integerPart;
    }
}

  useEffect(() => {
    if (earnings.length > 0) {
      const updatedEarnings = earnings.map((item, index) => {
        const value = item?.value || 0;
        const newValue = parseFloat(value) || 0;
        // Check if the salary head name is 'BASIC'
        // if (item.salaryheadName === 'BASIC') {
          const basicAmount = newValue;
          console.log(basicAmount)
          updateDeductions(basicAmount); // Update PF and ESIC based on BASIC
        // }

        return 0;
      });
    }
  }, [earnings, GrossSal]);


  const handleEarningsInputChange = (event, index) => {
    const { value } = event.target;
    setearnings(prevEarnings => {
      const updatedEarnings = [...prevEarnings];
      const newValue = parseFloat(value) || 0;

      updatedEarnings[index] = {
        ...updatedEarnings[index], value,
        salaryhead_name: updatedEarnings[index]._id,
        salryhd_sign: updatedEarnings[index].head_type,
        salryhd_odr: updatedEarnings[index].order,
        salryhd_calcb: updatedEarnings[index].calculation_basis,
        salryhd_round: updatedEarnings[index].round_of_zero,
        salryhd_vlu: value,
        FieldArr: updatedEarnings[index].FieldArr,
        LimitArr: updatedEarnings[index].LimitArr,
        max_limit: updatedEarnings[index].max_limit,
      };

      // if (updatedEarnings[index].salaryheadName === 'BASIC') {
        const basicAmount = newValue;
        updateDeductions(basicAmount); // Update PF and ESIC based on BASIC
      // }

      return updatedEarnings;
    });
  };

  const updateDeductions = (basicAmount) => {
    console.log(basicAmount)
    setdeductions(prevDeductions => {
      const updatedDeductions = prevDeductions.map(deduction => {
        if (deduction?.calculation_basis === "Fix Amount") {
          return {
            ...deduction,
            amount: (deduction?.value / 30 * 30).toFixed(2), // 12% of BASIC for PF
            // salryhd_vlu: (basicAmount * 0.12).toFixed(2)
          };
      }

      else if (deduction?.calculation_basis === "% of Basic") {
          if (deduction.salaryheadName === 'PF') {
            return {
              ...deduction,
              amount: ((basicAmount * Number(deduction?.value)) / 100).toFixed(2), // 12% of BASIC for PF
              // salryhd_vlu: (basicAmount * 0.12).toFixed(2)
            };
          } else if (deduction.salaryheadName === 'ESIC') {
            return {
              ...deduction,
              amount: ((basicAmount * Number(deduction?.value)) / 100).toFixed(2), // 0.75% of BASIC for ESIC
              // salryhd_vlu: (basicAmount * 0.0075).toFixed(2)
            };
          }
        }

        else if (deduction?.calculation_basis === "% of Gross") {
          if (deduction.salaryheadName === 'ESIC') {
            return {
              ...deduction,
              amount: ((parseFloat(GrossSal) / 30 * 30)* deduction?.value / 100).toFixed(2), // 0.75% of BASIC for ESIC
              // salryhd_vlu: (basicAmount * 0.0075).toFixed(2)
            };
          }
          else {
            return {
              ...deduction,
              amount: ((parseFloat(GrossSal) / 30 * 30)* deduction?.value/ 100).toFixed(2), // 0.75% of BASIC for ESIC
              // salryhd_vlu: (basicAmount * 0.0075).toFixed(2)
            };
          }
        }

        else if (deduction?.calculation_basis === "Formula") {
          const PercField = deduction?.FieldArr || [];
          const matchingData = earnings.filter(item => PercField.includes(item?._id));
          const totalPercentage = matchingData.reduce((acc, item) => acc + (parseFloat(item?.value || 0) * parseFloat(deduction?.value) / 100), 0);
          return {
            ...deduction,
            amount: totalPercentage.toFixed(2),
            // salryhd_vlu: (basicAmount * 0.0075).toFixed(2)
          };
        }

        else if (deduction?.calculation_basis === "Slab") {
          const PercField = deduction?.LimitArr || [];
          const result = calculateValue(PercField, parseFloat(GrossSal), 1);
          // console.log("**********",result);
          if (deduction.salaryheadName === 'Professional Tax') {
            return {
              ...deduction,
              amount: result, 
            };
          }
          // return result;
      }
        return deduction;
      });
      return updatedDeductions;
    });
  };

  const handleDeductionsInputChange = (event, index) => {
    const { value } = event.target;
    setdeductions(prevEarnings => {
      const updatedEarnings = [...prevEarnings];
      updatedEarnings[index] = {
        ...updatedEarnings[index], value,
        salaryhead_name: updatedEarnings[index]._id,
        salryhd_sign: updatedEarnings[index].head_type,
        salryhd_odr: updatedEarnings[index].order,
        salryhd_calcb: updatedEarnings[index].calculation_basis,
        salryhd_round: updatedEarnings[index].round_of_zero,
        salryhd_vlu: updatedEarnings[index].salryhd_vlu,
        FieldArr: updatedEarnings[index].FieldArr,
        LimitArr: updatedEarnings[index].LimitArr,
        max_limit: updatedEarnings[index].max_limit,
        // salryhd_vlu: value,
        amount: value
      }; return updatedEarnings;
    });
  };

  const handleECInputChange = (event, index) => {
    const { value } = event.target;
    setEC(prevEarnings => {
      const updatedEarnings = [...prevEarnings];
      updatedEarnings[index] = {
        ...updatedEarnings[index], value,
        salaryhead_name: updatedEarnings[index]._id,
        salryhd_sign: updatedEarnings[index].head_type,
        salryhd_odr: updatedEarnings[index].order,
        salryhd_calcb: updatedEarnings[index].calculation_basis,
        salryhd_round: updatedEarnings[index].round_of_zero,
        salryhd_vlu: value
      }; return updatedEarnings;
    });
  };

  const handleDeductionDelete = (id) => {
    const updatedDeductions = deductions.filter(item => item._id !== id);
    setdeductions(updatedDeductions);
  };
  const handleEarningDelete = (id) => {
    const updatedEarnings = earnings.filter(item => item._id !== id);
    setearnings(updatedEarnings);
  };
  const handleEcDelete = (id) => {
    const updatedEc = EC.filter(item => item._id !== id);
    setEC(updatedEc);
  };

  const fetchData = async () => {
    try {
      const userSchemaString = localStorage.getItem('userSchema');
      const userSchema = JSON.parse(userSchemaString);
      const companyIdString = localStorage.getItem('companyId');
      const divIdString = localStorage.getItem('divId');


      const data = {
        user: userSchema?.usrnm,
        compid: JSON.parse(companyIdString),
        divid: JSON.parse(divIdString),
        masterid: userSchema?.masterid
      };
      const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/salary_master/salaryHeadGet`, data,
        {
          headers: {
            authorization: `Bearer ${token}`
          }
        }
      );

      // setearnings(response.data.earnings)
      // setdeductions(response.data.deductions)
      // setEC(response.data.EC)
      setStaff_type_values(response.data.staff_type)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const SalaryStrucfetchData = async () => {
    try {
      const userSchemaString = localStorage.getItem('userSchema');
      const userSchema = JSON.parse(userSchemaString);
      const companyIdString = localStorage.getItem('companyId');
      const divIdString = localStorage.getItem('divId');
      const token = localStorage.getItem('user-token'); // Get JWT token from localStorage

      if (!userSchema || !companyIdString || !divIdString || !token) {
        console.error('Missing user schema, company ID, division ID, or JWT token.');
        return;
      }

      const data = {
        user: userSchema.usrnm,
        compid: JSON.parse(companyIdString),
        divid: JSON.parse(divIdString),
        masterid: userSchema.masterid,
        salarystru_name: SalryStucType?.salarystru_name
      };

      let response;
      if (SalryStucType) {
        response = await axios.post(
          `${process.env.REACT_APP_API_URL}/employee/salaryHeadGet`,
          data,
          {
            headers: {
              authorization: `Bearer ${token}`
            }
          }
        );
      } else {
        response = await axios.post(
          `${process.env.REACT_APP_API_URL}/salary_master/salaryHeadGet`, data,
          {
            params: data,
            headers: {
              authorization: `Bearer ${token}`
            }
          }
        );
      }

      // Update state with fetched data
      setearnings(response.data.earnings || []);
      setdeductions(response.data.deductions || []);
      setEC(response.data.EC || []);
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle errors here, e.g., show an error message to the user
    }
  };


  const shiftTypeGET = async () => {
    const userSchemaString = localStorage.getItem('userSchema');
    const userSchema = JSON.parse(userSchemaString);
    const companyIdString = localStorage.getItem('companyId');
    const divIdString = localStorage.getItem('divId');


    const data = {
      user: userSchema?.usrnm,
      compid: JSON.parse(companyIdString),
      divid: JSON.parse(divIdString),
      masterid: userSchema?.masterid
    };
    try {
      const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/attendance/shiftTypeGET`, data,
        {
          headers: {
            authorization: `Bearer ${token}`
          }
        }
      );

      setShifTtype(response.data.lastEntryNo);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const SalryStrucGET = async () => {
    const userSchemaString = localStorage.getItem('userSchema');
    const userSchema = JSON.parse(userSchemaString);
    const companyIdString = localStorage.getItem('companyId');
    const divIdString = localStorage.getItem('divId');


    const data = {
      user: userSchema?.usrnm,
      compid: JSON.parse(companyIdString),
      divid: JSON.parse(divIdString),
      masterid: userSchema?.masterid
    };
    try {
      const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/attendance/salaryStrucGET`, data,
        {
          headers: {
            authorization: `Bearer ${token}`
          }
        }
      );

      setSalryStucValues(response.data.lastEntryNo);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const DepTypeGET = async () => {
    const userSchemaString = localStorage.getItem('userSchema');
    const userSchema = JSON.parse(userSchemaString);
    const companyIdString = localStorage.getItem('companyId');
    const divIdString = localStorage.getItem('divId');

    const data = {
      user: userSchema?.usrnm,
      compid: JSON.parse(companyIdString),
      divid: JSON.parse(divIdString),
      masterid: userSchema?.masterid
    };

    try {
      const token = localStorage.getItem('user-token');
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/employee/DepartmentTypeGET`,
        data,
        {
          headers: {
            authorization: `Bearer ${token}`
          }
        }
      );

      setDepValues(response.data.lastEntryNo);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const EmpolyeeIDMake = async () => {
    const userSchemaString = localStorage.getItem('userSchema');
    const userSchema = JSON.parse(userSchemaString);
    const companyIdString = localStorage.getItem('companyId');
    const divIdString = localStorage.getItem('divId');

    const data = {
      user: userSchema?.usrnm,
      compid: JSON.parse(companyIdString),
      divid: JSON.parse(divIdString),
      masterid: userSchema?.masterid
    };

    try {
      const token = localStorage.getItem('user-token');
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/employee/EmpolyeeIDMake`,
        data,
        {
          headers: {
            authorization: `Bearer ${token}`
          }
        }
      );

      setmachineCode(response.data.nextMachineCode);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    EmpolyeeIDMake();
  }, []);

  useEffect(() => {
    SalaryStrucfetchData();
  }, [SalryStucType]);

  useEffect(() => {
    fetchData();
    shiftTypeGET();
    SalryStrucGET();
    DepTypeGET();
  }, []);

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    border: '2px solid',
    boxShadow: 24,
    padding: 16,
    minWidth: 300,
    maxWidth: 500,
    textAlign: 'center'
  };
  const handleSuccessModalClose = () => {
    setSuccessModalOpen(false);
  };

  const handleErrorModalClose = () => {
    setErrorModalOpen(false);
  };

  const SalaryCyclenumbers = Array.from({ length: 31 }, (_, index) => index + 1);


  return (
    <Container sx={{ marginTop: '20px' }} ref={lastContentRef}>
      <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: '10px', width: '410px', fontSize: '20px' }}>
        Add Regular Staff
      </Typography>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ width: '30%' }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              if (isStepOptional(index)) {
                labelProps.optional = (
                  <Typography variant="caption">Optional</Typography>
                );
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>

        {activeStep === 0 && (
          <div style={{ padding: '20px' }}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={12}>
                <Paper elevation={3} style={{ padding: '20px' }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: 'auto', marginBottom: '10px' }}>
                    Basic Details
                  </Typography>
                  <Divider /> {/* Divider */}
                  <div style={{ marginBottom: '10px', marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body1" sx={{ marginRight: '20px', width: '410px', marginLeft: '10px' }}>
                      First Name
                    </Typography>
                    <FormControl style={{ flex: '0.9', minWidth: '400px', }}>
                      <input
                        type="text"
                        value={Firstname}
                        onChange={(e) => setFirstname(e.target.value)}
                        style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', flex: '0.38' }} // Adjusted flex to take half of the container width
                      />
                    </FormControl>
                  </div>
                  <Divider /> {/* Divider */}
                  <div style={{ marginBottom: '10px', marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body1" sx={{ marginRight: '20px', width: '410px', marginLeft: '10px' }}>
                      Last Name
                    </Typography>
                    <FormControl style={{ flex: '0.9', minWidth: '400px', }}>
                      <input
                        type="text"
                        value={Lastname}
                        onChange={(e) => setLastname(e.target.value)}
                        style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', flex: '0.38' }} // Adjusted flex to take half of the container width
                      />
                    </FormControl>
                  </div> <Divider /> {/* Divider */}
                  <div style={{ marginBottom: '10px', marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body1" sx={{ marginRight: '20px', width: '410px', marginLeft: '10px' }}>
                      Full Name
                    </Typography>
                    <FormControl style={{ flex: '0.9', minWidth: '400px', }}>
                      <input
                        type="text"
                        value={Firstname + ' ' + Lastname} // Concatenate Firstname and Lastname here
                        readOnly
                        // onChange={(e) => setFullname(e.target.value)}
                        style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', flex: '0.38' }} // Adjusted flex to take half of the container width
                      />
                    </FormControl>
                  </div>
                  <Divider /> {/* Divider */}
                  <div style={{ marginBottom: '10px', marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body1" sx={{ marginRight: '20px', width: '410px', marginLeft: '10px' }}>
                      Mobile Number
                    </Typography>
                    <FormControl style={{ flex: '0.9', minWidth: '400px', }}>
                      <input
                        type="number"
                        value={PhoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', flex: '0.38' }} // Adjusted flex to take half of the container width
                      />
                    </FormControl>
                  </div>
                  <div style={{ marginBottom: '10px', marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body1" sx={{ marginRight: '20px', width: '410px', marginLeft: '10px' }}>
                      Alternative Mobile Number
                    </Typography>
                    <FormControl style={{ flex: '0.9', minWidth: '400px', }}>
                      <input
                        type="number"
                        value={MobileNumber}
                        onChange={(e) => setMobileNumber(e.target.value)}
                        style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', flex: '0.38' }} // Adjusted flex to take half of the container width
                      />
                    </FormControl>
                  </div>
                  <Divider /> {/* Divider */}
                  <div style={{ marginBottom: '10px', marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body1" sx={{ marginRight: '20px', width: '410px', marginLeft: '10px' }}>
                      Employee ID
                    </Typography>
                    <FormControl style={{ flex: '0.9', minWidth: '400px', }}>
                      <input
                        type="text"
                        readOnly
                        value={machineCode}
                        onChange={(e) => setmachineCode(e.target.value)}
                        style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', flex: '0.38' }} // Adjusted flex to take half of the container width
                      />
                    </FormControl>
                  </div>
                </Paper>
              </Grid>
              <Grid item xs={12} md={12}>
                <Paper elevation={3} style={{ padding: '20px' }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: 'auto', marginBottom: '10px' }}>
                    Salary Details
                  </Typography>
                  <Divider /> {/* Divider */}
                  <div style={{ marginBottom: '10px', marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body1" sx={{ marginRight: '20px', width: '410px', marginLeft: '10px' }}>
                      Salary Cycle
                    </Typography>
                    {/* <FormControl style={{ flex: '0.9', minWidth: '400px', }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={SalaryCycle}
                          onChange={(date) => setSalaryCycle(date)}
                        />
                      </LocalizationProvider>
                    </FormControl> */}
                    <FormControl style={{ flex: '0.9', minWidth: '400px', }}>
                      <Autocomplete
                        value={SalaryCycle}
                        onChange={(event, newValue) => {
                          setSalaryCycle(newValue);
                        }}
                        options={SalaryCyclenumbers}
                        style={{ height: '38px', fontSize: '14px' }}
                        renderInput={(params) => <TextField {...params} variant="outlined"

                          inputProps={{ ...params.inputProps, 'aria-label': 'Select Department Type', style: { paddingTop: '0px' } }}
                          sx={{
                            "& .MuiInputBase-root": { height: "38px" },
                          }}
                        />}
                      />
                    </FormControl>
                  </div>
                  <Divider /> {/* Divider */}
                  <div style={{ marginBottom: '10px', marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body1" sx={{ marginRight: '20px', width: '410px', marginLeft: '10px' }}>
                      Staff Type
                    </Typography>
                    <FormControl style={{ flex: '0.9', minWidth: '400px', }}>
                      {/* <input
                    type="text"
                    value={""}
                    style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', flex: '0.38' }} // Adjusted flex to take half of the container width
                  /> */}
                      {/* <Autocomplete
                        value={staff_type}
                        onChange={(event, newValue) => {
                          setStaff_Type(newValue);
                        }}
                        options={staff_type_values.map((type) => type.staff_type)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // label="Select Staff Type"
                            // variant="outlined"
                            inputProps={{ ...params.inputProps, 'aria-label': 'Select Staff Type', style: { paddingTop: '0px' } }}
                            sx={{
                              "& .MuiInputBase-root": { height: "38px", },
                            }} />
                        )}
                      /> */}
                      <Autocomplete
                        value={staff_type}
                        onChange={(event, newValue) => {
                          setStaff_Type(newValue);
                        }}
                        options={staff_type_values}
                        getOptionLabel={(option) => option.staff_type}
                        getOptionSelected={(option, value) => option._id === value}
                        style={{ height: '38px', fontSize: '14px' }}
                        renderInput={(params) => <TextField {...params} variant="outlined"

                          inputProps={{ ...params.inputProps, 'aria-label': 'Select Department Type', style: { paddingTop: '0px' } }}
                          sx={{
                            "& .MuiInputBase-root": { height: "38px" },
                          }}
                        />}
                      />
                    </FormControl>
                  </div>
                  <Divider /> {/* Divider */}
                  <div style={{ marginBottom: '10px', marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body1" sx={{ marginRight: '20px', width: '410px', marginLeft: '10px' }}>
                      Department Type
                    </Typography>
                    <FormControl style={{ flex: '0.9', minWidth: '400px', }}>
                      {/* <input
                    type="text"
                    value={""}
                    style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', flex: '0.38' }} // Adjusted flex to take half of the container width
                  /> */}
                      <Autocomplete
                        value={DepType}
                        onChange={(event, newValue) => {
                          setDepType(newValue);
                        }}
                        options={DepValues}
                        getOptionLabel={(option) => option.description}
                        getOptionSelected={(option, value) => option._id === value}
                        style={{ height: '38px', fontSize: '14px' }}
                        renderInput={(params) => <TextField {...params} variant="outlined"

                          inputProps={{ ...params.inputProps, 'aria-label': 'Select Department Type', style: { paddingTop: '0px' } }}
                          sx={{
                            "& .MuiInputBase-root": { height: "38px" },
                          }}
                        />}
                      />
                    </FormControl>
                  </div>
                  <Divider /> {/* Divider */}
                  <div style={{ marginBottom: '10px', marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body1" sx={{ marginRight: '20px', width: '410px', marginLeft: '10px' }}>
                      Salary Structure Template
                    </Typography>
                    <FormControl style={{ flex: '0.9', minWidth: '400px', }}>
                      {/* <input
                    type="text"
                    value={""}
                    style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', flex: '0.38' }} // Adjusted flex to take half of the container width
                  /> */}
                      <Autocomplete
                        value={SalryStucType}
                        onChange={(event, newValue) => {
                          setSalryStucType(newValue);
                        }}
                        options={SalryStucValues}
                        getOptionLabel={(option) => option.salarystru_name}
                        getOptionSelected={(option, value) => option._id === value}
                        style={{ height: '38px', fontSize: '14px' }}
                        renderInput={(params) => <TextField {...params} variant="outlined"

                          inputProps={{ ...params.inputProps, 'aria-label': 'Select Staff Type', style: { paddingTop: '0px' } }}
                          sx={{
                            "& .MuiInputBase-root": { height: "38px" },
                          }}
                        />}
                      />
                    </FormControl>
                  </div>
                  {/* <Divider /> Divider */}
                  {/* <div style={{ marginBottom: '20px', marginTop: '20px', display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body1" sx={{ marginRight: '20px', width: '410px', marginLeft: '10px' }}>
                      Opening Balance
                    </Typography>
                    <FormControl style={{ flex: '0.9', minWidth: '200px', }}>
                      <Select
                        // value={calculationBasis}
                        // onChange={(e) => setCalculationBasis(e.target.value)}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Staff Type' }}
                        style={{ height: '38px', fontSize: '14px' }} // Adjust height and font size

                      >
                        <MenuItem value="" disabled >
                          Opening Balance
                        </MenuItem>
                        <MenuItem value="Opening Balance">Opening Balance
                        </MenuItem>

                      </Select>
                    </FormControl>

                    <FormControl style={{ flex: '0.9', minWidth: '200px', }}>
                      <input
                        type="text"
                        value={"Default"}
                        style={{ marginLeft: '20px', padding: '10px', borderRadius: '5px', border: '1px solid #ccc', flex: '0.38' }} // Adjusted flex to take half of the container width
                      />
                    </FormControl>
                  </div> */}
                  <Divider /> {/* Divider */}
                  <div style={{ marginBottom: '10px', marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body1" sx={{ marginRight: '20px', width: '410px', marginLeft: '10px' }}>
                      Select Shift
                    </Typography>
                    <FormControl style={{ flex: '0.9', minWidth: '400px', }}>
                      {/* <input
                    type="text"
                    value={""}
                    style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', flex: '0.38' }} // Adjusted flex to take half of the container width
                  /> */}
                      {/* <Select
                        value={shiftTypeValue}
                        onChange={(e) => { setShifTtypeValue(e.target.value) }}
                        style={{ height: '38px', fontSize: '14px' }} // Adjust height and font size
                      >
                        {shiftType.map((option, index) => (
                          <MenuItem key={index} value={option._id}>{option.shift_name}</MenuItem>
                        ))}
                      </Select> */}
                      <Autocomplete
                        value={shiftTypeValue}
                        onChange={(event, newValue) => {
                          setShifTtypeValue(newValue);
                        }}
                        options={shiftType}
                        getOptionLabel={(option) => option.shift_name}
                        getOptionSelected={(option, value) => option._id === value}
                        style={{ height: '38px', fontSize: '14px' }}
                        renderInput={(params) => <TextField {...params} variant="outlined"

                          inputProps={{ ...params.inputProps, 'aria-label': 'Select Staff Type', style: { paddingTop: '0px' } }}
                          sx={{
                            "& .MuiInputBase-root": { height: "38px" },
                          }}
                        />}
                      />
                    </FormControl>
                  </div>
                  <Divider /> {/* Divider */}
                  <div style={{ marginBottom: '10px', marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body1" sx={{ marginRight: '20px', width: '410px', marginLeft: '10px' }}>
                      Salary Details Access
                    </Typography>
                    <FormControl style={{ flex: '0.9', minWidth: '400px', }}>
                      <FormControlLabel
                        control={<IOSSwitch sx={{ m: 1 }} />}
                        onChange={(e) => setSalaryDetails(e.target.checked)}
                        label={salaryDetails ? 'Allow' : "Don't Allow"}
                        checked={salaryDetails}
                      />
                    </FormControl>
                  </div>
                  <Divider /> {/* Divider */}
                  <div style={{ marginBottom: '10px', marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body1" sx={{ marginRight: '20px', width: '410px', marginLeft: '10px' }}>
                      Allow Current Cycle Salary Access
                    </Typography>
                    <FormControl style={{ flex: '0.9', minWidth: '400px', }}>
                      <FormControlLabel
                        control={<IOSSwitch sx={{ m: 1 }} />}
                        onChange={(e) => setCycleSalaryAccess(e.target.checked)}
                        label={cycleSalaryAccess ? 'Allow' : "Don't Allow"}
                        checked={cycleSalaryAccess}
                      />
                    </FormControl>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </div>
        )}
        {activeStep === 1 && (
          <div style={{ padding: '20px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
              <Paper elevation={3} style={{ padding: '5px' }}>
              <div style={{ marginBottom: '10px', marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body1" sx={{ marginRight: '20px', width: '150px', marginLeft: '10px', fontWeight:'bold' }}>
                      Gross Salary
                    </Typography>
                    <FormControl style={{ flex: '0.2', }}>
                      <input
                        type="number"
                        value={GrossSal}
                        onChange={(e) => setGrossSal(e.target.value)}
                        style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', flex: '0.38' }} // Adjusted flex to take half of the container width
                      />
                    </FormControl>
                  </div>
                  </Paper>
                  <Divider /> {/* Divider */}
                <Paper elevation={3} style={{ padding: '20px' }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: 'auto', marginBottom: '10px' }}>
                    Earnings
                  </Typography>
                  {/* <Divider /> Divider */}
                  {earnings.map((item, index) => (
                    <React.Fragment key={item._id}>
                      <Divider />
                      <div style={{ marginBottom: '10px', marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1" sx={{ marginRight: '20px', width: '410px', marginLeft: '10px' }}>
                          {item.salaryheadName}
                        </Typography>
                        <FormControl style={{ flex: '0.7', minWidth: '100px', }}>
                          <input
                            type="number"
                            min="0" // Prevents entering values less than 0
                            value={item.value || ""} // Use item.value as the input value
                            onChange={(event) => {
                              const newValue = event.target.value;
                              if (newValue >= 0) { // Check if the value is non-negative
                                handleEarningsInputChange(event, index);
                              }
                            }}
                            style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', flex: '0.38' }} // Adjusted flex to take half of the container width
                          />
                        </FormControl>
                        <FormControlLabel
                          control={<IconButton onClick={() => handleEarningDelete(item._id)}><DeleteIcon /></IconButton>}
                          sx={{ marginInlineStart: '200px', maxWidth: 'fit-content' }} // Adjust maxWidth to fit-content
                        />
                      </div>
                    </React.Fragment>
                  ))}
                  {/* <Button variant="contained" onClick={() => console.log("Assign More clicked")} sx={{ marginLeft: 'auto' }}>
                    Add More
                  </Button> */}
                </Paper>
              </Grid>
              <Grid item xs={12} md={12}>
                <Paper elevation={3} style={{ padding: '20px' }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: 'auto', marginBottom: '10px' }}>
                    Deductions
                  </Typography>
                  {/* <Divider /> Divider */}
                  {deductions.map((item, index) => (
                    <React.Fragment key={item._id}>
                      <Divider />
                      <div style={{ marginBottom: '10px', marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1" sx={{ marginRight: '20px', width: '410px', marginLeft: '10px' }}>
                          {item.salaryheadName}
                        </Typography>
                        <FormControl style={{ flex: '0.7', minWidth: '100px', }}>
                          <input
                            type="number"
                            min="0" // Prevents entering values less than 0
                            value={item.amount || ""} // Use item.value as the input value
                            onChange={(event) => {
                              const newValue = event.target.value;
                              if (newValue >= 0) { // Check if the value is non-negative
                                handleDeductionsInputChange(event, index);
                              }
                            }}
                            // onChange={(event) => handleDeductionsInputChange(event, index)}
                            style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', flex: '0.38' }} // Adjusted flex to take half of the container width
                          />
                        </FormControl>
                        <FormControlLabel
                          control={<IconButton onClick={() => handleDeductionDelete(item._id)}><DeleteIcon /></IconButton>} // Pass item._id to the click handler
                          sx={{ marginInlineStart: '200px', maxWidth: 'fit-content' }} />
                      </div>
                    </React.Fragment>
                  ))}

                  {/* <Button variant="contained" onClick={() => console.log("Assign More clicked")} sx={{ marginLeft: 'auto' }}>
                    Add More
                  </Button> */}
                </Paper>
              </Grid>
              <Grid item xs={12} md={12}>
                <Paper elevation={3} style={{ padding: '20px' }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: 'auto', marginBottom: '10px' }}>
                    Employer's Contribution
                  </Typography>
                  {/* <Divider /> Divider */}
                  {EC.map((item, index) => (
                    <React.Fragment key={item._id}>
                      <Divider />
                      <div style={{ marginBottom: '10px', marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1" sx={{ marginRight: '20px', width: '410px', marginLeft: '10px' }}>
                          {item.salaryheadName}
                        </Typography>
                        <FormControl style={{ flex: '0.7', minWidth: '100px', }}>
                          <input
                            type="number"
                            value={item.value || ""} // Use item.value as the input value
                            onChange={(event) => handleECInputChange(event, index)} style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', flex: '0.38' }} // Adjusted flex to take half of the container width
                          />
                        </FormControl>
                        <FormControlLabel
                          control={<IconButton onClick={() => handleEcDelete(item._id)}><DeleteIcon /></IconButton>} // Pass item._id to the click handler
                          sx={{ marginInlineStart: '200px', maxWidth: 'fit-content' }} />
                      </div>
                    </React.Fragment>
                  ))}
                  {/* <Button variant="contained" onClick={() => console.log("Assign More clicked")} sx={{ marginLeft: 'auto' }}>
                    Add More
                  </Button> */}
                </Paper>
              </Grid>

            </Grid>
          </div>
        )}

        <Paper
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 999,
            padding: 2,
            width: '60%',
            margin: '0 auto'
          }}
        >
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  sx={{ bgcolor: '#4f87d6', color: '#fff', '&:hover': { bgcolor: '#4f87d6' } }} // Blue background color
                  onClick={handleReset}
                >
                  Reset
                </Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  color="inherit"
                  variant=''
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1, bgcolor: '#4f87d6', color: '#fff', '&:hover': { bgcolor: '#4f87d6' } }} // Red background color
                >
                  Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                {isStepOptional(activeStep) && (
                  <Button
                    color="inherit"
                    onClick={handleSkip}
                    sx={{ bgcolor: '#4f87d6', color: '#fff', '&:hover': { bgcolor: '#4f87d6' }, mr: 1 }} // Green background color
                  >
                    Skip
                  </Button>
                )}
                <Button
                  onClick={() => {
                    if (activeStep === steps.length - 1) {
                      // Call handleNext when 'Onboard Staff' is clicked
                      handleSave();
                    } else {
                      // Call handleNext for other cases
                      handleNext();
                    }
                  }} sx={{ bgcolor: '#4f87d6', color: '#fff', '&:hover': { bgcolor: '#4f87d6' } }} // Red background color
                >
                  {activeStep === steps.length - 1 ? 'Onboard Staff' : 'Next'}
                </Button>
              </Box>
            </React.Fragment>
          )}
        </Paper>
      </Box>
      <Modal
        open={successModalOpen}
        onClose={handleSuccessModalClose}
        aria-labelledby="success-modal-title"
        aria-describedby="success-modal-description"
      >
        <div style={{ ...modalStyle, borderColor: '#007bff' }}>
          <h2 id="success-modal-title" style={{ color: '#007bff' }}>Success!</h2>
          <p id="success-modal-description">{actionMessage}</p>
          <Button variant="contained" onClick={handleSuccessModalClose} style={{ backgroundColor: '#007bff', color: 'white' }}>Close</Button>
        </div>
      </Modal>
      <Modal
        open={errorModalOpen}
        onClose={handleErrorModalClose}
        aria-labelledby="error-modal-title"
        aria-describedby="error-modal-description"
      >
        <div style={{ ...modalStyle, borderColor: '#ff0000' }}>
          <h2 id="error-modal-title" style={{ color: '#ff0000' }}>Error!</h2>
          <p id="error-modal-description">{actionMessage}.</p>
          <Button variant="contained" onClick={handleErrorModalClose} style={{ backgroundColor: '#ff0000', color: 'white' }}>Close</Button>
        </div>
      </Modal>
    </Container>
  );
}
