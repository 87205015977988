import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const NotFound = () => {
    return (
        <div style={styles.container}>
            <h1 style={styles.heading}>
                <FontAwesomeIcon icon={faExclamationCircle} style={styles.icon} /> 404 - Page Not Found
            </h1>
            <p style={styles.paragraph}>The page you are looking for does not exist.</p>
        </div>
    );
};

const styles = {
    container: {
        textAlign: 'center',
        marginTop: '100px',
    },
    heading: {
        fontSize: '3em',
        color: '#333',
        marginBottom: '20px',
    },
    paragraph: {
        fontSize: '1.2em',
        color: '#666',
    },
    icon: {
        marginRight: '10px', // Add some space between the icon and the text
    },
};

export default NotFound;
