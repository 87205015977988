import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Grid, Divider, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import { useNavigate } from 'react-router-dom';
import ProfileNav from './profileNav';
import ProfileHeader from './profileheader';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

function ProfileDocumentCenter() {
    const [DocDataList, setDocDataList] = useState([]);
    const [fileStates, setFileStates] = useState({});
    const [remarkStates, setRemarkStates] = useState({});
    const [files, setFiles] = useState([]);
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [actionMessage, setActionMessage] = useState('');
    const navigate = useNavigate();


    const [showSlider, setShowSlider] = useState(false);
    const [sliderImages, setSliderImages] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);

    function concatenateUrls(filenames, baseUrl) {
        return filenames
            .filter(filename => {
                const fileType = getFileType(filename.fileName);
                return fileType !== 'pdf' && fileType !== 'excel';
            })
            .map(filename => {
                const isBlob = filename.file.startsWith('blob:');
                return isBlob ? filename.file : baseUrl + filename.fileName;
            });
    }

    const handleImageClick = async (imageUrls, imageIndex, entryID) => {
        // const fileType = getFileType(fileStates[entryID].fileName[imageIndex].);
        setCurrentIndex(imageIndex);
        setSliderImages(await concatenateUrls(imageUrls, `${process.env.REACT_APP_API_URL}/uploads/`));
        setShowSlider(true);
    };

    const downloadFile = async (file) => {
        try {
            if (file.file.startsWith('blob:')) {
                // Directly handle blob URLs
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = file.file;
                a.download = file.fileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            } else {
                // Fetch and handle regular URLs
                const response = await fetch(file.file, {
                    headers: {
                        'Content-Type': 'application/octet-stream'
                    }
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = file.fileName;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            }
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };
    const handleCloseSlider = () => {
        setShowSlider(false);
    };

    const settings = {
        customPaging: function (i) {
            return (
                <div>
                    <img
                        src={sliderImages[i]}
                        alt=""
                        style={{
                            height: "50px",
                            width: "50px",
                            objectFit: "cover",
                            borderRadius: "10px"
                        }}
                    />
                </div>
            );
        },
        dots: true,
        cssEase: "linear",
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: currentIndex, // Start with the third image
        beforeChange: (oldIndex, newIndex) => setCurrentIndex(newIndex)
    };

    const lastContentRef = useRef(null);
    useLayoutEffect(() => {
        if (lastContentRef.current) {
            lastContentRef.current.style.marginBottom = '70px';
        }
    }, []);

    function truncateString(str, maxLength) {
        if (str.length > maxLength) {
            return str.substring(0, maxLength - 3) + '...';
        }
        return str;
    }

    const handlesave = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const token = localStorage.getItem('user-token');

            const mergedData = Object.keys(fileStates).reduce((acc, key) => {
                acc[key] = { files: fileStates[key].files, remark: remarkStates[key] || "" };
                return acc;
            }, {});

            function generateRandomString() {
                const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+';
                let randomString = '';
                for (let i = 0; i < 8; i++) {
                    const randomIndex = Math.floor(Math.random() * characters.length);
                    randomString += characters.charAt(randomIndex);
                }
                return randomString;
            }
            const randomString = await generateRandomString();
            
            for (const key in mergedData) {
                mergedData[key].files.forEach(fileObj => {
                    const currentDate = new Date().toISOString().slice(0, 10);
                    const originalFileName = fileObj.fileName;
                    // Check if the file name already contains a timestamp
                    if (!originalFileName.includes("iwtfar&sl")) {
                        // If not, append the current date to the file name
                        fileObj.fileName = `${currentDate}iwtfar&sl${randomString}_${fileObj.fileName}`;
                        // Or you can append the random string if needed
                        // fileObj.fileName = `${currentDate}${randomString}_${fileObj.fileName}`;
                    }
                });
            }

            const formData = new FormData();
            formData.append('form', userSchema?.usrnm);
            formData.append('compid', JSON.parse(companyIdString));
            formData.append('divid', JSON.parse(divIdString));
            formData.append('masterid', userSchema?.masterid);
            formData.append('_id', localStorage.getItem('StaffDetailEmpID'));

            formData.append('documents', JSON.stringify(mergedData));

            // files.forEach((file) => {
            //     formData.append('files', file);
            // });
            files.forEach((file) => {
                const currentDate = new Date().toISOString().slice(0, 10); // Get current date in YYYY-MM-DD format
                const originalFileName = file.name;
                const newFileName = `${currentDate}iwtfar&sl${randomString}_${originalFileName}`;
                // const newFileName = `${currentDate}${randomString}_${originalFileName}`;
                const newFile = new File([file], newFileName, { type: file.type });
                formData.append('files', newFile);
            });
            
            axios.post(`${process.env.REACT_APP_API_URL}/employee/EmployeeDocumentsUpdate`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'authorization': `Bearer ${token}`
                }
            })
                .then((response) => {
                    if (response.data.status) {
                        EmployeeDocumentsGet();
                        setActionMessage(response.data.message);
                        setSuccessModalOpen(true);
                    } else {
                        setActionMessage(response.data.message);
                        setErrorModalOpen(true);
                    }
                })
                .catch((error) => {
                    console.error('Error uploading file:', error);
                    setActionMessage("Ooops an error occured.");
                    setErrorModalOpen(true);
                });
        } catch (error) {
            console.error('Error uploading file:', error);
            setActionMessage("Ooops an error occured.");
            setErrorModalOpen(true);
        }
    };

    const handlecancel = () => {
        EmployeeDocumentsGet();
    };

    const handleRemoveImage = (entryId, index) => {
        setFileStates((prevFileStates) => {
            const updatedFiles = prevFileStates[entryId].files.filter((_, i) => i !== index);
            return {
                ...prevFileStates,
                [entryId]: {
                    ...prevFileStates[entryId],
                    files: updatedFiles
                }
            };
        });

        setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    const handleDropzoneClick = (id) => {
        document.getElementById(`input_${id}`).click();
    };

    const handleChange = (e, id) => {
        const newFiles = Array.from(e.target.files);

        setFileStates((prevFileStates) => {
            const existingFiles = prevFileStates[id]?.files || [];
            return {
                ...prevFileStates,
                [id]: {
                    files: [...existingFiles, ...newFiles.map(file => ({
                        file: URL.createObjectURL(file),
                        fileName: file.name,
                        fileE: file
                    }))]
                }
            };
        });
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    };

    const handleRemarkChange = (e, id) => {
        const remark = e.target.value;
        setRemarkStates({
            ...remarkStates,
            [id]: remark
        });
    };

    const handleDrop = (e, id) => {
        e.preventDefault();
        const newFiles = Array.from(e.dataTransfer.files);

        setFileStates((prevFileStates) => {
            const existingFiles = prevFileStates[id]?.files || [];
            return {
                ...prevFileStates,
                [id]: {
                    files: [...existingFiles, ...newFiles.map(file => ({
                        file: URL.createObjectURL(file),
                        fileName: file.name
                    }))]
                }
            };
        });
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const getFileType = (fileName) => {
        const extension = fileName.split('.').pop().toLowerCase();
        if (extension === 'pdf') return 'pdf';
        if (['xls', 'xlsx', 'csv'].includes(extension)) return 'excel';
        return 'image';
    };

    const empName = localStorage.getItem('StaffDetailEmpName');
    const empMid = localStorage.getItem('StaffDetailEmpMid');
    const empID = localStorage.getItem('StaffDetailEmpID');
    const empStatus = localStorage.getItem('StaffDetailEmpStatus');

    const fetchDocListData = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const dataToSend = {
                usrnm: userSchema?.usrnm,
                compid: JSON.parse(companyIdString),
                divid: JSON.parse(divIdString),
                masterid: userSchema?.masterid,
                employee: localStorage.getItem('StaffDetailEmpID')
            };
            const token = localStorage.getItem('user-token');
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/employeegrade_master/DocumentsGET`,
                dataToSend,
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );
            setDocDataList(response.data.lastEntryNo);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const EmployeeDocumentsGet = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const dataToSend = {
                usrnm: userSchema?.usrnm,
                compid: JSON.parse(companyIdString),
                divid: JSON.parse(divIdString),
                masterid: userSchema?.masterid,
                _id: localStorage.getItem('StaffDetailEmpID')
            };
            const token = localStorage.getItem('user-token');
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/employee/EmployeeDocumentsGet`,
                dataToSend,
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );
            let data = {};
            let remark = {};

            for (const key in response?.data?.documents) {
                if (Object.hasOwnProperty.call(response?.data?.documents, key)) {
                    const { files, remark: fileRemark } = response?.data?.documents[key];
                    data[key] = {
                        files: files.map(file => {
                            const fileExtension = getFileType(file.fileName)

                            const isBlob = file.file.startsWith('blob:');
                            return {
                                file: fileExtension !== "image"? file.file : `${process.env.REACT_APP_API_URL}/uploads/${file.fileName}`,
                                fileName: file.fileName,
                                fileE: file.fileE
                            };
                        })
                    };
                    if (fileRemark) {
                        remark[key] = fileRemark;
                    }
                }
            }
            console.log("^^^^^^^^", data);
            setFileStates(data);
            setRemarkStates(remark);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchDocListData();
        EmployeeDocumentsGet();
    }, []);

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        border: '2px solid',
        boxShadow: 24,
        padding: 16,
        minWidth: 300,
        maxWidth: 500,
        textAlign: 'center'
    };

    const handleSuccessModalClose = () => {
        setSuccessModalOpen(false);
    };

    const handleErrorModalClose = () => {
        setErrorModalOpen(false);
    };

    return (
        <>
            <Grid container spacing={2} ref={lastContentRef}>
                <Grid item xs={12}>
                    <ProfileHeader name={empName} id={empMid} status={empStatus} EmpID={empID} />
                </Grid>

                {/* Side Navbar */}
                <Grid item xs={2}>
                    <ProfileNav />
                </Grid>

                {/* Content Section */}
                <Grid item xs={10}>
                    <Paper sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '10px', marginTop: '10px' }}>
                        <Grid container spacing={4} direction="column">
                            {DocDataList.map(entry => (
                                <Grid item key={entry._id}>
                                    <Paper sx={{ padding: '20px' }}>
                                        <Typography gutterBottom sx={{ fontSize: '15px', fontWeight: 'bold', color: 'black', }}> Upload {entry.discription}</Typography>
                                        <Divider />
                                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                            <div
                                                style={{
                                                    border: '2px dashed #ccc',
                                                    borderRadius: '10px',
                                                    padding: '20px',
                                                    textAlign: 'center',
                                                    cursor: 'pointer',
                                                    marginRight: '20px'
                                                }}
                                                onClick={() => handleDropzoneClick(entry._id)}
                                                onDrop={(e) => handleDrop(e, entry._id)}
                                                onDragOver={(e) => handleDragOver(e)}
                                            >
                                                <Typography variant="body1" gutterBottom>Drag & drop files here or click to select</Typography>
                                            </div>
                                            <label htmlFor={entry.discription} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                <input
                                                    id={`input_${entry._id}`}
                                                    type="file"
                                                    style={{ display: 'none' }}
                                                    onChange={(e) => handleChange(e, entry._id)}
                                                    multiple
                                                />
                                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                    {fileStates[entry._id] && fileStates[entry._id].files.map((fileObj, index) => (
                                                        <div key={index} style={{ marginBottom: '10px', marginRight: '10px', position: 'relative' }}>
                                                            <IconButton
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: '-10px',
                                                                    right: '-10px',
                                                                    zIndex: '1',
                                                                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                                                    borderRadius: '50%',
                                                                }}
                                                                onClick={() => handleRemoveImage(entry._id, index)}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                            {getFileType(fileObj.fileName) === 'pdf' ? (
                                                                <embed src={fileObj.file} style={{ width: '100px', height: '55px', padding: '10px' }} />
                                                            ) : getFileType(fileObj.fileName) === 'excel' ? (
                                                                <img src="xl-logo.png" alt="Excel" style={{ width: '100px', height: '55px', padding: '10px' }} onClick={()=>{downloadFile(fileStates[entry._id].files[index])}} />
                                                            ) : (
                                                                <img
                                                                    src={fileObj.file}
                                                                    alt=""
                                                                    style={{ width: '100px', height: '55px', padding: '10px' }}
                                                                    onClick={() => handleImageClick(fileStates[entry._id].files, index, entry._id)}
                                                                />
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>

                                            </label>
                                        </div>
                                        <TextField
                                            id={`remark_${entry._id}`}
                                            label="Remark"
                                            variant="outlined"
                                            margin="normal"
                                            value={remarkStates[entry._id] || ''}
                                            onChange={(e) => handleRemarkChange(e, entry._id)}
                                        />
                                    </Paper>
                                </Grid>
                            ))}
                        </Grid>
                    </Paper>
                </Grid>
                <Paper style={{ padding: '16px', position: 'fixed', bottom: '16px', zIndex: 1000, width: '60%', left: '0', right: '0', margin: '0 auto' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }}>
                        <Button style={{ marginRight: '8px' }} variant="contained" color="error" onClick={() => { handlecancel() }}>
                            Cancel
                        </Button>
                        <Button style={{ marginLeft: '8px' }} variant="contained" color="primary" onClick={() => { handlesave() }}>
                            Save
                        </Button>
                    </div>
                </Paper>
                <div style={{ position: 'fixed', top: '100px', left: 0, right: 0, zIndex: '999', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                    {showSlider && (
                        <>
                            {sliderImages.length === 1 ? (
                                <div style={{ textAlign: "center" }}>
                                    <button onClick={handleCloseSlider}>Close</button>
                                    <img
                                        src={sliderImages[0]}
                                        alt=""
                                        style={{ width: "100%", maxWidth: "500px", height: "50vh" }}
                                    />
                                </div>
                            ) : (
                                <div style={{ maxWidth: "500px", margin: "0 auto" }}>
                                    <button onClick={handleCloseSlider}>Close</button>
                                    <Slider {...settings}>
                                        {sliderImages.map((item, index) => (
                                            <div key={index}>
                                                <img
                                                    src={item}
                                                    alt=""
                                                    style={{ width: "100%", height: "50vh" }}
                                                />
                                            </div>
                                        ))}
                                    </Slider>
                                    <div style={{ textAlign: "center" }}>
                                        {sliderImages.map((_, index) => (
                                            <span
                                                key={index}
                                                style={{
                                                    display: "inline-block",
                                                    width: "10px",
                                                    height: "10px",
                                                    borderRadius: "50%",
                                                    backgroundColor: currentIndex === index ? "#000" : "#ccc",
                                                    margin: "0 5px",
                                                    cursor: "pointer"
                                                }}
                                                onClick={() => setCurrentIndex(index)}
                                            />
                                        ))}
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
                <Modal
                    open={successModalOpen}
                    onClose={handleSuccessModalClose}
                    aria-labelledby="success-modal-title"
                    aria-describedby="success-modal-description"
                >
                    <div style={{ ...modalStyle, borderColor: '#007bff' }}>
                        <h2 id="success-modal-title" style={{ color: '#007bff' }}>Success!</h2>
                        <p id="success-modal-description">{actionMessage}</p>
                        <Button variant="contained" onClick={handleSuccessModalClose} style={{ backgroundColor: '#007bff', color: 'white' }}>Close</Button>
                    </div>
                </Modal>
                <Modal
                    open={errorModalOpen}
                    onClose={handleErrorModalClose}
                    aria-labelledby="error-modal-title"
                    aria-describedby="error-modal-description"
                >
                    <div style={{ ...modalStyle, borderColor: '#ff0000' }}>
                        <h2 id="error-modal-title" style={{ color: '#ff0000' }}>Error!</h2>
                        <p id="error-modal-description">{actionMessage}.</p>
                        <Button variant="contained" onClick={handleErrorModalClose} style={{ backgroundColor: '#ff0000', color: 'white' }}>Close</Button>
                    </div>
                </Modal>
            </Grid>
        </>
    );
}

export default ProfileDocumentCenter;
