import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

import Button from '@mui/material/Button';
import axios from 'axios';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress for the loader
import { useLocation } from 'react-router-dom';
import ProfileNav from './profileNav';
import ProfileHeader from './profileheader';
import moment from 'moment';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ConfirmationNumberRoundedIcon from '@mui/icons-material/ConfirmationNumberRounded';
import { Grid, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Typography } from '@mui/material';

function ProfileSalOverview() {
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [isLoading, setIsLoading] = useState(true); // State variable to manage loading state
  const [DummyData, setDummyData] = useState([]);
  const [currentMonthPayDays, setcurrentMonthPayDays] = useState();

  const currentMonth = new Date().toLocaleString('default', { month: 'long' });


  const empName = localStorage.getItem('StaffDetailEmpName');
  const empMid = localStorage.getItem('StaffDetailEmpMid');
  const empID = localStorage.getItem('StaffDetailEmpID');
  const empStatus = localStorage.getItem('StaffDetailEmpStatus');

  const handlePrevMonth = () => {
    setSelectedMonth(prevMonth => new Date(prevMonth.getFullYear(), prevMonth.getMonth() - 1, 1));
  };

  const handleNextMonth = () => {
    setSelectedMonth(prevMonth => new Date(prevMonth.getFullYear(), prevMonth.getMonth() + 1, 1));
  };

  const monthYearString = selectedMonth.toLocaleDateString('en-IN', { year: 'numeric', month: 'long' });

  // function organizeSalaryDataByMonth(salaryData) {
  //   const organizedData = [];

  //   salaryData.forEach(data => {
  //     const month = data.month;

  //     // Extracting earnings and deductions
  //     const earnings = [];
  //     const deductions = [];
  //     data.salryhd_or_group.forEach(salaryHead => {
  //       if (salaryHead.salryhd_sign === "(+)") {
  //         earnings.push(salaryHead);
  //       } else if (salaryHead.salryhd_sign === "(-)") {
  //         deductions.push(salaryHead);
  //       }
  //     });

  //     // Including leave_or_group and employee details
  //     const { leave_or_group, employee, ...rest } = data;

  //     const monthData = {
  //       month: month,
  //       earnings: earnings,
  //       deductions: deductions,
  //       leave_or_group: leave_or_group,
  //       employee: employee,
  //       other_details: rest
  //     };

  //     organizedData.push(monthData);
  //   });

  //   return organizedData;
  // }
  function organizeSalaryDataByMonth(salaryData) {
    const organizedData = [];

    salaryData.forEach(data => {
      const month = data.month;

      // Extracting earnings and deductions
      const earnings = [];
      const deductions = [];
      data.salryhd_or_group.forEach(salaryHead => {
        if (salaryHead.salryhd_sign === "(+)") {
          earnings.push(salaryHead);
        } else if (salaryHead.salryhd_sign === "(-)") {
          deductions.push(salaryHead);
        }
      });

      // Including leave_or_group and employee details
      const { leave_or_group, employee, ...rest } = data;

      const monthData = {
        month: month,
        earnings: earnings,
        deductions: deductions,
        leave_or_group: leave_or_group,
        employee: employee,
        other_details: rest
      };

      organizedData.push(monthData);
    });
    const firstEmployee = salaryData[0].employee;
    const { salryhd_or_group, ...restx } = firstEmployee;

    function separateEarningsAndDeductionsss(salaryData) {
      const earnings = [];
      const deductions = [];

      salaryData.forEach(item => {
        if (item.salryhd_sign === "(+)") {
          earnings.push(item);
        } else if (item.salryhd_sign === "(-)") {
          deductions.push(item);
        }
      });

      return { earnings, deductions };
    }

    const { earnings, deductions } = separateEarningsAndDeductionsss(salryhd_or_group);
    const currentDate = new Date();
    const curMonth = currentDate.toLocaleString('default', { month: 'long' });

    const currentMonth = {
      earnings: earnings,
      deductions: deductions,
      other_details: restx,
      month: curMonth
    };


    //   organizedData.push(currentMonth);
    organizedData.unshift(currentMonth);

    return organizedData;
  }

 function calculateTotalCount(attSummary) {
    let totalCount = 0;
    const lvNamesToCount = ["WO", "P", "HD", "H"];

    attSummary.forEach(summary => {
        const lvName = summary._id.lvname[0]; // Get lvname value
        if (lvNamesToCount.includes(lvName)) {
            if (lvName === "HD") {
                totalCount += summary.count / 2; // If lvname is "HD", add half of the count
            } else {
                totalCount += summary.count; // Otherwise, add the full count
            }
        }
    });

    return totalCount;
}

  const fetchData = async () => {
    try {
      const userSchemaString = localStorage.getItem('userSchema');
      const userSchema = JSON.parse(userSchemaString);
      const companyIdString = localStorage.getItem('companyId');
      const divIdString = localStorage.getItem('divId');
      const dataToSend = {
        usrnm: userSchema?.usrnm,
        compid: JSON.parse(companyIdString),
        divid: JSON.parse(divIdString),
        masterid: userSchema?.masterid,
        month: selectedMonth.toLocaleDateString('en-IN', { month: 'long' }),
        // id: state.id ? state.id : null,
        employee: localStorage.getItem('StaffDetailEmpID')
      };
      const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/attendance/GetSalary`,
        dataToSend,
        {
          headers: {
            authorization: `Bearer ${token}`
          }
        }
      );
      setDummyData(organizeSalaryDataByMonth(response.data.salaryschema))
      setcurrentMonthPayDays(calculateTotalCount(response.data.attsummary))
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData()
  }, [])
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ProfileHeader name={empName} id={empMid} status={empStatus} EmpID={empID} />
        </Grid>
        {/* Side Navbar */}

        <Grid item xs={2}>
          <ProfileNav />
        </Grid>

        {/* Content Section */}
        <Grid item xs={10}>
          {/* <Box display="flex" alignItems="center">
            <Button onClick={handlePrevMonth}><ArrowBackIosIcon /></Button>
            <div style={{ width: '150px' }}>
              <Typography variant="h6" align="center" >{monthYearString}</Typography>
            </div>
            <Button onClick={handleNextMonth}><ArrowForwardIosIcon /></Button>
          </Box> */}
          <Paper elevation={3} style={{ padding: '20px', marginBottom: '10px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                Salary Overview
              </Typography>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button variant='outlined' sx={{ marginRight: '5px' }}>Active</Button>
                <Button variant='contained' sx={{ marginLeft: '5px' }}>Generate Salary Slip</Button>
              </div>
            </div>

            {DummyData.map((monthData, index) => (
              <Paper key={index} elevation={0} style={{ marginTop: '10px' }}>
                <Accordion >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Grid container alignItems="center" justifyContent="space-between">
                      <Grid item xs={5}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <ConfirmationNumberRoundedIcon style={{ marginRight: '10px', color: '#3d73d9' }} />
                          <div>
                            <Typography sx={{ fontWeight: 'bold' }}>{`${monthData.month} ${new Date(monthData.other_details.entry).toLocaleString('en-us', { year: 'numeric' })}`}</Typography>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={5}>
                        <div>
                          <Typography style={{ fontSize: '0.9rem' }}>Gross Salary</Typography>
                          <Typography sx={{ fontWeight: 'bold' }}>{monthData.other_details.gross_sal || monthData.other_details.gross_salary || '0'}</Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      {/* Earnings Section */}
                      <Grid item xs={6}>
                        <TableContainer>
                          <Table>
                            <TableBody>
                              <TableRow>
                                <TableCell colSpan={6} sx={{ fontWeight: 'bold' }}>Earnings</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Actual</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Calculated</TableCell>
                              </TableRow>
                              {Object.entries(monthData.earnings).map(([index, value]) => (
                                <TableRow key={index}>
                                  <TableCell colSpan={6}>{value.salaryhead_name?.salaryhead_name || 'Not Marked'}</TableCell>
                                  <TableCell>{value.salryhd_vlu || '0'}</TableCell>
                                  <TableCell>{value.amount || '0'}</TableCell>
                                </TableRow>
                              ))}
                              <TableRow>
                                <TableCell colSpan={6} sx={{ fontWeight: 'bold' }}>Gross Earnings</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>{monthData.other_details.calculated_gross || '0'}</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>

                      {/* Deductions Section */}
                      <Grid item xs={6}>
                        <TableContainer>
                          <Table>
                            <TableBody>
                              <TableRow>
                                <TableCell colSpan={6} sx={{ fontWeight: 'bold' }}>Deductions</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Actual</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Calculated</TableCell>
                              </TableRow>
                              {Object.entries(monthData.deductions).map(([index, value]) => (
                                <TableRow key={index}>
                                  <TableCell colSpan={6}>{value.salaryhead_name?.salaryhead_name || 'Not Marked'}</TableCell>
                                  <TableCell>{value.salryhd_vlu || '0'}</TableCell>
                                  <TableCell>{value.amount || '0'}</TableCell>
                                </TableRow>
                              ))}
                              <TableRow>
                                <TableCell colSpan={6} sx={{ fontWeight: 'bold' }}>Total Deductions</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}></TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>{monthData.other_details.total_less || '0'}</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item xs={12} md={12} lg={12}>
                        <TableContainer>
                          <Table>
                            <TableBody>
                            <TableRow>
                                        <TableCell colSpan={10} sx={{ fontWeight: 'bold' }}>OT Hours</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}></TableCell>
                                        <TableCell colSpan={2} sx={{ fontWeight: 'bold', textAlign: 'center' }}>{monthData.other_details.ot_hrs || '0'}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={10} sx={{ fontWeight: 'bold' }}>OT Amount</TableCell>
                                        <TableCell sx={{ fontWeight: 'bold' }}></TableCell>
                                        <TableCell colSpan={2} sx={{ fontWeight: 'bold', textAlign: 'center' }}>{monthData.other_details.amt || '0'}</TableCell>
                                    </TableRow>
                              <TableRow>
                                <TableCell colSpan={10} sx={{ fontWeight: 'bold' }}>Payable Days</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}></TableCell>
                                <TableCell colSpan={2} sx={{ fontWeight: 'bold', textAlign: 'center' }}>{monthData.other_details.total_attendance || currentMonthPayDays || '0'}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell colSpan={10} sx={{ fontWeight: 'bold' }}>Advance</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}></TableCell>
                                <TableCell colSpan={2} sx={{ fontWeight: 'bold', textAlign: 'center' }}>{monthData.other_details.less_advance || '0'}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell colSpan={10} sx={{ fontWeight: 'bold' }}>Loan</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}></TableCell>
                                <TableCell colSpan={2} sx={{ fontWeight: 'bold', textAlign: 'center' }}>{monthData.other_details.less_loan || '0'}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell colSpan={10} sx={{ fontWeight: 'bold' }}>    Due Amount: {monthData.other_details.net_salary || parseFloat(monthData.other_details.gross_sal) / parseFloat(monthData.other_details.days) * parseFloat(currentMonthPayDays)}</TableCell>
                                <TableCell colSpan={1} sx={{ textAlign: 'right', color: 'blue', cursor: 'pointer' }} onClick={() => console.log("")}>Edit Salary Structure</TableCell>
                                <TableCell colSpan={1} sx={{ textAlign: 'center', color: 'blue', cursor: 'pointer' }} onClick={() => console.log("")}>Add Variables</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Paper>
            ))}

          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default ProfileSalOverview;
