import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import ProfileNav from './profileNav';
import ProfileHeader from './profileheader';
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid, Divider, TextField } from '@mui/material';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Collapse } from '@mui/material';

function TasksReport() {
    const [Taskdata, setTaskdata] = useState(null);

    const empName = localStorage.getItem('StaffDetailEmpName');
    const empMid = localStorage.getItem('StaffDetailEmpMid');
    const empID = localStorage.getItem('StaffDetailEmpID');
    const empStatus = localStorage.getItem('StaffDetailEmpStatus');

    function convertAndFormatDate(dateStr) {
        const dateObj = new Date(dateStr);
        const formattedDate = formatDate(dateObj);
        return formattedDate;
    }

    function formatDate(date) {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear()).slice(-2);
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${day}/${month}/${year} ${hours}:${minutes}`;
    }

    const GetEmployeeTasksDetails = async () => {
        try {
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const dataToSend = {
                task_employee: localStorage.getItem('StaffDetailEmpID'),
                compid: JSON.parse(companyIdString),
                divid: JSON.parse(divIdString),
                type: "all"
            };
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/task/GetEmployeeTasksDetails`, dataToSend, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            })
            setTaskdata(response.data.summary);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    function parseDate(dateString) {
        const [day, month, year, hours, minutes] = dateString.split(/[\/\s:]+/);
        return new Date(`20${year}-${month}-${day}T${hours}:${minutes}:00`);
    }
    
    function DelayCount(StartDate, DueDate, EndDate) {
    const startDate = parseDate(StartDate);
    const dueDate = parseDate(DueDate);
    const endDate = parseDate(EndDate);
    
    const dueDelay = dueDate - startDate;  
    const endDelay = endDate - startDate; 
    const extraDelay = endDate - dueDate; 
    
    const msToTime = (duration) => {
        let minutes = Math.floor((duration / (1000 * 60)) % 60);
        let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
        let days = Math.floor(duration / (1000 * 60 * 60 * 24));
    
        return { days, hours, minutes };
    };
    
    const dueDelayTime = msToTime(dueDelay);
    const endDelayTime = msToTime(endDelay);
    const extraDelayTime = msToTime(extraDelay);
    
    return `${extraDelayTime.days} days, ${extraDelayTime.hours} hours, ${extraDelayTime.minutes} minutes`
    }
    
    function getCurrentISTFormattedTime() {
        const now = new Date();
        const utcOffset = now.getTimezoneOffset() * 60000; // 
        const istOffset = 5.5 * 60 * 60000; // IST offset is 
        const istTime = new Date(now.getTime() + utcOffset + istOffset);
    
        const day = String(istTime.getDate()).padStart(2, '0');
        const month = String(istTime.getMonth() + 1).padStart(2, '0'); 
        const year = String(istTime.getFullYear()).slice(-2); 
        const hours = String(istTime.getHours()).padStart(2, '0');
        const minutes = String(istTime.getMinutes()).padStart(2, '0');
    
        return `${day}/${month}/${year} ${hours}:${minutes}`;
    }

    useEffect(() => {
        GetEmployeeTasksDetails();
    }, [])

    return (
        <Grid container spacing={2} >
            <Grid item xs={12}>
                <ProfileHeader name={empName} id={empMid} status={empStatus} EmpID={empID} />
            </Grid>

            {/* Side Navbar */}
            <Grid item xs={2}>
                <ProfileNav />
            </Grid>

            {/* Content Section */}
            <Grid item xs={10}>
                <Paper elevation={3} style={{ padding: '20px', marginBottom: '10px', borderRadius: '10px' }}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead style={{ background: 'linear-gradient(#FFFFFF, #60a5f2)' }}>
                                <TableRow>
                                    <TableCell align="center">Task Plan</TableCell>
                                    <TableCell align="center">Task Description</TableCell>
                                    <TableCell align="center">Assign By</TableCell>
                                    <TableCell align="center">Start Date</TableCell>
                                    <TableCell align="center">Due Date</TableCell>
                                    <TableCell align="center">Priority</TableCell>
                                    <TableCell align="center">End Date</TableCell>
                                    <TableCell align="center">Delay</TableCell>
                                    <TableCell align="center">Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Taskdata?.length > 0 ? (
                                    Taskdata.map((row, index) => (
                                        <React.Fragment key={index}>
                                            <TableRow >
                                                <TableCell align="center">{row?._id?.task_name}</TableCell>
                                                <TableCell align="center">{row?._id?.task_description}</TableCell>
                                                <TableCell align="center">{row?._id?.usrnm}</TableCell>
                                                <TableCell align="center">{`${convertAndFormatDate(row?._id?.startdate)}`}</TableCell>
                                                <TableCell align="center">{`${convertAndFormatDate(row?._id?.startdate)}`}</TableCell>
                                                <TableCell align="center">{row?._id?.task_priority}</TableCell>
                                                <TableCell align="center">{`${convertAndFormatDate(row?._id?.end_time)}`}</TableCell>
                                                <TableCell align="center">{DelayCount(`${convertAndFormatDate(row?._id?.startdate)}`,`${convertAndFormatDate(row?._id?.startdate)}`,row?._id?.end_time?`${convertAndFormatDate(row?._id?.end_time)}`:getCurrentISTFormattedTime())}</TableCell>
                                                <TableCell align="center">{row?._id?.task_status}</TableCell>
                                            </TableRow>
                                        </React.Fragment>
                                    ))
                                ) :
                                    <TableRow>
                                        <TableCell colSpan={7} align="center" style={{ fontSize: '16px', padding: '8px' }}>
                                            No Tasks
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>
        </Grid>

    );
}

export default TasksReport;
