import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import MUIDataTable from "mui-datatables";
import Modal from '@mui/material/Modal';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid'; // Import DataGrid from MUI
import { Grid, Paper, Typography, FormControl, TextField, Divider, Autocomplete } from '@mui/material';
import { useRef, useLayoutEffect } from 'react';

function LeaveReasonMaster() {
    const [Language, setLanguage] = useState('');
    const [code, setcode] = useState('');
    const [error, setError] = useState('');
    const [data, setdata] = useState([]);
    const [openModal, setOpenModal] = useState(false); // State to control the modal visibility
    const [deleteIndex, setDeleteIndex] = useState(null); // State to store the index of item to be deleted
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [ActionMessage, setActionMessage] = useState('');
    const [searchText, setSearchText] = useState(''); // State for search text
    const [rowsPerPage, setRowsPerPage] = useState(10); // State for rows per page, default 10
    const [currentPage, setCurrentPage] = useState(1); // State for current page, default 1
    const [editID, setEditID] = useState();

    const lastContentRef = useRef(null);
    useLayoutEffect(() => {
        if (lastContentRef.current) {
            lastContentRef.current.style.marginBottom = '80px';
        }
    }, []);

    const handleSubmit = (event) => {
        // event.preventDefault();
        if (Language.trim() === '') {
            setError('Language cannot be empty');
            return;
        }
        else {
            setError('');
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');

            const dataToSend = {
                Language: Language,
                code: code,
                usrnm: userSchema?.usrnm,
                compid: JSON.parse(companyIdString),
                divid: JSON.parse(divIdString),
                masterid: userSchema?.masterid,
                _id: editID ? editID : null
            };
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorages
            axios.post(`${process.env.REACT_APP_API_URL}/employeegrade_master/Language_Add`, dataToSend, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            })
                .then(response => {
                    console.log(response)
                    fetchData()
                    if (response.data.status) {
                        setActionMessage(response.data.message)
                        setSuccessModalOpen(true);
                        handleCancel()
                    } else {
                        setActionMessage(response.data.message)
                        handleCancel()
                        setErrorModalOpen(true)
                    }
                })
                .catch(error => {
                    console.log(error)
                    setActionMessage("Ooops an error occured.")
                    setErrorModalOpen(true)
                });

        }
    };

    const handleErrorModalClose = () => {
        setErrorModalOpen(false);
    };

    const handleSuccessModalClose = () => {
        setSuccessModalOpen(false);
    };


    const handleCancel = () => {
        setLanguage('');
        setcode('');
        setError('');
        setEditID('');
    };

    const handleDelete = (INFO) => {
        setDeleteIndex(INFO._id);
        setOpenModal(true);
    };

    const handleModalDelete = async () => {
        try {
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/employeegrade_master/LanguageDELETE/${deleteIndex}`, {}, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            });
            console.log('Delete API Response:', response.data);
            handleCloseModal();
            fetchData();
        } catch (error) {
            console.error('Error deleting item:', error);
        }
    };


    const handleCloseModal = () => {
        setOpenModal(false);
    };
    const handleTableChange = (action, tableState) => {
        if (action === 'search') {
            setSearchText(tableState.searchText);
        }
        if (action === 'changeRowsPerPage') {
            setRowsPerPage(tableState.rowsPerPage);
        }
        if (action === 'changePage') {
            setCurrentPage(tableState.page);
        }
    };
    const fetchData = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const masterid = userSchema?.masterid;
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/employeegrade_master/LanguageGET`,
                {
                    params: {
                        searchText,
                        rowsPerPage,
                        currentPage,
                        masterid,
                        compid: JSON.parse(companyIdString),
                        divid: JSON.parse(divIdString)
                    },
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            console.log('API Response:', response.data.lastEntryNo);
            setdata(response.data.lastEntryNo)

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData(); // Call fetchData when component mounts or when searchText, rowsPerPage, or currentPage changes
        // }, [searchText, rowsPerPage, currentPage]);
    }, []);



    const options = {
        pagination: true,
        download: true,
        downloadOptions: {
            filename: 'data',
            separator: ',',
            filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true,
            },
        },
        filter: false,
        selectableRows: false,
        onTableChange: handleTableChange
    };
    const dataWithSerialNumbers = data.map((item, index) => ({
        ...item,
        serialNumber: index + 1, // Serial number starts from 1
        id: index + 1, // Serial number starts from 1
    }));

    const columns = [
        { field: "serialNumber", headerName: "S. No.", flex: 1, }, // Serial number column
        { field: "code", headerName: "Code", flex: 1, },
        { field: "Language", headerName: "Language", flex: 1, },
        {
            field: "edit",
            headerName: "Edit",
            flex: 1,
            renderCell: (params) => (
                <Button variant="text" color="primary" size='small'
                    onClick={() => handleEdit(params.row)}><EditIcon fontSize="small" /></Button>
            )

        },
        {
            field: "delete",
            headerName: "Delete",
            flex: 1,
            renderCell: (params) => (
                <Button variant="text" color="error" size='small' onClick={() => handleDelete(params.row)}><DeleteIcon fontSize="small" /></Button>
            )
        }
    ];

    const handleEdit = (editdata) => {
        setLanguage(editdata?.Language);
        setcode(editdata?.code);

        setEditID(editdata._id);
        window.scrollTo(0, 0); // Scroll to the top of the page
    };
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        border: '2px solid',
        boxShadow: 24,
        padding: 16,
        minWidth: 300,
        maxWidth: 500,
        textAlign: 'center'
    };
    return (
        <>
            <Container maxWidth="xl" style={{ marginTop: '10px' }} ref={lastContentRef}>
                <div>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: '10px', fontSize: '20px' }}>
                        Add Language
                    </Typography>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={12}>
                            <Paper elevation={3} style={{ padding: '15px' }}>
                                <div style={{ marginBottom: '10px', marginTop: '10px' }}>
                                    <Grid container alignItems="center" spacing={2}>
                                        <Grid item xs={2}>
                                            <Typography variant="body1">Language</Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    value={Language}
                                                    onChange={(e) => setLanguage(e.target.value)}
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={2}>
                                            <Typography variant="body1">Code</Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControl fullWidth>
                                                <TextField
                                                    value={code}
                                                    onChange={(e) => setcode(e.target.value)}
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
                {/* <Box sx={{ border: '1px solid black', padding: '20px', height: '255px', margin: '-1px', display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ backgroundColor: '#007bff', padding: '10px', marginBottom: '20px' }}>
                        <Typography variant="h6" sx={{ color: 'white', margin: '0' }}>
                            Attendance Type
                        </Typography>
                    </Box>
                    <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: '10px', marginBottom: '10px', width: '120px' }}>
                            Description:
                        </Typography>
                        <input
                            type="text"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', flex: '1' }}
                        />
                    </div>
                    <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: '10px', marginBottom: '10px', width: '120px' }}>
                            Code:
                        </Typography>
                        <input
                            type="text"
                            value={code}
                            onChange={(e) => setcode(e.target.value)}
                            style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', flex: '1' }}
                        />
                    </div>
                    {error && (
                        <Typography variant="body2" sx={{ color: 'red', marginBottom: '10px', width: '100%', textAlign: 'center' }}>
                            {error}
                        </Typography>
                    )}
                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '12px' }}>
                        <Button type="submit" variant="contained" color="success" onClick={handleSubmit} sx={{ marginRight: '10px' }}>
                            Submit
                        </Button>
                        <Button variant="contained" onClick={handleCancel}>
                            Cancel
                        </Button>
                    </div>
                </Box> */}

                {/* <MUIDataTable title={""} data={dataWithSerialNumbers} columns={columns} options={options} /> */}
                <div style={{ marginTop: '10px' }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: '10px', fontSize: '20px' }}>
                    Language Master List
                    </Typography>
                    <DataGrid
                        rows={dataWithSerialNumbers}
                        columns={columns}
                        initialState={{
                            ...data.initialState,
                            pagination: { paginationModel: { pageSize: 10 } },

                        }}
                        pageSizeOptions={[10, 25, 100]}
                        pagination
                        autoHeight
                        checkboxSelection={false}
                        disableSelectionOnClick
                        density="compact"
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                            },
                        }}
                    />
                </div>

                <Paper style={{ padding: '16px', position: 'fixed', bottom: '10px', zIndex: 1000, width: '60%', left: '0', right: '0', margin: '0 auto' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }}>
                        <Button style={{ marginRight: '8px' }} variant="contained" color="error" onClick={() => { handleCancel() }}>
                            Cancel
                        </Button>
                        <Button style={{ marginLeft: '8px' }} variant="contained" color="primary" onClick={() => { handleSubmit() }} >
                            Save
                        </Button>
                    </div>
                </Paper>
            </Container>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, width: 400 }}>
                    <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
                        Confirm Deletion
                    </Typography>
                    <Typography id="modal-description" sx={{ mt: 2 }}>
                        Are you sure you want to delete this item?
                    </Typography>
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={handleCloseModal} color="primary" sx={{ mr: 2 }}>
                            Cancel
                        </Button>
                        <Button onClick={handleModalDelete} variant="contained" color="error">
                            Delete
                        </Button>
                    </Box>
                </Box>
            </Modal>


            <Modal
                open={successModalOpen}
                onClose={handleSuccessModalClose}
                aria-labelledby="success-modal-title"
                aria-describedby="success-modal-description"
            >
                <div style={{ ...modalStyle, borderColor: '#007bff' }}>
                    <h2 id="success-modal-title" style={{ color: '#007bff' }}>Success!</h2>
                    <p id="success-modal-description">{ActionMessage}</p>
                    <Button variant="contained" onClick={handleSuccessModalClose} style={{ backgroundColor: '#007bff', color: 'white' }}>Close</Button>
                </div>
            </Modal>

            <Modal
                open={errorModalOpen}
                onClose={handleErrorModalClose}
                aria-labelledby="error-modal-title"
                aria-describedby="error-modal-description"
            >
                <div style={{ ...modalStyle, borderColor: '#ff0000' }}>
                    <h2 id="error-modal-title" style={{ color: '#ff0000' }}>Error!</h2>
                    <p id="error-modal-description">{ActionMessage}.</p>
                    <Button variant="contained" onClick={handleErrorModalClose} style={{ backgroundColor: '#ff0000', color: 'white' }}>Close</Button>
                </div>
            </Modal>



        </>
    );
}

export default LeaveReasonMaster;