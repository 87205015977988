import React, { useState, useEffect } from 'react';
import { Modal, Typography, Grid, Box, Button, Paper, TextField, Divider, CircularProgress, Select, MenuItem, Container } from '@mui/material';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import axios from 'axios';
import MUIDataTable from "mui-datatables";
import Autocomplete from '@mui/material/Autocomplete';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid'; // Import DataGrid from MUI

import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useRef, useLayoutEffect } from 'react';

import moment from 'moment';

const ResignReport = () => {
    const [attritionRate, setattritionRate] = useState({ attritionRate: 0, Opening: 0, Closing: 0 });
    const dateToday = new Date(); 
    const thirtyDaysAgo = new Date(dateToday.getFullYear(), dateToday.getMonth(), 1);
    const today = new Date(dateToday.getFullYear(), dateToday.getMonth() + 1, 0);

    const [selectionRange, setSelectionRange] = useState({
        startDate: thirtyDaysAgo,
        endDate: today,
        key: 'selection',
    });

    const [tempStartDate, setTempStartDate] = useState(thirtyDaysAgo);
    const [tempEndDate, setTempEndDate] = useState(today);
    const dateRangePickerRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleSelect = (ranges) => {
        // Update the tempStartDate and tempEndDate independently
        const { startDate, endDate } = ranges.selection;
        if (startDate && endDate) {
            if (startDate <= tempEndDate) {
                setTempStartDate(startDate);
            }
            if (endDate >= tempStartDate) {
                setTempEndDate(endDate);
            }
        }
    };

    const applyDateRange = () => {
        setSelectionRange({
            startDate: tempStartDate,
            endDate: tempEndDate,
            key: 'selection',
        });
        setIsOpen(false);
    };

    const handleClickOutside = (event) => {
        if (dateRangePickerRef.current && !dateRangePickerRef.current.contains(event.target)) {
            setIsOpen(false); // Close the date picker when clicked outside
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDateRangePicker = () => {
        setIsOpen(!isOpen);
    };

    const formatDates = (date) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const [sumdata, setSumData] = useState([]);
    const [loading, setloading] = useState(false);

    const fetchSummaryData = async () => {
        try {
            setloading(true)
            if (!loading) {
                setloading(true);
            }
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const masterid = userSchema?.masterid;
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const token = localStorage.getItem('user-token');
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/employee/LeavingReport`,
                {
                    compid: JSON.parse(companyIdString),
                    divid: JSON.parse(divIdString),
                    masterid,
                    // startDate,
                    // endDate,
                    startDate: selectionRange.startDate,
                    endDate: selectionRange.endDate,
                },
                {
                    params: {
                    },
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );
            setSumData(response.data.summary);
            setattritionRate({
                attritionRate: response.data.attritionRate,
                Opening: response.data.openingHeadcount,
                Closing: response.data.closingHeadcount
            });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        finally {
            setloading(false);
        }
    };


    useEffect(() => {
        fetchSummaryData()
        // }, [startDate, endDate, SelectedDay])
    }, [selectionRange])

    const columns = [
        { field: "full_name", headerName: "Employee", flex: 1, },
        { field: "machine_code", headerName: "Employee ID", flex: 1 },
        { field: "date", headerName: "Resign", flex: 1 },
    ];

    function convertToIST(dateString) {
        const utcDate = new Date(dateString);

        utcDate.setHours(utcDate.getHours() + 5); // Add 5 hours for IST
        utcDate.setMinutes(utcDate.getMinutes() + 30); // Add 30 minutes for IST

        const formattedDate = utcDate.toLocaleDateString('en-IN', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });

        return formattedDate;
    }

    const Newsumdata = sumdata.map((item, index) => ({
        ...item,
        id: index,
        date: convertToIST(item?.resign)
    }));

    const generateFileName = () => {
        const now = new Date();
        const date = now.toLocaleDateString().replace(/\//g, '-'); // Format date as YYYY-MM-DD
        const time = now.toLocaleTimeString().replace(/:/g, '-');  // Format time as HH-MM-SS

        return `ABSENT REPORT_${date}_${time}`;
    };

    return (
        <>
            <Container maxWidth="xl" style={{ minHeight: '400px' }}>

                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>

                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '0px',
                        gap: '50px'
                    }}>

                        <Typography variant="h5" sx={{ color: '#333', fontWeight: '600', letterSpacing: '1px' }}>
                            Leaving Report
                        </Typography>

                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                gap: 3, // Space between each item
                                // padding: '10px',
                                backgroundColor: '#f5f5f5',
                                borderRadius: '8px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            <Typography
                                variant="body1"
                                sx={{ color: '#333', fontWeight: '600', letterSpacing: '0.5px' }}
                            >
                                Attrition Rate: <span style={{ color: '#ff5722' }}>{attritionRate?.attritionRate}</span>
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{ color: '#333', fontWeight: '600', letterSpacing: '0.5px' }}
                            >
                                Opening Head Count: <span style={{ color: '#ff5722' }}>{attritionRate?.Opening}</span>
                            </Typography>

                            <Typography
                                variant="body1"
                                sx={{ color: '#333', fontWeight: '600', letterSpacing: '0.5px' }}
                            >
                                Closing Head Count: <span style={{ color: '#ff5722' }}>{attritionRate?.Closing}</span>
                            </Typography>
                        </Box>
                    </div>


                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', position: 'relative', padding: '0px' }}>
                            <TextField
                                type="text"
                                size="small"
                                label="Select Range"
                                value={`${formatDates(selectionRange.startDate)} - ${formatDates(selectionRange.endDate)}`}
                                onFocus={toggleDateRangePicker}
                                readOnly
                                fullWidth
                                InputProps={{
                                    disableUnderline: true, // Remove default underline
                                    style: { cursor: 'pointer', textAlign: 'center' } // Center-align text and change cursor to pointer when hovering
                                }}
                                InputLabelProps={{
                                    shrink: true, // Ensure the label doesn't float above the input when value is present
                                }}
                                onClick={toggleDateRangePicker} // Open DateRangePicker on click
                            />
                            {isOpen && (
                                <div style={{ position: 'absolute', zIndex: 999, marginRight: '400px' }} ref={dateRangePickerRef}>
                                    <DateRangePicker
                                        ranges={[{ startDate: tempStartDate, endDate: tempEndDate, key: 'selection' }]}
                                        onChange={handleSelect}
                                    />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={applyDateRange}
                                        fullWidth
                                        style={{ marginTop: '10px' }}
                                    >
                                        Apply
                                    </Button>
                                </div>
                            )}

                        </div>
                    </div>
                </div>

                <DataGrid
                    rows={Newsumdata}
                    columns={columns}
                    initialState={{
                        ...Newsumdata.initialState,
                        pagination: { paginationModel: { pageSize: 10 } },

                    }}
                    pageSizeOptions={[10, 25, 100]}
                    pagination
                    autoHeight
                    checkboxSelection={false}
                    disableSelectionOnClick
                    density="compact"
                    loading={loading}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            csvOptions: { fileName: generateFileName() },
                        },
                    }}
                />
                {/* </Box> */}
            </Container>
        </>
    );
};

export default ResignReport;
