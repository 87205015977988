import React, { useState, useEffect } from 'react';
import MUIDataTable from "mui-datatables";
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button, Grid, Paper, Select, MenuItem } from '@mui/material';
import { TableContainer, Table, TableBody, TableRow, TableCell } from '@mui/material';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ConfirmationNumberRoundedIcon from '@mui/icons-material/ConfirmationNumberRounded';
import axios from 'axios';
import IconButton from '@material-ui/core/IconButton';
import PrintIcon from '@mui/icons-material/Print';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid'; // Import DataGrid from MUI
import FormControl from '@mui/material/FormControl';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Modal from '@mui/material/Modal';
import { useNavigate } from 'react-router-dom';

const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

function PFReport() {
    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [Month, setMonth] = useState(new Date().toLocaleString('default', { month: 'long' }));
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [printdata, setPrintData] = useState([]);
    const [deleteIndex, setDeleteIndex] = useState(null); // State to store the index of item to be deleted
    const [openModal, setOpenModal] = useState(false); // State to control the modal visibility
    const [PFMax, setPFMax] = useState(null);
    const [maindata, setmainData] = useState([]);

    useEffect(() => {
        fetchAdvanceData();
    }, [Month]);

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    useEffect(() => {
            const filteredArray = maindata.filter(item => {
                return Number(item?.less_esic) > 0; // Ensure to return the boolean result
            });
            console.log("item"); // This will log each employee object
            setData(filteredArray);
    }, [maindata]); // Make sure to include maindata as a dependency

    const fetchAdvanceData = async () => {
        setLoading(true);
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const masterid = userSchema?.masterid;
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            setData([]);
            setmainData([]);
            setPrintData([]);
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/salart_handleRouter/Salary_List?search=${search}`,
                {
                    user: userSchema?.usrnm,
                    compid: JSON.parse(companyIdString),
                    divid: JSON.parse(divIdString),
                    masterid: userSchema?.masterid,
                    Month: Month
                },
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );
            setData(extractEmployeeData(generateArrays(response.data.data)));
            setmainData(extractEmployeeData(generateArrays(response.data.data)));
            
            // setPrintData(generateArrays(addAtdResultsToData(response.data.data, response.data.atdresults)))
            // console.log("090", generateArrays(addAtdResultsToData(response.data.data, response.data.atdresults)))

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const PFMaxLimitGET = async () => {
        try {
          const userSchemaString = localStorage.getItem('userSchema');
          const userSchema = JSON.parse(userSchemaString);
          const masterid = userSchema?.masterid;
          const companyIdString = localStorage.getItem('companyId');
          const divIdString = localStorage.getItem('divId');
          const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/salary_master/PFMaxLimitGET`,
            {
              params: {
                masterid,
                compid: JSON.parse(companyIdString),
                divid: JSON.parse(divIdString)
              },
              headers: {
                authorization: `Bearer ${token}`
              }
            }
          );
          setPFMax(response.data.MaxLimit)
          console.log(response.data.MaxLimit)

        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      useEffect(()=>{
        PFMaxLimitGET();
      },[])

    function addAtdResultsToData(data, atdresults) {
        const newData = [];

        data.forEach(employeeData => {
            const updatedEmployeeData = { ...employeeData };

            const atdResults = atdresults
                .filter(atd => atd.employee_name?._id === updatedEmployeeData.employee?._id)
                .sort((a, b) => new Date(a?.dalatten_date) - new Date(b?.dalatten_date));

            updatedEmployeeData.atdresults = atdResults;

            newData.push(updatedEmployeeData);
        });

        return newData;
    }

    const generateArrays = (data) => {
        return data.map(item => {
            const earnings = item.salryhd_or_group.filter(head => head.salryhd_sign === "(+)");
            const deductions = item.salryhd_or_group.filter(head => head.salryhd_sign === "(-)");
            const eArray = item.salryhd_or_group.filter(head => head.salryhd_sign === "E");

            return {
                ...item,
                earnings,
                deductions,
                eArray
            };
        });
    };

    const columns = [
        { field: 'uan_no', headerName: 'IP No.', flex: 1 },
        { field: 'full_name', headerName: 'IP Name', flex: 1 },
        { field: 'total_attendance', headerName: 'Payable Days', flex: 1 },
        { field: 'ESICCalBy', headerName: 'Total Monthly Wages', flex: 1 },
    ];

    function extractEmployeeData(data) {
        return data.map((employee, index) => {
            const pfItem = employee?.salryhd_or_group?.find(
                item => item?.salaryhead_name?.salaryhead_name === "PF"
            );
            const pfMaxLimit = Number(pfItem?.max_limit || Number(PFMax?.max_limit || 0));
            const calculated_gross = Number(employee?.PFCalBy || 0);
            const actualPF = calculated_gross >= pfMaxLimit ? pfMaxLimit : calculated_gross;


            return {
                _id: employee?._id,
                id: index,
                machine_code: employee.employee.machine_code,
                bank: employee?.employee?.bank?.discription,
                bankNo: employee?.employee?.bank_ac_no,
                bankBranch: employee?.employee?.bankBranch,
                ifsc_code: employee?.employee?.ifsc_code,
                
                full_name: employee.employee.full_name,
                uan_no: employee.employee?.EsicNumber,
                ESICCalBy: calculated_gross,

                bankHold: employee?.employee?.bank_acc_holder,
                department_description: employee?.employee?.department?.discription || '',
                gross_salary: employee.gross_salary,
                total_attendance: employee.total_attendance,
                calculated_gross: employee.calculated_gross,
                amt: employee.amt,
                total_add: employee.total_add,
                less_advance: employee.less_advance,
                less_pf: employee.less_pf,
                less_loan: employee.less_loan,
                less_esic: employee.less_esic,
                total_less: employee.total_less,
                net_salary: employee.net_salary,
                ot_hrs: Number(employee.ot_hrs?.toFixed(2)),
                PT : employee?.salryhd_or_group.find(
                    item => item.salaryhead_name.salaryhead_name === "Professional Tax"
                )?.amount || 0,
                
                PF_max_limit: actualPF,

            // EPS Contribution (8.33% of PF max limit or net salary)
            EPSCont: ((actualPF * 8.33) / 100).toFixed(2),

            // EPS Difference (Difference between less_pf and actualPF)
            EPSDiff: (Number(employee?.less_pf || 0) - (actualPF * 8.33) / 100).toFixed(2),

            // Non-Contributory Period (NCP) Days
            NCPDays: 30 - Number(employee?.total_attendance || 0),
            };
        });
    }

    const generateFileName = () => {
        const now = new Date();
        const date = now.toLocaleDateString().replace(/\//g, '-'); // Format date as YYYY-MM-DD
        const time = now.toLocaleTimeString().replace(/:/g, '-');  // Format time as HH-MM-SS

        const prefix = 'ESIC SHEET';
        return `${prefix}_${Month}_${date}_${time}`;

        // return `AbsentReport_${date}_${time}`;
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper elevation={3} style={{ padding: '10px', }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
                            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
                            ESIC Report
                            </Typography>

                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>

                                <FormControl sx={{ m: 1, width: 120 }} size="small">

                                    <Select
                                        value={Month}
                                        onChange={(e) => setMonth(e.target.value)}
                                        sx={{ borderRadius: '14px' }}
                                    >
                                        {months.map(month => (
                                            <MenuItem key={month} value={month}>{month}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        {/* <MUIDataTable
                            title={null}
                            data={data}
                            columns={columns}
                            options={options}
                        /> */}

                        <DataGrid
                            rows={data}
                            columns={columns}
                            initialState={{
                                ...data.initialState,
                                pagination: { paginationModel: { pageSize: 15 } },
                            }}
                            pageSizeOptions={[15, 25, 100]}
                            pagination
                            autoHeight
                            checkboxSelection={false}
                            disableSelectionOnClick
                            density="compact"
                            loading={loading}
                            slots={{ toolbar: GridToolbar }}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    csvOptions: { fileName: generateFileName() }, 
                                },
                            }}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}

export default PFReport;
