import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import MUIDataTable from "mui-datatables";
import Modal from '@mui/material/Modal';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useRef, useLayoutEffect } from 'react';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid'; // Import DataGrid from MUI
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ConfirmationNumberRoundedIcon from '@mui/icons-material/ConfirmationNumberRounded';
import axios from 'axios';
import { Select, MenuItem, Grid, TextField, Paper } from '@mui/material';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress for loader
import { useNavigate } from 'react-router-dom';

const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];
function TransactionSalary() {
    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [Month, setMonth] = useState(new Date().toLocaleString('default', { month: 'long' }));
    const [loading, setLoading] = useState(false); // State to track loading status
    const [search, setsearch] = useState(''); // Initial state for active status
    const [openModal, setOpenModal] = useState(false); // State to control the modal visibility
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [ActionMessage, setActionMessage] = useState('');
    const [newCalCheckopenModal, setnewCalCheckOpenModal] = useState(false); // State to control the modal visibility
    const [NoSalArr, setNoSalArr] = useState([]);
    const [SalWaitModal, setSalWaitModal] = useState(false); // State to control the modal visibility

    const lastContentRef = useRef(null);
    useLayoutEffect(() => {
        if (lastContentRef.current) {
            lastContentRef.current.style.marginBottom = '80px';
        }
    }, []);

    const generateArrays = (data) => {
        return data.map(item => {
            const earnings = item.salryhd_or_group.filter(head => head.salryhd_sign === "(+)");
            const deductions = item.salryhd_or_group.filter(head => head.salryhd_sign === "(-)");
            const eArray = item.salryhd_or_group.filter(head => head.salryhd_sign === "E");

            return {
                ...item,
                earnings,
                deductions,
                eArray
            };
        });
    };

    const handleErrorModalClose = () => {
        setErrorModalOpen(false);
    };

    const handleSuccessModalClose = () => {
        setSuccessModalOpen(false);
    };

    const handleWaitModalClose = () => {
        setSalWaitModal(false);
    };

    const SalaryDelete = async () => {
        setLoading(true);
        setNoSalArr([]);
        handleCloseModal()
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const masterid = userSchema?.masterid;
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/salart_handleRouter/DeleteSalary`,
                {
                    user: userSchema?.usrnm,
                    compid: JSON.parse(companyIdString),
                    divid: JSON.parse(divIdString),
                    masterid: userSchema?.masterid,
                    Month: Month
                },
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            if (response.data.status) {
                setActionMessage(response.data.message)
                fetchAdvanceData();
                setSuccessModalOpen(true)
            }
            else {
                fetchAdvanceData()
                setActionMessage(response.data.message)
                setErrorModalOpen(true)
            }

        } catch (error) {
            console.error('Error fetching data:', error);
            fetchAdvanceData()
            setActionMessage('Oops... an error occured.')
            setErrorModalOpen(true)
        }
        finally {
            fetchAdvanceData()
            setLoading(false); // Set loading state to false after receiving response or encountering error
        }
    };

    const getMonthStartAndEndDate = (monthString) => {
        const today = new Date();
        let year = today.getFullYear(); 
        const monthIndex = new Date(`${monthString} 1, ${year}`).getMonth();
        if (today.getMonth() === 0 && monthIndex === 11) {
            year -= 1; 
        }
        const startDate = new Date(year, monthIndex, 1);
        const endDate = new Date(year, monthIndex + 1, 0);
        endDate.setUTCHours(23, 59, 59, 999); 
        return {
            startDate,
            endDate
        };
    };
    
    const RunNightCron = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const masterid = userSchema?.masterid;
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const dfdf = await getMonthStartAndEndDate(Month);
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/attendance/NightCronManual`,
                {
                    compid: JSON.parse(companyIdString),
                    divid: JSON.parse(divIdString),
                    masterid,
                    month: Month,
                    startDate: dfdf.startDate,
                    endDate: dfdf.endDate,
                },
                {
                    params: {
                    },
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );
        } catch (error) {
            handleWaitModalClose();
            console.error('Error fetching data:', error);
        }
    };

    const GenerateSalaryYes = async () => {
        // handleCloseNewCalModal();
        setSalWaitModal(true);
        setLoading(true);
        try {
            await RunNightCron();
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const masterid = userSchema?.masterid;
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/salart_handleRouter/GeneratingSalary2`,
                {
                    user: userSchema?.usrnm,
                    compid: JSON.parse(companyIdString),
                    divid: JSON.parse(divIdString),
                    masterid: userSchema?.masterid,
                    month: Month,
                    newCal: true,
                },
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            if (response.data.status) {
                setNoSalArr(response?.data?.NoSalArr);
                setActionMessage(response.data.message)
                setSuccessModalOpen(true)
                fetchAdvanceData();
                handleWaitModalClose();
            }
            else {
                fetchAdvanceData()
                setActionMessage(response.data.message)
                setErrorModalOpen(true)
                handleWaitModalClose();
            }

        } catch (error) {
            console.error('Error fetching data:', error);
            setActionMessage('Oops... an error occured.')
            setErrorModalOpen(true)
            handleWaitModalClose();
        }
        finally {
            fetchAdvanceData();
            setLoading(false); // Set loading state to false after receiving response or encountering error
        }
    };

    const GenerateSalaryNo = async () => {
        // handleCloseNewCalModal();
        setSalWaitModal(true);
        setLoading(true);
        try {
            await RunNightCron();
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const masterid = userSchema?.masterid;
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/salart_handleRouter/GeneratingSalary2`,
                {
                    user: userSchema?.usrnm,
                    compid: JSON.parse(companyIdString),
                    divid: JSON.parse(divIdString),
                    masterid: userSchema?.masterid,
                    month: Month,
                    newCal: false,
                },
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            if (response.data.status) {
                setNoSalArr(response?.data?.NoSalArr);
                setActionMessage(response.data.message)
                setSuccessModalOpen(true)
                fetchAdvanceData();
                handleWaitModalClose();
            }
            else {
                fetchAdvanceData()
                setActionMessage(response.data.message)
                setErrorModalOpen(true)
                handleWaitModalClose();
            }

        } catch (error) {
            console.error('Error fetching data:', error);
            setActionMessage('Oops... an error occured.')
            setErrorModalOpen(true)
            handleWaitModalClose();
        }
        finally {
            fetchAdvanceData();
            setLoading(false); // Set loading state to false after receiving response or encountering error
        }
    };

    const handleDelete = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleOpenNewCalModal = () => {
        setnewCalCheckOpenModal(true);
    };

    const handleCloseNewCalModal = () => {
        setnewCalCheckOpenModal(false);
    };

    const handleYesClick = () => {
        handleCloseNewCalModal();
        GenerateSalaryYes();
    };

    const handleNoClick = () => {
        handleCloseNewCalModal();
        GenerateSalaryNo();
    };

    const fetchAdvanceData = async () => {
        setLoading(true);
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const masterid = userSchema?.masterid;
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/salart_handleRouter/Salary_List?search=${search}`,
                {
                    user: userSchema?.usrnm,
                    compid: JSON.parse(companyIdString),
                    divid: JSON.parse(divIdString),
                    masterid: userSchema?.masterid,
                    Month: Month
                },
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            console.log(generateArrays(response.data.data));
            setData(generateArrays(response.data.data));

        } catch (error) {
            console.error('Error fetching data:', error);
        }
        finally {
            setLoading(false); // Set loading state to false after receiving response or encountering error
        }
    };

    useEffect(() => {
        fetchAdvanceData()
    }, [Month, search])

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        border: '2px solid',
        boxShadow: 24,
        padding: 16,
        minWidth: 300,
        maxWidth: 500,
        textAlign: 'center'
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        { field: 'full_name', headerName: 'Full Name', width: 250 },
        { field: 'reason', headerName: 'Reason', width: 300 },
    ];

    const NoSalArrrows = NoSalArr?.map((item, index) => ({
        id: index + 1,
        Sno: index + 1,
        full_name: item.full_name,
        reason: item.reason,
    }));


    return (
        <>
            <Container ref={lastContentRef}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {/* <Box display="flex" alignItems="center">
            <Button onClick={handlePrevMonth}><ArrowBackIosIcon /></Button>
            <div style={{ width: '150px' }}>
              <Typography variant="h6" align="center" >{monthYearString}</Typography>
            </div>
            <Button onClick={handleNextMonth}><ArrowForwardIosIcon /></Button>
          </Box> */}
                        <Paper elevation={3} style={{ padding: '20px', marginBottom: '10px' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                                    Salary Overview
                                </Typography>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px'}}>

                                    <input
                                        type="text"
                                        placeholder="Search..."
                                        value={search}
                                        onChange={(e) => setsearch(e.target.value)}
                                        style={{ padding: '8px', borderRadius: '14px', border: '1px solid #ccc', width: '200px', height: '22px' }}
                                    />

                                    <Select
                                        value={Month}
                                        onChange={(e) => setMonth(e.target.value)}
                                        fullWidth
                                        sx={{ textAlign: 'center', width: '200px', borderRadius: '14px', height: '40px' }}
                                    >
                                        {months.map(month => (
                                            <MenuItem key={month} value={month}>{month}</MenuItem>
                                        ))}
                                    </Select>
                                    <Button onClick={(e) => {
                                        if (e.metaKey || e.ctrlKey) {
                                            const url = `/transaction/nosalry_rec`;
                                            window.open(url, '_blank');
                                        } else {
                                            navigate('/transaction/nosalry_rec')
                                        }
                                    }}
                                    variant='contained' sx={{borderRadius: '14px', }}>Unprocessed Salaries</Button>
                                </div>
                            </div>
                            {loading ? (
                                <Box display="flex" justifyContent="center" mt={2}>
                                    <CircularProgress size={60} /> {/* Adjust the size as needed */}
                                </Box>
                            ) : (
                                data.length === 0 ? (
                                    <Typography variant="body1" align="center" mt={2}>No records found.</Typography>
                                ) : (data.map((monthData, index) => (
                                    <Paper key={index} elevation={0} style={{ marginTop: '10px' }}>
                                        <Accordion >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Grid container alignItems="center" justifyContent="space-between">
                                                    <Grid item xs={5}>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <ConfirmationNumberRoundedIcon style={{ marginRight: '10px', color: '#3d73d9' }} />
                                                            <div>
                                                                <Typography sx={{ fontWeight: 'bold' }}>{monthData?.employee?.full_name}</Typography>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <div>
                                                            <Typography style={{ fontSize: '0.9rem' }}>Payable Days</Typography>
                                                            <Typography sx={{ fontWeight: 'bold' }}>{monthData?.total_attendance || 0}</Typography>
                                                        </div>
                                                    </Grid>

                                                    <Grid item xs={3}>
                                                        <div>
                                                            <Typography style={{ fontSize: '0.9rem' }}>Gross Salary</Typography>
                                                            <Typography sx={{ fontWeight: 'bold' }}>{monthData.gross_salary || '0'}</Typography>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container spacing={2}>
                                                    {/* Earnings Section */}
                                                    <Grid item xs={6}>
                                                        <TableContainer>
                                                            <Table>
                                                                <TableBody>
                                                                    <TableRow>
                                                                        <TableCell colSpan={6} sx={{ fontWeight: 'bold' }}>Earnings</TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold' }}>Actual</TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold' }}>Calculated</TableCell>
                                                                    </TableRow>
                                                                    {Object.entries(monthData.earnings).map(([index, value]) => (
                                                                        <TableRow key={index}>
                                                                            <TableCell colSpan={6}>{value?.salaryhead_name.salaryhead_name || 'Not Marked'}</TableCell>
                                                                            <TableCell>{value.salryhd_vlu || '0'}</TableCell>
                                                                            <TableCell>{value.amount || '0'}</TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                    <TableRow>
                                                                        <TableCell colSpan={6} sx={{ fontWeight: 'bold' }}>Calculated Gross</TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold' }}></TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold' }}>{monthData.calculated_gross || '0'}</TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </Grid>

                                                    {/* Deductions Section */}
                                                    <Grid item xs={6}>
                                                        <TableContainer>
                                                            <Table>
                                                                <TableBody>
                                                                    <TableRow>
                                                                        <TableCell colSpan={6} sx={{ fontWeight: 'bold' }}>Deductions</TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold' }}>Actual</TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold' }}>Calculated</TableCell>
                                                                    </TableRow>
                                                                    {Object.entries(monthData.deductions).map(([index, value]) => (
                                                                        <TableRow key={index}>
                                                                            <TableCell colSpan={6}>{value?.salaryhead_name.salaryhead_name || 'Not Marked'}</TableCell>
                                                                            <TableCell>{value.salryhd_vlu || '0'}</TableCell>
                                                                            <TableCell>{value.amount || '0'}</TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                    <TableRow>
                                                                        <TableCell colSpan={6} sx={{ fontWeight: 'bold' }}>Total Deductions</TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold' }}></TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold' }}>{monthData.total_less || '0'}</TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </Grid>
                                                </Grid>

                                                <Grid container>
                                                    <Grid item xs={12} md={12} lg={12}>
                                                        <TableContainer>
                                                            <Table>
                                                                <TableBody>
                                                                    <TableRow>
                                                                        <TableCell colSpan={10} sx={{ fontWeight: 'bold' }}>OT Hours</TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold' }}></TableCell>
                                                                        <TableCell colSpan={2} sx={{ fontWeight: 'bold', textAlign: 'center' }}>{monthData.ot_hrs || '0'}</TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell colSpan={10} sx={{ fontWeight: 'bold' }}>OT Amount</TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold' }}></TableCell>
                                                                        <TableCell colSpan={2} sx={{ fontWeight: 'bold', textAlign: 'center' }}>{monthData.amt || '0'}</TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell colSpan={10} sx={{ fontWeight: 'bold' }}>Payable Days</TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold' }}></TableCell>
                                                                        <TableCell colSpan={2} sx={{ fontWeight: 'bold', textAlign: 'center' }}>{monthData.total_attendance || '0'}</TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell colSpan={10} sx={{ fontWeight: 'bold' }}>Advance</TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold' }}></TableCell>
                                                                        <TableCell colSpan={2} sx={{ fontWeight: 'bold', textAlign: 'center' }}>{monthData.less_advance || '0'}</TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell colSpan={10} sx={{ fontWeight: 'bold' }}>Loan</TableCell>
                                                                        <TableCell sx={{ fontWeight: 'bold' }}></TableCell>
                                                                        <TableCell colSpan={2} sx={{ fontWeight: 'bold', textAlign: 'center' }}>{monthData.less_loan || '0'}</TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell colSpan={10} sx={{ fontWeight: 'bold' }}>    Due Amount: {monthData.net_salary}</TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Paper>
                                ))
                                ))}
                        </Paper>
                    </Grid>
                </Grid>
                <Paper style={{ padding: '16px', position: 'fixed', bottom: '16px', zIndex: 1000, width: '60%', left: '0', right: '0', margin: '0 auto' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }}>
                        <Button style={{ marginRight: '8px' }} variant="contained" color="error" onClick={handleDelete}>
                            Delete Salary
                        </Button>
                        <Button style={{ marginLeft: '8px' }} variant="contained" color="primary" onClick={handleOpenNewCalModal}>
                            Generate Salary
                        </Button>
                    </div>
                </Paper>
                <Modal
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, width: 400 }}>
                        <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
                            Confirm Deletion
                        </Typography>
                        <Typography id="modal-description" sx={{ mt: 2 }}>
                            Are you sure you want to delete Salaries?
                        </Typography>
                        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={handleCloseModal} color="primary" sx={{ mr: 2 }}>
                                Cancel
                            </Button>
                            <Button onClick={SalaryDelete} variant="contained" color="error">
                                Delete
                            </Button>
                        </Box>
                    </Box>
                </Modal>

                <Modal
                    open={newCalCheckopenModal}
                    onClose={handleCloseNewCalModal}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                            width: 400,
                        }}
                    >
                        <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
                            Select
                        </Typography>
                        <Typography id="modal-description" sx={{ mt: 2 }}>
                            Do you want to use the new calculation method?
                        </Typography>
                        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={handleNoClick} color="primary" sx={{ mr: 2 }}>
                                No
                            </Button>
                            <Button onClick={handleYesClick} variant="contained" color="success">
                                Yes
                            </Button>
                        </Box>
                    </Box>
                </Modal>

                <Modal
                    open={successModalOpen}
                    onClose={handleSuccessModalClose}
                    aria-labelledby="success-modal-title"
                    aria-describedby="success-modal-description"
                >
                    <div style={{ ...modalStyle, borderColor: '#007bff' }}>
                        <h2 id="success-modal-title" style={{ color: '#007bff' }}>Success!</h2>
                        <p id="success-modal-description">{ActionMessage}</p>
                        {NoSalArr.length > 0 && (
                            <Typography variant="h6" gutterBottom>
                                No Salary or Attendance Records
                            </Typography>
                        )}
                        {NoSalArr.length > 0 && (
                            <DataGrid
                                rows={NoSalArrrows}
                                columns={columns}
                                initialState={{
                                    ...data.initialState,
                                    pagination: { paginationModel: { pageSize: 5 } },

                                }}
                                pageSizeOptions={[5, 10]}
                                pagination
                                autoHeight
                                checkboxSelection={false}
                                disableSelectionOnClick
                                density="compact"
                                slots={{ toolbar: GridToolbar }}
                                slotProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                    },
                                }}
                            />
                        )}

                        <Button variant="contained" onClick={handleSuccessModalClose} style={{ backgroundColor: '#007bff', color: 'white' }}>Close</Button>
                    </div>
                </Modal>

                <Modal
                    open={errorModalOpen}
                    onClose={handleErrorModalClose}
                    aria-labelledby="error-modal-title"
                    aria-describedby="error-modal-description"
                >
                    <div style={{ ...modalStyle, borderColor: '#ff0000' }}>
                        <h2 id="error-modal-title" style={{ color: '#ff0000' }}>Error!</h2>
                        <p id="error-modal-description">{ActionMessage}.</p>
                        <Button variant="contained" onClick={handleErrorModalClose} style={{ backgroundColor: '#ff0000', color: 'white' }}>Close</Button>
                    </div>
                </Modal>

                <Modal
                    open={SalWaitModal}
                    onClose={handleWaitModalClose}
                    aria-labelledby="success-modal-title"
                    aria-describedby="success-modal-description"
                >
                    <div style={{ ...modalStyle, borderColor: '#007bff' }}>
                        <h2 id="success-modal-title" style={{ color: '#007bff' }}>Salary generation in progress! It may take up to 15 minutes, so feel free to step away and enjoy some tea time.</h2>
                        <Button variant="contained" onClick={handleWaitModalClose} style={{ backgroundColor: '#007bff', color: 'white' }}>Close</Button>
                    </div>
                </Modal>
            </Container>
        </>
    );
}

export default TransactionSalary;
