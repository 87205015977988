import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
// import LogoImage from './logo.jpeg';

const Registration = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [company, setCompany] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [email, setEmail] = useState('');
    const [division, setDivision] = useState('');
    const [companyError, setCompanyError] = useState('');
    const [mobileNoError, setMobileNoError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [divisionError, setDivisionError] = useState('');

    const handleCompanyChange = (event) => {
        setCompany(event.target.value);
        validateCompany(event.target.value);
    };

    const handleMobileNoChange = (event) => {
        setMobileNo(event.target.value);
        // validateMobileNo(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        // validateEmail(event.target.value);
    };

    const handleDivisionChange = (event) => {
        setDivision(event.target.value);
        // validateDivision(event.target.value);
    };

    const validateCompany = (value) => {
        if (!value) {
            toast.error('Company is required');
            setCompanyError('Company is required');
            return false;
        }
        setCompanyError('');
        return true;
    };

    const validateMobileNo = (value) => {
        if (!value) {
            toast.error('Mobile number is required');
            setMobileNoError('Mobile number is required');
            return false;
        } else if (!/^\d{10}$/.test(value)) {
            toast.error('Mobile number must be 10 digits');
            setMobileNoError('Mobile number must be 10 digits');
            return false;
        }
        setMobileNoError('');
        return true;
    };

    const validateEmail = (value) => {
        if (!value) {
            toast.error('Email id is required');
            setEmailError('Email id is required');
            return false;
        } else if (!/\S+@\S+\.\S+/.test(value)) {
            toast.error('Invalid email format');
            setEmailError('Invalid email format');
            return false;
        }
        setEmailError('');
        return true;
    };

    const validateDivision = (value) => {
        if (!value) {
            toast.error('Division is required');
            setDivisionError('Division is required');
            return false;
        }
        setDivisionError('');
        return true;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const isCompanyValid = validateCompany(company);
        const isMobileNoValid = validateMobileNo(mobileNo);
        const isEmailValid = validateEmail(email);
        const isDivisionValid = validateDivision(division);

        if (!isCompanyValid || !isMobileNoValid || !isEmailValid || !isDivisionValid) {
            return;
        }

        setIsLoading(true);
        const loginAPI = `${process.env.REACT_APP_API_URL}/auth/register`;
        const requestData = {
            company: company,
            mobileNo: mobileNo,
            email: email,
            division: division
        };

        try {
            const response = await axios.post(loginAPI, requestData);
            setIsLoading(false);
            console.log(response)
            if (response.data.status) {
                toast.success('Registration successful!');
                setTimeout(() => {
                    navigate('/auth/login');
                }, 2500);
            }
            else {
                toast.error(response.data.message);
            }
        } catch (error) {
            setIsLoading(false);
            toast.error('Oops! Some error occurred.');
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <div style={{ padding: '40px', maxWidth: '400px', width: '100%', backgroundColor: '#f9f9f9', borderRadius: '10px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)' }}>
                {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={"/logo.jpeg"} alt="Profile" style={{ width: '200px', height: '200px' }} />
                </div>                 */}
                <h2 style={{ textAlign: 'center', marginBottom: '30px', fontSize: '40px' }}>Registration</h2>
                <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                    <input
                        type="text"
                        placeholder="Company"
                        style={{ padding: '15px', marginBottom: '20px', borderRadius: '8px', border: `1px solid ${companyError ? 'red' : '#ccc'}` }}
                        value={company}
                        onChange={handleCompanyChange}
                    />

                    <input
                        type="number"
                        placeholder="Mobile no"
                        style={{ padding: '15px', marginBottom: '20px', borderRadius: '8px', border: `1px solid ${mobileNoError ? 'red' : '#ccc'}` }}
                        value={mobileNo}
                        onChange={handleMobileNoChange}
                    />

                    <input
                        type="email"
                        placeholder="Email id"
                        style={{ padding: '15px', marginBottom: '20px', borderRadius: '8px', border: `1px solid ${emailError ? 'red' : '#ccc'}` }}
                        value={email}
                        onChange={handleEmailChange}
                    />

                    <input
                        type="text"
                        placeholder="Division"
                        style={{ padding: '15px', marginBottom: '20px', borderRadius: '8px', border: `1px solid ${divisionError ? 'red' : '#ccc'}` }}
                        value={division}
                        onChange={handleDivisionChange}
                    />

                    <button
                        type="submit"
                        style={{ padding: '15px 0', borderRadius: '8px', border: 'none', backgroundColor: '#007bff', color: '#fff', cursor: isLoading ? 'not-allowed' : 'pointer', transition: 'background-color 0.3s ease' }}
                        disabled={isLoading}
                    >
                        {isLoading ? 'Registering...' : 'Register'}
                    </button>
                    <div style={{ textAlign: 'right', marginTop: '10px' }}>
                        <button
                            onClick={() => navigate('/auth/login')}
                            style={{
                                textDecoration: 'underline',
                                color: '#007bff',
                                transition: 'color 0.3s, font-size 0.3s',
                                fontSize: '16px',
                                background: 'none',
                                border: 'none',
                                cursor: 'pointer',
                            }}
                            onMouseEnter={(e) => e.target.style.color = '#0056b3'}
                            onMouseLeave={(e) => e.target.style.color = '#007bff'}
                        >
                            Login
                        </button>
                    </div>
                </form>
            </div>
            <ToastContainer />
        </div>
    );
};

export default Registration;









