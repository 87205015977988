import React from 'react';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useLocation, useNavigate } from 'react-router-dom';

function Nav() {

    const location = useLocation();
    const Navigate = useNavigate();

    const navItems = [
        { label: 'Edit User', path: '/user_rights/user_rights_edit' },
        { label: 'Edit User Rights', path: '/user_rights/user_rights_edit_list' },
    ];

    const handleItemClick = (path) => {
        Navigate(path);
    };

    return (
        <Paper elevation={3} style={{ padding: '20px', marginBottom: '10px', borderRadius: '10px' }}>
            <List style={{ maxWidth: '100%', overflowX: 'auto' }}>
                {navItems.map((item) => (
                    <ListItem 
                        key={item.label} 
                        button 
                        onClick={() => handleItemClick(item.path)}
                        sx={{ 
                            borderRadius: '10px', 
                            '&:hover': { 
                                backgroundColor: location.pathname === item.path ? '#2196F4' : 'inherit',
                                color: location.pathname === item.path ? '#fff' : 'inherit',
                            },
                            backgroundColor: location.pathname === item.path ? '#2196F4' : 'inherit',
                            color: location.pathname === item.path ? '#fff' : 'inherit'
                        }}
                    >
                        <ListItemText primary={item.label} />
                    </ListItem>
                ))}
            </List>
        </Paper>
    );
}

export default Nav;
