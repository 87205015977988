import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Typography, Grid, Box, Button, Paper, TextField, Divider, CircularProgress, Select, MenuItem, Container } from '@mui/material';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import axios from 'axios';
import MUIDataTable from "mui-datatables";
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Tooltip from '@mui/material/Tooltip';
import FileDownloadIcon from '@mui/icons-material/FileDownload'; // Import the FileDownloadIcon
import { useRef, useLayoutEffect } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid'; // Import DataGrid from MUI
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import '../../App.css';
import moment from 'moment';
import LoadingModal from '../loader'; // Import LoadingModal component
import { useGridApiRef } from '@mui/x-data-grid';

const MissPunchReport = () => {
    const navigate = useNavigate();

    const [selectionRange, setSelectionRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });

    const [tempStartDate, setTempStartDate] = useState(new Date());
    const [tempEndDate, setTempEndDate] = useState(new Date());
    const dateRangePickerRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleSelect = (ranges) => {
        // Update the tempStartDate and tempEndDate independently
        const { startDate, endDate } = ranges.selection;
        if (startDate && endDate) {
            if (startDate <= tempEndDate) {
                setTempStartDate(startDate);
            }
            if (endDate >= tempStartDate) {
                setTempEndDate(endDate);
            }
        }
    };

    const applyDateRange = () => {
        setSelectionRange({
            startDate: tempStartDate,
            endDate: tempEndDate,
            key: 'selection',
        });
        setIsOpen(false);
    };

    const handleClickOutside = (event) => {
        if (dateRangePickerRef.current && !dateRangePickerRef.current.contains(event.target)) {
            setIsOpen(false); // Close the date picker when clicked outside
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDateRangePicker = () => {
        setIsOpen(!isOpen);
    };

    const formatDates = (date) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const [isLoading, setIsLoading] = useState(false); // State variable to manage loading state
    const [shiftisLoading, setshiftIsLoading] = useState(false); // State variable to manage loading state
    const [DepisLoading, setDepIsLoading] = useState(false); // State variable to manage loading state

    const [isFileLoading, setisFileLoading] = useState(false); // State variable to manage loading state
    const [loader, setloader] = useState(false);
    const [data, setData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0); // Initialize currentPage to 0
    const [totalCount, setTotalCount] = useState(0); // State to hold total count of rows
    const [MonthName, setMonthName] = useState(new Date().toLocaleString('default', { month: 'long' }));
    const [EmployeeName, setEmployeeName] = useState({_id: '', full_name:'ALL'});
    const [otChecked, setOtChecked] = useState(false);
    const [latcoming, setlatcoming] = useState(null);
    const [misspunch, setmisspunch] = useState(true);
    const [LeaveName, setLeaveName] = useState('');
    const [startDate, setstartDate] = React.useState(dayjs());
    const [endDate, setendDate] = React.useState(dayjs());

    const [totalRowCount, setTotalRowCount] = React.useState(0);
    const [searchString, setsearchString] = useState(null);
    const [queryOptions, setQueryOptions] = React.useState({});
    const [filterModel, setFilterModel] = React.useState({
        items: [],
        quickFilterValues: [],
    });
    const apiRef = useGridApiRef();
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 10,
    });

    const [EditDetailsDate, setEditDetailsDate] = useState();
    const [EditDetailsEmployee, setEditDetailsEmployee] = useState();
    const [currentIndex, setCurrentIndex] = useState(null);

    const [deleteIndex, setDeleteIndex] = useState(null); // State to store the index of item to be deleted

    const [shiftdata, setshiftData] = useState([]);

    const [depdata, setdepData] = useState([]);

    const [open, setOpen] = useState(false);
    const [Intime, setIntime] = useState();
    const [Outtime, setOuttime] = useState();
    const [shiftType, setShifTtype] = useState([]);
    const [LeaveType, setLeaveType] = useState([]);
    const [DepartType, setDepartType] = useState([]);
    const [shiftTypeValue, setShifTtypeValue] = useState('');
    const [LeaveTypeValue, setLeaveTypeValue] = useState('');
    const [DepartTypeValue, setDepartTypeValue] = useState('');
    const [openModal, setOpenModal] = useState(false); // State to control the modal visibility
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [ActionMessage, setActionMessage] = useState('');
    const [EditID, setEditID] = useState();
    const [openAdd, setOpenAdd] = useState(false);
    const [EmpList, setEmpList] = useState([]);
    const [Empname, setEmpname] = useState('');
    const [date, setDate] = useState(dayjs());
    const [month, setMonth] = useState('');
    const [openDeleteAllModal, setopenDeleteAllModal] = useState(false); // State to control the modal visibility
    const [DeleteIndexArray, setDeleteIndexArray] = useState([]); // State to store the index of item to be deleted
    const [selectedRows, setSelectedRows] = useState([]);

    const handleSelectionModelChange = (newSelectionModel) => {
        setSelectedRows(newSelectionModel);
    };

    const handleDeleteAll = () => {
        // Map selected row IDs to an array
        const ids = selectedRows.map(index => data[index]?._id?.atdid);
        console.log(ids)
        setDeleteIndexArray(ids);
        setopenDeleteAllModal(true);
    };

    const dataGridRef = useRef(null);

    const handleModalDeleteAll = async () => {
        try {
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/attendance/AttandanceDELETEAll`, { DeleteIndexArray }, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            });
            console.log('Delete API Response:', response.data);
            handleDeleteAllCloseModal();
            if (dataGridRef.current) {
                dataGridRef.current.api.setRowSelectionModel([]);
            }
            await fetchData();
            setSelectedRows([])
            setDeleteIndexArray([]);
        } catch (error) {
            console.error('Error deleting item:', error);
        }
    };

    const handleDeleteAllCloseModal = () => {

        setopenDeleteAllModal(false);
        setSelectedRows([]);
        setDeleteIndexArray([]);
        if (dataGridRef.current) {
            dataGridRef.current.api.setRowSelectionModel([]);
        }
    };

    const lastContentRef = useRef(null);
    useLayoutEffect(() => {
        if (lastContentRef.current) {
            lastContentRef.current.style.marginBottom = '70px';
        }
    }, []);

    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const handleCheckboxChange = () => {
        setOtChecked(prevState => !prevState); // Toggle the state
    };

    const handleMissPunchChange = () => {
        setmisspunch(prevState => !prevState); // Toggle the state
    };

    const handleAttendFileDownload = async () => {
        const userSchemaString = localStorage.getItem('userSchema');
        const userSchema = JSON.parse(userSchemaString);
        const companyIdString = localStorage.getItem('companyId');
        const divIdString = localStorage.getItem('divId');

        const dataToSend = {
            usrnm: userSchema?.usrnm,
            compid: JSON.parse(companyIdString),
            divid: JSON.parse(divIdString),
            masterid: userSchema?.masterid,
        };
        const token = localStorage.getItem('user-token');
        const config = {
            url: `${process.env.REACT_APP_API_URL}/attendance/AttendanceFileDownload`,
            method: 'post',
            responseType: 'blob',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: dataToSend,
        };


        axios(config)
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Attendance_Sheet.xls');
                document.body.appendChild(link);
                link.click();
            })
            .catch((error) => {
                console.error('Error downloading file:', error);
                // Handle error
            });
    };

    const fetchData = async () => {
        try {
            if(EmployeeName?.full_name){
                setIsLoading(true)
                const userSchemaString = localStorage.getItem('userSchema');
                const userSchema = JSON.parse(userSchemaString);
                const masterid = userSchema?.masterid;
                const companyIdString = localStorage.getItem('companyId');
                const divIdString = localStorage.getItem('divId');
                const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
                const response = await axios.post(
                    `${process.env.REACT_APP_API_URL}/attendance/GetattendanceDetails`,
                    {
                        compid: JSON.parse(companyIdString),
                        divid: JSON.parse(divIdString),
                        masterid,
                        month: MonthName,
                        employee: EmployeeName?._id,
                        ot: otChecked,
                        misspunch,
                        leaveType: LeaveName?._id,
                        // DepartType: DepartTypeValue?._id,
                        searchText,
                        rowsPerPage,
                        currentPage,
                        latcoming,
                        startDate: selectionRange.startDate,
                        endDate: selectionRange.endDate,
                        page: paginationModel.page + 1,
                        pageSize: paginationModel.pageSize,
                    },
                    {
                        params: {
                            searchText,
                            rowsPerPage,
                            currentPage,
                        },
                        headers: {
                            authorization: `Bearer ${token}`
                        }
                    }
                );
    
    
                setData(response.data.summary);
                // setTotalCount(response.data.recordsFiltered); // Update total count of rows
                console.log('225', response.data.summarycount)
                setTotalCount(response.data.summarycount); // Update total count of rows
                setTotalRowCount(response.data.totalDocuments);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        finally {
            setIsLoading(false)
        }
    };

    const RunNightCron = async () => {
        try {
            setloader(true);
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const masterid = userSchema?.masterid;
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/attendance/NightCronManual`,
                {
                    compid: JSON.parse(companyIdString),
                    divid: JSON.parse(divIdString),
                    masterid,
                    month: MonthName,
                    employee: EmployeeName?._id,
                    ot: otChecked,
                    leaveType: LeaveName?._id,
                    // DepartType: DepartTypeValue?._id,
                    searchText,
                    rowsPerPage,
                    currentPage,
                    startDate: selectionRange.startDate,
                    endDate: selectionRange.endDate,
                },
                {
                    params: {
                        searchText,
                        rowsPerPage,
                        currentPage,
                    },
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );
            await fetchData();
            console.log('225', response.data)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        finally {
            setloader(false);
        }
    };

    const RunAttendCron = async () => {
        try {
            setloader(true);
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const masterid = userSchema?.masterid;
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/attendance/AttendCronManual`,
                {
                    compid: JSON.parse(companyIdString),
                    divid: JSON.parse(divIdString),
                    masterid,
                    month: MonthName,
                    employee: EmployeeName?._id,
                    ot: otChecked,
                    leaveType: LeaveName?._id,
                    // DepartType: DepartTypeValue?._id,
                    searchText,
                    rowsPerPage,
                    currentPage,
                    startDate: selectionRange.startDate,
                    endDate: selectionRange.endDate,
                },
                {
                    params: {
                        searchText,
                        rowsPerPage,
                        currentPage,
                    },
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );
            await fetchData();
            console.log('225', response.data)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        finally {
            setloader(false)
        }
    };

    const fetchShiftData = async () => {
        try {
            setshiftIsLoading(true);
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const masterid = userSchema?.masterid;
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/attendance/GetattendanceShift`,
                {
                    compid: JSON.parse(companyIdString),
                    divid: JSON.parse(divIdString),
                    masterid,
                    // month: MonthName,
                    startDate: selectionRange.startDate,
                    endDate: selectionRange.endDate,
                },
                {
                    params: {
                        searchText,
                        rowsPerPage,
                        currentPage,
                    },
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );


            const calculateAttendanceCountByShiftAndCategory = (summaryData) => {

                const shifts = new Set(summaryData.map(entry => entry._id.shiftname[0]));
                const categories = new Set(summaryData.flatMap(entry => entry._id.lvname));
                categories.add("Not Marked");

                const attendanceCountByShiftAndCategory = {};
                shifts.forEach(shift => {
                    attendanceCountByShiftAndCategory[shift] = { shiftName: shift }; // Add shift name property
                    categories.forEach(category => {
                        attendanceCountByShiftAndCategory[shift][category] = 0;
                    });
                });
                summaryData.forEach(entry => {
                    const shiftName = entry._id.shiftname[0]; // Assuming shiftname is always an array with a single value
                    const shiftData = attendanceCountByShiftAndCategory[shiftName];
                    const lvnames = entry._id.lvname;
                    if (lvnames.length === 0) {
                        shiftData["Not Marked"] += entry.count;
                    } else {
                        lvnames.forEach(category => {
                            shiftData[category] = (shiftData[category] || 0) + entry.count;
                        });
                    }
                });
                return attendanceCountByShiftAndCategory;
            };
            const attendanceCountByShiftAndCategory = await calculateAttendanceCountByShiftAndCategory(response.data.summary);
            const attendanceCountArray = Object.keys(attendanceCountByShiftAndCategory).map(key => attendanceCountByShiftAndCategory[key]);

            setshiftData(attendanceCountArray);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        finally {
            setshiftIsLoading(false);
        }
    };

    const fetchDepData = async () => {
        try {
            setDepIsLoading(true);
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const masterid = userSchema?.masterid;
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/attendance/Getattendancedept`,
                {
                    compid: JSON.parse(companyIdString),
                    divid: JSON.parse(divIdString),
                    masterid,
                    // month: "May",
                    startDate: selectionRange.startDate,
                    endDate: selectionRange.endDate,
                },
                {
                    params: {
                        searchText,
                        rowsPerPage,
                        currentPage,
                    },
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );



            const countAttendanceTypesByDept = (summaryData) => {
                const attendanceCountByDept = [];

                summaryData.forEach(entry => {
                    const deptNames = entry._id.deptname;
                    const lvnames = entry._id.lvname;
                    const count = entry.count;

                    let department;

                    if (deptNames.length === 0) {
                        department = "Unknown Department";
                    } else {
                        department = deptNames[0]; // Assuming deptname is always an array with a single value
                    }

                    // Find department in the attendanceCountByDept array
                    let departmentIndex = attendanceCountByDept.findIndex(obj => obj.department === department);

                    // If department does not exist in the array, create a new object for it
                    if (departmentIndex === -1) {
                        departmentIndex = attendanceCountByDept.length;
                        attendanceCountByDept.push({
                            department: department,
                            Present: 0,
                            Absent: 0,
                            "Paid Holiday": 0,
                            "Weekly Off": 0,
                            "Half Day": 0,
                            Night: 0,
                            "Not Marked": 0
                        });
                    }

                    // Increment the count for the corresponding type within the department object
                    if (lvnames.length === 0) {
                        attendanceCountByDept[departmentIndex]["Not Marked"] += count;
                    } else {
                        lvnames.forEach(lvname => {
                            if (attendanceCountByDept[departmentIndex].hasOwnProperty(lvname)) {
                                attendanceCountByDept[departmentIndex][lvname] += count;
                            } else {
                                attendanceCountByDept[departmentIndex]["Not Marked"] += count;
                            }
                        });
                    }
                });

                return attendanceCountByDept;
            };

            const attendanceCountByDept = countAttendanceTypesByDept(response.data.summary);

            setdepData(attendanceCountByDept);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        finally {
            setDepIsLoading(false);
        }
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = async () => {
        await fetchData();
        setOpen(false);
        setEditID('')
        setIntime('');
        setOuttime('')
        setShifTtypeValue('')
        setLeaveTypeValue('')
    };

    const handleDelete = (INFO) => {
        console.log(INFO)
        setDeleteIndex(INFO?.atdid);
        setOpenModal(true);
    };

    const handleModalDelete = async () => {
        try {
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/attendance/AttandanceDELETE/${deleteIndex}`, {}, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            });
            console.log('Delete API Response:', response.data);
            handleCloseModal();
            fetchData();
        } catch (error) {
            console.error('Error deleting item:', error);
        }
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };


    // const handleEdit = (params) => {
    //     const rowIndex = params.api.getAllRowIds().indexOf(params.id); // Using getAllRowIds to get the index of the row by id
    //     const editdata = data[rowIndex]
    //     setEditID(editdata._id.atdid);
    //     setIntime(editdata._id.dalatten_intime || '');
    //     setOuttime(editdata._id.dalatten_outtime || '');
    //     setEditDetailsDate(convertToIndianTime(editdata?._id?.dalatten_date));
    //     // Find shift type _id based on shift name
    //     if (editdata._id.shiftname[0]) {
    //         const shiftName = editdata._id.shiftname[0].toUpperCase();
    //         const matchingShift = shiftType.find(shift => shift.shift_name.toUpperCase() === shiftName);
    //         if (matchingShift) {
    //             setShifTtypeValue(matchingShift._id);
    //         } else {
    //             setShifTtypeValue('');
    //         }
    //     } else {
    //         setShifTtypeValue('');
    //     }

    //     // Find leave type _id based on description
    //     if (editdata._id.lvname[0]) {
    //         const leaveDescription = editdata._id.lvname[0].toUpperCase();
    //         const matchingLeaveType = LeaveType.find(leave => leave.discription.toUpperCase() === leaveDescription);
    //         if (matchingLeaveType) {
    //             setLeaveTypeValue(matchingLeaveType._id);
    //         } else {
    //             setLeaveTypeValue('');
    //         }
    //     } else {
    //         setLeaveTypeValue('');
    //     }

    //     handleOpen();
    // };

    const handleEdit = (params) => {
        const rowIndex = params.api.getAllRowIds().indexOf(params.id);
        setCurrentIndex(rowIndex);
        updateModalData(rowIndex);
        handleOpen();
    };

    const updateModalData = (rowIndex) => {
        const editdata = data[rowIndex];
        setEditID(editdata._id.atdid);
        setIntime(editdata._id.dalatten_intime || '');
        setOuttime(editdata._id.dalatten_outtime || '');
        setEditDetailsDate(convertToIndianTime(editdata?._id?.dalatten_date));
        setEditDetailsEmployee(editdata?._id?.empname[0]);
        if (editdata._id.shiftname[0]) {
            const shiftName = editdata._id.shiftname[0].toUpperCase();
            const matchingShift = shiftType.find(shift => shift.shift_name.toUpperCase() === shiftName);
            setShifTtypeValue(matchingShift ? matchingShift._id : '');
        } else {
            setShifTtypeValue('');
        }

        if (editdata._id.lvname[0]) {
            const leaveDescription = editdata._id.lvname[0].toUpperCase();
            const matchingLeaveType = LeaveType.find(leave => leave.discription.toUpperCase() === leaveDescription);
            setLeaveTypeValue(matchingLeaveType ? matchingLeaveType._id : '');
        } else {
            setLeaveTypeValue('');
        }
    };

    const handleNext = async () => {
        await handleNextUpdate();
        if (currentIndex !== null && currentIndex < data.length - 1) {
            const nextIndex = currentIndex + 1;
            setCurrentIndex(nextIndex);
            updateModalData(nextIndex);
        }
    };


    const shiftTypeGET = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');

            const data = {
                user: userSchema?.usrnm,
                compid: JSON.parse(companyIdString),
                divid: JSON.parse(divIdString),
                masterid: userSchema?.masterid
            }
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/attendance/shiftTypeGET`, data,
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            setShifTtype(response.data.lastEntryNo);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const DepartmentTypeGET = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');

            const data = {
                user: userSchema?.usrnm,
                compid: JSON.parse(companyIdString),
                divid: JSON.parse(divIdString),
                masterid: userSchema?.masterid
            }
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/attendance/DepartmentTypeGET`, data,
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            setDepartType(response.data.lastEntryNo);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const EmpListGET = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');

            const data = {
                user: userSchema?.usrnm,
                compid: JSON.parse(companyIdString),
                divid: JSON.parse(divIdString),
                masterid: userSchema?.masterid
            }
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/attendance/EmployeeNamesGET`, data,
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            setEmpList(response.data.lastEntryNo);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const fetchLeaveType = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');


            const data = {
                user: userSchema?.usrnm,
                compid: JSON.parse(companyIdString),
                divid: JSON.parse(divIdString),
                masterid: userSchema?.masterid
            };
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/attendance/LeaveTypeGET`, data,
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            setLeaveType(response.data.lastEntryNo);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleClear = async () => {
        setEditID('')
        setIntime('');
        setOuttime('')
        setShifTtypeValue('')
        setLeaveTypeValue('')
        setMonth('')
        setDate(dayjs())
        setEmpname('')
    }
    const handleAdd = async () => {

        try {
            const TimeDifference = await getTimeDifferenceInHours(Intime, Outtime)
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const dataToSend = {
                dalatten_shiftnm: shiftTypeValue,
                dalatten_atdtype: LeaveTypeValue,
                dalatten_intime: Intime,
                dalatten_outtime: Outtime,
                dalatten_total_hour: isNaN(TimeDifference) ? 0 : TimeDifference,
                month: month,
                dalatten_date: date,
                employee_name: Empname?._id,
                usrnm: userSchema?.usrnm,
                compid: JSON.parse(companyIdString),
                divid: JSON.parse(divIdString),
                masterid: userSchema?.masterid,
            };
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/attendance/AddAttendance`, dataToSend, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            });
            if (response.data.status) {
                setActionMessage(response.data.message)
                handleAddClose()
                fetchData()
                handleClear()
                setSuccessModalOpen(true)
            }
            else {
                setActionMessage(response.data.message)
                // handleAddClose()
                // handleClear()
                setErrorModalOpen(true)
            }
        } catch (error) {
            fetchData()
            // handleClear()
            // handleAddClose()
            setActionMessage(error.message)
            setErrorModalOpen(true)
            console.error('Error:', error);
        }
    }

    function getTimeDifferenceInHours(intime, outtime) {
        const [inHours, inMinutes] = intime.split(':').map(Number);
        const [outHours, outMinutes] = outtime.split(':').map(Number);

        const inDate = new Date();
        inDate.setHours(inHours, inMinutes, 0, 0);

        const outDate = new Date();
        outDate.setHours(outHours, outMinutes, 0, 0);

        // If outDate is earlier than or equal to inDate, move outDate to the next day
        if (outDate <= inDate) {
            outDate.setDate(outDate.getDate() + 1);
        }

        // Calculate the difference in milliseconds
        const diffMs = outDate - inDate;

        // Convert milliseconds to total minutes
        const totalMinutes = diffMs / (1000 * 60);

        // Convert total minutes to hours and minutes
        const hours = Math.floor(totalMinutes / 60);
        const minutes = Math.round(totalMinutes % 60);

        // Format minutes as a two-digit number
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

        // Combine hours and formatted minutes as a string
        const timeDifference = `${hours}.${formattedMinutes}`;
        return timeDifference;
    }

    const handleNextUpdate = async () => {
        try {
            const TimeDifference = await getTimeDifferenceInHours(Intime, Outtime)
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/attendance/UpdateAttandance/${EditID}`,
                {
                    dalatten_shiftnm: shiftTypeValue,
                    dalatten_atdtype: LeaveTypeValue,
                    dalatten_intime: Intime,
                    dalatten_outtime: Outtime,
                    dalatten_total_hour: isNaN(TimeDifference) ? 0 : TimeDifference,
                    usrnm: userSchema?.usrnm,
                    compid: JSON.parse(companyIdString),
                    divid: JSON.parse(divIdString),
                    masterid: userSchema?.masterid,
                },
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            if (response.data.status) {
                // setActionMessage(response.data.message)
                // handleClose()
                // fetchData()
                // setEditID('')
                // setIntime('');
                // setOuttime('')
                // setShifTtypeValue('')
                // setLeaveTypeValue('')
                // setSuccessModalOpen(true)
            }
            else {
                setActionMessage(response.data.message)
                fetchData()
                // handleClose()
                // setEditID('')
                // setIntime('');
                // setOuttime('')
                // setShifTtypeValue('')
                // setLeaveTypeValue('')
                setErrorModalOpen(true)
                return;
            }
        } catch (error) {
            fetchData()
            // setEditID('')
            // setIntime('');
            // setOuttime('')
            // setShifTtypeValue('')
            // setLeaveTypeValue('')
            setActionMessage('Oops an error caught')
            setErrorModalOpen()
            console.error('Error:', error);
            return;
        }
    };

    const handleUpdate = async () => {
        try {
            const TimeDifference = await getTimeDifferenceInHours(Intime, Outtime)
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/attendance/UpdateAttandance/${EditID}`,
                {
                    dalatten_shiftnm: shiftTypeValue,
                    dalatten_atdtype: LeaveTypeValue,
                    dalatten_intime: Intime,
                    dalatten_outtime: Outtime,
                    dalatten_total_hour: isNaN(TimeDifference) ? 0 : TimeDifference,
                    usrnm: userSchema?.usrnm,
                    compid: JSON.parse(companyIdString),
                    divid: JSON.parse(divIdString),
                    masterid: userSchema?.masterid,
                },
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            if (response.data.status) {
                setActionMessage(response.data.message)
                handleClose()
                // fetchData()
                setEditID('')
                setIntime('');
                setOuttime('')
                setShifTtypeValue('')
                setLeaveTypeValue('')
                setSuccessModalOpen(true)
            }
            else {
                setActionMessage(response.data.message)
                // fetchData()
                handleClose()
                setEditID('')
                setIntime('');
                setOuttime('')
                setShifTtypeValue('')
                setLeaveTypeValue('')
                setErrorModalOpen(true)
            }
        } catch (error) {
            fetchData()
            setEditID('')
            setIntime('');
            setOuttime('')
            setShifTtypeValue('')
            setLeaveTypeValue('')
            setActionMessage('Oops an error caught')
            setErrorModalOpen()
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        EmpListGET()
        shiftTypeGET()
        fetchLeaveType()
        // fetchDepData()
        // DepartmentTypeGET()
    }, [])

    useEffect(() => {
        fetchShiftData();
    }, [selectionRange]);

    useEffect(() => {
        fetchDepData();
    }, [selectionRange]);

    // useEffect(() => {
    //     fetchData();
    // }, [latcoming,startDate, endDate, selectionRange, EmployeeName, otChecked, LeaveName, rowsPerPage]);

    const debouncedFetchData = useCallback(
        debounce(fetchData, 1000),
        [paginationModel, latcoming, misspunch, startDate, endDate, selectionRange, EmployeeName, otChecked, LeaveName, rowsPerPage]
    );

    useEffect(() => {
        debouncedFetchData();
        return () => {
            debouncedFetchData.cancel();
        };
    }, [debouncedFetchData]);


    // const convertToIndianTime = (utcDate) => {
    //     const date = new Date(utcDate);
    //     // Add 5 hours and 30 minutes to the UTC date
    //     date.setHours(date.getHours() + 5);
    //     date.setMinutes(date.getMinutes() + 30);
    //     // Format the date as required
    //     const year = date.getFullYear();
    //     const month = (date.getMonth() + 1).toString().padStart(2, '0');
    //     const day = date.getDate().toString().padStart(2, '0');
    //     const hours = date.getHours().toString().padStart(2, '0');
    //     const minutes = date.getMinutes().toString().padStart(2, '0');
    //     const seconds = date.getSeconds().toString().padStart(2, '0');
    //     const indianTime = `${day}-${month}-${year}`;
    //     return indianTime;
    // };
    function convertToIndianTime(dateString) {
        const padZero = (num) => (num < 10 ? '0' : '') + num;

        // Convert the string to a Date object
        let date = new Date(dateString);

        // Add 5.5 hours to the date
        date.setHours(date.getHours() + 5, date.getMinutes() + 30);

        // Format the date to "dd-mm-yyyy hh:mm:ss"
        const day = padZero(date.getUTCDate());
        const month = padZero(date.getUTCMonth() + 1); // Months are zero-based
        const year = date.getUTCFullYear();

        const hours = padZero(date.getUTCHours());
        const minutes = padZero(date.getUTCMinutes());
        const seconds = padZero(date.getUTCSeconds());

        return `${day}-${month}-${year}`;
    }

    function convertTo100Format(time) {
        if (time == null) {
            return "00.00";
        }

        const timeStr = time.toString();
        const isNegative = timeStr.startsWith('-');
        let [hoursStr, minutesStr] = timeStr.replace('-', '').split('.');

        // Handle cases where minutesStr might be undefined or empty
        minutesStr = minutesStr ? minutesStr.padEnd(2, '0') : '00';
        const minutes = parseInt(minutesStr, 10);

        // Convert minutes to a fraction of 100
        const minutesFraction = Math.round((minutes / 60) * 100);

        // Ensure hours and minutes are two digits
        hoursStr = hoursStr.padStart(2, '0');
        let formattedMinutes = minutesFraction.toString().padStart(2, '0');

        // Combine hours and formatted minutes
        let timeIn100Format = `${hoursStr}.${formattedMinutes}`;

        // Reapply the negative sign if needed
        if (isNegative) {
            timeIn100Format = `-${timeIn100Format}`;
        }

        return timeIn100Format;
    }

    const dataWithSerialNumbers = data.map((item, index) => {
        // var constper_hour = item._id.gross_sal[0] ? ((Number(item?._id?.gross_sal[0]) / 30) / item?.dalatten_total_hour).toFixed(2) : 0;
        var constper_hour = item._id.gross_sal[0]
            ? (Number(item._id.gross_sal[0]) / 30 / item.dalatten_total_hour)
            : 0;

        constper_hour = !isFinite(constper_hour) ? 0 : constper_hour.toFixed(2);

        let per_hour = 0;

        if ((item._id?.ol_appl[0] && item._id?.ol_appl[0] === 'Y') || (item._id?.ol_appl[0] && item._id?.ol_appl[0] === '1.5')) {
            var otamtcalc = item._id.gross_sal[0] ? ((Number(item?._id?.gross_sal[0]) / 30) / item?.dalatten_total_hour).toFixed(2) : '';
            if (item._id?.ol_appl[0] && item._id?.ol_appl[0] === '1.5') otamtcalc = parseFloat(otamtcalc) * 1.5
            per_hour = otamtcalc === Infinity ? 0 : otamtcalc;
        }

        // const ot_amount = (per_hour * convertTo100Format(item?._id?.dalatten_overtime)).toFixed(2);
        let ot_amount = (per_hour * convertTo100Format(item?._id?.dalatten_overtime));
        ot_amount = !isFinite(ot_amount) ? 0 : ot_amount.toFixed(2);

        console.log("Check", item._id?.ol_appl[0]);
        return {
            ...item,
            id: index,
            empname: item._id.empname[0] ? item._id.empname[0] : '',
            atdid: item._id.atdid ? item._id.atdid : '',
            machine_code: item._id.machine_code[0] ? item._id.machine_code[0] : '',
            shiftname: item._id.shiftname[0] ? item._id.shiftname[0] : '',
            deptname: item._id.deptname[0] ? item._id.deptname[0] : '',
            lvname: item._id.lvname[0] ? item._id.lvname[0] : '',
            gross_sal: item._id.gross_sal[0] ? item._id.gross_sal[0] : '',
            shiftinTime: item?._id?.shiftinTime[0] ? item?._id?.shiftinTime[0] : '',
            per_day: item._id.gross_sal[0] ? (Number(item._id.gross_sal[0]) / 30).toFixed(2) : '',
            per_hour: constper_hour,
            ot_amount: ot_amount !== '' && !isNaN(ot_amount) ? ot_amount : '', // Check for NaN
            designation: item._id.designation[0] ? item._id.designation[0] : '',
            machineschemas: item._id.machineschemas[0] ? item._id.machineschemas[0] : '',
            dalatten_intime: item?._id?.dalatten_intime,
            dalatten_outtime: item?._id?.dalatten_outtime,
            dalatten_date: item._id.dalatten_date ? convertToIndianTime(item._id.dalatten_date) : '',
            dalatten_overtime: (item._id?.ol_appl[0] && item._id?.ol_appl[0] === 'Y') || (item._id?.ol_appl[0] && item._id?.ol_appl[0] === '1.5') ? item?._id?.dalatten_overtime?.toFixed(2) : 0.00,
            dalatten_overtimehrs: item._id.dalatten_overtimehrs ? item._id.dalatten_overtimehrs.toFixed(2) : '',
            dalatten_total_hour: item._id.dalatten_total_hour ? item._id.dalatten_total_hour : '',
            tot_hrs: item._id.tot_hrs ? item._id.tot_hrs : '',
            edit_by: item._id.edit_by ? item._id.edit_by : '',
        };
    });

    useEffect(() => {
        console.log(dataWithSerialNumbers);
    }, [dataWithSerialNumbers]);

    const handlePrintRow = (params) => {
        const userSchemaString = localStorage.getItem('userSchema');
        const userSchema = JSON.parse(userSchemaString);

        const printWindow = window.open('', '_blank');
        const data = params.filter(employee => parseFloat(employee.ot_amount) > 0); // Filter where ot_amount > 0

        let content = '';

        // Group data by department and calculate totals
        const groupedByDepartment = data.reduce((acc, employee) => {
            const department = employee.deptname || "Unknown Department";
            if (!acc[department]) {
                acc[department] = {
                    employees: [],
                    totalOtHours: 0,
                    totalOtAmount: 0
                };
            }
            acc[department].employees.push(employee);
            let [hours, minutes] = employee.dalatten_overtime.split('.').map(Number);

            // Convert minutes to a fraction of an hour
            let fractionalHours = (minutes / 60) || 0;

            // Calculate the total overtime for this entry
            let totalOvertime = (hours || 0) + fractionalHours;

            // Add to the total overtime hours for the department
            acc[department].totalOtHours += totalOvertime;

            // acc[department].totalOtHours += parseFloat(employee.dalatten_overtime) || 0;
            acc[department].totalOtAmount += parseFloat(employee.ot_amount) || 0;
            return acc;
        }, {});

        // Generate HTML for each department
        Object.keys(groupedByDepartment).forEach(department => {
            let { employees, totalOtHours, totalOtAmount } = groupedByDepartment[department];
            employees = employees.sort((a, b) => a.shiftname.localeCompare(b.shiftname));

            content += `
            <div class="department">
                <div class="department-header">Department: ${department} (Total OT Hours: ${totalOtHours.toFixed(2)}, Total OT Amount: ₹${totalOtAmount.toFixed(2)})</div>
                <table class="employee-list">
                    <thead>
                        <tr>
                            <th>Employee Name</th>
                            <th>Date</th>
                            <th>Shift</th>
                            <th>In Time</th>
                            <th>Out Time</th>
                            <th>Night In Time</th>
                            <th>Night Out Time</th>
                            <th>OT Hours</th>
                            <th>OT Amount</th>
                        </tr>
                    </thead>
                    <tbody>
            `;

            employees.forEach(employee => {
                content += `
                    <tr>
                        <td>${employee.empname || ""}</td>
                        <td>${employee.dalatten_date || ""}</td>
                        <td>${employee.shiftname || ""}</td>
                        <td>${employee.lvname !== 'Night' ? employee.dalatten_intime : "X"}</td>
                        <td>${employee.lvname !== 'Night' ? employee.dalatten_outtime : "X"}</td>
                        <td>${employee.lvname === 'Night' ? employee.dalatten_intime : "X"}</td>
                        <td>${employee.lvname === 'Night' ? employee.dalatten_outtime : "X"}</td>
                        <td>${employee.dalatten_overtime || "0"}</td>
                        <td>${employee.ot_amount || "0.00"}</td>
                    </tr>
                `;
            });

            content += `
                    </tbody>
                </table>
            </div>`;
        });

        // Write content to the print window
        printWindow.document.write(`
            <html>
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>Department OT Hours and Amount</title>
                <style>
                    body {
                        font-family: Arial, sans-serif;
                    }
                    .department {
                        margin-bottom: 30px;
                    }
                    .header-container {
                        position: relative;
                        padding: 10px;
                        text-align: center;
                    }
                    .header-container h3 {
                        margin: 0;
                    }
                    .hr-text {
                        position: absolute;
                        below: 10px;
                        right: 10px;
                        font-size: 16px;
                        font-weight: bold;
                        color: #333;
                    }
                    .department-header {
                        background-color: #f0f0f0;
                        padding: 10px;
                        font-weight: bold;
                        border: 1px solid #ccc;
                    }
                    .employee-list {
                        width: 100%;
                        border-collapse: collapse;
                        margin: 10px 0;
                    }
                    .employee-list th, .employee-list td {
                        border: 1px solid #ddd;
                        padding: 8px;
                    }
                    .employee-list th {
                        background-color: #f9f9f9;
                    }
                    
                </style>
            </head>
            <body>
            <div class="header-container">
            <h3>Department-wise OT Hours and Amount</h3>
        </div>               
        ${content}
        <span class="hr-text">Print By ${userSchema?.usrnm || ''}</span>
            </body>
            </html>
        `);

        printWindow.document.close();
        printWindow.print();
    };

    const handlePrintRowMachine = (params) => {
        const userSchemaString = localStorage.getItem('userSchema');
        const userSchema = JSON.parse(userSchemaString);
        const printWindow = window.open('', '_blank');
        const data = params.filter(employee => parseFloat(employee.ot_amount) > 0); // Filter where ot_amount > 0

        let content = '';

        // Group data by machine and calculate totals
        const groupedByMachine = data.reduce((acc, employee) => {
            const machine = employee.machineschemas || "Unknown Machine";  // Group by machine
            if (!acc[machine]) {
                acc[machine] = {
                    employees: [],
                    totalOtHours: 0,
                    totalOtAmount: 0
                };
            }
            acc[machine].employees.push(employee);
            let [hours, minutes] = employee.dalatten_overtime.split('.').map(Number);

            // Convert minutes to a fraction of an hour
            let fractionalHours = (minutes / 60) || 0;

            // Calculate the total overtime for this entry
            let totalOvertime = (hours || 0) + fractionalHours;

            // Add to the total overtime hours for the machine
            acc[machine].totalOtHours += totalOvertime;

            // Add to the total OT amount for the machine
            acc[machine].totalOtAmount += parseFloat(employee.ot_amount) || 0;
            return acc;
        }, {});

        // Generate HTML for each machine
        Object.keys(groupedByMachine).forEach(machine => {
            let { employees, totalOtHours, totalOtAmount } = groupedByMachine[machine];
            employees = employees.sort((a, b) => a.shiftname.localeCompare(b.shiftname));

            content += `
            <div class="machine">
                <div class="machine-header">Machine: ${machine} (Total OT Hours: ${totalOtHours.toFixed(2)}, Total OT Amount: ₹${totalOtAmount.toFixed(2)})</div>
                <table class="employee-list">
                    <thead>
                        <tr>
                            <th>Employee Name</th>
                            <th>Date</th>
                            <th>Machine</th>
                            <th>Shift</th>
                            <th>In Time</th>
                            <th>Out Time</th>
                            <th>Night In Time</th>
                            <th>Night Out Time</th>
                            <th>OT Hours</th>
                            <th>OT Amount</th>
                        </tr>
                    </thead>
                    <tbody>
            `;

            employees.forEach(employee => {
                content += `
                    <tr>
                        <td>${employee.empname || ""}</td>
                        <td>${employee.dalatten_date || ""}</td>
                        <td>${employee.machineschemas || ""}</td>
                        <td>${employee.shiftname || ""}</td>
                        <td>${employee.lvname !== 'Night' ? employee.dalatten_intime : "X"}</td>
                        <td>${employee.lvname !== 'Night' ? employee.dalatten_outtime : "X"}</td>
                        <td>${employee.lvname === 'Night' ? employee.dalatten_intime : "X"}</td>
                        <td>${employee.lvname === 'Night' ? employee.dalatten_outtime : "X"}</td>
                        <td>${employee.dalatten_overtime || "0"}</td>
                        <td>${employee.ot_amount || "0.00"}</td>
                    </tr>
                `;
            });

            content += `
                    </tbody>
                </table>
            </div>`;
        });

        // Write content to the print window
        printWindow.document.write(`
            <html>
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>Machine OT Hours and Amount</title>
                <style>
                    body {
                        font-family: Arial, sans-serif;
                    }
                    .machine {
                        margin-bottom: 30px;
                    }
                    .machine-header {
                        background-color: #f0f0f0;
                        padding: 10px;
                        font-weight: bold;
                        border: 1px solid #ccc;
                    }
                    .employee-list {
                        width: 100%;
                        border-collapse: collapse;
                        margin: 10px 0;
                    }
                    .employee-list th, .employee-list td {
                        border: 1px solid #ddd;
                        padding: 8px;
                    }
                    .employee-list th {
                        background-color: #f9f9f9;
                    }
                    .header-container {
                        position: relative;
                        padding: 10px;
                        text-align: center;
                    }
                    .header-container h3 {
                        margin: 0;
                    }
                    .hr-text {
                        position: absolute;
                        below: 10px;
                        right: 10px;
                        font-size: 16px;
                        font-weight: bold;
                        color: #333;
                    }
                </style>
            </head>
            <body>
            <div class="header-container">
            <h3>Machine-wise OT Hours and Amount</h3>
        </div>   
            ${content}
            <span class="hr-text">Print By ${userSchema?.usrnm || ''}</span>
            </body>
            </html>
        `);

        printWindow.document.close();
        printWindow.print();
    };


    const columns = [
        { field: "atdid", headerName: "ID" },
        { field: "machine_code", headerName: "Employee ID" },
        { field: "empname", headerName: "Employee Name" },
        { field: "deptname", headerName: "Department" },
        { field: "designation", headerName: "Designation" },
        { field: "machineschemas", headerName: "Machine" },
        {
            field: "lvname", headerName: "Atd Type",
            renderCell: (params) => {
                const lvname = params.row.lvname ?? '';
                const cellStyle = {
                    backgroundColor: lvname === 'Present' ? '#92bd8a' : lvname === 'Absent' ? '#e3877f' : 'inherit',
                    color: 'black',
                    textAlign: 'center',
                };
                return (
                    <div style={cellStyle}>
                        {lvname}
                    </div>
                );
            }
        }, { field: "dalatten_date", headerName: "Date" },
        // { field: "gross_sal", headerName: "Gross Sal" },
        { field: "per_hour", headerName: "Per Hour" },
        { field: "shiftname", headerName: "Shift Name" },
        { field: "dalatten_total_hour", headerName: "Shift Hours" },
        { field: "shiftinTime", headerName: "Shift Time" },
        { field: "dalatten_intime", headerName: "In-Time" },
        { field: "dalatten_outtime", headerName: "Out-Time" },
        { field: "tot_hrs", headerName: "Total Hours" },
        { field: "dalatten_overtime", headerName: "OT Hours" },
        { field: "ot_amount", headerName: "Ot Amount" },
        { field: "edit_by", headerName: "Edit By User" },
    ];


    const handleAddClose = () => {
        setOpenAdd(false);
        setIntime('');
        setOuttime('')
        setShifTtypeValue('')
        setLeaveTypeValue('')
        setMonth('')
        setDate(dayjs())
        setEmpname('')
    };

    const handleAddOpen = () => {
        setOpenAdd(true);
    };

    const handleErrorModalClose = () => {
        setErrorModalOpen(false);
    };
    const handleSuccessModalClose = () => {
        setSuccessModalOpen(false);
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        border: '2px solid',
        boxShadow: 24,
        padding: 16,
        minWidth: 300,
        maxWidth: 500,
        textAlign: 'center'
    };

    const handleDateChange = (newValue) => {
        setDate(newValue);
        setMonth(newValue.format('MMMM'));
    };

    const generateFileName = () => {
        const now = new Date();
        const date = now.toLocaleDateString().replace(/\//g, '-'); // Format date as YYYY-MM-DD
        const time = now.toLocaleTimeString().replace(/:/g, '-');  // Format time as HH-MM-SS

        const prefix = 'MISS PUNCH REPORT';
        return `${prefix}_${date}_${time}`;
    };

    useEffect(() => {
        console.log(selectedRows)
    }, [selectedRows]);

    return (
        <>
            <LoadingModal open={loader} /> {/* Add LoadingModal here */}
            {/* <Container maxWidth="lg" style={{ marginTop: '25px', minHeight: '400px' }}> */}
            <div style={{ position: 'fixed', zIndex: 1000, }}>
                {isFileLoading ? <CircularProgress /> : null}
            </div>

            <Box sx={{ marginBottom: '20px', width: '100%' }}>
                <div style={{ padding: '5px' }}>
                    <Grid container spacing={1} alignItems="center" justifyContent="flex-start" style={{ width: '100%' }}>
                        <Grid item xs={3} sx={{ marginBlockStart: '0px' }}>
                            <Typography variant="body1" sx={{ color: 'black', fontWeight: 'bold' }}>
                                MissPunch Report List
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ marginBlockStart: '0px' }}>

                            <Autocomplete
                                value={EmployeeName}
                                onChange={(e, newValue) => setEmployeeName(newValue)}
                                options={EmpList}
                                getOptionLabel={(option) => (option ? option.full_name : "")}
                                isOptionEqualToValue={(option, value) => option._id === value._id}
                                filterOptions={(options, state) => {
                                    const inputValue = state.inputValue.toLowerCase();
                                    return options.filter(option =>
                                        option.full_name && option.full_name.toLowerCase().includes(inputValue)
                                    );
                                }}
                                renderOption={(props, option) => (
                                    <li {...props} key={option._id}>
                                        {option.full_name}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Employee"
                                        inputProps={{
                                            ...params.inputProps,
                                            'aria-label': 'Select Employee',
                                            style: { paddingTop: '0px' },
                                        }}
                                        variant="outlined"
                                        size="small"
                                    />
                                )}
                                fullWidth
                            />
                            {/* </FormControl> */}
                        </Grid>
                        <Grid item xs={2} sx={{ marginBlockStart: '0px' }}>
                            {/* <FormControl fullWidth> */}
                            <Autocomplete
                                value={LeaveName}
                                onChange={(e, newValue) => setLeaveName(newValue)}
                                options={LeaveType}
                                getOptionLabel={(option) => option?.discription || ""}
                                getOptionSelected={(option, value) => option._id === value}
                                renderInput={(params) => <TextField {...params} label="Attendance Type"
                                    inputProps={{ ...params.inputProps, 'aria-label': 'Select Department Type', style: { paddingTop: '0px' } }}
                                    // sx={{ "& .MuiInputBase-root": { height: '56px', marginTop: "10px" } }}    
                                    variant="outlined" size="small" />}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3} sx={{ marginBlockStart: '0px' }} >
                        <div style={{ display: 'flex', justifyContent: 'center', position: 'relative', padding: '10px' }}>
                                <TextField
                                    type="text"
                                    size="small"
                                    label="Select Range"
                                    value={`${formatDates(selectionRange.startDate)} - ${formatDates(selectionRange.endDate)}`}
                                    onFocus={toggleDateRangePicker}
                                    readOnly
                                    fullWidth
                                    InputProps={{
                                        disableUnderline: true, // Remove default underline
                                        style: { cursor: 'pointer', textAlign: 'center' } // Center-align text and change cursor to pointer when hovering
                                    }}
                                    InputLabelProps={{
                                        shrink: true, // Ensure the label doesn't float above the input when value is present
                                    }}
                                    onClick={toggleDateRangePicker} // Open DateRangePicker on click
                                />
                                {isOpen && (
                                    <div style={{ position: 'absolute', zIndex: 999, marginRight: '400px' }} ref={dateRangePickerRef}>
                                        <DateRangePicker
                                            ranges={[{ startDate: tempStartDate, endDate: tempEndDate, key: 'selection' }]}
                                            onChange={handleSelect}
                                        />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={applyDateRange}
                                            fullWidth
                                            style={{ marginTop: '10px' }}
                                        >
                                            Apply
                                        </Button>
                                    </div>
                                )}

                            </div>
                        </Grid>
                    </Grid>
                </div>

                {/* <MuiThemeProvider theme={theme}>
                    <MUIDataTable title={<span style={{ fontWeight: 'bold', fontSize: '23px' }}>Attendance List</span>} data={dataWithSerialNumbers} columns={columns} options={options} />
                </MuiThemeProvider>; */}
                <DataGrid
                    rows={dataWithSerialNumbers}
                    columns={columns}
                    initialState={{
                        ...dataWithSerialNumbers.initialState,
                        pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    apiRef={apiRef}

                    pageSizeOptions={[10, 25, 100, { label: "All", value: 99 }]}
                    pagination
                    paginationMode={paginationModel.pageSize === 99 ? undefined : 'server'}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    rowCount={totalRowCount}

                    autoHeight
                    checkboxSelection={misspunch}
                    onRowSelectionModelChange={handleSelectionModelChange}
                    rowSelectionModel={selectedRows}
                    disableRowSelectionOnClick={true}  // This is the correct property
                    density="compact"
                    loading={isLoading}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            csvOptions: { fileName: generateFileName() },
                        },
                    }}
                />
            </Box>

            <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 450, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography id="modal-title" variant="h6" component="h2" style={{ textAlign: 'center', fontWeight: 'bold', background: 'linear-gradient(135deg, #FFFFFF,#60a5f2,#FFFFFF)' }}  >{EditDetailsEmployee}</Typography>
                        <Typography id="modal-title" variant="h6" component="h2" style={{ textAlign: 'center', fontWeight: 'bold', background: 'linear-gradient(135deg, #FFFFFF,#60a5f2,#FFFFFF)' }}  >{EditDetailsDate}</Typography>
                    </div>

                    <div style={{ marginTop: '10px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '8px' }}>
                            <div style={{ marginRight: '40px', width: '100px' }}>
                                <Typography variant="subtitle1" >In Time</Typography>
                            </div>
                            <input type='time' name="field1" value={Intime} onChange={(e) => { setIntime(e.target.value) }} style={{ fontFamily: 'Arial, sans-serif', flex: 1, padding: '15px', border: '1px solid #ccc', borderRadius: '4px' }} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '8px' }}>
                            <div style={{ marginRight: '40px', width: '100px' }}>
                                <Typography variant="subtitle1" >Out Time</Typography>
                            </div>
                            <input type='time' name="field1" value={Outtime} onChange={(e) => { setOuttime(e.target.value) }} style={{ fontFamily: 'Arial, sans-serif', flex: 1, padding: '15px', border: '1px solid #ccc', borderRadius: '4px' }} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <div style={{ marginRight: '40px', width: '100px' }}>
                                <Typography variant="subtitle1" >Shift Type</Typography>
                            </div>
                            <Select
                                value={shiftTypeValue}
                                onChange={(e) => { setShifTtypeValue(e.target.value) }}
                                style={{ flex: 1, height: '50px', borderRadius: '4px' }}
                            >
                                {shiftType.map((option, index) => (
                                    <MenuItem key={index} value={option._id}>{option.shift_name}</MenuItem>
                                ))}
                            </Select>
                        </div>
                        <div style={{ display: 'flex', paddingTop: '8px', flexDirection: 'row', alignItems: 'center' }}>
                            <div style={{ marginRight: '40px', width: '100px' }}>
                                <Typography variant="subtitle1" >Attendance Type</Typography>
                            </div>
                            <Select
                                value={LeaveTypeValue}
                                onChange={(e) => { setLeaveTypeValue(e.target.value) }}
                                style={{ flex: 1, height: '50px', borderRadius: '4px' }}
                            >
                                {LeaveType.map((option, index) => (
                                    <MenuItem key={index} value={option?._id}>{option?.discription}</MenuItem>
                                ))}
                            </Select>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '25px' }}>
                        <Button onClick={handleClose} style={{ marginLeft: '8px' }}>Close</Button>
                        <Button onClick={handleNext} style={{ marginLeft: '8px' }}>Next</Button>
                        <Button onClick={() => { handleUpdate(); }} variant="contained" color="primary">Update</Button>
                    </div>
                </Box>
            </Modal>

            <Modal
                open={successModalOpen}
                onClose={handleSuccessModalClose}
                aria-labelledby="success-modal-title"
                aria-describedby="success-modal-description"
            >
                <div style={{ ...modalStyle, borderColor: '#007bff' }}>
                    <h2 id="success-modal-title" style={{ color: '#007bff' }}>Success!</h2>
                    <p id="success-modal-description">{ActionMessage}</p>
                    <Button variant="contained" onClick={handleSuccessModalClose} style={{ backgroundColor: '#007bff', color: 'white' }}>Close</Button>
                </div>
            </Modal>

            <Modal
                open={openDeleteAllModal}
                onClose={handleDeleteAllCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, width: 400 }}>
                    <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
                        Confirm Deletion
                    </Typography>
                    <Typography id="modal-description" sx={{ mt: 2 }}>
                        Are you sure you want to delete selected attendance?
                    </Typography>
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={handleDeleteAllCloseModal} color="primary" sx={{ mr: 2 }}>
                            Cancel
                        </Button>
                        <Button onClick={handleModalDeleteAll} variant="contained" color="error">
                            Delete
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={errorModalOpen}
                onClose={handleErrorModalClose}
                aria-labelledby="error-modal-title"
                aria-describedby="error-modal-description"
            >
                <div style={{ ...modalStyle, borderColor: '#ff0000' }}>
                    <h2 id="error-modal-title" style={{ color: '#ff0000' }}>Error!</h2>
                    <p id="error-modal-description">{ActionMessage}.</p>
                    <Button variant="contained" onClick={handleErrorModalClose} style={{ backgroundColor: '#ff0000', color: 'white' }}>Close</Button>
                </div>
            </Modal>

            <Modal open={openAdd} onClose={handleAddClose} aria-labelledby="modal-title" aria-describedby="modal-description">
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 450, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                    <Typography id="modal-title" variant="h6" component="h2" style={{ textAlign: 'center', paddingBottom: '20px', fontWeight: 'bold' }}>Add Details</Typography>
                    <div style={{ display: 'flex', paddingTop: '8px', flexDirection: 'row', alignItems: 'center' }}>
                        <div style={{ marginRight: '40px', width: '100px' }}>
                            <Typography variant="subtitle1" >Employee Name</Typography>
                        </div>
                        {/* <Select
                                value={Empname}
                                onChange={(e) => { setEmpname(e.target.value) }}
                                style={{ flex: 1, height: '50px', borderRadius: '4px' }}
                            >
                                {EmpList.map((option, index) => (
                                    <MenuItem key={index} value={option?._id}>{option?.full_name}</MenuItem>
                                ))}
                            </Select> */}
                        <Autocomplete
                            value={Empname}
                            onChange={(event, newValue) => {
                                setEmpname(newValue);
                            }}
                            // options={EmpList}
                            options={[{_id: '', full_name:'ALL'}, ...EmpList]}
                            getOptionLabel={(option) => option?.full_name || ""}
                            getOptionSelected={(option, value) => option._id === value}
                            // style={{ height: '38px', fontSize: '14px' }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    // label="Employee"
                                    inputProps={{ ...params.inputProps, 'aria-label': 'Select Department Type', style: { paddingTop: '0px', fontSize: '15px' } }}
                                    sx={{
                                        "& .MuiInputBase-root": {
                                            flex: 1,
                                            height: '35px',  // Adjust height
                                            borderRadius: '4px'
                                        }
                                    }} />
                            )}
                            sx={{ flex: 1, }}
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '8px' }}>
                        <div style={{ marginRight: '40px', width: '100px' }}>
                            <Typography variant="subtitle1" >In Time</Typography>
                        </div>
                        <input type='time' name="field1" value={Intime} onChange={(e) => { setIntime(e.target.value) }} style={{ fontFamily: 'Arial, sans-serif', flex: 1, padding: '10px', border: '1px solid #ccc', borderRadius: '4px' }} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '8px' }}>
                        <div style={{ marginRight: '40px', width: '100px' }}>
                            <Typography variant="subtitle1" >Out Time</Typography>
                        </div>
                        <input type='time' name="field2" value={Outtime} onChange={(e) => { setOuttime(e.target.value) }} style={{ fontFamily: 'Arial, sans-serif', flex: 1, padding: '10px', border: '1px solid #ccc', borderRadius: '4px' }} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div style={{ marginRight: '40px', width: '100px' }}>
                            <Typography variant="subtitle1" >Shift Type</Typography>
                        </div>
                        <Select
                            value={shiftTypeValue}
                            onChange={(e) => { setShifTtypeValue(e.target.value) }}
                            style={{ flex: 1, height: '40px', borderRadius: '4px', }}
                        >
                            {shiftType.map((option, index) => (
                                <MenuItem key={index} value={option._id}>{option.shift_name}</MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div style={{ display: 'flex', paddingTop: '8px', flexDirection: 'row', alignItems: 'center' }}>
                        <div style={{ marginRight: '40px', width: '100px' }}>
                            <Typography variant="subtitle1" >Attendance Type</Typography>
                        </div>
                        <Select
                            value={LeaveTypeValue}
                            onChange={(e) => { setLeaveTypeValue(e.target.value) }}
                            style={{ flex: 1, height: '40px', borderRadius: '4px', }}
                        >
                            {LeaveType.map((option, index) => (
                                <MenuItem key={index} value={option?._id}>{option?.discription}</MenuItem>
                            ))}
                        </Select>
                    </div>
                    {/* <div style={{ display: 'flex', paddingTop: '8px', flexDirection: 'row', alignItems: 'center' }}>
                            <div style={{ marginRight: '40px', width: '100px' }}>
                                <Typography variant="subtitle1" >Leave Type</Typography>
                            </div>
                            <Select
                                value={LeaveTypeValue}
                                onChange={(e) => { setLeaveTypeValue(e.target.value) }}
                                style={{ flex: 1, height: '50px', borderRadius: '4px' }}
                            >
                                {LeaveType.map((option, index) => (
                                    <MenuItem key={index} value={option?._id}>{option?.discription}</MenuItem>
                                ))}
                            </Select>
                        </div> */}
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '8px' }}>
                        <div style={{ marginRight: '40px', width: '100px' }}>
                            <Typography variant="subtitle1" >Date</Typography>
                        </div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                value={date}
                                onChange={(newValue) => handleDateChange(newValue)}
                                fullWidth
                                // label='Date'
                                inputFormat="dd-MM-yyyy"
                                format="DD/MM/YYYY" // Set the desired date format here
                                slotProps={{ textField: { size: 'small' } }}
                            />
                        </LocalizationProvider>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '25px' }}>
                        <Button onClick={handleAddClose} style={{ marginLeft: '8px' }}>Close</Button>
                        <Button onClick={() => { handleAdd() }} variant="contained" color="primary">Save</Button>
                    </div>
                </Box>
            </Modal>

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, width: 400 }}>
                    <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
                        Confirm Deletion
                    </Typography>
                    <Typography id="modal-description" sx={{ mt: 2 }}>
                        Are you sure you want to delete this item?
                    </Typography>
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={handleCloseModal} color="primary" sx={{ mr: 2 }}>
                            Cancel
                        </Button>
                        <Button onClick={handleModalDelete} variant="contained" color="error">
                            Delete
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <Paper style={{ padding: '16px', position: 'fixed', bottom: '16px', zIndex: 1000, width: '70%', left: '0', right: '0', margin: '0 auto' }}>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }}>
                    <Button disabled={!selectedRows.length > 0} variant='contained' color='error' size='small' onClick={() => { handleDeleteAll() }} sx={{
                        marginRight: '10px',
                        borderRadius: '14px',
                        borderColor: 'red',
                    }}
                    >Delete Attendance</Button>
                </div>
            </Paper >
            {/* </Container> */}
        </>
    );
};

export default MissPunchReport;
