import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid'; // Import DataGrid from MUI
import Container from '@mui/material/Container';
import { Select, MenuItem } from '@mui/material';

function EmployeesList() {
    const [DepValues, setDepValues] = useState([]);
    const [department, setDepartment] = useState('');
    const [data, setData] = useState([]);
    const [activeStatus, setActiveStatus] = useState('Active'); // Initial state for active status

    const fetchData = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const employeeIDD = JSON.parse(localStorage.getItem('EmployeeIDD'))

            const dataToSend = {
                usrnm: userSchema?.usrnm,
                compid: JSON.parse(companyIdString),
                divid: JSON.parse(divIdString),
                masterid: userSchema?.masterid,
                employeeIDD: employeeIDD ? employeeIDD : null
            };
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/attendance/GetEmployeesList?active=${activeStatus}`, dataToSend, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            });
            setData(response.data.data)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [activeStatus])

    const DepTypeGET = async () => {
        const userSchemaString = localStorage.getItem('userSchema');
        const userSchema = JSON.parse(userSchemaString);
        const companyIdString = localStorage.getItem('companyId');
        const divIdString = localStorage.getItem('divId');

        const data = {
            user: userSchema?.usrnm,
            compid: JSON.parse(companyIdString),
            divid: JSON.parse(divIdString),
            masterid: userSchema?.masterid
        };

        try {
            const token = localStorage.getItem('user-token');
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/employee/DepartmentTypeGET`,
                data,
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            setDepValues(response.data.lastEntryNo);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    function convertToIST(dateString) {
        if(!dateString){
            return 'N/A'
        }
        const utcDate = new Date(dateString);

        utcDate.setHours(utcDate.getHours() + 5); // Add 5 hours for IST
        utcDate.setMinutes(utcDate.getMinutes() + 30); // Add 30 minutes for IST

        const formattedDate = utcDate.toLocaleDateString('en-IN', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });

        return formattedDate;
    }

    const dataWithSerialNumbers = data.map((item, index) => ({
        ...item,
        serialNumber: index + 1, // Serial number starts from 1
        id: index, // Serial number starts from 1
        machine: item?.machine?.machine_name,
        designation: item?.designation?.discription,
        default_shift: item?.default_shift?.shift_name,
        department: item?.department?.discription,
        category: item?.category?.discription,
        bank: item?.bank?.discription,
        resign: convertToIST(item?.resign),

    }));

    const columns = [
        { field: "full_name", headerName: "Full Name", width: 100 },
        { field: "machine_code", headerName: "Employee Code", width: 100 },
        { field: "machine", headerName: "Machine", width: 100 },
        { field: "gender_radio", headerName: "Gender", width: 100 },
        { field: "father_name", headerName: "Father's Name", width: 100 },
        { field: "mother_name", headerName: "Mother's Name", width: 100 },
        { field: "marital_status", headerName: "Marital Status", width: 100 },
        { field: "mobile_no", headerName: "Contact No.", width: 100 },
        { field: "email", headerName: "email", width: 100 },
        { field: "emergencyContact", headerName: "Emergency Contact", width: 100 },
        { field: "designation", headerName: "Designation", width: 100 },
        { field: "default_shift", headerName: "Default Shift", width: 100 },
        { field: "department", headerName: "Department", width: 100 },
        { field: "category", headerName: "Category", width: 100 },
        { field: "joining", headerName: "Joining Date", width: 100 },
        { field: "address", headerName: "Address Line 1", width: 100 },
        { field: "addressLine2", headerName: "Address Line 2", width: 100 },
        { field: "addressLine3", headerName: "Address Line 3", width: 100 },
        { field: "adhar_enroll_no", headerName: "Aadhar Enroll No", width: 100 },
        { field: "adhar_no", headerName: "Aadhar No.", width: 100 },
        { field: "pan", headerName: "Pan No.", width: 100 },
        { field: "pfNumber", headerName: "PF No.", width: 100 },
        { field: "PfJoiningDate", headerName: "Pf Joining Date", width: 100 },
        { field: "EpsExitDate", headerName: "Eps Exit Date", width: 100 },
        { field: "EpsJoiningDate", headerName: "Eps Joining Date", width: 100 },
        { field: "EsiNumber", headerName: "Esi Number", width: 100 },

        { field: "pt_application", headerName: "PT Application", width: 100 },
        { field: "eps_application", headerName: "EPS Application", width: 100 },
        { field: "hps_application", headerName: "HPS Application", width: 100 },
        { field: "lwf_application", headerName: "LWF Application", width: 100 },
        { field: "bloodGroup", headerName: "Blood Group", width: 100 },
        { field: "physicallyChallenged", headerName: "Physically Challenged", width: 100 },
        { field: "birth_date", headerName: "Birth Date", width: 100 },
        { field: "ol_appl", headerName: "OL Application", width: 100 },
        { field: "weekly_leave", headerName: "Weekly Leave", width: 100 },
        { field: "bank", headerName: "Bank", width: 100 },
        { field: "salary_mode", headerName: "Salary Mode", width: 100 },
        { field: "emi_per", headerName: "EMI Percentage", width: 100 },
        { field: "bank_ac_no", headerName: "Bank Account Number", width: 100 },
        { field: "bank_acc_holder", headerName: "Bank Account Holder", width: 100 },
        { field: "ifsc_code", headerName: "IFSC Code", width: 100 },
        { field: "upiId", headerName: "UPI ID", width: 100 },
        { field: "esi_applicable", headerName: "ESI Applicable", width: 100 },
        { field: "pf_application", headerName: "PF Application", width: 100 },
        { field: "uan_no", headerName: "UAN Number", width: 100 },
        { field: "days", headerName: "Days", width: 50 },
        { field: "gross_sal", headerName: "Gross Salary", width: 100 },
        { field: "resign", headerName: "Resign", width: 100 },
        { field: "entry", headerName: "Entry", width: 100 },
        { field: "user", headerName: "User", width: 100 }
    ];


    return (
        <>
            <Container maxWidth="xl" style={{ minHeight: '400px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h5" sx={{ color: 'black', fontWeight: 'bold' }}>
                        Employees List
                    </Typography>
                    <Select
                        value={activeStatus} // Use state value for the selected value
                        onChange={(e) => { setActiveStatus(e.target.value) }} // Call handleChange function when the value changes
                        sx={{ width: '120px', borderRadius: '14px', borderColor: 'red', marginLeft: '10px', height: '40px' }} // Apply some styling
                    >
                        <MenuItem value="Active">Active</MenuItem>
                        <MenuItem value="InActive">InActive</MenuItem>
                        <MenuItem value="All">All</MenuItem>
                    </Select>
                </div>

                <DataGrid
                    rows={dataWithSerialNumbers}
                    columns={columns}
                    initialState={{
                        ...dataWithSerialNumbers.initialState,
                        pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[10, 25, 100]}
                    pagination
                    autoHeight
                    checkboxSelection={false}
                    disableSelectionOnClick
                    density="compact"
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                        },
                    }}
                />
            </Container>

        </>
    );
}

export default EmployeesList;
