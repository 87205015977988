import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import ProfileNav from './profileNav';
import ProfileHeader from './profileheader';
import Divider from '@mui/material/Divider';
import { Select, MenuItem, Modal, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Typography, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import FormControl from '@mui/material/FormControl';
import axios from 'axios';

function ProfileLoan() {
    const [editedLoans, setEditedLoans] = useState([]);
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [ActionMessage, setActionMessage] = useState('');
    const [EmpoyeeIID, setEmpoyeeIID] = useState(null);
    const [TotalAmo, setTotalAmo] = useState(0);

    const empName = localStorage.getItem('StaffDetailEmpName');
    const empMid = localStorage.getItem('StaffDetailEmpMid');
    const empID = localStorage.getItem('StaffDetailEmpID');
    const empStatus = localStorage.getItem('StaffDetailEmpStatus');

    useEffect(() => {
        setEmpoyeeIID(localStorage.getItem('EmployeeIDD'))
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const dataToSend = {
                usrnm: userSchema?.usrnm,
                compid: JSON.parse(companyIdString),
                divid: JSON.parse(divIdString),
                masterid: userSchema?.masterid,
                _id: localStorage.getItem('StaffDetailEmpID')
            };
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/employee/EmployeeLoanGet`,
                dataToSend,
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );
            setEditedLoans(response.data?.loangroup?.loangroup)

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleLoanChange = (event, index, field) => {
        if (field === 'loan_date') {
            const updatedLoans = [...editedLoans];
            updatedLoans[index][field] = event;
            setEditedLoans(updatedLoans);
        }
        else {
            const updatedLoans = [...editedLoans];
            updatedLoans[index][field] = event.target.value;
            setEditedLoans(updatedLoans);
        }

    };

    const handleSave = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const masterid = userSchema?.masterid;
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage

            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/employee/EmployeeLoanUpdate`,
                {
                    user: userSchema?.usrnm,
                    compid: JSON.parse(companyIdString),
                    divid: JSON.parse(divIdString),
                    masterid: userSchema?.masterid,
                    loanGroup: editedLoans,
                    _id: localStorage.getItem('StaffDetailEmpID')
                },
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );
            if (response.data.status) {
                setActionMessage(response.data.message)
                setSuccessModalOpen(true)
            }
            else {
                setActionMessage(response.data.message)
                setErrorModalOpen(true)
            }

        } catch (error) {
            setActionMessage('Some error occured.')
            setErrorModalOpen(true)
            console.error('Error fetching data:', error);
        }
    }

    const calculatePositiveTotal = () => {
        let total = 0;
        editedLoans?.forEach(loan => {
            if (loan.loandc === '(+)') {
                total += loan.loanamount;
            }
        });
        return total;
    };

    const calculateNegativeTotal = () => {
        let total = 0;
        editedLoans?.forEach(loan => {
            if (loan.loandc === '(-)') {
                total += loan.loanamount;
            }
        });
        return total;
    };

    const calculateBalanceTotal = () => {
        let total = 0;
        editedLoans?.forEach(loan => {
                total += loan?.loanbalance || 0;
        });
        return total;
    };

    useEffect(() => {
        const positiveTotal = calculatePositiveTotal();
        const negativeTotal = calculateNegativeTotal();
        const BalanceTotal = calculateBalanceTotal();

        setTotalAmo(BalanceTotal);
        // setTotalAmo(positiveTotal - negativeTotal);
    }, [editedLoans])

    const handleErrorModalClose = () => {
        setErrorModalOpen(false);
    };

    const handleSuccessModalClose = () => {
        setSuccessModalOpen(false);
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        border: '2px solid',
        boxShadow: 24,
        padding: 16,
        minWidth: 300,
        maxWidth: 500,
        textAlign: 'center'
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {/* Header */}
                    <ProfileHeader name={empName} id={empMid} status={empStatus} EmpID={empID} />
                </Grid>

                {/* Side Navbar */}
                <Grid item xs={2}>
                    <ProfileNav />
                </Grid>

                {/* Content Section */}
                <Grid item xs={10}>
                    <Paper elevation={3} style={{ padding: '20px', marginBottom: '10px' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <div style={{ display: 'flex', alignItems: 'stretch' }}>
                                        <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold' }}>
                                            Loan Balance: {TotalAmo}
                                        </Typography>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {/* Loans Table */}
                                <TableContainer sx={{ fontSize: '0.8rem' }}>
                                    <Table size='small'>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell sx={{ fontWeight: 'bold', width: '20%' }}>Date</TableCell>
                                                <TableCell sx={{ fontWeight: 'bold', }}>(+)/(-)</TableCell>
                                                <TableCell sx={{ fontWeight: 'bold' }}>Amount</TableCell>
                                                <TableCell sx={{ fontWeight: 'bold' }}>Installment</TableCell>
                                                <TableCell sx={{ fontWeight: 'bold' }}>Balance</TableCell>
                                                <TableCell sx={{ fontWeight: 'bold', width: '30%' }}>Remarks</TableCell>
                                            </TableRow>
                                            {editedLoans?.map((loan, index) => (
                                                <TableRow key={loan._id}>
                                                    <TableCell>
                                                        <FormControl>
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <DatePicker
                                                                    value={dayjs(loan.loan_date)}
                                                                    onChange={(date) => handleLoanChange(date, index, 'loan_date')}
                                                                    format="DD/MM/YYYY" // Set the desired date format here
                                                                    renderInput={(params) => <TextField {...params}/>}
                                                                    slotProps={{ textField: { size: 'small' } }}
                                                                    readOnly={EmpoyeeIID ? true : false} 
                                                                />
                                                            </LocalizationProvider>
                                                        </FormControl>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Select
                                                            value={loan.loandc}
                                                            onChange={(event) => handleLoanChange(event, index, 'loandc')}
                                                            size="small"
                                                            readOnly={EmpoyeeIID ? true : false}
                                                        >
                                                            <MenuItem value="(+)">(+)</MenuItem>
                                                            <MenuItem value="(-)">(-)</MenuItem>
                                                        </Select>
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            value={loan.loanamount}
                                                            onChange={(event) => handleLoanChange(event, index, 'loanamount')}
                                                            size='small'
                                                            InputProps={{
                                                                readOnly: EmpoyeeIID ? true : false,
                                                            }}
                                                            sx={{input: {textAlign: "right"}}}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            value={loan.loaninstallment}
                                                            onChange={(event) => handleLoanChange(event, index, 'loaninstallment')}
                                                            size='small'
                                                            InputProps={{
                                                                readOnly: EmpoyeeIID ? true : false,
                                                            }}
                                                            sx={{input: {textAlign: "right"}}}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            value={loan.loanbalance}
                                                            onChange={(event) => handleLoanChange(event, index, 'loanbalance')}
                                                            size='small'
                                                            InputProps={{
                                                                readOnly: EmpoyeeIID ? true : false,
                                                            }}
                                                            sx={{input: {textAlign: "right"}}}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            value={loan.loanremarks}
                                                            onChange={(event) => handleLoanChange(event, index, 'loanremarks')}
                                                            size='small'
                                                            fullWidth
                                                            InputProps={{
                                                                readOnly: EmpoyeeIID ? true : false,
                                                            }}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                        <Modal
                            open={successModalOpen}
                            onClose={handleSuccessModalClose}
                            aria-labelledby="success-modal-title"
                            aria-describedby="success-modal-description"
                        >
                            <div style={{ ...modalStyle, borderColor: '#007bff' }}>
                                <h2 id="success-modal-title" style={{ color: '#007bff' }}>Success!</h2>
                                <p id="success-modal-description">{ActionMessage}</p>
                                <Button variant="contained" onClick={handleSuccessModalClose} style={{ backgroundColor: '#007bff', color: 'white' }}>Close</Button>
                            </div>
                        </Modal>

                        <Modal
                            open={errorModalOpen}
                            onClose={handleErrorModalClose}
                            aria-labelledby="error-modal-title"
                            aria-describedby="error-modal-description"
                        >
                            <div style={{ ...modalStyle, borderColor: '#ff0000' }}>
                                <h2 id="error-modal-title" style={{ color: '#ff0000' }}>Error!</h2>
                                <p id="error-modal-description">{ActionMessage}.</p>
                                <Button variant="contained" onClick={handleErrorModalClose} style={{ backgroundColor: '#ff0000', color: 'white' }}>Close</Button>
                            </div>
                        </Modal>
                    </Paper>

                    {/* Save Button */}
                    {!EmpoyeeIID && (
                        <Paper style={{ padding: '16px', position: 'fixed', bottom: '16px', zIndex: 1000, width: 'auto', left: '0', right: '0', margin: '0 auto' }}>
                            <div style={{ display: 'flex', justifyContent: 'center', }}>
                                <Button style={{ marginRight: '8px', color: 'white', backgroundColor: '#c91e32' }} variant="contained" onClick={() => { fetchData() }}>
                                    Cancel
                                </Button>
                                <Button style={{ marginLeft: '8px', color: 'white', backgroundColor: '#2656ab' }} variant="contained" onClick={() => { handleSave() }}>
                                    Save
                                </Button>
                            </div>
                        </Paper>
                    )}
                </Grid>
            </Grid>
        </>
    );
}

export default ProfileLoan;
