import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import ProfileNav from './profileNav';
import ProfileHeader from './profileheader';
import { Select, MenuItem, Modal, Box, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Typography, TextField } from '@mui/material';
import { useRef, useLayoutEffect } from 'react';

import axios from 'axios';

function ProfileLeave() {
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [actionMessage, setActionMessage] = useState('');

    const [data, setdata] = useState([]);
    const [leaveTypes, setleaveTypes] = useState();
    const [BenLeaves, setBenLeaves] = useState([]);
    const empName = localStorage.getItem('StaffDetailEmpName');
    const empMid = localStorage.getItem('StaffDetailEmpMid');
    const empID = localStorage.getItem('StaffDetailEmpID');
    const empStatus = localStorage.getItem('StaffDetailEmpStatus ');
    const [totalLeaves, settotalLeaves] = useState([]);

    const lastContentRef = useRef(null);
    useLayoutEffect(() => {
        if (lastContentRef.current) {
            lastContentRef.current.style.marginBottom = '95px';
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    function aggregateLeaveData(leave) {
        const result = {};

        leave.forEach(entry => {
            const month = entry.month;
            if (!result[month]) {
                result[month] = { month };
            }

            entry.leave_or_group.forEach(leaveGroup => {
                const desc = leaveGroup.leave_name.discription;
                const nos = parseInt(leaveGroup.nos, 10);

                if (!result[month][desc]) {
                    result[month][desc] = 0;
                }

                result[month][desc] += nos;
            });
        });

        return Object.values(result);
    }

    const getUniqueLeaveTypes = (data) => {
        const leaveTypes = new Set();
        data.forEach((entry) => {
            Object.keys(entry).forEach((key) => {
                if (key !== 'month') leaveTypes.add(key);
            });
        });
        return Array.from(leaveTypes);
    };

    const fetchData = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const dataToSend = {
                usrnm: userSchema?.usrnm,
                compid: JSON.parse(companyIdString),
                divid: JSON.parse(divIdString),
                masterid: userSchema?.masterid,
                _id: localStorage.getItem('StaffDetailEmpID')
            };
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/employee/EmployeeLeavesGet`,
                dataToSend,
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );
            const dsf = await aggregateLeaveData(response.data?.leave)
            setdata(dsf)
            setleaveTypes(getUniqueLeaveTypes(dsf))
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const BenLeaveGet = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const dataToSend = {
                usrnm: userSchema?.usrnm,
                compid: JSON.parse(companyIdString),
                divid: JSON.parse(divIdString),
                masterid: userSchema?.masterid,
                _id: localStorage.getItem('StaffDetailEmpID')
            };
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/leave_reqsRouter/BenLeaveGet`,
                dataToSend,
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );
            const dsf = response.data?.Leaves;
            const emp = response.data?.emp?.BenLeaves; // Assuming this contains the initial BenLeaves state

            dsf.forEach(item => {
                const index = emp.findIndex(leave => leave.discription === item.discription);
                if (index !== -1) {
                    // Update existing entry
                    emp[index].yearly_nos = emp[index].yearly_nos; // Update with the new yearly_nos
                } else {
                    // Add new entry
                    emp.push(item);
                }
            });

            // Update the state with the modified emp array
            setBenLeaves(emp);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        BenLeaveGet();
    }, [])

    const handleInputChange = (id, value) => {
        setBenLeaves(prevData =>
            prevData.map(leave =>
                leave._id === id ? { ...leave, yearly_nos: value } : leave
            )
        );
    };

    const calculateLeaveTotals = (data, leaveTypes) => {
        const totals = {};

        leaveTypes?.forEach(leaveType => {
            totals[leaveType] = 0; // Initialize each leave type total to 0
        });

        data.forEach(row => {
            leaveTypes.forEach(leaveType => {
                totals[leaveType] += row[leaveType] || 0; // Sum up the leave counts
            });
        });

        return totals;
    };

    useEffect(() => {
        settotalLeaves(calculateLeaveTotals(data, leaveTypes));
    }, [data])

    function aggregateLeaveData(leave) {
        const result = {};
        leave.forEach(entry => {
            const month = entry.month;
            if (!result[month]) {
                result[month] = { month };
            }
            entry.leave_or_group.forEach(leaveGroup => {
                const desc = leaveGroup.leave_name.discription;
                const nos = parseInt(leaveGroup.nos, 10);
                if (!result[month][desc]) {
                    result[month][desc] = 0;
                }
                result[month][desc] += nos;
            });
        });
        return Object.values(result);
    }

    const prepareLeaveData = () => {
        return BenLeaves.map((leave) => {
            const initialLeaves = parseInt(leave.yearly_nos) || 0;
            const usedLeaves = totalLeaves[leave.discription] || 0;
            const leftLeaves = initialLeaves - usedLeaves;

            return {
                description: leave.discription,
                initialLeaves,
                usedLeaves,
                leftLeaves,
            };
        });
    };

    const handleSubmit = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');

            // const transformedData = BenLeaves?.map(item => {
            //     const { _id, ...rest } = item; // Destructure _id out of item
            //     return {
            //         ...rest,
            //     };
            // });
            const BenLeavesSum = await prepareLeaveData();
            console.log('BenLeaves', BenLeaves);
            console.log('BenLeavesSum', BenLeavesSum)
            const data = {
                user: userSchema?.usrnm,
                compid: JSON.parse(companyIdString),
                divid: JSON.parse(divIdString),
                masterid: userSchema?.masterid,
                BenLeaves: BenLeaves || [],
                // BenLeavesSum: BenLeavesSum || [],
                _id: localStorage.getItem('StaffDetailEmpID')
            };
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage

            const response = await axios.post(`${process.env.REACT_APP_API_URL}/leave_reqsRouter/UpdatedLeavesSum`, data, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            })
                .then(response => {
                    if (response.data.status) {
                        setActionMessage(response.data.message)
                        setSuccessModalOpen(true);
                    } else {
                        setActionMessage(response.data.message)
                        setErrorModalOpen(true)
                    }
                })
                .catch(error => {
                    console.log(error)
                    setActionMessage("Ooops an error occured.")
                    setErrorModalOpen(true)
                });

        } catch (error) {
            console.error('Error saving data:', error);
        }

    }

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        border: '2px solid',
        boxShadow: 24,
        padding: 16,
        minWidth: 300,
        maxWidth: 500,
        textAlign: 'center'
    };
    const handleSuccessModalClose = () => {
        setSuccessModalOpen(false);
    };

    const handleErrorModalClose = () => {
        setErrorModalOpen(false);
    };

    return (
        <div ref={lastContentRef}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {/* Header */}
                    <ProfileHeader name={empName} id={empMid} status={empStatus} EmpID={empID} />
                </Grid>

                {/* Side Navbar */}
                <Grid item xs={2}>
                    <ProfileNav />
                </Grid>

                {/* Content Section */}
                <Grid item xs={10}>
                    <Paper elevation={3} style={{ padding: '20px', marginBottom: '10px', marginLeft: '10px' }}>
                        <Grid container spacing={2}>
                            {BenLeaves.map((leave) => (
                                <Grid item xs={6} sm={4} md={2} key={leave._id}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <TextField
                                            label={leave.discription}
                                            type="number"
                                            value={leave?.yearly_nos}
                                            size='small'
                                            inputProps={{
                                                sx: { textAlign: 'right' }
                                            }}
                                            onChange={(e) => handleInputChange(leave._id, e.target.value)}
                                        />
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    {data.length === 0 ? (
                                        <Box p={2}>
                                            <Typography variant="h6" align="center">
                                                No data available
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Table size='small'>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ fontWeight: 'bold' }}> Month</TableCell>
                                                    {leaveTypes?.map((leaveType) => (
                                                        <TableCell key={leaveType} sx={{ fontWeight: 'bold' }}>{leaveType}</TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data?.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{row.month}</TableCell>
                                                        {leaveTypes?.map((leaveType) => (
                                                            <TableCell key={leaveType}>{row[leaveType] || 0}</TableCell>
                                                        ))}
                                                    </TableRow>
                                                ))}
                                                <TableRow>
                                                    <TableCell sx={{ fontWeight: 'bold' }}>Total</TableCell>
                                                    {leaveTypes?.map((leaveType) => (
                                                        <TableCell sx={{ fontWeight: 'bold' }} key={leaveType}>{totalLeaves[leaveType] || 0}</TableCell> // Display total for each leave type
                                                    ))}
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    )}
                                </TableContainer>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                            <Grid item xs={12}>
                                <TableContainer component={Paper}>
                                    <Table size='small'>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ fontWeight: 'bold' }}>Leave Name</TableCell>
                                                <TableCell sx={{ fontWeight: 'bold' }}>Initial Leaves</TableCell>
                                                <TableCell sx={{ fontWeight: 'bold' }}>Used Leaves</TableCell>
                                                <TableCell sx={{ fontWeight: 'bold' }}>Left Leaves</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {BenLeaves.map((leave) => {
                                                const initialLeaves = parseInt(leave.yearly_nos) || 0;
                                                const usedLeaves = totalLeaves[leave.discription] || 0;
                                                const leftLeaves = initialLeaves - usedLeaves;

                                                return (
                                                    <TableRow key={leave._id}>
                                                        <TableCell>{leave.discription}</TableCell>
                                                        <TableCell>{initialLeaves}</TableCell>
                                                        <TableCell>{usedLeaves}</TableCell>
                                                        <TableCell>{leftLeaves}</TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <Paper style={{ padding: '16px', position: 'fixed', bottom: '10px', zIndex: 1000, width: '60%', left: '0', right: '0', margin: '0 auto' }}>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }}>
                    <Button style={{ marginRight: '8px' }} variant="contained" color="error">
                        Cancel
                    </Button>
                    <Button style={{ marginLeft: '8px' }} variant="contained" color="primary" onClick={() => { handleSubmit() }} >
                        Save
                    </Button>
                </div>
            </Paper>

            <Modal
                open={successModalOpen}
                onClose={handleSuccessModalClose}
                aria-labelledby="success-modal-title"
                aria-describedby="success-modal-description"
            >
                <div style={{ ...modalStyle, borderColor: '#007bff' }}>
                    <h2 id="success-modal-title" style={{ color: '#007bff' }}>Success!</h2>
                    <p id="success-modal-description">{actionMessage}</p>
                    <Button variant="contained" onClick={handleSuccessModalClose} style={{ backgroundColor: '#007bff', color: 'white' }}>Close</Button>
                </div>
            </Modal>
            <Modal
                open={errorModalOpen}
                onClose={handleErrorModalClose}
                aria-labelledby="error-modal-title"
                aria-describedby="error-modal-description"
            >
                <div style={{ ...modalStyle, borderColor: '#ff0000' }}>
                    <h2 id="error-modal-title" style={{ color: '#ff0000' }}>Error!</h2>
                    <p id="error-modal-description">{actionMessage}.</p>
                    <Button variant="contained" onClick={handleErrorModalClose} style={{ backgroundColor: '#ff0000', color: 'white' }}>Close</Button>
                </div>
            </Modal>
        </div>
    );
}

export default ProfileLeave;
