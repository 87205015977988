import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { InputLabel } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { useRef, useLayoutEffect } from 'react';
import Modal from '@mui/material/Modal';
import { useNavigate } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

function SalaryStructureEdit() {
  const [isPositive, setIsPositive] = useState(true);
  const [salaryStrTemp, setsalaryStrTemp] = useState('');
  const [earnings, setearnings] = useState([]);
  const [deductions, setdeductions] = useState([]);
  const [EC, setEC] = useState([]);
  const [staff_type, setStaff_Type] = useState('');
  const [staff_type_values, setStaff_type_values] = useState([])
  const [gratutity, setgratutity] = useState('Y');
  const [bonus, setbonus] = useState('Y');
  const [remark, setremark] = useState('');
  const [salCalBy, setSalCalBy] = useState('Fixed Amount');
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [actionMessage, setActionMessage] = useState('');

  const [earningsX, setearningsX] = useState([]);
  const [deductionsX, setdeductionsX] = useState([]);
  const [ECX, setECX] = useState([]);

  const handleSelectChange = (event) => {
    const selectedSalaryheadName = event.target.value;
    const selectedObject = earningsX.find(item => item.salaryheadName === selectedSalaryheadName);

    if (selectedObject) {
      setearnings(prevEarnings => [
        ...prevEarnings,
        {
          headername: selectedObject.salaryheadName,
          salryhd_sign: selectedObject.head_type,
          salryhd_odr: selectedObject.order,
          salryhd_calcb: selectedObject.calculation_basis,
          salryhd_round: selectedObject.round_of_zero,
          salryhd_vlu: selectedObject.value || 0, // Default value or 0 if not present
          salaryhead_id: selectedObject?._id, // Using the length of the current earnings array as a unique ID
          _id: prevEarnings.length
        }
      ]);
    }
  };

  const handleSelectDeductionChange = (event) => {
    const selectedSalaryheadName = event.target.value;
    const selectedObject = deductionsX.find(item => item.salaryheadName === selectedSalaryheadName);

    if (selectedObject) {
      setdeductions(prevEarnings => [
        ...prevEarnings,
        {
          headername: selectedObject.salaryheadName,
          salryhd_sign: selectedObject.head_type,
          salryhd_odr: selectedObject.order,
          salryhd_calcb: selectedObject.calculation_basis,
          salryhd_round: selectedObject.round_of_zero,
          salryhd_vlu: selectedObject.value || 0, // Default value or 0 if not present
          salaryhead_id: selectedObject?._id, // Using the length of the current earnings array as a unique ID
          _id: prevEarnings.length
        }
      ]);
    }
  };

  const handleSelectEcChange = (event) => {
    const selectedSalaryheadName = event.target.value;
    const selectedObject = ECX.find(item => item.salaryheadName === selectedSalaryheadName);

    if (selectedObject) {
      setEC(prevEarnings => [
        ...prevEarnings,
        {
          headername: selectedObject.salaryheadName,
          salryhd_sign: selectedObject.head_type,
          salryhd_odr: selectedObject.order,
          salryhd_calcb: selectedObject.calculation_basis,
          salryhd_round: selectedObject.round_of_zero,
          salryhd_vlu: selectedObject.value || 0, // Default value or 0 if not present
          salaryhead_id: selectedObject?._id, // Using the length of the current earnings array as a unique ID
          _id: prevEarnings.length
        }
      ]);
    }
  };

  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const receivedData = state;
      if (receivedData) {
        const datas = receivedData._id
        try {
          const data = {
            _id: datas
          };
          const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/salary_master/salary_strucEditData`, data, {
            headers: {
              authorization: `Bearer ${token}`
            }
          });
          console.log(response);
          const plusArray = [];
          const minusArray = [];
          const eArray = [];

          response.data.data.salryhd_or_group.forEach(obj => {
            if (obj.salaryhead_name) {
              const newObj = {
                ...obj,
                // Include salaryhead_name within each object
                salaryhead_name: obj.salaryhead_name.salaryhead_name,
                // Include salaryhead_id within each object
                salaryhead_id: obj.salaryhead_name._id,
                headername: obj.salaryhead_name.salaryhead_name,
              };
              delete newObj.salaryhead_name;

              // Categorize the objects based on salryhd_sign
              if (obj.salryhd_sign === '+') {
                plusArray.push(newObj);
              } else if (obj.salryhd_sign === '-') {
                minusArray.push(newObj);
              } else if (obj.salryhd_sign === 'E') {
                eArray.push(newObj);
              }
            }
          });
          setearnings(plusArray);
          setdeductions(minusArray);
          setEC(eArray);
          setsalaryStrTemp(response.data.data.salarystru_name);
          setgratutity(response.data.data.gratutity);
          setbonus(response.data.data.bonus);
          setremark(response.data.data.remarks);
        } catch (error) {
          console.log(error);
        }
      }
      else {
        console.log("0000000000")
      }
    }
    const StaffType = async () => {
      try {
        const userSchemaString = localStorage.getItem('userSchema');
        const userSchema = JSON.parse(userSchemaString);
        const companyIdString = localStorage.getItem('companyId');
        const divIdString = localStorage.getItem('divId');

        const data = {
          user: userSchema?.usrnm,
          compid: JSON.parse(companyIdString),
          divid: JSON.parse(divIdString),
          masterid: userSchema?.masterid
        };
        const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/salary_master/salaryHeadGet`, data,
          {
            headers: {
              authorization: `Bearer ${token}`
            }
          }
        );

        setStaff_type_values(response.data.staff_type)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData()
    StaffType()
  }, [state])

  const lastContentRef = useRef(null);
  useLayoutEffect(() => {
    if (lastContentRef.current) {
      lastContentRef.current.style.marginBottom = '75px';
    }
  }, []);

  const fetchDataX = async () => {
    try {
      const userSchemaString = localStorage.getItem('userSchema');
      const userSchema = JSON.parse(userSchemaString);
      const companyIdString = localStorage.getItem('companyId');
      const divIdString = localStorage.getItem('divId');


      const data = {
        user: userSchema?.usrnm,
        compid: JSON.parse(companyIdString),
        divid: JSON.parse(divIdString),
        masterid: userSchema?.masterid
      };
      const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/salary_master/salaryHeadGet`, data,
        {
          headers: {
            authorization: `Bearer ${token}`
          }
        }
      );

      // if (response.data.earnings && response.data.earnings.length) {
      //   const initializedEarnings = response.data.earnings.map((item) => ({
      //     ...item,
      //     salaryhead_name: item._id,
      //     salryhd_sign: item.head_type,
      //     salryhd_odr: item.order,
      //     salryhd_calcb: item.calculation_basis,
      //     salryhd_round: item.round_of_zero,
      //     salryhd_vlu: item.value || 0, // Default value or 0 if not present
      //   }));
      //   setearningsX(initializedEarnings);
      // }

      setearningsX(response.data.earnings)

      // if (response.data.deductions && response.data.deductions.length) {
      //   const initializeddeductions = response.data.deductions.map((item) => ({
      //     ...item,
      //     salaryhead_name: item._id,
      //     salryhd_sign: item.head_type,
      //     salryhd_odr: item.order,
      //     salryhd_calcb: item.calculation_basis,
      //     salryhd_round: item.round_of_zero,
      //     salryhd_vlu: item.value || 0, // Default value or 0 if not present
      //   }));
      //   setdeductionsX(initializeddeductions);
      // }

      setdeductionsX(response.data.deductions)

      // if (response.data.EC && response.data.EC.length) {
      //   const initializedEC = response.data.EC.map((item) => ({
      //     ...item,
      //     salaryhead_name: item._id,
      //     salryhd_sign: item.head_type,
      //     salryhd_odr: item.order,
      //     salryhd_calcb: item.calculation_basis,
      //     salryhd_round: item.round_of_zero,
      //     salryhd_vlu: item.value || 0, // Default value or 0 if not present
      //   }));
      //   setECX(initializedEC);
      // }

      setECX(response.data.EC);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    fetchDataX();
  }, []);

  const handleSave = async () => {
    try {
      const userSchemaString = localStorage.getItem('userSchema');
      const userSchema = JSON.parse(userSchemaString);
      const companyIdString = localStorage.getItem('companyId');
      const divIdString = localStorage.getItem('divId');
      const salryhd_or_group = [...earnings, ...deductions, ...EC]
      
      const newArray = salryhd_or_group.map(item => ({
        ...item,
        salaryhead_name: item.salaryhead_name || item.salaryhead_id
      }));
      const receivedData = state;
      const EditID = receivedData._id
      const data = {
        salarystru_name: salaryStrTemp,
        gratutity: gratutity,
        bonus: bonus,
        remarks: remark,
        salCalBy: salCalBy,
        staff_type: staff_type,
        salryhd_or_group: newArray,
        user: userSchema?.usrnm,
        compid: JSON.parse(companyIdString),
        divid: JSON.parse(divIdString),
        masterid: userSchema?.masterid,
        editID: EditID
      };

      const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/salary_master/salaryStrucUpdate`, data, {
        headers: {
          authorization: `Bearer ${token}`
        }
      })
        .then(response => {
          console.log(response)
          if (response.data.status) {
            setActionMessage(response.data.message)
            setSuccessModalOpen(true);
            setTimeout(() => {
              handleCancel();
            }, "2000");

          } else {
            setActionMessage(response.data.message)
            // handleCancel();
            setErrorModalOpen(true)
          }
        })
        .catch(error => {
          console.log(error)
          setActionMessage("Ooops an error occured.")
          setErrorModalOpen(true)
        });
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  const handleCancel = () => {
    navigate('/HrMaster/salaryMaster/Salary_StrucList')
  }

  //   const handleAddMore = () => {
  //     setearnings([...earnings, { user: "", role: "" }]);
  // };

  const handleEarningsInputChange = (event, index) => {
    const { value } = event.target;
    setearnings(prevEarnings => {
      const updatedEarnings = [...prevEarnings];
      updatedEarnings[index] = {
        ...updatedEarnings[index], value,
        salaryhead_name: updatedEarnings[index].salaryhead_id,
        salryhd_sign: updatedEarnings[index].salryhd_sign,
        salryhd_odr: updatedEarnings[index].salryhd_odr,
        salryhd_calcb: updatedEarnings[index].salryhd_calcb,
        salryhd_round: updatedEarnings[index].salryhd_round,
        salryhd_vlu: value
      };
      console.log(updatedEarnings);
      return updatedEarnings;
    });
  };

  const handleDeductionsInputChange = (event, index) => {
    const { value } = event.target;
    setdeductions(prevEarnings => {
      const updatedEarnings = [...prevEarnings];
      updatedEarnings[index] = {
        ...updatedEarnings[index], value,
        salaryhead_name: updatedEarnings[index].salaryhead_id,
        salryhd_sign: updatedEarnings[index].salryhd_sign,
        salryhd_odr: updatedEarnings[index].salryhd_odr,
        salryhd_calcb: updatedEarnings[index].salryhd_calcb,
        salryhd_round: updatedEarnings[index].salryhd_round,
        salryhd_vlu: value
      };
      console.log(updatedEarnings);
      return updatedEarnings;
    });
  };

  const handleECInputChange = (event, index) => {
    const { value } = event.target;
    setEC(prevEarnings => {
      const updatedEarnings = [...prevEarnings];
      updatedEarnings[index] = {
        ...updatedEarnings[index], value,
        salaryhead_name: updatedEarnings[index].salaryhead_id,
        salryhd_sign: updatedEarnings[index].salryhd_sign,
        salryhd_odr: updatedEarnings[index].salryhd_odr,
        salryhd_calcb: updatedEarnings[index].salryhd_calcb,
        salryhd_round: updatedEarnings[index].salryhd_round,
        salryhd_vlu: value
      };
      console.log(updatedEarnings);
      return updatedEarnings;
    });
  };

  // useEffect(() => {
  //   earnings.forEach((earning, index) => {
  //     handleEarningsInputChange({ target: { value: earning.salryhd_vlu }}, index);
  //   });
  //   deductions.forEach((deduction, index) => {
  //     handleDeductionsInputChange({ target: { value: deduction.salryhd_vlu }}, index);
  //   });
  //   EC.forEach((ec, index) => {
  //     handleECInputChange({ target: { value: ec.salryhd_vlu }}, index);
  //   });
  // }, []);

  const handleDeductionDelete = (id) => {
    const updatedDeductions = deductions.filter(item => item._id !== id);
    setdeductions(updatedDeductions);
  };
  const handleEarningDelete = (id) => {
    const updatedEarnings = earnings.filter(item => item._id !== id);
    setearnings(updatedEarnings);
  };
  const handleEcDelete = (id) => {
    const updatedEc = EC.filter(item => item._id !== id);
    setEC(updatedEc);
  };
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    border: '2px solid',
    boxShadow: 24,
    padding: 16,
    minWidth: 300,
    maxWidth: 500,
    textAlign: 'center'
  };
  const handleSuccessModalClose = () => {
    setSuccessModalOpen(false);
  };

  const handleErrorModalClose = () => {
    setErrorModalOpen(false);
  };
  return (
    <div style={{ padding: '20px' }} ref={lastContentRef}>
      <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', marginBottom: '3px' }}>
        Salary Structure Template
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Paper style={{ padding: '16px', position: 'fixed', bottom: '16px', zIndex: 1000, width: '60%', left: '0', right: '0', margin: '0 auto' }}>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }}>
              <Button style={{ marginRight: '8px' }} variant="contained" color="error" onClick={handleCancel}>
                Cancel
              </Button>
              <Button style={{ marginLeft: '8px' }} variant="contained" color="primary" onClick={handleSave}>
                Save
              </Button>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12}>
          <Paper elevation={3} style={{ padding: '2px' }}>
            <div style={{ marginBottom: '20px', marginTop: '20px', display: 'flex', alignItems: 'center' }}>
              <Typography variant="body1" sx={{ marginRight: '20px', width: '410px', marginLeft: '10px' }}>
                Salary Structure Templates
              </Typography>
              <FormControl style={{ flex: '0.7', minWidth: '100px', }}>
                <input
                  type="text"
                  value={salaryStrTemp}
                  onChange={(e) => setsalaryStrTemp(e.target.value)}
                  style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', flex: '0.38' }} // Adjusted flex to take half of the container width
                />
              </FormControl>

              <FormControlLabel
                sx={{ marginInlineStart: '200px', maxWidth: 'fit-content' }}
                control={<Checkbox checked={isPositive} onChange={(e) => setIsPositive(e.target.checked)} />}
                label="Default"
              />
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12}>
          <Paper elevation={3} style={{ padding: '20px' }}>
            <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: 'auto', marginBottom: '10px' }}>
              Staff Details
            </Typography>
            <Divider />
            <div style={{ marginBottom: '10px', marginTop: '10px', display: 'flex', alignItems: 'center' }}>
              <Typography variant="body1" sx={{ marginRight: '20px', width: '410px', marginLeft: '10px' }}>
                Staff Type
              </Typography>
              <FormControl style={{ flex: '0.48', minWidth: '100px', }}>
                <Select
                  value={staff_type}
                  onChange={(e) => setStaff_Type(e.target.value)}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Select Staff Type' }}
                  style={{ height: '38px', fontSize: '14px' }}
                >
                  <MenuItem value="" disabled>
                    Select Staff Type
                  </MenuItem>
                  {staff_type_values.map((type) => (
                    <MenuItem key={type._id} value={type.staff_type}>
                      {type.staff_type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <Divider />
            <div style={{ marginBottom: '10px', marginTop: '10px', display: 'flex', alignItems: 'center' }}>
              <Typography variant="body1" sx={{ marginRight: '20px', width: '410px', marginLeft: '10px' }}>
                Gratutity
              </Typography>
              <FormControl style={{ flex: '0.48', minWidth: '100px', }}>
                <Select
                  value={gratutity}
                  onChange={(e) => setgratutity(e.target.value)}
                  // displayEmpty
                  inputProps={{ 'aria-label': 'Select Staff Type' }}
                  style={{ height: '38px', fontSize: '14px' }}
                >
                  <MenuItem value="" disabled >
                    Select Gratutity
                  </MenuItem>
                  <MenuItem value="Y">Y</MenuItem>
                  <MenuItem value="N">N</MenuItem>
                </Select>
              </FormControl>
            </div>
            <Divider />
            <div style={{ marginBottom: '10px', marginTop: '10px', display: 'flex', alignItems: 'center' }}>
              <Typography variant="body1" sx={{ marginRight: '20px', width: '410px', marginLeft: '10px' }}>
                Bonus
              </Typography>
              <FormControl style={{ flex: '0.48', minWidth: '100px', }}>
                <Select
                  value={bonus}
                  onChange={(e) => setbonus(e.target.value)}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Select Staff Type' }}
                  style={{ height: '38px', fontSize: '14px' }}
                >
                  <MenuItem value="" disabled >
                    Select Bonus
                  </MenuItem>
                  <MenuItem value="Y">Y</MenuItem>
                  <MenuItem value="N">N</MenuItem>
                </Select>
              </FormControl>
            </div>
            <Divider />
            <div style={{ marginBottom: '10px', marginTop: '10px', display: 'flex', alignItems: 'center' }}>
              <Typography variant="body1" sx={{ marginRight: '20px', width: '410px', marginLeft: '10px' }}>
                Salary Calulated By
              </Typography>
              <FormControl style={{ flex: '0.48', minWidth: '100px', }}>
                <Select
                  value={salCalBy}
                  onChange={(e) => setSalCalBy(e.target.value)}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Select Staff Type' }}
                  style={{ height: '38px', fontSize: '14px' }}
                >
                  <MenuItem value="" disabled >
                    Select
                  </MenuItem>
                  <MenuItem value="Fixed Amount">₹ (Fixed Amount)</MenuItem>
                  <MenuItem value="Percentage">% (Percentage)</MenuItem>
                </Select>
              </FormControl>
            </div>
            <Divider />
            <div style={{ marginBottom: '10px', marginTop: '10px', display: 'flex', alignItems: 'center' }}>
              <Typography variant="body1" sx={{ marginRight: '20px', width: '410px', marginLeft: '10px' }}>
                Remark
              </Typography>
              <FormControl style={{ flex: '0.48', minWidth: '100px', }}>
                <input
                  type="text"
                  // placeholder='Remark'
                  value={remark}
                  onChange={(e) => setremark(e.target.value)}
                  style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', flex: '1' }}
                />
              </FormControl>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12}>
          <Paper elevation={3} style={{ padding: '20px' }}>
            <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: 'auto', marginBottom: '10px' }}>
              Earnings
            </Typography>
            {earnings.map((item, index) => (
              <React.Fragment key={item._id}>
                <Divider />
                <div style={{ marginBottom: '10px', marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                  <Typography variant="body1" sx={{ marginRight: '20px', width: '410px', marginLeft: '10px' }}>
                    {item.headername}
                  </Typography>
                  <FormControl style={{ flex: '0.7', minWidth: '100px', }}>
                    <input
                      type="number"
                      value={item.salryhd_vlu || ""} // Use item.value as the input value
                      onChange={(event) => handleEarningsInputChange(event, index)} style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', flex: '0.38' }} // Adjusted flex to take half of the container width
                    />
                  </FormControl>
                  <FormControlLabel
                    control={<IconButton onClick={() => handleEarningDelete(item._id)}><DeleteIcon /></IconButton>}
                    sx={{ marginInlineStart: '200px', maxWidth: 'fit-content' }} // Adjust maxWidth to fit-content
                  />
                </div>
              </React.Fragment>
            ))}
            {/* <Button variant="contained" onClick={handleAddMore} sx={{ marginLeft: 'auto' }}>
              Add More
            </Button> */}
            {/* {showEarnDropdown && ( */}
            <FormControl fullWidth
              sx={{
                marginTop: 0,
                backgroundColor: '#f5f5f5', // Light background color
                padding: '8px', // Add some padding
                borderRadius: '8px', // Rounded corners
                // boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Subtle shadow
              }}
            >
              <InputLabel id="select-label">Add More</InputLabel>
              <Select
                labelId="select-label"
                id="select"
                shrink
                label="Add More"
                onChange={(e) => { handleSelectChange(e); }} // Handle change event
                size="small" // Make the select component smaller
              >
                {earningsX
                  .filter(item => !earnings.some(e => e.headername === item.salaryheadName)) // Filter out existing earnings
                  .map(item => (
                    <MenuItem key={item._id} value={item.salaryheadName}>
                      {item.salaryheadName}
                    </MenuItem>
                  ))}
                {earningsX.filter(item => !earnings.some(e => e.headername === item.salaryheadName)).length === 0 && (
                  <MenuItem disabled>No options available</MenuItem>
                )}
              </Select>
            </FormControl>

            {/* )} */}
          </Paper>
        </Grid>
        <Grid item xs={12} md={12}>
          <Paper elevation={3} style={{ padding: '20px' }}>
            <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: 'auto', marginBottom: '10px' }}>
              Deductions
            </Typography>
            {deductions.map((item, index) => (
              <React.Fragment key={item._id}>
                <Divider />
                <div style={{ marginBottom: '10px', marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                  <Typography variant="body1" sx={{ marginRight: '20px', width: '410px', marginLeft: '10px' }}>
                    {item.headername}
                  </Typography>
                  <FormControl style={{ flex: '0.7', minWidth: '100px', }}>
                    <input
                      type="number"
                      value={item.salryhd_vlu || ""} // Use item.value as the input value
                      onChange={(event) => handleDeductionsInputChange(event, index)}
                      style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', flex: '0.38' }} // Adjusted flex to take half of the container width
                    />
                  </FormControl>
                  <FormControlLabel
                    control={<IconButton onClick={() => handleDeductionDelete(item._id)}><DeleteIcon /></IconButton>} // Pass item._id to the click handler
                    sx={{ marginInlineStart: '200px', maxWidth: 'fit-content' }} />
                </div>
              </React.Fragment>
            ))}

            {/* <Button variant="contained" onClick={() => console.log("Assign More clicked")} sx={{ marginLeft: 'auto' }}>
              Add More
            </Button> */}

            <FormControl fullWidth
              sx={{
                marginTop: 0,
                backgroundColor: '#f5f5f5', // Light background color
                padding: '8px', // Add some padding
                borderRadius: '8px', // Rounded corners
                // boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Subtle shadow
              }}            >
              <InputLabel id="select-labelDec">Add More</InputLabel>
              <Select
                labelId="select-labelDec"
                id="selectDec"
                shrink
                label="Add More"
                onChange={(e) => { handleSelectDeductionChange(e); }} // Handle change event
                size="small" // Make the select component smaller
              >
                {deductionsX
                  .filter(item => !deductions.some(e => e.headername === item.salaryheadName)) // Filter out existing earnings
                  .map(item => (
                    <MenuItem key={item._id} value={item.salaryheadName}>
                      {item.salaryheadName}
                    </MenuItem>
                  ))}
                {deductionsX.filter(item => !deductions.some(e => e.headername === item.salaryheadName)).length === 0 && (
                  <MenuItem disabled>No options available</MenuItem>
                )}
              </Select>
            </FormControl>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12}>
          <Paper elevation={3} style={{ padding: '20px' }}>
            <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: 'auto', marginBottom: '10px' }}>
              Employer's Contribution
            </Typography>
            {EC.map((item, index) => (
              <React.Fragment key={item._id}>
                <Divider />
                <div style={{ marginBottom: '10px', marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                  <Typography variant="body1" sx={{ marginRight: '20px', width: '410px', marginLeft: '10px' }}>
                    {item?.headername}
                  </Typography>
                  <FormControl style={{ flex: '0.7', minWidth: '100px', }}>
                    <input
                      type="number"
                      value={item?.salryhd_vlu || ""} // Use item.value as the input value
                      onChange={(event) => handleECInputChange(event, index)} style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', flex: '0.38' }} // Adjusted flex to take half of the container width
                    />
                  </FormControl>
                  <FormControlLabel
                    control={<IconButton onClick={() => handleEcDelete(item._id)}><DeleteIcon /></IconButton>} // Pass item._id to the click handler
                    sx={{ marginInlineStart: '200px', maxWidth: 'fit-content' }} />
                </div>
              </React.Fragment>
            ))}
            {/* <Button variant="contained" onClick={() => console.log("Assign More clicked")} sx={{ marginLeft: 'auto' }}>
              Add More
            </Button> */}
            <FormControl fullWidth
              sx={{
                marginTop: 0,
                backgroundColor: '#f5f5f5', // Light background color
                padding: '8px', // Add some padding
                borderRadius: '8px', // Rounded corners
                // boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Subtle shadow
              }}            >
              <InputLabel id="select-labelEc">Add More</InputLabel>
              <Select
                labelId="select-labelEc"
                id="selectEc"
                shrink
                label="Add More"
                onChange={(e) => { handleSelectEcChange(e); }} // Handle change event
                size="small" // Make the select component smaller
              >
                {ECX
                  .filter(item => !EC.some(e => e.headername === item.salaryheadName)) // Filter out existing earnings
                  .map(item => (
                    <MenuItem key={item._id} value={item.salaryheadName}>
                      {item.salaryheadName}
                    </MenuItem>
                  ))}
                {ECX.filter(item => !EC.some(e => e.headername === item.salaryheadName)).length === 0 && (
                  <MenuItem disabled>No options available</MenuItem>
                )}
              </Select>
            </FormControl>
          </Paper>
        </Grid>
      </Grid>
      <Modal
        open={successModalOpen}
        onClose={handleSuccessModalClose}
        aria-labelledby="success-modal-title"
        aria-describedby="success-modal-description"
      >
        <div style={{ ...modalStyle, borderColor: '#007bff' }}>
          <h2 id="success-modal-title" style={{ color: '#007bff' }}>Success!</h2>
          <p id="success-modal-description">{actionMessage}</p>
          <Button variant="contained" onClick={handleSuccessModalClose} style={{ backgroundColor: '#007bff', color: 'white' }}>Close</Button>
        </div>
      </Modal>
      <Modal
        open={errorModalOpen}
        onClose={handleErrorModalClose}
        aria-labelledby="error-modal-title"
        aria-describedby="error-modal-description"
      >
        <div style={{ ...modalStyle, borderColor: '#ff0000' }}>
          <h2 id="error-modal-title" style={{ color: '#ff0000' }}>Error!</h2>
          <p id="error-modal-description">{actionMessage}.</p>
          <Button variant="contained" onClick={handleErrorModalClose} style={{ backgroundColor: '#ff0000', color: 'white' }}>Close</Button>
        </div>
      </Modal>
    </div>
  );
}

export default SalaryStructureEdit;
