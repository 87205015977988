import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Typography, Grid, Box, Button, Paper, TextField, Divider, CircularProgress, Select, MenuItem, Container } from '@mui/material';
import axios from 'axios';
import Autocomplete from '@mui/material/Autocomplete';
import dayjs from 'dayjs';
import { useRef, useLayoutEffect } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid'; // Import DataGrid from MUI
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import '../../App.css';
import { useGridApiRef } from '@mui/x-data-grid';

const OTReport = () => {
    const navigate = useNavigate();

    const [selectionRange, setSelectionRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });

    const [tempStartDate, setTempStartDate] = useState(new Date());
    const [tempEndDate, setTempEndDate] = useState(new Date());
    const dateRangePickerRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleSelect = (ranges) => {
        // Update the tempStartDate and tempEndDate independently
        const { startDate, endDate } = ranges.selection;
        if (startDate && endDate) {
            if (startDate <= tempEndDate) {
                setTempStartDate(startDate);
            }
            if (endDate >= tempStartDate) {
                setTempEndDate(endDate);
            }
        }
    };

    const applyDateRange = () => {
        setSelectionRange({
            startDate: tempStartDate,
            endDate: tempEndDate,
            key: 'selection',
        });
        setIsOpen(false);
    };

    const handleClickOutside = (event) => {
        if (dateRangePickerRef.current && !dateRangePickerRef.current.contains(event.target)) {
            setIsOpen(false); // Close the date picker when clicked outside
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDateRangePicker = () => {
        setIsOpen(!isOpen);
    };

    const formatDates = (date) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const [isLoading, setIsLoading] = useState(false); // State variable to manage loading state

    const [data, setData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(0); // Initialize currentPage to 0
    const [totalCount, setTotalCount] = useState(0); // State to hold total count of rows
    const [MonthName, setMonthName] = useState(new Date().toLocaleString('default', { month: 'long' }));
    const [EmployeeName, setEmployeeName] = useState({_id: '', full_name:'ALL'});
    const [otChecked, setOtChecked] = useState(false);
    const [latcoming, setlatcoming] = useState(15);
    const [misspunch, setmisspunch] = useState(false);
    const [LeaveName, setLeaveName] = useState('');
    const [startDate, setstartDate] = React.useState(dayjs());
    const [endDate, setendDate] = React.useState(dayjs());

    const [totalRowCount, setTotalRowCount] = React.useState(0);
    const [searchString, setsearchString] = useState(null);
    const [queryOptions, setQueryOptions] = React.useState({});
    const [filterModel, setFilterModel] = React.useState({
        items: [],
        quickFilterValues: [],
    });
    const apiRef = useGridApiRef();
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 10,
    });

    const [LeaveType, setLeaveType] = useState([]);
    const [EmpList, setEmpList] = useState([]);
    const [openDeleteAllModal, setopenDeleteAllModal] = useState(false); // State to control the modal visibility
    const [DeleteIndexArray, setDeleteIndexArray] = useState([]); // State to store the index of item to be deleted
    const [selectedRows, setSelectedRows] = useState([]);

    const handleSelectionModelChange = (newSelectionModel) => {
        setSelectedRows(newSelectionModel);
    };

    const dataGridRef = useRef(null);

    const lastContentRef = useRef(null);
    useLayoutEffect(() => {
        if (lastContentRef.current) {
            lastContentRef.current.style.marginBottom = '70px';
        }
    }, []);

    const fetchData = async () => {
        try {
            if(EmployeeName?.full_name){
            setIsLoading(true)
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const masterid = userSchema?.masterid;
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/attendance/GetattendanceDetails`,
                {
                    compid: JSON.parse(companyIdString),
                    divid: JSON.parse(divIdString),
                    masterid,
                    month: MonthName,
                    employee: EmployeeName?._id,
                    ot: false,
                    misspunch,
                    leaveType: LeaveName?._id,
                    // DepartType: DepartTypeValue?._id,
                    searchText,
                    rowsPerPage,
                    currentPage,
                    latcoming,
                    startDate: selectionRange.startDate,
                    endDate: selectionRange.endDate,
                    page: paginationModel.page + 1,
                        pageSize: paginationModel.pageSize,
                },
                {
                    params: {
                        searchText,
                        rowsPerPage,
                        currentPage,
                    },
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );


            setData(response.data.summary);
            // setTotalCount(response.data.recordsFiltered); // Update total count of rows
            console.log('225', response.data.summarycount)
            setTotalCount(response.data.summarycount); // Update total count of rows
            setTotalRowCount(response.data.totalDocuments);
        }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        finally {
            setIsLoading(false)
        }
    };

    const EmpListGET = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');

            const data = {
                user: userSchema?.usrnm,
                compid: JSON.parse(companyIdString),
                divid: JSON.parse(divIdString),
                masterid: userSchema?.masterid
            }
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/attendance/EmployeeNamesGET`, data,
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            setEmpList(response.data.lastEntryNo);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchLeaveType = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');


            const data = {
                user: userSchema?.usrnm,
                compid: JSON.parse(companyIdString),
                divid: JSON.parse(divIdString),
                masterid: userSchema?.masterid
            };
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/attendance/LeaveTypeGET`, data,
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            setLeaveType(response.data.lastEntryNo);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        EmpListGET();
        fetchLeaveType();
    }, [])

    // useEffect(() => {
    //     fetchData();
    // }, [latcoming,startDate, endDate, selectionRange, EmployeeName, otChecked, LeaveName, rowsPerPage]);

    const debouncedFetchData = useCallback(
        debounce(fetchData, 1000),
        [paginationModel, latcoming, misspunch, startDate, endDate, selectionRange, EmployeeName, otChecked, LeaveName, rowsPerPage]
    );

    useEffect(() => {
        debouncedFetchData();
        return () => {
            debouncedFetchData.cancel();
        };
    }, [debouncedFetchData]);

    function convertToIndianTime(dateString) {
        const padZero = (num) => (num < 10 ? '0' : '') + num;

        // Convert the string to a Date object
        let date = new Date(dateString);

        // Add 5.5 hours to the date
        date.setHours(date.getHours() + 5, date.getMinutes() + 30);

        // Format the date to "dd-mm-yyyy hh:mm:ss"
        const day = padZero(date.getUTCDate());
        const month = padZero(date.getUTCMonth() + 1); // Months are zero-based
        const year = date.getUTCFullYear();

        const hours = padZero(date.getUTCHours());
        const minutes = padZero(date.getUTCMinutes());
        const seconds = padZero(date.getUTCSeconds());

        return `${day}-${month}-${year}`;
    }

    function convertTo100Format(time) {
        if (time == null) {
            return "00.00";
        }

        const timeStr = time.toString();
        const isNegative = timeStr.startsWith('-');
        let [hoursStr, minutesStr] = timeStr.replace('-', '').split('.');

        // Handle cases where minutesStr might be undefined or empty
        minutesStr = minutesStr ? minutesStr.padEnd(2, '0') : '00';
        const minutes = parseInt(minutesStr, 10);

        // Convert minutes to a fraction of 100
        const minutesFraction = Math.round((minutes / 60) * 100);

        // Ensure hours and minutes are two digits
        hoursStr = hoursStr.padStart(2, '0');
        let formattedMinutes = minutesFraction.toString().padStart(2, '0');

        // Combine hours and formatted minutes
        let timeIn100Format = `${hoursStr}.${formattedMinutes}`;

        // Reapply the negative sign if needed
        if (isNegative) {
            timeIn100Format = `-${timeIn100Format}`;
        }

        return timeIn100Format;
    }

    const dataWithSerialNumbers = data.map((item, index) => {
        // var constper_hour = item._id.gross_sal[0] ? ((Number(item?._id?.gross_sal[0]) / 30) / item?.dalatten_total_hour).toFixed(2) : 0;
        var constper_hour = item._id.gross_sal[0]
            ? (Number(item._id.gross_sal[0]) / 30 / item.dalatten_total_hour)
            : 0;

        constper_hour = !isFinite(constper_hour) ? 0 : constper_hour.toFixed(2);

        let per_hour = 0;

        if ((item._id?.ol_appl[0] && item._id?.ol_appl[0] === 'Y') || (item._id?.ol_appl[0] && item._id?.ol_appl[0] === '1.5')) {
            var otamtcalc = item._id.gross_sal[0] ? ((Number(item?._id?.gross_sal[0]) / 30) / item?.dalatten_total_hour).toFixed(2) : '';
            if (item._id?.ol_appl[0] && item._id?.ol_appl[0] === '1.5') otamtcalc = parseFloat(otamtcalc) * 1.5
            per_hour = otamtcalc === Infinity ? 0 : otamtcalc;
        }

        // const ot_amount = (per_hour * convertTo100Format(item?._id?.dalatten_overtime)).toFixed(2);
        let ot_amount = (per_hour * convertTo100Format(item?._id?.dalatten_overtime));
        ot_amount = !isFinite(ot_amount) ? 0 : ot_amount.toFixed(2);

        console.log("Check", item._id?.ol_appl[0]);
        return {
            ...item,
            id: index,
            empname: item._id.empname[0] ? item._id.empname[0] : '',
            atdid: item._id.atdid ? item._id.atdid : '',
            machine_code: item._id.machine_code[0] ? item._id.machine_code[0] : '',
            shiftname: item._id.shiftname[0] ? item._id.shiftname[0] : '',
            deptname: item._id.deptname[0] ? item._id.deptname[0] : '',
            lvname: item._id.lvname[0] ? item._id.lvname[0] : '',
            gross_sal: item._id.gross_sal[0] ? item._id.gross_sal[0] : '',
            shiftinTime: item?._id?.shiftinTime[0] ? item?._id?.shiftinTime[0] : '',
            per_day: item._id.gross_sal[0] ? (Number(item._id.gross_sal[0]) / 30).toFixed(2) : '',
            per_hour: constper_hour,
            ot_amount: ot_amount !== '' && !isNaN(ot_amount) ? ot_amount : '', // Check for NaN
            designation: item._id.designation[0] ? item._id.designation[0] : '',
            machineschemas: item._id.machineschemas[0] ? item._id.machineschemas[0] : '',
            dalatten_intime: item?._id?.dalatten_intime,
            dalatten_outtime: item?._id?.dalatten_outtime,
            dalatten_date: item._id.dalatten_date ? convertToIndianTime(item._id.dalatten_date) : '',
            dalatten_overtime: (item._id?.ol_appl[0] && item._id?.ol_appl[0] === 'Y') || (item._id?.ol_appl[0] && item._id?.ol_appl[0] === '1.5') ? item?._id?.dalatten_overtime?.toFixed(2) : 0.00,
            dalatten_overtimehrs: item._id.dalatten_overtimehrs ? item._id.dalatten_overtimehrs.toFixed(2) : '',
            dalatten_total_hour: item._id.dalatten_total_hour ? item._id.dalatten_total_hour : '',
            tot_hrs: item._id.tot_hrs ? item._id.tot_hrs : '',
            edit_by: item._id.edit_by ? item._id.edit_by : '',
        };
    });

    const columns = [
        { field: "atdid", headerName: "ID" },
        { field: "machine_code", headerName: "Employee ID" },
        { field: "empname", headerName: "Employee Name" },
        { field: "deptname", headerName: "Department" },
        { field: "designation", headerName: "Designation" },
        { field: "machineschemas", headerName: "Machine" },
        {
            field: "lvname", headerName: "Atd Type",
            renderCell: (params) => {
                const lvname = params.row.lvname ?? '';
                const cellStyle = {
                    backgroundColor: lvname === 'Present' ? '#92bd8a' : lvname === 'Absent' ? '#e3877f' : 'inherit',
                    color: 'black',
                    textAlign: 'center',
                };
                return (
                    <div style={cellStyle}>
                        {lvname}
                    </div>
                );
            }
        }, { field: "dalatten_date", headerName: "Date" },
        // { field: "gross_sal", headerName: "Gross Sal" },
        { field: "per_hour", headerName: "Per Hour" },
        { field: "shiftname", headerName: "Shift Name" },
        { field: "dalatten_total_hour", headerName: "Shift Hours" },
        { field: "shiftinTime", headerName: "Shift Time" },
        { field: "dalatten_intime", headerName: "In-Time" },
        { field: "dalatten_outtime", headerName: "Out-Time" },
        { field: "tot_hrs", headerName: "Total Hours" },
        { field: "dalatten_overtime", headerName: "OT Hours" },
        { field: "ot_amount", headerName: "Ot Amount" },
    ];

    const generateFileName = () => {
        const now = new Date();
        const date = now.toLocaleDateString().replace(/\//g, '-'); // Format date as YYYY-MM-DD
        const time = now.toLocaleTimeString().replace(/:/g, '-');  // Format time as HH-MM-SS

        const prefix = 'LATE COMING REPORT';
        return `${prefix}_${date}_${time}`;
    };

    return (
        <div ref={lastContentRef}>
            <Box sx={{ marginBottom: '20px', width: '100%' }}>
                <div style={{ padding: '10px' }}>
                    <Grid container spacing={1} alignItems="center" justifyContent="flex-start" style={{ width: '100%' }}>
                        <Grid item xs={3} sx={{ marginBlockStart: '0px' }}>
                            <Typography variant="body1" sx={{ color: 'black', fontWeight: 'bold' }}>
                                Late Coming Report
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ marginBlockStart: '0px' }}>
                            <TextField
                                label='Late Coming'
                                value={latcoming}
                                type="number"
                                size='small'
                                onChange={(e) => setlatcoming(e.target.value)}
                                sx={{
                                    borderRadius: '14px',
                                }}
                            />
                        </Grid>
                        <Grid item xs={2} sx={{ marginBlockStart: '0px' }}>
                            <Autocomplete
                                value={EmployeeName}
                                onChange={(e, newValue) => setEmployeeName(newValue)}
                                // options={EmpList}
                                options={[{_id: '', full_name:'All'}, ...EmpList]}
                                getOptionLabel={(option) => (option ? option.full_name : "")}
                                isOptionEqualToValue={(option, value) => option._id === value._id}
                                filterOptions={(options, state) => {
                                    const inputValue = state.inputValue.toLowerCase();
                                    return options.filter(option =>
                                        option.full_name && option.full_name.toLowerCase().includes(inputValue)
                                    );
                                }}
                                renderOption={(props, option) => (
                                    <li {...props} key={option._id}>
                                        {option.full_name}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Employee"
                                        inputProps={{
                                            ...params.inputProps,
                                            'aria-label': 'Select Employee',
                                            style: { paddingTop: '0px' },
                                        }}
                                        variant="outlined"
                                        size="small"
                                    />
                                )}
                                fullWidth
                            />
                            {/* </FormControl> */}
                        </Grid>
                        <Grid item xs={2} sx={{ marginBlockStart: '0px' }}>
                            {/* <FormControl fullWidth> */}
                            <Autocomplete
                                value={LeaveName}
                                onChange={(e, newValue) => setLeaveName(newValue)}
                                options={LeaveType}
                                getOptionLabel={(option) => option?.discription || ""}
                                getOptionSelected={(option, value) => option._id === value}
                                renderInput={(params) => <TextField {...params} label="Attendance Type"
                                    inputProps={{ ...params.inputProps, 'aria-label': 'Select Department Type', style: { paddingTop: '0px' } }}
                                    // sx={{ "& .MuiInputBase-root": { height: '56px', marginTop: "10px" } }}    
                                    variant="outlined" size="small" />}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3} sx={{ marginBlockStart: '0px' }} >
                            <div style={{ display: 'flex', justifyContent: 'center', position: 'relative', padding: '10px' }}>
                                <TextField
                                    type="text"
                                    size="small"
                                    label="Select Range"
                                    value={`${formatDates(selectionRange.startDate)} - ${formatDates(selectionRange.endDate)}`}
                                    onFocus={toggleDateRangePicker}
                                    readOnly
                                    fullWidth
                                    InputProps={{
                                        disableUnderline: true, // Remove default underline
                                        style: { cursor: 'pointer', textAlign: 'center' } // Center-align text and change cursor to pointer when hovering
                                    }}
                                    InputLabelProps={{
                                        shrink: true, // Ensure the label doesn't float above the input when value is present
                                    }}
                                    onClick={toggleDateRangePicker} // Open DateRangePicker on click
                                />
                                {isOpen && (
                                    <div style={{ position: 'absolute', zIndex: 999, marginRight: '400px' }} ref={dateRangePickerRef}>
                                        <DateRangePicker
                                            ranges={[{ startDate: tempStartDate, endDate: tempEndDate, key: 'selection' }]}
                                            onChange={handleSelect}
                                        />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={applyDateRange}
                                            fullWidth
                                            style={{ marginTop: '10px' }}
                                        >
                                            Apply
                                        </Button>
                                    </div>
                                )}

                            </div>
                        </Grid>
                    </Grid>
                </div>

                <DataGrid
                    rows={dataWithSerialNumbers}
                    columns={columns}
                    initialState={{
                        ...dataWithSerialNumbers.initialState,
                        pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    apiRef={apiRef}

                    pageSizeOptions={[10, 25, 100, { label: "All", value: 99 }]}
                    pagination
                    paginationMode={paginationModel.pageSize === 99 ? undefined : 'server'}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    rowCount={totalRowCount}

                    autoHeight
                    checkboxSelection={misspunch}
                    onRowSelectionModelChange={handleSelectionModelChange}
                    rowSelectionModel={selectedRows}
                    disableRowSelectionOnClick={true}  // This is the correct property
                    density="compact"
                    loading={isLoading}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            csvOptions: { fileName: generateFileName() },
                        },
                    }}
                />
            </Box>
            {/* </Container> */}
        </div>
    );
};

export default OTReport;
