import React, { useState, useEffect } from 'react';
import { Select, MenuItem, Accordion, AccordionDetails, AccordionSummary, Grid, Chip, Box, Typography, Paper, Container, Divider, Button } from '@mui/material';
import MUIDataTable from "mui-datatables";
import Modal from '@mui/material/Modal';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress for the loader
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function UserList() {

    const [error, setError] = useState('');
    const [data, setdata] = useState([]);
    const [openModal, setOpenModal] = useState(false); // State to control the modal visibility
    const [deleteIndex, setDeleteIndex] = useState(null); // State to store the index of item to be deleted
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [ActionMessage, setActionMessage] = useState('');
    const [searchText, setSearchText] = useState(''); // State for search text
    const [editID, setEditID] = useState();
    const [isLoading, setIsLoading] = useState(true); // State variable to manage loading state

    const navigate = useNavigate();
    const handleErrorModalClose = () => {
        setErrorModalOpen(false);
    };

    const handleSuccessModalClose = () => {
        setSuccessModalOpen(false);
    };



    const UsersGET = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const masterid = userSchema?.masterid;
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/user_rights/UsersGET`,
                {
                    params: {
                        searchText,
                        masterid,
                        compid: JSON.parse(companyIdString),
                        divid: JSON.parse(divIdString)
                    },
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            console.log('API Response:', response.data.lastEntryNo);
            setdata(response.data.lastEntryNo)

        } catch (error) {
            console.error('Error fetching data:', error);
        }
        finally {
            setIsLoading(false)
        }
    };

    const handleClick = (_id, pp, e) => {
        // Data to send to TargetComponent
        const dataToSend = { id: _id };
        // localStorage.removeItem('StaffDetailEmpID');
        localStorage.setItem('UserRightsID', _id);
        localStorage.setItem('UserRightsName', pp);
        // navigate('/user_rights/user_rights_edit', { state: dataToSend });
        if (e.metaKey || e.ctrlKey) {
            const dataString = encodeURIComponent(JSON.stringify(dataToSend));
            const url = `/user_rights/user_rights_edit?data=${dataString}`;
            window.open(url, '_blank');
        } else {
            navigate('/user_rights/user_rights_edit', { state: dataToSend });
        }
    };

    useEffect(() => {
        UsersGET();
    }, []);

    return (
        <>
            <Container maxWidth="xl">
                <Paper elevation={3} style={{ padding: '20px', marginBottom: '5px', background: 'linear-gradient(135deg, #FFFFFF,#60a5f2,#FFFFFF)', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                        <div>
                            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: 'black' }}>
                                Users List
                            </Typography>
                        </div>
                        <Button style={{ marginLeft: '8px' }} variant="contained" color="success"
                            // onClick={()=>{navigate('/user_rights/UserRightsAddUser')}}
                            onClick={(e) => {
                                if (e.metaKey || e.ctrlKey) {
                                    const url = `/user_rights/UserRightsAddUser`;
                                    window.open(url, '_blank');
                                } else {
                                    navigate('/user_rights/UserRightsAddUser')
                                }
                            }}
                        >
                            Add User
                        </Button>
                    </div>
                </Paper>
                <Paper elevation={3} sx={{ padding: 3, marginBottom: 3 }}>
                    {isLoading ? (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        // height="100vh" // Adjust height as needed
                        >
                            <CircularProgress size={80} /> {/* Adjust size as needed */}
                        </Box>
                    ) : (
                        <Box>
                            {data.length === 0 ? (
                                <Typography variant="body1">No data found</Typography>
                            ) : (
                                <Accordion sx={{ marginBottom: 0 }} defaultExpanded={true}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography variant="h6" sx={{ marginRight: 1 }}>Users</Typography>
                                        <Chip label={data.length} color="default" />
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Box sx={{ width: '100%' }}>
                                            {data.map(user => (
                                                <Grid container key={user._id} alignItems="center" sx={{ marginBottom: 1 }}>
                                                    <Grid item xs={2}>
                                                        <Typography variant="body2" color="textSecondary">{user?.first_name}</Typography>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Typography variant="body2" color="textSecondary">{user?.last_name}</Typography>
                                                    </Grid>
                                                    <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                        <Typography variant="body1">{user?.usrnm}</Typography>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <Typography variant="body2" color="textSecondary">{user?.emailid}</Typography>
                                                    </Grid>
                                                    <Grid item xs={2} sx={{ textAlign: 'right' }}>
                                                        <Button variant="contained" color="primary"
                                                            onClick={(e) => { handleClick(user?._id, user?.usrnm, e) }}
                                                            sx={{ backgroundColor: '#2196f3', color: '#fff', borderRadius: '20px', padding: '10px 20px', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', '&:hover': { backgroundColor: '#0d8bf5' } }}
                                                        >
                                                            Edit
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                            )}
                        </Box>

                    )}
                </Paper>
            </Container>

        </>
    );
}

export default UserList;


