import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Modal from '@mui/material/Modal';
import MUIDataTable from "mui-datatables";
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid'; // Import DataGrid from MUI
import axios from 'axios';

function SalaryStrucList() {
  const [data, setdata] = useState([]);
  const [openModal, setOpenModal] = useState(false); // State to control the modal visibility
  const [deleteIndex, setDeleteIndex] = useState(null); // State to store the index of item to be deleted
  const [error, setError] = useState('');
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [actionMessage, setActionMessage] = useState('');
  const [searchText, setSearchText] = useState(''); // State for search text
  const [rowsPerPage, setRowsPerPage] = useState(10); // State for rows per page, default 10
  const [currentPage, setCurrentPage] = useState(); // State for current page, default 1
  const navigate = useNavigate();

  const handleSuccessModalClose = () => {
    setSuccessModalOpen(false);
  };

  const handleErrorModalClose = () => {
    setErrorModalOpen(false);
  };

  const handleTableChange = (action, tableState) => {
    if (action === 'search') {
      setSearchText(tableState.searchText);
    }
    if (action === 'changeRowsPerPage') {
      setRowsPerPage(tableState.rowsPerPage);
    }
    if (action === 'changePage') {
      console.log("-", tableState.page)
      setCurrentPage(tableState.page);
    }
  };

  const fetchData = async () => {
    try {
      const userSchemaString = localStorage.getItem('userSchema');
      const userSchema = JSON.parse(userSchemaString);
      const masterid = userSchema?.masterid;
      const companyIdString = localStorage.getItem('companyId');
      const divIdString = localStorage.getItem('divId');
      const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/salary_master/salary_strucListGET`,
        {
          params: {
            searchText,
            rowsPerPage,
            currentPage,
            masterid,
            compid: JSON.parse(companyIdString),
            divid: JSON.parse(divIdString)
          },
          headers: {
            authorization: `Bearer ${token}`
          }
        }
      );

      console.log('API Response:', response.data.lastEntryNo);
      setdata(response.data.lastEntryNo)

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData(); // Call fetchData when component mounts or when searchText, rowsPerPage, or currentPage changes
  }, [searchText, rowsPerPage, currentPage]);

  const options = {
    pagination: true,
    download: true,
    downloadOptions: {
      filename: 'data',
      separator: ',',
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    filter: false,
    page: currentPage,
    count: data.length,
    selectableRows: false,
    onTableChange: handleTableChange
  };
  const dataWithSerialNumbers = data.map((item, index) => ({
    ...item,
    serialNumber: index + 1, // Serial number starts from 1
    id: index + 1, // Serial number starts from 1
  }));

  const handleDelete = (INFO) => {
    setDeleteIndex(INFO._id);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleEdit = async (editdata) => {
    const dataToSend = { _id: editdata._id };
    console.log(editdata?._id)
    navigate('/HrMaster/salaryMaster/Salary_StrucEdit', { state: dataToSend });
  };

  const handleModalDelete = async () => {
    try {
      const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/salary_master/salary_StrucDELETE/${deleteIndex}`, {},
        {
          headers: {
            authorization: `Bearer ${token}`
          }
        });
      console.log('Delete API Response:', response.data);
      handleCloseModal();
      fetchData();
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  const columns = [
    { field: "serialNumber", headerName: "S. No.", flex: 1, },
    { field: "salarystru_name", headerName: "Salary Structure Name", flex: 1 },
    { field: "gratutity", headerName: "Gratutity", flex: 1 },
    { field: "bonus", headerName: "Bonus", flex: 1 },
    { field: "remarks", headerName: "Remarks", flex: 1 },
    {
      field: "edit",
      headerName: "Edit",
      flex: 1,
      renderCell: (params) =>  (
          <Button variant="text" color="primary" size='small'
            onClick={() => handleEdit(params.row)}
          ><EditIcon fontSize="small" /></Button>
        )
    },
    {
      field: "delete",
      flex: 1,
      headerName: "Delete",
      renderCell: (params) => (
          <Button variant="text" color="error" size='small'
            onClick={() => handleDelete(params.row)}
          ><DeleteIcon fontSize="small" /></Button>
        )
    }
  ];

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    border: '2px solid',
    boxShadow: 24,
    padding: 16,
    minWidth: 300,
    maxWidth: 500,
    textAlign: 'center'
  };
  return (
    <>
      <Container maxWidth="xl" style={{ marginTop: '25px', minHeight: '400px' }}>
        {/* <div style={{ display:'flex', justifyContent:'space-between',backgroundColor: '#007bff',padding:'10px'}}>
          <Typography variant="h6" sx={{ color: 'white', margin: '0',  padding: '10px' }}>
            
          </Typography>
          <Button variant='contained' color='success'  style={{ marginLeft: '8px', color: 'white', borderColor: 'white' }}>Add  Attendance</Button>

          </div> */}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5" sx={{ color: 'black', fontWeight: 'bold' }}>
            Employee Structure List
          </Typography>
          <Button variant='contained' color='success' onClick={() => navigate('/HrMaster/salaryMaster/SalaryStructure')} style={{ marginLeft: '8px', color: 'white', borderColor: 'white' }}>Add</Button>
        </div>
        {/* <MUIDataTable title={""} data={dataWithSerialNumbers} columns={columns} options={options} /> */}
        <DataGrid
          rows={dataWithSerialNumbers}
          columns={columns}
          initialState={{
            ...data.initialState,
            pagination: { paginationModel: { pageSize: 10 } },

          }}
          pageSizeOptions={[10, 25, 100]}
          pagination
          autoHeight
          checkboxSelection={false}
          disableSelectionOnClick
          density="compact"
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
        />
      </Container>
      <Modal
        open={successModalOpen}
        onClose={handleSuccessModalClose}
        aria-labelledby="success-modal-title"
        aria-describedby="success-modal-description"
      >
        <div style={{ ...modalStyle, borderColor: '#007bff' }}>
          <h2 id="success-modal-title" style={{ color: '#007bff' }}>Success!</h2>
          <p id="success-modal-description">{actionMessage}</p>
          <Button variant="contained" onClick={handleSuccessModalClose} style={{ backgroundColor: '#007bff', color: 'white' }}>Close</Button>
        </div>
      </Modal>

      <Modal
        open={errorModalOpen}
        onClose={handleErrorModalClose}
        aria-labelledby="error-modal-title"
        aria-describedby="error-modal-description"
      >
        <div style={{ ...modalStyle, borderColor: '#ff0000' }}>
          <h2 id="error-modal-title" style={{ color: '#ff0000' }}>Error!</h2>
          <p id="error-modal-description">{actionMessage}.</p>
          <Button variant="contained" onClick={handleErrorModalClose} style={{ backgroundColor: '#ff0000', color: 'white' }}>Close</Button>
        </div>
      </Modal>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, width: 400 }}>
          <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
            Confirm Deletion
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            Are you sure you want to delete this item?
          </Typography>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={handleCloseModal} color="primary" sx={{ mr: 2 }}>
              Cancel
            </Button>
            <Button onClick={handleModalDelete} variant="contained" color="error">
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default SalaryStrucList;

