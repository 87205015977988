import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import MUIDataTable from "mui-datatables";
import Modal from '@mui/material/Modal';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid'; // Import DataGrid from MUI
import PrintIcon from '@mui/icons-material/Print';

function LoanReqList() {

    const [data, setdata] = useState([]);
    const [openModal, setOpenModal] = useState(false); // State to control the modal visibility
    const [deleteIndex, setDeleteIndex] = useState(null); // State to store the index of item to be deleted
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [ActionMessage, setActionMessage] = useState('');
    const [searchText, setSearchText] = useState(''); // State for search text
    const [rowsPerPage, setRowsPerPage] = useState(10); // State for rows per page, default 10
    const [currentPage, setCurrentPage] = useState(1); // State for current page, default 1
    const [editID, setEditID] = useState();
    const [userRights, setUserRights] = useState(null); // Replaced state name
    const [administrator, setadministrator] = useState(false);
    const navigate = useNavigate();
    const [DivValues, setDivValues] = useState([]);

    const Division_masterGET = async () => {
        const userSchemaString = localStorage.getItem('userSchema');
        const userSchema = JSON.parse(userSchemaString);
        const companyIdString = localStorage.getItem('companyId');
        const divIdString = localStorage.getItem('divId');

        const data = {
            masterid: userSchema?.masterid
        };

        try {
            const token = localStorage.getItem('user-token');
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/user_rights/Division_masterGET`,
                {
                    params: {
                        masterid: userSchema?.masterid,
                        compid: JSON.parse(companyIdString),
                        divid: JSON.parse(divIdString),
                        getDiv: JSON.parse(divIdString)
                    },
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            setDivValues(response?.data?.lastEntryNo[0]);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        Division_masterGET();
    }, []);

    useEffect(() => {
        const userSchemaString = localStorage.getItem('userSchema');
        const userSchema = JSON.parse(userSchemaString);
        // setUserDetail(userSchema);
        setadministrator(userSchema?.administrator === "Yes" ? true : false);
        setUserRights(userSchema?.admin ? userSchema.admin.split(/,\s*/) : [])
    }, []);


    const handleErrorModalClose = () => {
        setErrorModalOpen(false);
    };

    const handleSuccessModalClose = () => {
        setSuccessModalOpen(false);
    };

    const handleDelete = (INFO) => {
        setDeleteIndex(INFO._id);
        setOpenModal(true);
    };

    const handleModalDelete = async () => {
        try {
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/loan_reqs/LoanReqDELETE/${deleteIndex}`, {}, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            });
            console.log('Delete API Response:', response.data);
            handleCloseModal();
            fetchData();
        } catch (error) {
            console.error('Error deleting item:', error);
        }
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };
    const handleTableChange = (action, tableState) => {
        if (action === 'search') {
            setSearchText(tableState.searchText);
        }
        if (action === 'changeRowsPerPage') {
            setRowsPerPage(tableState.rowsPerPage);
        }
        if (action === 'changePage') {
            setCurrentPage(tableState.page);
        }
    };
    const fetchData = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const masterid = userSchema?.masterid;
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/loan_reqs/LoanReqListGET`,
                {
                    params: {
                        searchText,
                        rowsPerPage,
                        currentPage,
                        masterid,
                        compid: JSON.parse(companyIdString),
                        divid: JSON.parse(divIdString),
                        emp: JSON.parse(localStorage.getItem('EmployeeIDD')) || null
                    },
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            console.log('API Response:', response.data.lastEntryNo);
            setdata(response.data.lastEntryNo)

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
        // }, [searchText, rowsPerPage, currentPage]);
    }, []);

    const CustomToolbar = () => {
        if (userRights?.includes("Loan Request List-D") || administrator) {
            return (
                <Button variant='contained' color='success' size='small' 
                // onClick={() => { navigate('/transaction/loan_req') }}
                onClick={(e) => {
                    if (e.metaKey || e.ctrlKey) {
                        const url = `/transaction/loan_req`;
                        window.open(url, '_blank');
                    } else {
                        navigate('/transaction/loan_req')
                    }
                }}
  
                >
                    Add Loan Request
                </Button>
            );
        }
        else {
            return
        }
    };

    const options = {
        pagination: true,
        download: true,
        customToolbar: () => <CustomToolbar />,
        downloadOptions: {
            filename: 'data',
            separator: ',',
            filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true,
            },
        },
        filter: false,
        selectableRows: false,
        onTableChange: handleTableChange
    };

    function convertToIST(dateString) {
        const utcDate = new Date(dateString);

        utcDate.setHours(utcDate.getHours() + 5); // Add 5 hours for IST
        utcDate.setMinutes(utcDate.getMinutes() + 30); // Add 30 minutes for IST

        const formattedDate = utcDate.toLocaleDateString('en-IN', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });

        return formattedDate;
    }

    const NewreqData = data.map(item => ({
        ...item,
        id: item._id,
        request_fromdate: convertToIST(item.request_fromdate),
        request_todate: convertToIST(item.request_todate),
        request_date: convertToIST(item.request_date),
        employee_name: item.employee_name?.full_name,
        leave_reason: item.leave_reason?.discription,
        request_typ: item.request_loan_purpose,
        request_interest: `${item.request_interest}%`
    }));

    const columns = [
        {
            field: "Print",
            headerName: "Print",
            flex: 1,
            renderCell: (params) => (
              <Button onClick={() => handlePrintRow(params)}>
                <PrintIcon sx={{ color: '#616161' }} />
              </Button>
            )
          },
        { field: "request_number", headerName: "Request No.", flex: 1, },
        { field: "employee_name", headerName: "Employee", flex: 1, },
        { field: "request_typ", headerName: "Loan Purpose", flex: 1, },
        { field: "request_date", headerName: "Request Date", flex: 1, },
        { field: "request_fromdate", headerName: "From", flex: 1, },
        { field: "request_todate", headerName: "To", flex: 1, },
        { field: "request_nos", headerName: "Nos", flex: 1, },
        { field: "request_amount", headerName: "Amount", flex: 1, },
        { field: "request_installment", headerName: "Installment", flex: 1, },
        { field: "request_interest", headerName: "Interest", flex: 1, },
        { field: "bank", headerName: "Bank", flex: 1, },
        { field: "cheque", headerName: "Cheque No.", flex: 1, },

        { field: "request_remarks", headerName: "Remarks", flex: 1, },
        { field: "leave_reason", headerName: "Reason", flex: 1, },
        { field: "status", headerName: "Status", flex: 1, }
    ];

    if (userRights?.includes("Loan Request List-E") || administrator) {
        columns.push({
            field: "edit",
            headerName: "Edit",
            flex: 1,
            renderCell: (params) => (
                <Button
                    variant="text"
                    color="primary"
                    size="small"
                    sx={{ padding: '0px' }}
                    onClick={(e) => handleEdit(params, e)}
                >
                    <EditIcon fontSize="small" />
                </Button>
            )
        });
    }

    if (userRights?.includes("Loan Request List-D") || administrator) {
        columns.push({
            field: "delete",
            headerName: "Delete",
            flex: 1,
            renderCell: (params) => (
                <Button
                    variant="text"
                    color="error"
                    size="small"
                    sx={{ padding: '0px' }}
                    onClick={() => handleDelete(params.row)}
                >
                    <DeleteIcon fontSize="small" />
                </Button>
            )
        });
    }

    // const columns = [
    //     { name: "request_number", label: "Request No." },
    //     { name: "employee_name", label: "Employee" },
    //     { name: "request_typ", label: "Request Type" },
    //     { name: "request_date", label: "Request Date" },
    //     { name: "request_fromdate", label: "From" },
    //     { name: "request_todate", label: "To" },
    //     { name: "request_nos", label: "Nos" },
    //     { name: "request_remarks", label: "Remarks" },
    //     { name: "leave_reason", label: "Leave Reason" },
    //     { name: "status", label: "Status" },
    //     {
    //         name: "edit",
    //         label: "Edit",
    //         options: {
    //             customBodyRenderLite: (dataIndex) => {
    //                 return (
    //                     <Button
    //                         variant="text"
    //                         color="primary"
    //                         size='small'
    //                         sx={{ padding: '0px' }}
    //                         onClick={() => handleEdit(data[dataIndex])}
    //                     >
    //                         <EditIcon fontSize="small" />
    //                     </Button>
    //                 );
    //             }
    //         }
    //     },
    //     {
    //         name: "delete",
    //         label: "Delete",
    //         options: {
    //             customBodyRenderLite: (dataIndex) => {
    //                 return (
    //                     <Button variant="text" color="error" size='small' sx={{ padding: '0px' }} onClick={() => handleDelete(data[dataIndex])}><DeleteIcon fontSize="small" /></Button>
    //                 );
    //             }
    //         }
    //     }
    // ];

    const handlePrintRow = (params) => {
        const rowIndex = params.api.getAllRowIds().indexOf(params.id);
        const datas = data[rowIndex];
        console.log('Printing row data:', datas);
    
        // Check if there are loans in loangroup
        const hasLoans = datas?.employee_name?.loangroup?.length > 0;
    
        // Generate table rows only if loans exist
        const rowsHTML = hasLoans
            ? datas.employee_name?.loangroup
                  .filter(loan => loan?.loanbalance > 0)
                  .map((loan, index) => `
                    <tr>
                        <td>${index + 1}</td>
                        <td>${new Date(loan?.loan_date)?.toLocaleDateString('en-GB')}</td>
                        <td>${loan.loandc}</td>
                        <td>₹ ${loan?.loanamount?.toLocaleString()}</td>
                        <td>₹ ${loan?.loaninstallment?.toLocaleString()}</td>
                        <td>₹ ${loan?.loanbalance?.toLocaleString()}</td>
                        <td>${loan?.loanremarks || 'N/A'}</td>
                    </tr>
                `).join('')
            : '';
    
        const printWindow = window.open('', '_blank');
        printWindow.document.write(`
            <!DOCTYPE html>
            <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>Loan Details</title>
                <style>
                    body {
                        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
                        background-color: #f9f9f9;
                        padding: 0;
                        margin: 0;
                    }
                    .loan-container {
                        width: 90%;
                        max-width: 800px;
                        margin: 30px auto;
                        padding: 20px;
                        background-color: #ffffff;
                        border-radius: 10px;
                        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                        border: 1px solid #ddd;
                    }
                    .header {
                        text-align: center;
                        margin-bottom: 20px;
                    }
                    .header h1 {
                        font-size: 30px;
                        color: #333;
                    }
                    .header p {
                        margin: 5px 0;
                        font-size: 16px;
                        color: #555;
                    }
                    .details-container {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 20px;
                    }
                    .loan-details, .loan-details-right {
                        width: 48%;
                        background-color: #f4f4f4;
                        padding: 10px;
                        border-radius: 8px;
                        font-size: 14px;
                        line-height: 1.6;
                    }
                    .loan-details strong, .loan-details-right strong {
                        font-size: 16px;
                        color: #333;
                    }
                    .loan-items, .loan-summary {
                        width: 100%;
                        margin-top: 20px;
                        border-collapse: collapse;
                        background-color: #ffffff;
                    }
                    .loan-items th, .loan-items td {
                        border: 1px solid #ddd;
                        padding: 10px;
                        text-align: left;
                    }
                    .loan-items th {
                        background-color: #444;
                        color: white;
                    }
                    .loan-summary td {
                        padding: 10px;
                        font-size: 16px;
                        font-weight: bold;
                    }
                    .terms {
                        margin-top: 30px;
                        font-size: 14px;
                        text-align: center;
                    }
                    .terms p {
                        margin: 0;
                    }
                    .loan-history-heading {
                        margin-top: 20px;
                        font-size: 22px;
                        text-align: left;
                        color: #333;
                        font-weight: bold;
                    }
                </style>
            </head>
            <body>
                <div class="loan-container">
                    <div class="header">
                        <h1>${DivValues?.div_mast}</h1>
                    </div>
    
                    <div class="details-container">
                        <div class="loan-details">
                            <h2>Employee Information</h2>
                            <p><strong>Employee Name:</strong> ${datas?.employee_name?.full_name}</p>
                            <p><strong>Loan Request No:</strong> ${datas?.request_number}</p>
                            <p><strong>Loan Purpose:</strong> ${datas?.request_loan_purpose || 'N/A'}</p>
                            <p><strong>Status:</strong> ${datas?.status}</p>
                        </div>
                        <div class="loan-details loan-details-right">
                            <h2>Loan Request Details</h2>
                            <p><strong>Guarantor:</strong> ${datas?.guarantors[0]?.full_name || 'N/A'}</p>
                            <p><strong>Request Date:</strong> ${new Date(datas?.request_date)?.toLocaleDateString('en-GB')}</p>
                            <p><strong>From Date:</strong> ${new Date(datas?.request_fromdate)?.toLocaleDateString('en-GB')}</p>
                            <p><strong>To Date:</strong> ${new Date(datas?.request_todate)?.toLocaleDateString('en-GB')}</p>
                        </div>
                    </div>
    
                    ${hasLoans ? `
                        <div class="loan-history-heading">Loan History</div>
                        <table class="loan-items">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Date</th>
                                    <th>Loan DC</th>
                                    <th>Loan Amount (₹)</th>
                                    <th>Installment (₹)</th>
                                    <th>Balance (₹)</th>
                                    <th>Remarks</th>
                                </tr>
                            </thead>
                            <tbody>
                                ${rowsHTML}
                            </tbody>
                        </table>
                    ` : ''}
    
                    <table class="loan-summary">
                        <tr>
                            <td><strong>Loan Amount:</strong></td>
                            <td>₹ ${datas?.request_amount}</td>
                        </tr>
                        <tr>
                            <td><strong>Installment Amount:</strong></td>
                            <td>₹ ${datas?.request_installment}</td>
                        </tr>
                        <tr>
                            <td><strong>Bank:</strong></td>
                            <td>₹ ${datas?.bank || 'N/A'}</td>
                        </tr>
                        <tr>
                            <td><strong>Cheque No.:</strong></td>
                            <td>₹ ${datas?.cheque || 'N/A'}</td>
                        </tr>
                        <tr>
                            <td><strong>Interest:</strong></td>
                            <td>${datas?.request_interest}%</td>
                        </tr>
                        <tr>
                            <td><strong>Checked By:</strong></td>
                            <td><strong>Approved By:</strong></td>
                        </tr>
                    </table>
                </div>
            </body>
            </html>
        `);
        printWindow.document.close();
    };
    
    const handleEdit = (params, e) => {
        const rowIndex = params.api.getAllRowIds().indexOf(params.id); // Using getAllRowIds to get the index of the row by id
        const dataToSend = { data: data[rowIndex] };
        // navigate('/transaction/LoanReqEdit', { state: dataToSend });
        if (e.metaKey || e.ctrlKey) {
            const dataString = encodeURIComponent(JSON.stringify(dataToSend));
            const url = `/transaction/LoanReqEdit?data=${dataString}`;
            window.open(url, '_blank'); 
        } else {
            navigate('/transaction/LoanReqEdit', { state: dataToSend });
        }
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        border: '2px solid',
        boxShadow: 24,
        padding: 16,
        minWidth: 300,
        maxWidth: 500,
        textAlign: 'center'
    };
    return (
        <>
            <Container maxWidth="xl" style={{minHeight: '400px' }}>
            <Box sx={{ padding: '10px', marginBottom: '20px' }}>
                {/* <MUIDataTable title={<span style={{ fontWeight: 'bold', fontSize: '23px' }}>Loan Requests</span>} data={NewreqData} columns={columns} options={options} /> */}

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h5" sx={{ color: 'black', fontWeight: 'bold' }}>
                        Loan Requests
                    </Typography>
                    {(userRights?.includes("Leave Request List-D") || administrator) && (
                        <Button variant='contained' color='success' size='small' onClick={() => { navigate('/transaction/loan_req') }}>
                            Add Loan Request
                        </Button>
                    )}
                </div>

                <DataGrid
                    rows={NewreqData}
                    columns={columns}
                    initialState={{
                        ...NewreqData.initialState,
                        pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[10, 25, 100]}
                    pagination
                    autoHeight
                    checkboxSelection={false}
                    disableSelectionOnClick
                    density="compact"
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                        },
                    }}
                />
            </Box>
            </Container>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, width: 400 }}>
                    <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
                        Confirm Deletion
                    </Typography>
                    <Typography id="modal-description" sx={{ mt: 2 }}>
                        Are you sure you want to delete this item?
                    </Typography>
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={handleCloseModal} color="primary" sx={{ mr: 2 }}>
                            Cancel
                        </Button>
                        <Button onClick={handleModalDelete} variant="contained" color="error">
                            Delete
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={successModalOpen}
                onClose={handleSuccessModalClose}
                aria-labelledby="success-modal-title"
                aria-describedby="success-modal-description"
            >
                <div style={{ ...modalStyle, borderColor: '#007bff' }}>
                    <h2 id="success-modal-title" style={{ color: '#007bff' }}>Success!</h2>
                    <p id="success-modal-description">{ActionMessage}</p>
                    <Button variant="contained" onClick={handleSuccessModalClose} style={{ backgroundColor: '#007bff', color: 'white' }}>Close</Button>
                </div>
            </Modal>

            <Modal
                open={errorModalOpen}
                onClose={handleErrorModalClose}
                aria-labelledby="error-modal-title"
                aria-describedby="error-modal-description"
            >
                <div style={{ ...modalStyle, borderColor: '#ff0000' }}>
                    <h2 id="error-modal-title" style={{ color: '#ff0000' }}>Error!</h2>
                    <p id="error-modal-description">{ActionMessage}.</p>
                    <Button variant="contained" onClick={handleErrorModalClose} style={{ backgroundColor: '#ff0000', color: 'white' }}>Close</Button>
                </div>
            </Modal>
        </>
    );
}

export default LoanReqList;
