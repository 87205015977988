import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

function Nav() {
    const [securityList, setSecurityList] = useState([]);
    const location = useLocation();
    const Navigate = useNavigate();

    const user_rights_securityGET = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
    
            const data = {
                user: userSchema?.usrnm,
                compid: JSON.parse(companyIdString),
                divid: JSON.parse(divIdString),
                masterid: userSchema?.masterid
            }
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/user_rights/user_rights_securityGET`, data,
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );
    
            setSecurityList(response.data.lastEntryNo);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        user_rights_securityGET();
    }, []);

    useEffect(() => {
        if (securityList.length > 0 && !location.pathname.includes('_id')) {
            const firstItem = securityList[0]; // Get the first item
            const { _id, right_name } = firstItem; // Destructure _id and right_name from the first item
            const url = `${location.pathname}?_id=${_id}&right_name=${encodeURIComponent(right_name)}`; // Construct the URL
            Navigate(url); 
        }
    }, [securityList]); 

    const handleItemClick = (_id, right_name) => {
        const url = `${location.pathname}?_id=${_id}&right_name=${right_name}`;
        Navigate(url);
    };
    

    return (
        <Paper elevation={3} style={{ padding: '20px', marginBottom: '10px', borderRadius: '10px' }}>
            <List style={{ maxWidth: '100%', overflowX: 'auto' }}>
                {securityList.map((item) => (
                    <ListItem
                        key={item?._id}
                        button
                        onClick={() => handleItemClick(item?._id, item?.right_name)}
                        sx={{
                            borderRadius: '10px',
                            '&:hover': {
                                backgroundColor: 'inherit',
                            },
                            '&.active': {
                                backgroundColor: '#2196F4',
                                color: '#fff',
                            },
                            backgroundColor: location.search.includes(`_id=${item?._id}`) ? '#2196F4' : 'inherit',
                            color: location.search.includes(`_id=${item?._id}`) ? '#fff' : 'inherit'
                        }}
                        className={location.search.includes(`_id=${item?._id}`) ? 'active' : ''}
                    >
                        <ListItemText primary={item?.right_name} />
                    </ListItem>
                ))}
            </List>
        </Paper>
    );
}

export default Nav;
