import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import MUIDataTable from "mui-datatables";
import Modal from '@mui/material/Modal';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid'; // Import DataGrid from MUI

function Security() {
    const [shiftName, setShiftName] = useState(''); // Updated state variable
    const [inTime, setInTime] = useState(''); // Updated state variable
    const [outTime, setOutTime] = useState(''); // Updated state variable
    const [totalHours, setTotalHours] = useState(''); // Updated state variables
    const [Sno, setSno] = useState(''); // Updated state variable
    const [RightName, setRightName] = useState(''); // Updated state variable
    const [RightDescription, setRightDescription] = useState(''); // Updated state variable

    const [error, setError] = useState('');
    const [data, setdata] = useState([]);
    const [openModal, setOpenModal] = useState(false); // State to control the modal visibility
    const [deleteIndex, setDeleteIndex] = useState(null); // State to store the index of item to be deleted
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [ActionMessage, setActionMessage] = useState('');
    const [searchText, setSearchText] = useState(''); // State for search text
    const [rowsPerPage, setRowsPerPage] = useState(10); // State for rows per page, default 10
    const [currentPage, setCurrentPage] = useState(1); // State for current page, default 1
    const [editID, setEditID] = useState();
    const handleSubmit = (event) => {
        event.preventDefault();
        if (RightName.trim() === '') {
            setError('Right Name cannot be empty');
            return;
        }
        if (RightDescription.trim() === '') {
            setError('Right Description cannot be empty');
            return;
        } else {
            setError('');
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const dataToSend = {
                right_name: RightName,
                right_desc: RightDescription,
                sno: Sno,
                usrnm: userSchema?.usrnm,
                compid: JSON.parse(companyIdString),
                divid: JSON.parse(divIdString),
                masterid: userSchema?.masterid,
                _id: editID ? editID : null
            };

            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            axios.post(`${process.env.REACT_APP_API_URL}/user_rights/securityAdd`, dataToSend, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            })
                .then(response => {
                    console.log(response)
                    fetchData()
                    if (response.data.status) {
                        setActionMessage(response.data.message)
                        setSuccessModalOpen(true);
                        setSno('');
                        setRightName('');
                        setRightDescription('');
                        setEditID('');
                    } else {
                        setActionMessage(response.data.message)
                        setSno('');
                        setRightName('');
                        setRightDescription('');
                        setEditID('');
                        setErrorModalOpen(true)
                    }
                })
                .catch(error => {
                    console.log(error)
                    setActionMessage("Ooops an error occured.")
                    setErrorModalOpen(true)
                });

        }
    };

    const handleErrorModalClose = () => {
        setErrorModalOpen(false);
    };

    const handleSuccessModalClose = () => {
        setSuccessModalOpen(false);
    };


    const handleCancel = () => {
        setSno('');
        setRightName('');
        setRightDescription('');
    };

    const handleDelete = (INFO) => {
        setDeleteIndex(INFO._id);
        setOpenModal(true);
    };

    const handleModalDelete = async () => {
        try {
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/user_rights/securityDELETE/${deleteIndex}`, {}, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            });
            console.log('Delete API Response:', response.data);
            handleCloseModal();
            fetchData();
        } catch (error) {
            console.error('Error deleting item:', error);
        }
    };


    const handleCloseModal = () => {
        setOpenModal(false);
    };
    const handleTableChange = (action, tableState) => {
        if (action === 'search') {
            setSearchText(tableState.searchText);
        }
        if (action === 'changeRowsPerPage') {
            setRowsPerPage(tableState.rowsPerPage);
        }
        if (action === 'changePage') {
            setCurrentPage(tableState.page);
        }
    };
    const fetchData = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const masterid = userSchema?.masterid;
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/user_rights/securityGET`,
                {
                    params: {
                        searchText,
                        rowsPerPage,
                        currentPage,
                        masterid,
                        compid: JSON.parse(companyIdString),
                        divid: JSON.parse(divIdString)
                    },
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            console.log('API Response:', response.data.lastEntryNo);
            setdata(response.data.lastEntryNo)

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const UsersGET = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const masterid = userSchema?.masterid;
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/user_rights/UsersGET`,
                {
                    params: {
                        searchText,
                        rowsPerPage,
                        currentPage,
                        masterid,
                        compid: JSON.parse(companyIdString),
                        divid: JSON.parse(divIdString)
                    },
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            console.log('API Response:', response.data.lastEntryNo);
            // setdata(response.data.lastEntryNo)

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        UsersGET();
        fetchData(); // Call fetchData when component mounts or when searchText, rowsPerPage, or currentPage changes
        // }, [searchText, rowsPerPage, currentPage]);
    }, []);



    const options = {
        pagination: true,
        download: true,
        downloadOptions: {
            filename: 'data',
            separator: ',',
            filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true,
            },
        },
        filter: false,
        selectableRows: false,
        onTableChange: handleTableChange
    };
    const dataWithSerialNumbers = data.map((item, index) => ({
        ...item,
        id: index + 1, // Serial number starts from 1
        serialNumber: index + 1, // Serial number starts from 1
    }));

    const columns = [
        { field: "sno", headerName: "S. No.", flex: 1, }, // Serial number column
        { field: "right_name", headerName: "Right Name", flex: 1, },
        { field: "right_desc", headerName: "Right Description", flex: 1, },
        {
            field: "edit",
            headerName: "Edit",
            flex: 1,
            renderCell: (params) => (
                <Button variant="text" color="primary" size='small'
                    onClick={() => handleEdit(params.row)}
                ><EditIcon fontSize="small" /></Button>
            )
        },
        // {
        //     name: "delete",
        //     label: "Delete",
        //     options: {
        //         customBodyRenderLite: (dataIndex) => {
        //             return (
        //                 <Button variant="contained" color="error" onClick={() => handleDelete(data[dataIndex])}>Delete</Button>
        //             );
        //         }
        //     }
        // }
    ];

    const handleEdit = (editdata) => {
        setSno(editdata.sno);
        setRightName(editdata.right_name);
        setRightDescription(editdata.right_desc);
        setEditID(editdata._id);
        window.scrollTo(0, 0); // Scroll to the top of the page
    };
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        border: '2px solid',
        boxShadow: 24,
        padding: 16,
        minWidth: 300,
        maxWidth: 500,
        textAlign: 'center'
    };
    return (
        <>
            <Container maxWidth="xl" style={{ marginTop: '25px' }}>
                <Box sx={{ border: '1px solid black', padding: '20px', height: '320px', margin: '-1px', display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ backgroundColor: '#007bff', padding: '10px', marginBottom: '20px' }}>
                        <Typography variant="h6" sx={{ color: 'white', margin: '0' }}>
                            Security
                        </Typography>
                    </Box>
                    <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: '10px', marginBottom: '10px', width: '350px' }}>
                            S. No:
                        </Typography>
                        <input
                            type="number"
                            value={Sno}
                            onChange={(e) => setSno(e.target.value)}
                            style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', flex: '0.5' }}
                        />
                    </div>

                    <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: '10px', marginBottom: '10px', width: '350px' }}>
                            Right Name:
                        </Typography>
                        <input
                            type="text"
                            value={RightName}
                            onChange={(e) => setRightName(e.target.value)}
                            style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', flex: '0.5' }}
                        />
                    </div>

                    <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: '10px', marginBottom: '10px', width: '350px' }}>
                            Authorization setup (Y/N):
                        </Typography>
                        <input
                            type="text"
                            value={RightDescription}
                            onChange={(e) => setRightDescription(e.target.value)}
                            style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', flex: '0.5' }}
                        />
                    </div>
                    {error && (
                        <Typography variant="body2" sx={{ color: 'red', marginBottom: '10px', width: '100%', textAlign: 'center' }}>
                            {error}
                        </Typography>
                    )}
                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '12px' }}>
                        <Button type="submit" variant="contained" color="success" onClick={handleSubmit} sx={{ marginRight: '10px' }}>
                            Submit
                        </Button>
                        <Button variant="contained" onClick={handleCancel}>
                            Cancel
                        </Button>
                    </div>
                </Box>
            </Container>
            <Container maxWidth="xl" style={{ marginTop: '25px', minHeight: '400px' }}>
                <Typography variant="h5" sx={{ color: 'black', fontWeight: 'bold' }}>
                    Security List
                </Typography>
                {/* <MUIDataTable title={""} data={dataWithSerialNumbers} columns={columns} options={options} /> */}
                <DataGrid
                    rows={dataWithSerialNumbers}
                    columns={columns}
                    initialState={{
                        ...dataWithSerialNumbers.initialState,
                        pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[10, 25, 100]}
                    pagination
                    autoHeight
                    checkboxSelection={false}
                    disableSelectionOnClick
                    density="compact"
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                        },
                    }}
                />
            </Container>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, width: 400 }}>
                    <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
                        Confirm Deletion
                    </Typography>
                    <Typography id="modal-description" sx={{ mt: 2 }}>
                        Are you sure you want to delete this item?
                    </Typography>
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={handleCloseModal} color="primary" sx={{ mr: 2 }}>
                            Cancel
                        </Button>
                        <Button onClick={handleModalDelete} variant="contained" color="error">
                            Delete
                        </Button>
                    </Box>
                </Box>
            </Modal>


            <Modal
                open={successModalOpen}
                onClose={handleSuccessModalClose}
                aria-labelledby="success-modal-title"
                aria-describedby="success-modal-description"
            >
                <div style={{ ...modalStyle, borderColor: '#007bff' }}>
                    <h2 id="success-modal-title" style={{ color: '#007bff' }}>Success!</h2>
                    <p id="success-modal-description">{ActionMessage}</p>
                    <Button variant="contained" onClick={handleSuccessModalClose} style={{ backgroundColor: '#007bff', color: 'white' }}>Close</Button>
                </div>
            </Modal>

            <Modal
                open={errorModalOpen}
                onClose={handleErrorModalClose}
                aria-labelledby="error-modal-title"
                aria-describedby="error-modal-description"
            >
                <div style={{ ...modalStyle, borderColor: '#ff0000' }}>
                    <h2 id="error-modal-title" style={{ color: '#ff0000' }}>Error!</h2>
                    <p id="error-modal-description">{ActionMessage}.</p>
                    <Button variant="contained" onClick={handleErrorModalClose} style={{ backgroundColor: '#ff0000', color: 'white' }}>Close</Button>
                </div>
            </Modal>



        </>
    );
}

export default Security;
