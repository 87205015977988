// import React from 'react';
// import { Typography, Container, Grid, Box, Divider } from '@mui/material';
// import { FaFacebook, FaYoutube, FaTwitter, FaLinkedin, FaEnvelope, FaInstagram } from 'react-icons/fa';

// const PrivacyPolicy = () => {
//     return (
//         <>
//             <Box
//                 sx={{
//                     position: 'fixed',
//                     top: 0,
//                     width: '100%',
//                     height: '100px',
//                     backgroundColor: 'whitesmoke',
//                     zIndex: 1000,
//                     display: 'flex',
//                     justifyContent: 'center',
//                     alignItems: 'center',
//                 }}
//             >
//                 <Box component="nav">
//                     <ul
//                         style={{
//                             listStyle: 'none',
//                             display: 'flex',
//                             justifyContent: 'center',
//                             padding: 0,
//                             margin: 0,
//                         }}
//                     >
//                         {['home', 'about', 'services', 'contact'].map((section) => (
//                             <li key={section} style={{ margin: '0 15px' }}>
//                                 <a
//                                     href={'/homepage'}
//                                     style={{
//                                         color: 'black',
//                                         textDecoration: 'none',
//                                         padding: '14px 20px',
//                                         display: 'block',
//                                         cursor: 'pointer',
//                                     }}
//                                 >
//                                     {section.charAt(0).toUpperCase() + section.slice(1)}
//                                 </a>
//                             </li>
//                         ))}
//                         <li style={{ margin: '0 15px' }}>
//                             <a
//                                 href="/homepage"
//                                 style={{
//                                     color: '#035afc',
//                                     fontWeight: 'bold',
//                                     textDecoration: 'none',
//                                     padding: '14px 20px',
//                                     display: 'block',
//                                     cursor: 'pointer',
//                                 }}
//                             >
//                                 Login / SignUp
//                             </a>
//                         </li>
//                         <li style={{ margin: '0 15px' }}>
//                             <a
//                                 href="/homepage"
//                                 style={{
//                                     color: 'white',
//                                     backgroundColor: '#035afc',
//                                     borderRadius: '10px',
//                                     textDecoration: 'none',
//                                     padding: '14px 20px',
//                                     display: 'block',
//                                     cursor: 'pointer',
//                                 }}
//                             >
//                                 Request Callback
//                             </a>
//                         </li>
//                     </ul>
//                 </Box>
//             </Box>

//             <Container maxWidth="xl" sx={{ marginTop: '120px', padding: '20px 0' }}>
//                 <Typography variant="h4" gutterBottom>
//                     Privacy Policy
//                 </Typography>
//                 <Typography variant="body1" paragraph>
//                     Your privacy is important to us. It is Gyankaar Technologies Pvt Ltd's policy to respect your privacy regarding any information we may collect from you across our website, [website address], and other sites we own and operate.
//                 </Typography>
//                 <Typography variant="body1" paragraph>
//                     We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.
//                 </Typography>
//                 <Typography variant="body1" paragraph>
//                     We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use, or modification.
//                 </Typography>
//                 <Typography variant="body1" paragraph>
//                     We don’t share any personally identifying information publicly or with third parties, except when required to by law.
//                 </Typography>
//                 <Typography variant="body1" paragraph>
//                     Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites and cannot accept responsibility or liability for their respective privacy policies.
//                 </Typography>
//                 <Typography variant="body1" paragraph>
//                     You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.
//                 </Typography>
//                 <Typography variant="body1" paragraph>
//                     Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.
//                 </Typography>
//                 <Typography variant="body1" paragraph>
//                     This policy is effective as of 1 January 2024.
//                 </Typography>

//                 <Grid container spacing={2} sx={{ textAlign: 'center', marginTop: '40px' }}>
//                     <Grid item xs={12} md={4}>
//                         <Typography variant="h5">Stafficity</Typography>
//                         <Typography sx={{ fontSize: '12px' }}>Gyankaar Technologies Pvt Ltd.</Typography>
//                         <Typography sx={{ fontSize: '12px' }}>
//                             3rd Floor, #1545, 19th Main Rd, opp. to BMTC Bus depot,
//                         </Typography>
//                         <Typography sx={{ fontSize: '12px' }}>1st Sector, HSR Layout, Bengaluru, Karnataka, 560102</Typography>
//                     </Grid>
//                     <Grid item xs={12} md={4}>
//                         <Typography variant="h5">Find us on</Typography>
//                         <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
//                             {[
//                                 { href: 'https://www.facebook.com', icon: FaFacebook },
//                                 { href: 'https://www.youtube.com', icon: FaYoutube },
//                                 { href: 'https://www.twitter.com', icon: FaTwitter },
//                                 { href: 'https://www.linkedin.com', icon: FaLinkedin },
//                                 { href: 'mailto:contact@stafficity.com', icon: FaEnvelope },
//                                 { href: 'https://www.instagram.com', icon: FaInstagram },
//                             ].map((social, index) => (
//                                 <a
//                                     key={index}
//                                     href={social.href}
//                                     style={{ margin: '0 10px', textDecoration: 'none', color: '#333' }}
//                                 >
//                                     {React.createElement(social.icon, { size: 30 })}
//                                 </a>
//                             ))}
//                         </Box>
//                         <Typography sx={{ fontWeight: 'bold' }}>contact@stafficity.com</Typography>
//                     </Grid>
//                     <Grid item xs={12} md={4}>
//                         <Typography variant="h5">Other Links</Typography>
//                         <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
//                             <a
//                                 href="/privacy-policy"
//                                 style={{
//                                     color: 'black',
//                                     textDecoration: 'none',
//                                     padding: '14px 20px',
//                                     display: 'block',
//                                     cursor: 'pointer',
//                                 }}
//                             >
//                                 Privacy Policy
//                             </a>
//                             <a
//                                 href="/terms-conditions"
//                                 style={{
//                                     color: 'black',
//                                     textDecoration: 'none',
//                                     padding: '14px 20px',
//                                     display: 'block',
//                                     cursor: 'pointer',
//                                 }}
//                             >
//                                 Terms & Conditions
//                             </a>
//                         </Box>
//                     </Grid>
//                 </Grid>
//                 <Divider sx={{ padding: '10px' }} />
//                 <Box sx={{ marginTop: '20px', textAlign: 'left' }}>
//                     <Typography sx={{ fontWeight: 'bold' }}>
//                         Need assistance? We're here to help. (Monday to Sunday, 8am - 8pm)
//                     </Typography>
//                     <Typography>
//                         Call Us: 08095332013 &nbsp;&nbsp; | &nbsp;&nbsp; WhatsApp: +91 8095332013
//                     </Typography>
//                 </Box>
//                 <Box sx={{ textAlign: 'left', marginTop: '20px' }}>
//                     <Typography>Copyrights 2024, Gyankaar Technologies Pvt Ltd</Typography>
//                 </Box>
//             </Container>
//         </>
//     );
// };

// export default PrivacyPolicy;
// import React from 'react';
// import { Link } from 'react-scroll';
// import { FaFacebook, FaYoutube, FaTwitter, FaLinkedin, FaEnvelope, FaInstagram } from 'react-icons/fa';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';

// const homepage = () => {
//   return (
//     <div>
//       <nav style={{
//         position: 'fixed',
//         top: 0,
//         width: '100%',
//         height:'100px',
//         backgroundColor: 'whitesmoke',
//         zIndex: 1000,
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems:'center'
//       }}>
//         <ul style={{
//           listStyle: 'none',
//           display: 'flex',
//           justifyContent: 'center',
//           padding: 0,
//           margin: 0,
//         }}>
//           <li style={{ margin: '0 15px' }}>
//             <Link to="home" smooth={true} duration={500} style={{
//               color: 'black',
//               textDecoration: 'none',
//               padding: '14px 20px',
//               display: 'block',
//               cursor: 'pointer',
//             }}>
//               Home
//             </Link>
//           </li>
//           <li style={{ margin: '0 15px' }}>
//             <Link to="about" smooth={true} duration={500} style={{
//               color: 'black',
//               textDecoration: 'none',
//               padding: '14px 20px',
//               display: 'block',
//               cursor: 'pointer',
//             }}>
//               About
//             </Link>
//           </li>
//           <li style={{ margin: '0 15px' }}>
//             <Link to="services" smooth={true} duration={500} style={{
//               color: 'black',
//               textDecoration: 'none',
//               padding: '14px 20px',
//               display: 'block',
//               cursor: 'pointer',
//             }}>
//               Services
//             </Link>
//           </li>
//           <li style={{ margin: '0 15px' }}>
//             <Link to="contact" smooth={true} duration={500} style={{
//               color: 'black',
//               textDecoration: 'none',
//               padding: '14px 20px',
//               display: 'block',
//               cursor: 'pointer',
//             }}>
//               Contact
//             </Link>
//           </li>

//           <li style={{ margin: '0 15px' }}>
//             <Link to="contact" smooth={true} duration={500} style={{
//               color: '#035afc',
//               fontWeight:'bold',
//               textDecoration: 'none',
//               padding: '14px 20px',
//               display: 'block',
//               cursor: 'pointer',
//             }}>
//               Login / SignUp
//             </Link>
//           </li>

//           <li style={{ margin: '0 15px' }}>
//             <Link to="callback" smooth={true} duration={500} style={{
//               color: 'white',
//               backgroundColor:'#035afc',
//               borderRadius:'10px',
//               textDecoration: 'none',
//               padding: '14px 20px',
//               display: 'block',
//               cursor: 'pointer',
//             }}>
//               Request Callback
//             </Link>
//           </li>
//         </ul>
//       </nav>
//       <Section id="home" title="Home" style={{ backgroundColor: 'white' }}>
//         <p>Welcome to our website! We are glad to have you here.</p>
//       </Section>
//       <Section id="about" title="About" style={{ backgroundColor: 'white' }}>
//         <p>We are a company dedicated to providing the best services in the industry. Our team is passionate and driven to achieve excellence.</p>
//       </Section>
//       <Section id="services" title="Services" style={{ backgroundColor: 'white' }}>
//         <p>We offer a wide range of services to meet your needs, including consulting, development, and support.</p>
//       </Section>
//       <Section id="callback" title="Request Callback" style={{ backgroundColor: 'white' }}>
//       <div style={{ display:'flex', justifyContent:'space-evenly', backgroundColor:'whitesmoke',padding: '60px', height: '50px' }}>
//         <div> 
//         <Typography sx={{fontSize:'25px'}}>Get in touch with us now!</Typography>
//         </div>
//         <div>
//             <Button variant='contained' size='large' sx={{backgroundColor:'#035afc'}}>
//                 Request Callback
//             </Button>
//         </div>
//         </div>
//       </Section>
//       <Section id="contact" title="Contact" style={{ backgroundColor: 'white' }}>
//         <ContactContent />
//       </Section>
//     </div>
//   );
// };

// const Section = ({ id, title, style, children }) => {
//   return (
//     <div id={id} style={{ padding: '60px 20px', height: 'auto', ...style }}>
//       {/* <h1>{title}</h1> */}
//       {children}
//     </div>
//   );
// };

// const ContactContent = () => {
//   return (
//     <>
//       <div style={{
//         display: 'flex',
//         justifyContent: 'space-between',
//         alignItems: 'center',
//         textAlign: 'center',
//       }}>
//         <div style={{ marginBottom: '20px' }}>
//           <h2>stafflicity</h2>
//           <p style={{fontSize:'12px'}}>Gyankaar Technologies Pvt Ltd.</p>
//           <p style={{fontSize:'12px'}}>3rd Floor, #1545, 19th Main Rd, opp. to BMTC Bus depot,</p>
//           <p style={{fontSize:'12px'}}>1st Sector, HSR Layout, Bengaluru, Karnataka, 560102</p>
//         </div>
//         <div style={{ marginBottom: '20px' }}>
//           <h2>Find us on</h2>
//           <div style={{
//             display: 'flex',
//             justifyContent: 'center',
//             marginBottom: '10px',
//           }}>
//             <a href="https://www.facebook.com" style={{ margin: '0 10px', textDecoration: 'none', color: '#333' }}>
//               <FaFacebook size={30} />
//             </a>
//             <a href="https://www.youtube.com" style={{ margin: '0 10px', textDecoration: 'none', color: '#333' }}>
//               <FaYoutube size={30} />
//             </a>
//             <a href="https://www.twitter.com" style={{ margin: '0 10px', textDecoration: 'none', color: '#333' }}>
//               <FaTwitter size={30} />
//             </a>
//             <a href="https://www.linkedin.com" style={{ margin: '0 10px', textDecoration: 'none', color: '#333' }}>
//               <FaLinkedin size={30} />
//             </a>
//             <a href="mailto:contact@stafflicity.com" style={{ margin: '0 10px', textDecoration: 'none', color: '#333' }}>
//               <FaEnvelope size={30} />
//             </a>
//             <a href="https://www.instagram.com" style={{ margin: '0 10px', textDecoration: 'none', color: '#333' }}>
//               <FaInstagram size={30} />
//             </a>

//           </div>
//           <p>contact@stafflicity.com</p>
//         </div>
//         <div style={{ marginBottom: '20px' }}>
//           <h2>Other Links</h2>
//           <div style={{ display:'flex', justifyContent:'space-evenly' }}>
//           <a href="/privacy-policy" style={{
//             color: 'black',
//             textDecoration: 'none',
//             padding: '14px 20px',
//             display: 'block',
//             cursor: 'pointer',
//           }}>Privacy Policy</a>
//           <a href="/terms-conditions" style={{
//             color: 'black',
//             textDecoration: 'none',
//             padding: '14px 20px',
//             display: 'block',
//             cursor: 'pointer',
//           }}>Terms & Conditions</a>
//         </div>
//         </div>
//       </div>
//       <div style={{ marginBottom: '20px' }}>
//         <p style={{fontWeight:'bold'}}>Need assistance? We're here to help. (Monday to Sunday, 8am - 8pm)</p>
//         <p >Call Us: 08095332013 &nbsp;&nbsp; | &nbsp;&nbsp; WhatsApp: +91 8095332013</p>
//       </div>
//       <div style={{ marginTop: '20px' }}>
//         <p>Copyrights 2024, Gyankaar Technologies Pvt Ltd</p>
//       </div>
//     </>
//   );
// };

// export default homepage;

import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-scroll';
import { FaFacebook, FaYoutube, FaTwitter, FaLinkedin, FaEnvelope, FaInstagram } from 'react-icons/fa';
import { Drawer, List, ListItem, ListItemText, AppBar, Toolbar, Paper, IconButton, TextField, Button, Typography, Container, Grid, Box, Divider, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';

const PrivacyPolicy = () => {
    const navigate = useNavigate();
    const [callbackModal, setCallbackModal] = useState(false);
    const [contactNumber, setcontactNumber] = useState(null);
    const [Name, setName] = useState('');
    const [Email, setEmail] = useState('');
    const [StaffSize, setStaffSize] = useState(null);
    const [contactNumberError, setContactNumberError] = useState('');
    const [NameError, setNameError] = useState('');
    const [EmailError, setEmailError] = useState('');
    const [loginFormVisible, setLoginFormVisible] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [drawerOpen, setDrawerOpen] = useState(false);

    const loginFormRef = useRef(null);

    const handleToggleLoginForm = () => {
        setLoginFormVisible(true);
        setUsername('');
        setPassword('');
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (loginFormRef.current && !loginFormRef.current.contains(event.target)) {
                setLoginFormVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    async function convertCodesToArray(data) {
        if (data) {
            data.co_code = [data.co_code];
            data.div_code = [data.div_code];
        }
        return data;
    }

    const handleSubmit = async () => {

        const loginAPI = `${process.env.REACT_APP_API_URL}/auth/signin`;
        const requestData = {
            username: username,
            password: password
        };

        try {
            const response = await axios.post(loginAPI, requestData);
            console.log(response)
            const token = response.data.jwt; // Assuming token is within response.data
            if (!token) {
                toast.error('Unable to login. Please try after some time.');
                return;
            }
            setLoginFormVisible(false);
            if (response.data.message === "Employee login success") {
                const newData = await convertCodesToArray(response.data.user);
                localStorage.clear();
                localStorage.setItem('user-token', token);
                localStorage.setItem('userSchema', JSON.stringify(newData));
                localStorage.setItem('EmployeeIDD', JSON.stringify(newData?._id));
                localStorage.setItem('companyId', JSON.stringify(newData?.co_code[0]));
                localStorage.setItem('divId', JSON.stringify(newData?.div_code[0]));

                toast.success('Login successful!');
                setTimeout(() => {
                    navigate('/dash');
                }, 2500);
            }
            else {
                const dataToSend = { co_code: response?.data?.user?.co_code };
                localStorage.clear();
                localStorage.setItem('user-token', token);
                localStorage.setItem('userSchema', JSON.stringify(response.data.user));
                toast.success('Login successful!');
                setTimeout(() => {
                    navigate('/devision', { state: dataToSend });
                }, 2500);
            }

        } catch (error) {
            toast.error('Oops! Some error occurred.');
        }
    };

    const handleContactNumberChange = (value) => {
        setcontactNumber(value);
        if (value.length !== 10) {
            setContactNumberError('Contact number must be 10 digits long');
        } else {
            setContactNumberError(''); // Clear error if input is valid
        }
    };

    const handleNameChange = (value) => {
        setName(value);
        if (value.length < 1) {
            setNameError('Name Required');
        } else {
            setNameError(''); // Clear error if input is valid
        }
    };

    const handleEmailChange = (value) => {
        setEmail(value);

        if (value.length < 1) {
            setEmailError('Email is required');
        } else {
            // Regular expression for email validation
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            if (!emailPattern.test(value)) {
                setEmailError('Please enter a valid email address');
            } else {
                setEmailError(''); // Clear error if input is valid
            }
        }
    };

    const handleTaskStatusSave = async () => {
        try {
            handleCloseCallbackModal();
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/auth/callbackReq`,
                {
                    name: Name,
                    number: contactNumber,
                    email: Email,
                    staff: StaffSize,
                }
            );
            console.log('Callback request submitted successfully:', response.data);
        } catch (error) {
            console.error('Error submitting callback request:', error);
        }
    };

    const handleCloseCallbackModal = () => {
        setCallbackModal(false);
        setcontactNumber(null);
        setName('');
        setEmail('');
        setStaffSize(null);
        setContactNumberError('');
        setNameError('');
        setEmailError('');
    }
    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };
    return (
        <div>
            {/* <Paper
        elevation='16'
        sx={{
          position: 'fixed',
          top: 0,
          width: '100%',
          height: '100px',
          // backgroundColor: 'whitesmoke',
          zIndex: 1000,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      > */}
            <AppBar position="fixed" color="default" elevation={16} sx={{ zIndex: 1000, height: '80px' }}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={toggleDrawer}>
                        <MenuIcon />
                    </IconButton>
                    <Box sx={{ flexGrow: 1 }}>
                        <img src={"/logo-removebg-preview.png"} alt="Logo" style={{ height: '100px', width: 'auto' }} />
                    </Box>
                    <Box component="nav" sx={{ display: { xs: 'none', sm: 'block' } }}>
                        <ul
                            style={{
                                listStyle: 'none',
                                display: 'flex',
                                justifyContent: 'center',
                                padding: 0,
                                margin: 0,
                            }}
                        >


                            {['home', 'about', 'services', 'contact'].map((section) => (
                                <li key={section} style={{ margin: '0 15px' }}>
                                    <a
                                        href="/homepage"
                                        style={{
                                            color: 'black',
                                            textDecoration: 'none',
                                            padding: '14px 20px',
                                            display: 'block',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        {section.charAt(0).toUpperCase() + section.slice(1)}
                                    </a>
                                </li>
                            ))}
                            <li style={{ margin: '0 15px' }}>
                                {/* {loginFormVisible && (
                  <Paper
                    ref={loginFormRef}
                    elevation={4}
                    sx={{
                      position: 'absolute',
                      top: '80px',
                      right: '100px',
                      padding: '10px',
                      zIndex: 1000,
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                      <TextField
                        label="Username"
                        variant="outlined"
                        size="small"
                        sx={{ flex: 1 }}
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                      />
                      <TextField
                        label="Password"
                        type="password"
                        variant="outlined"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        size="small"
                        sx={{ flex: 1 }}
                      />
                      <Button variant="contained" color="primary" size="small" onClick={handleSubmit}>
                        Login
                      </Button>
                    </Box>
                  </Paper>
                )} */}
                                <Button
                                    sx={{
                                        padding: '14px 20px',
                                        fontWeight: 'bold',
                                        '&:hover': {
                                            backgroundColor: 'transparent',
                                        },
                                    }}
                                    onClick={handleToggleLoginForm}
                                >
                                    Login
                                </Button>
                            </li>
                            <li style={{ margin: '0 15px' }}>
                                <Button
                                    href="/registration"
                                    target="_blank"
                                    sx={{
                                        padding: '14px 20px',
                                        fontWeight: 'bold',
                                        '&:hover': {
                                            backgroundColor: 'transparent',
                                        },
                                    }}
                                >
                                    SignUp
                                </Button>
                            </li>
                            <li style={{ margin: '0 15px' }}>
                                <Button sx={{ borderRadius: '10px', padding: '14px 20px' }} variant="contained" onClick={() => setCallbackModal(true)}>
                                    Request Callback
                                </Button>
                            </li>
                        </ul>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer}>
                <IconButton onClick={toggleDrawer} sx={{ m: 2 }}>
                    <CancelIcon />
                </IconButton>
                <List>
                    {['home', 'about', 'services', 'contact'].map((section) => (
                        // <ListItem button key={section} onClick={toggleDrawer}>
                        //     <Link
                        //         to={section}
                        //         smooth={true}
                        //         duration={500}
                        //         style={{ color: 'black', textDecoration: 'none', display: 'block', width: '100%' }}
                        //     >
                        //         <ListItemText primary={section.charAt(0).toUpperCase() + section.slice(1)} onClick={() => { setDrawerOpen(false); }} />
                        //     </Link>
                        // </ListItem>
                        <ListItem button key={section} onClick={toggleDrawer}>
                            <a
                                href={'/homepage'} // Assuming you want to use hash links
                                style={{ color: 'black', textDecoration: 'none', display: 'block', width: '100%' }}
                                onClick={() => { setDrawerOpen(false); }}
                            >
                                <ListItemText primary={section.charAt(0).toUpperCase() + section.slice(1)} />
                            </a>
                        </ListItem>

                    ))}
                    <ListItem button onClick={handleToggleLoginForm}>
                        <ListItemText primary="Login" onClick={() => { setDrawerOpen(false); }} />
                    </ListItem>
                    <ListItem button component="a" href="/registration" target="_blank">
                        <ListItemText primary="SignUp" onClick={() => { setDrawerOpen(false); }} />
                    </ListItem>
                    <ListItem button onClick={() => { setCallbackModal(true); toggleDrawer(); }}>
                        <ListItemText primary="Request Callback" onClick={() => { setDrawerOpen(false); }} />
                    </ListItem>
                </List>
            </Drawer>
            {/* </Paper> */}
            <Container maxWidth="xl" sx={{ marginTop: '60px' }}>
                {['home', 'contact'].map((section) => (
                    <Section key={section} id={section} title={section.charAt(0).toUpperCase() + section.slice(1)}>
                        {getSectionContent(section, setCallbackModal)}
                    </Section>
                ))}
            </Container>
            {loginFormVisible && (
                <Paper
                    ref={loginFormRef}
                    elevation={4}
                    sx={{
                        position: 'fixed',
                        top: '80px',
                        right: '20px',
                        padding: '10px',
                        zIndex: 1000,
                    }}
                >
                    {/* <Box sx={{ position: 'fixed', top: '100px', right: '20px', zIndex: 1000 }}> */}
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <TextField
                            label="Username"
                            variant="outlined"
                            size="small"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        <TextField
                            label="Password"
                            type="password"
                            variant="outlined"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            size="small"
                        />
                        <Button variant="contained" color="primary" size="small" onClick={handleSubmit}>
                            Login
                        </Button>
                    </div>
                    {/* </Box> */}

                </Paper>
            )}
            <Dialog open={callbackModal} onClose={handleCloseCallbackModal} fullWidth maxWidth="xs">
                <DialogTitle sx={{ fontWeight: 'bold', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    Register for Callback
                    <IconButton onClick={handleCloseCallbackModal} color="inherit">
                        <CancelIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Typography><span style={{ color: 'red' }}>*</span>Contact Number</Typography>
                    <TextField
                        placeholder="Please Enter Contact Number"
                        value={contactNumber}
                        type='number'
                        size='small'
                        onChange={(e) => { handleContactNumberChange(e.target.value) }}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                    />
                    <Typography color="error" sx={{ fontSize: '12px' }}>  {contactNumberError ? contactNumberError : '\u00A0'}</Typography>
                    <Typography><span style={{ color: 'red' }}>*</span>Name</Typography>
                    <TextField
                        placeholder="Please Enter Your Name"
                        value={Name}
                        size='small'
                        onChange={(e) => { handleNameChange(e.target.value) }}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                    />
                    <Typography color="error" sx={{ fontSize: '12px' }}>  {NameError ? NameError : '\u00A0'}</Typography>
                    <Typography><span style={{ color: 'red' }}>*</span>Business Email</Typography>
                    <TextField
                        placeholder="Please Enter Your Business Email"
                        value={Email}
                        type='email'
                        size='small'
                        onChange={(e) => { handleEmailChange(e.target.value) }}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                    />

                    <Typography color="error" sx={{ fontSize: '12px' }}>  {EmailError ? EmailError : '\u00A0'}</Typography>
                    <Typography><span style={{ color: 'red' }}>*</span>Staff Size</Typography>
                    <TextField
                        placeholder="Please Enter Your Staff Size"
                        value={StaffSize}
                        type='number'
                        size='small'
                        onChange={(e) => { setStaffSize(e.target.value) }}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                    />
                </DialogContent>
                <DialogActions sx={{ padding: '20px' }}>
                    <Button disabled={contactNumberError || NameError || EmailError || !contactNumber || !Name || !Email} color="primary" variant='contained' fullWidth sx={{ borderRadius: '10px' }} onClick={() => { handleTaskStatusSave() }}>Submit For Callback</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

const Section = ({ id, title, children }) => (
    <Box id={id} sx={{ padding: '80px 20px', height: 'auto', backgroundColor: 'white' }}>
        {children}
    </Box>
);

const getSectionContent = (section, setCallbackModal) => {
    switch (section) {
        case 'home':
            return <>

                <Typography variant="h4" gutterBottom>
                    Privacy Policy
                </Typography>
                <Typography variant="body1" paragraph>
                    Your privacy is important to us. It is Soft Solutions policy to respect your privacy regarding any information we may collect from you across our website.
                </Typography>
                <Typography variant="body1" paragraph>
                    We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used.
                </Typography>
                <Typography variant="body1" paragraph>
                    We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use, or modification.
                </Typography>
                <Typography variant="body1" paragraph>
                    We don’t share any personally identifying information publicly or with third parties, except when required to by law.
                </Typography>
                <Typography variant="body1" paragraph>
                    Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites and cannot accept responsibility or liability for their respective privacy policies.
                </Typography>
                <Typography variant="body1" paragraph>
                    You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.
                </Typography>
                <Typography variant="body1" paragraph>
                    Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.
                </Typography>
                <Typography variant="body1" paragraph>
                    This policy is effective as of 1 January 2024.
                </Typography>
            </>
                ;

        case 'contact':
            return <ContactContent />;
        default:
            return null;
    }
};

const ContactContent = () => (
    <>
        <Grid container spacing={2} sx={{ textAlign: 'center' }}>
            <Grid item xs={12} md={4}>
                <Typography variant="h5">Stafflicity</Typography>
                <Typography sx={{ fontSize: '12px' }}>Soft Solutions</Typography>
                <Typography sx={{ fontSize: '12px' }}>
                    110,Western Business Center,1/6, opp. Cakes & More,
                </Typography>
                <Typography sx={{ fontSize: '12px' }}>near Mangilal Doodhwala, New Palasia, Indore, Madhya Pradesh 452001, India</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
                <Typography variant="h5">Find us on</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                    {[
                        { href: 'https://softsolution.org/', icon: FaFacebook },
                        { href: 'https://www.youtube.com/channel/UCtLQLJQ-2-iLkBVX9GLLb0w', icon: FaYoutube },
                        { href: 'https://softsolution.org/', icon: FaTwitter },
                        { href: 'https://www.linkedin.com/in/ravi-arora-7637765a/', icon: FaLinkedin },
                        { href: 'mailto:support@softsolution.org', icon: FaEnvelope },
                        { href: 'https://softsolution.org/', icon: FaInstagram },
                    ].map((social, index) => (
                        <a
                            key={index}
                            href={social.href}
                            target="_blank"
                            style={{ margin: '0 10px', textDecoration: 'none', color: '#147dd9' }}
                        >
                            {React.createElement(social.icon, { size: 30 })}
                        </a>
                    ))}
                </Box>
                <Typography sx={{ fontWeight: 'bold' }}>support@softsolution.org</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
                <Typography variant="h5">Other Links</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                    <a
                        href="/privacy-policy"
                        target="_blank"
                        style={{
                            color: 'black',
                            textDecoration: 'none',
                            padding: '14px 20px',
                            display: 'block',
                            cursor: 'pointer',
                        }}
                    >
                        Privacy Policy
                    </a>
                    <a
                        href="/terms-conditions"
                        target="_blank"
                        style={{
                            color: 'black',
                            textDecoration: 'none',
                            padding: '14px 20px',
                            display: 'block',
                            cursor: 'pointer',
                        }}
                    >
                        Terms & Conditions
                    </a>
                </Box>
            </Grid>
        </Grid>
        <Divider sx={{ padding: '10px' }} />
        <Box sx={{ marginTop: '20px', textAlign: 'left' }}>
            <Typography sx={{ fontWeight: 'bold' }}>
                Need assistance? We're here to help. (Monday to Sunday, 8am - 8pm)
            </Typography>
            <Typography>
                Call Us: +91-731-4980808 &nbsp;&nbsp; | &nbsp;&nbsp; WhatsApp: +91 7694938404
            </Typography>
        </Box>
        <Box sx={{ textAlign: 'left', marginTop: '20px' }}>
            <Typography>Copyrights 2024, Soft Solutions</Typography>
        </Box>
        <ToastContainer position='top-right' />
    </>
);

export default PrivacyPolicy;

