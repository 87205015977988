import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ProfileNav from './navbar';
import ProfileHeader from './header';
import Modal from '@mui/material/Modal';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import { Button, Grid, Paper, Typography, TextField, Select, MenuItem, InputLabel } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { Container, Box } from '@mui/material';
import { useRef, useLayoutEffect } from 'react';

function UserRightEdit() {
    const [userData, setUserData] = useState({});
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const [moreDetails, setMoreDetails] = useState('');
    const [admin, setAdmin] = useState('');
    const [jobWork, setJobWork] = useState('');
    const [companiesChecked, setCompaniesChecked] = useState({});
    const [divisionsChecked, setDivisionsChecked] = useState({});
    const [data, setdata] = useState('');
    const [Allrights, setAllrights] = useState();
    const [master, setMaster] = useState('');
    const [masterList, setmasterList] = useState([]);
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [ActionMessage, setActionMessage] = useState('');


    const lastContentRef = useRef(null);
    useLayoutEffect(() => {
        if (lastContentRef.current) {
            lastContentRef.current.style.marginBottom = '75px';
        }
    }, []);

    const UserData = async (allrightsData) => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const masterid = userSchema?.masterid;
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const token = localStorage.getItem('user-token');
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/user_rights/UserDataGET`,
                {
                    params: {
                        masterid,
                        compid: JSON.parse(companyIdString),
                        divid: JSON.parse(divIdString),
                        user: localStorage.getItem('UserRightsID')
                    },
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            console.log('11000:', response.data);
            const data = await response.data.user;

            setdata(data)
            setFirstName(data.first_name || '');
            setLastName(data.last_name || '');
            setUserName(data.usrnm || '');
            setPassword(data.usrpwd || '');
            setEmail(data.emailid || '');
            setPhoneNo(data.phone_num || '');
            setMoreDetails(data.details || '');
            // setMaster(data.masterid || '');
            setAdmin(data?.administrator || '');
            setJobWork(data.jobworkcheck || '');

            const selectedCompanies = {};
            const selectedDivisions = {};

            if (data?.co_code && data?.co_code.length > 0 && allrightsData?.companies) {
                data.co_code.forEach(company => {
                    const companyExists = allrightsData.companies.some(comp => comp?._id === company);
                    if (companyExists) {
                        selectedCompanies[company] = true;
                    }
                });
            }

            if (data?.div_code && data?.div_code.length > 0 && allrightsData?.Division) {
                data.div_code.forEach(division => {
                    const divisionExists = allrightsData.Division.some(div => div._id === division);
                    if (divisionExists) {
                        selectedDivisions[division] = true;
                    }
                });
            }

            setCompaniesChecked(selectedCompanies);
            setDivisionsChecked(selectedDivisions);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleCancel = () => {
        const fetchData = async () => {
            try {
                const allrightsData = await AllrightsGET();
                setAllrights(allrightsData);
                await UserData(allrightsData);
            } catch (error) {
                console.error('Error fetching Allrights:', error);
            }
        };
        fetchData();    
    }
    const handleCompanyCheckboxChange = (event) => {
        setCompaniesChecked({ ...companiesChecked, [event.target.value]: event.target.checked });
    };

    const handleDivisionCheckboxChange = (event) => {
        setDivisionsChecked({ ...divisionsChecked, [event.target.value]: event.target.checked });
    };

    const AllrightsGET = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const masterid = userSchema?.masterid;
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const token = localStorage.getItem('user-token');
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/user_rights/AllrightsGET`,
                {
                    params: {
                        masterid,
                        compid: JSON.parse(companyIdString),
                        divid: JSON.parse(divIdString),
                        user: localStorage.getItem('UserRightsID')
                    },
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );
            // console.log('12000:', response.data);
            // setAllrights(response.data);
            return response.data; // Return the fetched data

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const allrightsData = await AllrightsGET();
                setAllrights(allrightsData);
                await UserData(allrightsData);
            } catch (error) {
                console.error('Error fetching Allrights:', error);
            }
        };
        fetchData();
    }, []);


    const MasterGET = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const masterid = userSchema?.masterid;

            const data = {
                user: userSchema?.usrnm,
                compid: JSON.parse(companyIdString),
                divid: JSON.parse(divIdString),
                masterid: userSchema?.masterid
            }
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/user_rights/MasterGET`, data,
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            setmasterList(response.data.lastEntryNo);
            const datas = response.data.lastEntryNo;
            const matchedObject = datas.find(item => item._id === masterid);
            setMaster(matchedObject);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(()=>{
        MasterGET()
    },[]);
    const UpdateUser = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const masterid = userSchema?.masterid;
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const companies = [];
            const divisions = [];

            for (const key in companiesChecked) {
                if (companiesChecked[key] === true) {
                    companies.push(key);
                }
            }

            for (const key in divisionsChecked) {
                if (divisionsChecked[key] === true) {
                    divisions.push(key);
                }
            }

            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/user_rights/UpdateUserDetails`,
                {
                    firstName: firstName,
                    lastName: lastName,
                    userName: userName,
                    password: password,
                    companies,
                    divisions,
                    email: email,
                    phoneNo: phoneNo,
                    moreDetails: moreDetails,
                    master: master?._id,
                    admin: admin,
                    moreDetails: moreDetails,
                    user: userSchema?.usrnm,
                    compid: JSON.parse(companyIdString),
                    divid: JSON.parse(divIdString),
                    masterid: userSchema?.masterid,
                    _id: localStorage.getItem('UserRightsID')
                },
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );
            if (response.data.status) {
                setActionMessage(response.data.message)
                setSuccessModalOpen(true)
            }
            else {
                setActionMessage(response.data.message)
                setErrorModalOpen(true)

            }

        } catch (error) {
            setActionMessage('Some error occured.')
            setErrorModalOpen(true)
            console.error('Error fetching data:', error);
        }
    }

    const handleErrorModalClose = () => {
        setErrorModalOpen(false);
    };

    const handleSuccessModalClose = () => {
        setSuccessModalOpen(false);
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        border: '2px solid',
        boxShadow: 24,
        padding: 16,
        minWidth: 300,
        maxWidth: 500,
        textAlign: 'center'
    };

    return (
        <>
            <Grid container spacing={2} ref={lastContentRef}>
                <Grid item xs={12}>
                    <ProfileHeader />
                </Grid>
                <Grid item xs={2} sm={3} md={2}>
                    <ProfileNav />
                </Grid>
                <Grid item xs={10} sm={9} md={10}>
                    <Paper elevation={3} sx={{ border: '1px solid #ccc' }}>

                        <Box sx={{ padding: '20px' }}>
                            <Paper elevation={0} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '10px', marginTop: '10px' }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            type="text"
                                            value={firstName}
                                            label='First Name'
                                            onChange={(e) => setFirstName(e.target.value)}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            type="text"
                                            value={lastName}
                                            label='Last Name'
                                            onChange={(e) => setLastName(e.target.value)}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            type="text"
                                            value={userName}
                                            label='User Name'
                                            onChange={(e) => setUserName(e.target.value)}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                            <Paper elevation={0} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '10px', }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            type="password"
                                            value={password}
                                            label='Password'
                                            onChange={(e) => setPassword(e.target.value)}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            type="email"
                                            value={email}
                                            label='Email'
                                            onChange={(e) => setEmail(e.target.value)}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            type="number"
                                            value={phoneNo}
                                            label='Phone No'
                                            onChange={(e) => setPhoneNo(e.target.value)}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                            <Divider sx={{ padding: '10px' }} /> 
                            <Paper elevation={0} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '10px', }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            type="text"
                                            value={moreDetails}
                                            label='More Details'
                                            onChange={(e) => setMoreDetails(e.target.value)}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Autocomplete
                                            value={master}
                                            readOnly
                                            onChange={(e, newValue) => setMaster(newValue)}
                                            label='Master'
                                            options={masterList}
                                            getOptionLabel={(option) => option?.custname || ''}
                                            renderInput={(params) => <TextField {...params} label="Master"
                                                inputProps={{ ...params.inputProps, 'aria-label': 'Select Department Type', style: { paddingTop: '0px' } }}
                                                // sx={{ "& .MuiInputBase-root": { height: '30px', marginTop: "10px" } }}    
                                                variant="outlined"
                                                size="small" />}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                            <Divider sx={{ padding: '10px' }} />    
                            <Paper elevation={0} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '10px', }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: '10px', marginBottom: '5px' }}>
                                            Company Name:
                                        </Typography>
                                        {Allrights?.companies.map((company, index) => (
                                            <FormControlLabel
                                                key={index}
                                                control={
                                                    <Checkbox
                                                        checked={companiesChecked[company._id] || false}
                                                        onChange={handleCompanyCheckboxChange}
                                                        value={company._id}
                                                    />
                                                }
                                                label={company.com_name}
                                                sx={{ marginRight: '50px' }}
                                            />
                                        ))}
                                    </Grid>
                                </Grid>
                            </Paper>
                            <Divider sx={{ padding: '10px' }} /> 
                            <Paper elevation={0} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '10px', }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: '10px', marginBottom: '5px' }}>
                                            Division Name:
                                        </Typography>
                                        {Allrights?.Division.map((division, index) => (
                                            <FormControlLabel
                                                key={index}
                                                control={
                                                    <Checkbox
                                                        checked={divisionsChecked[division._id] || false}
                                                        onChange={handleDivisionCheckboxChange}
                                                        value={division._id}
                                                    />
                                                }
                                                label={division.div_mast}
                                                sx={{ marginRight: '50px' }}
                                            />
                                        ))}
                                    </Grid>
                                </Grid>
                            </Paper>
                            <Divider sx={{ padding: '10px' }} /> 
                            <Paper elevation={0} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '10px', }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Autocomplete
                                            value={admin}
                                            onChange={(e, newValue) => setAdmin(newValue)}
                                            options={['Yes', 'No']}
                                            renderInput={(params) => <TextField {...params} label="Admin"
                                                inputProps={{ ...params.inputProps, 'aria-label': 'Select Department Type', style: { paddingTop: '0px' } }}
                                                // sx={{ "& .MuiInputBase-root": { height: '30px', marginTop: "10px" } }}    
                                                variant="outlined" size="small" />}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        {/* <Typography variant="body1" sx={{ fontWeight: 'bold', marginRight: '10px', marginBottom: '5px' }}>
                                    Job Work:
                                </Typography>
                                <input
                                    type="number"
                                    style={{ padding: '5px', borderRadius: '5px', border: '1px solid #ccc', width: '80%' }}
                                /> */}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Box>
                    </Paper>
                </Grid>
                <Paper style={{ padding: '16px', position: 'fixed', bottom: '16px', zIndex: 1000, width: '60%', left: '0', right: '0', margin: '0 auto' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }}>
                        <Button style={{ marginRight: '8px' }} variant="contained" color="error" onClick={()=>{ handleCancel() }}>
                            Cancel
                        </Button>
                        <Button style={{ marginLeft: '8px' }} variant="contained" color="primary" onClick={() => { UpdateUser() }} >
                            Save
                        </Button>
                    </div>
                </Paper>
                <Modal
                    open={successModalOpen}
                    onClose={handleSuccessModalClose}
                    aria-labelledby="success-modal-title"
                    aria-describedby="success-modal-description"
                >
                    <div style={{ ...modalStyle, borderColor: '#007bff' }}>
                        <h2 id="success-modal-title" style={{ color: '#007bff' }}>Success!</h2>
                        <p id="success-modal-description">{ActionMessage}</p>
                        <Button variant="contained" onClick={handleSuccessModalClose} style={{ backgroundColor: '#007bff', color: 'white' }}>Close</Button>
                    </div>
                </Modal>

                <Modal
                    open={errorModalOpen}
                    onClose={handleErrorModalClose}
                    aria-labelledby="error-modal-title"
                    aria-describedby="error-modal-description"
                >
                    <div style={{ ...modalStyle, borderColor: '#ff0000' }}>
                        <h2 id="error-modal-title" style={{ color: '#ff0000' }}>Error!</h2>
                        <p id="error-modal-description">{ActionMessage}.</p>
                        <Button variant="contained" onClick={handleErrorModalClose} style={{ backgroundColor: '#ff0000', color: 'white' }}>Close</Button>
                    </div>
                </Modal>
            </Grid>
        </>
    );
}

export default UserRightEdit;
