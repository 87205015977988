import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

import Button from '@mui/material/Button';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress for the loader
import { useLocation } from 'react-router-dom';
import ProfileNav from './profileNav';
import ProfileHeader from './profileheader';
import Divider from '@mui/material/Divider';
import Autocomplete from '@mui/material/Autocomplete';

import moment from 'moment';
import {
  Modal, Grid, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Typography, TextField, Select, MenuItem
} from '@mui/material';
import { useRef, useLayoutEffect } from 'react';

function ProfileSalaryStruc() {

  const [data, setdata] = useState([]);
  const [positiveRows, setPositiveRows] = useState([]);
  const [negativeRows, setNegativeRows] = useState([]);
  const [GrossSal, setGrossSal] = useState(null);
  const [Gsal, setGsal] = useState(null);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [ActionMessage, setActionMessage] = useState('');
  const [EmpoyeeIID, setEmpoyeeIID] = useState(null);
  const [positiveTotal, setPositiveTotal] = useState(0);
  const [negativeTotal, setNegativeTotal] = useState(0);
  const [dueAmount, setDueAmount] = useState(0);
  const [bonus, setbonus] = useState('');
  const [gratuity, setgratuity] = useState('');

  const empName = localStorage.getItem('StaffDetailEmpName');
  const empMid = localStorage.getItem('StaffDetailEmpMid');
  const empID = localStorage.getItem('StaffDetailEmpID');
  const empStatus = localStorage.getItem('StaffDetailEmpStatus');

  const lastContentRef = useRef(null);
  useLayoutEffect(() => {
    if (lastContentRef.current) {
      lastContentRef.current.style.marginBottom = '80px';
    }
  }, []);

  useEffect(() => {
    setEmpoyeeIID(localStorage.getItem('EmployeeIDD'))
  }, [])

  useEffect(() => {
    // Calculate total for earnings
    let positiveSum = 0;
    positiveRows.forEach(row => {
      positiveSum += parseFloat(row.amount || 0);
    });
    setPositiveTotal(positiveSum);

    // Calculate total for deductions
    let negativeSum = 0;
    negativeRows.forEach(row => {
      negativeSum += parseFloat(row.amount || 0);
    });
    setNegativeTotal(negativeSum);

    // Calculate due amount
    const grossSalary = parseFloat(GrossSal || 0);
    const earningsTotal = positiveSum;
    const deductionsTotal = negativeSum;
    const due = grossSalary + earningsTotal - deductionsTotal;
    setDueAmount(due);
  }, [positiveRows, negativeRows, GrossSal]);


  const fetchData = async () => {
    try {
      const userSchemaString = localStorage.getItem('userSchema');
      const userSchema = JSON.parse(userSchemaString);
      const companyIdString = localStorage.getItem('companyId');
      const divIdString = localStorage.getItem('divId');
      const dataToSend = {
        usrnm: userSchema?.usrnm,
        compid: JSON.parse(companyIdString),
        divid: JSON.parse(divIdString),
        masterid: userSchema?.masterid,
        _id: localStorage.getItem('StaffDetailEmpID')
      };
      const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/employee/EmployeeSalaryHDGet`,
        dataToSend,
        {
          headers: {
            authorization: `Bearer ${token}`
          }
        }
      );
      setdata(response.data?.salryhd_or_group?.salryhd_or_group)
      const positiveData = await response.data?.salryhd_or_group?.salryhd_or_group?.filter(item => item.salryhd_sign === '(+)' || item.salryhd_sign === '+');
      const negativeData = await response.data?.salryhd_or_group?.salryhd_or_group?.filter(item => item.salryhd_sign === '(-)' || item.salryhd_sign === '-');
      console.log('positiveData',positiveData)
      setPositiveRows(positiveData);
      setNegativeRows(negativeData);
      setGsal(response.data?.salryhd_or_group?.g_sal);
      setGrossSal(response.data?.salryhd_or_group?.gross_sal);
      setgratuity(response.data?.salryhd_or_group?.gratuity);
      setbonus(response.data?.salryhd_or_group?.bonus);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handlePositiveChange = (event, index, field) => {
    const updatedRows = [...positiveRows];
    updatedRows[index][field] = event.target.value;
    setPositiveRows(updatedRows);
  };

  const handleNegativeChange = (event, index, field) => {
    const updatedRows = [...negativeRows];
    updatedRows[index][field] = event.target.value;
    setNegativeRows(updatedRows);
  };

  const handleCancel = async () => {
    fetchData();
  }

  const handleSave = async () => {
    try {
      const userSchemaString = localStorage.getItem('userSchema');
      const userSchema = JSON.parse(userSchemaString);
      const companyIdString = localStorage.getItem('companyId');
      const divIdString = localStorage.getItem('divId');
      const masterid = userSchema?.masterid;
      const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
      const salryhd_or_groups = [...positiveRows, ...negativeRows];
      const transformedData = salryhd_or_groups.map(item => ({
        ...item,
        salaryhead_name: item.salaryhead_name._id
      }));

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/employee/EmployeeSalaryHDUpdate`,
        {
          user: userSchema?.usrnm,
          compid: JSON.parse(companyIdString),
          divid: JSON.parse(divIdString),
          masterid: userSchema?.masterid,
          salryhd_or_group: transformedData,
          GrossSal,
          Gsal,
          bonus,
          gratuity,
          _id: localStorage.getItem('StaffDetailEmpID')
        },
        {
          headers: {
            authorization: `Bearer ${token}`
          }
        }
      );
      if (response.data.status) {
        setActionMessage(response.data.message)
        setSuccessModalOpen(true)
      }
      else {
        setActionMessage(response.data.message)
        setErrorModalOpen(true)

      }

    } catch (error) {
      setActionMessage('Some error occured.')
      setErrorModalOpen(true)
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleErrorModalClose = () => {
    setErrorModalOpen(false);
  };

  const handleSuccessModalClose = () => {
    setSuccessModalOpen(false);
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    border: '2px solid',
    boxShadow: 24,
    padding: 16,
    minWidth: 300,
    maxWidth: 500,
    textAlign: 'center'
  };
  return (
    <>
      <Grid container spacing={2} ref={lastContentRef}>
        <Grid item xs={12}>
          <ProfileHeader name={empName} id={empMid} status={empStatus} EmpID={empID} />
        </Grid>
        {/* Side Navbar */}

        <Grid item xs={2}>
          <ProfileNav />
        </Grid>

        {/* Content Section */}
        <Grid item xs={10}>
          <Paper elevation={3} style={{ padding: '20px', marginBottom: '10px' }}>
            {/* <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                Salary Structure
              </Typography>
            </div> */}

            <Grid container spacing={2}>
              <Grid item xs={3}>
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                  <div style={{ display: 'flex', alignItems: 'stretch' }}>
                    <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold' }}>
                      Gross Salary
                    </Typography>
                    <div style={{ marginLeft: '20px', flex: 1 }}>
                      <TextField
                        value={GrossSal}
                        onChange={(event) => { setGrossSal(event.target.value) }}
                        size="small"
                        InputProps={{
                          readOnly: EmpoyeeIID ? true : false,
                        }}
                      />
                    </div>

                  </div>

                </div>

                <Divider sx={{ padding: '5px' }} />
              </Grid>
              <Grid item xs={3}>
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                  <div style={{ display: 'flex', alignItems: 'stretch' }}>
                    <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold' }}>
                      Gal Sal
                    </Typography>
                    <div style={{ marginLeft: '20px', flex: 1 }}>
                      <TextField
                        value={Gsal}
                        onChange={(event) => { setGsal(event.target.value) }}
                        size="small"
                        InputProps={{
                          readOnly: EmpoyeeIID ? true : false,
                        }}                          // sx={{ width: '100px' }} // Adjust width as needed
                      />
                    </div>
                  </div>
                </div>
                <Divider sx={{ padding: '5px' }} />
              </Grid>
              <Grid item xs={3}>
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                  <div style={{ display: 'flex', alignItems: 'stretch' }}>
                    <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold' }}>
                      Bonus
                    </Typography>
                    <div style={{ marginLeft: '20px', flex: 1 }}>
                    
                      <Autocomplete
                        value={bonus}
                        onChange={(event, newValue) => {
                          setbonus(newValue);
                        }}
                        options={['Y', 'N']}
                        renderInput={(params) => (
                          <TextField
                              {...params}
                              // label="Bonus"
                              inputProps={{
                                  ...params.inputProps,
                                  'aria-label': 'Select Employee',
                                  style: { paddingTop: '0px' },
                              }}
                              variant="outlined"
                              size="small"
                          />
                      )}
                        // style={{ marginBottom: '5px', width: 'calc(30% - 10px)' }}
                      />
                    </div>
                  </div>
                </div>
                <Divider sx={{ padding: '5px' }} />
              </Grid>
              <Grid item xs={3}>
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                  <div style={{ display: 'flex', alignItems: 'stretch' }}>
                    <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold' }}>
                    Gratuity
                    </Typography>
                    <div style={{ marginLeft: '20px', flex: 1 }}>
                    
                      <Autocomplete
                        value={gratuity}
                        onChange={(event, newValue) => {
                          setgratuity(newValue);
                        }}
                        options={['Y', 'N']}
                        renderInput={(params) => (
                          <TextField
                              {...params}
                              // label="Bonus"
                              inputProps={{
                                  ...params.inputProps,
                                  'aria-label': 'Select Employee',
                                  style: { paddingTop: '0px' },
                              }}
                              variant="outlined"
                              size="small"
                          />
                      )}
                        // style={{ marginBottom: '5px', width: 'calc(30% - 10px)' }}
                      />
                    </div>
                  </div>
                </div>
                <Divider sx={{ padding: '5px' }} />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {/* Earnings Section */}
              <Grid item xs={12}>
                <TableContainer sx={{ fontSize: '0.8rem' }}>
                  <Table size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ fontWeight: 'bold', width: '20%' }}>Earnings</TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>Calculation</TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>Round</TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>Order</TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>Actual</TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>Calculated</TableCell>
                      </TableRow>
                      {Object.entries(positiveRows).map(([index, value]) => (
                        <TableRow key={index}>
                          <TableCell>
                            {value.salaryhead_name?.salaryhead_name || 'Not Marked'}
                          </TableCell>
                          <TableCell>
                            <Select
                              value={value.salryhd_calcb}
                              onChange={(event) => handlePositiveChange(event, index, 'salryhd_calcb')}
                              size="small"
                              readOnly={EmpoyeeIID ? true : false}
                            >
                              <MenuItem value="Fix Amount">Fix Amount</MenuItem>
                              <MenuItem value="% of Gross">% of Gross</MenuItem>
                              <MenuItem value="% of Basic">% of Basic</MenuItem>
                              <MenuItem value="Slab">Slab</MenuItem>
                              <MenuItem value="Formula">Formula</MenuItem>
                            </Select>
                          </TableCell>
                          <TableCell>
                            <Select
                              value={value.salryhd_round}
                              onChange={(event) => handlePositiveChange(event, index, 'salryhd_round')}
                              size="small"
                              readOnly={EmpoyeeIID ? true : false}
                            >
                              <MenuItem value="YES">Yes</MenuItem>
                              <MenuItem value="NO">No</MenuItem>
                            </Select>
                          </TableCell>
                          <TableCell>
                            {/* {value.salryhd_vlu || '0'} */}
                            <TextField
                              value={value.salryhd_odr}
                              onChange={(event) => handlePositiveChange(event, index, 'salryhd_odr')}
                              size="small"
                              InputProps={{
                                readOnly: EmpoyeeIID ? true : false,
                              }}
                              sx={{ input: { textAlign: "right" } }}
                            />
                          </TableCell>
                          <TableCell>
                            {/* {value.salryhd_vlu || '0'} */}
                            <TextField
                              value={value.salryhd_vlu}
                              onChange={(event) => handlePositiveChange(event, index, 'salryhd_vlu')}
                              size="small"
                              InputProps={{
                                readOnly: EmpoyeeIID ? true : false,
                              }}
                              sx={{ input: { textAlign: "right" } }}
                            />
                          </TableCell>
                          <TableCell>
                            {/* {value.amount || '0'} */}
                            <TextField
                              value={value.amount}
                              onChange={(event) => handlePositiveChange(event, index, 'amount')}
                              size="small"

                              InputProps={{
                                readOnly: EmpoyeeIID ? true : false,
                              }}
                              sx={{ input: { textAlign: "right" } }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              {/* Deductions Section */}
              <Grid item xs={12}>
                <TableContainer sx={{ fontSize: '0.8rem' }}>
                  <Table size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ fontWeight: 'bold', width: '20%' }}>Deductions</TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>Calculation</TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>Round</TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>Order</TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>Actual</TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>Calculated</TableCell>
                      </TableRow>
                      {Object.entries(negativeRows).map(([index, value]) => (
                        <TableRow key={index}>
                          <TableCell >
                            {value.salaryhead_name?.salaryhead_name || 'Not Marked'}
                          </TableCell>
                          <TableCell>
                            <Select
                              value={value.salryhd_calcb}
                              onChange={(event) => handleNegativeChange(event, index, 'salryhd_calcb')}
                              size="small"
                              readOnly={EmpoyeeIID ? true : false}
                            >
                              <MenuItem value="Fix Amount">Fix Amount</MenuItem>
                              <MenuItem value="% of Gross">% of Gross</MenuItem>
                              <MenuItem value="% of Basic">% of Basic</MenuItem>
                              <MenuItem value="Slab">Slab</MenuItem>
                              <MenuItem value="Formula">Formula</MenuItem>
                            </Select>
                          </TableCell>
                          <TableCell>
                            <Select
                              value={value.salryhd_round}
                              onChange={(event) => handleNegativeChange(event, index, 'salryhd_round')}
                              size="small"
                              readOnly={EmpoyeeIID ? true : false}
                            >
                              <MenuItem value="YES">Yes</MenuItem>
                              <MenuItem value="NO">No</MenuItem>
                            </Select>
                          </TableCell>
                          <TableCell>
                            <TextField
                              value={value.salryhd_odr}
                              onChange={(event) => handleNegativeChange(event, index, 'salryhd_odr')}
                              size="small"
                              InputProps={{
                                readOnly: EmpoyeeIID ? true : false,
                              }}
                              sx={{ input: { textAlign: "right" } }}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              value={value.salryhd_vlu}
                              onChange={(event) => handleNegativeChange(event, index, 'salryhd_vlu')}
                              size="small"
                              InputProps={{
                                readOnly: EmpoyeeIID ? true : false,
                              }}
                              sx={{ input: { textAlign: "right" } }}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              value={value.amount}
                              onChange={(event) => handleNegativeChange(event, index, 'amount')}
                              size="small"
                              InputProps={{
                                readOnly: EmpoyeeIID ? true : false,
                              }}
                              sx={{ input: { textAlign: "right" } }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Paper>
          <Paper style={{ padding: '16px', position: 'fixed', bottom: '16px', zIndex: 1000, width: 'auto', left: '0', right: '0', margin: '0 auto' }}>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: "-15px" }}>
              {!EmpoyeeIID && (
                <Button style={{ marginRight: '8px', color: 'white', backgroundColor: '#c91e32' }} variant="contained" onClick={() => { handleCancel() }}>
                  Cancel
                </Button>
              )}
              {!EmpoyeeIID && (
                <Button style={{ marginLeft: '8px', color: 'white', backgroundColor: '#2656ab' }} variant="contained" onClick={() => { handleSave() }}>
                  Save
                </Button>
              )}
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', }}>
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>Earnings : {positiveTotal}</Typography>
              <Typography variant="body2" sx={{ fontWeight: 'bold', marginLeft: '30px', }}>Deductions : {negativeTotal}</Typography>
              <Typography variant="body2" sx={{ fontWeight: 'bold', marginLeft: '30px', }}>Payble Amount: {dueAmount}</Typography>
            </div>
          </Paper>
        </Grid >
        <Modal
          open={successModalOpen}
          onClose={handleSuccessModalClose}
          aria-labelledby="success-modal-title"
          aria-describedby="success-modal-description"
        >
          <div style={{ ...modalStyle, borderColor: '#007bff' }}>
            <h2 id="success-modal-title" style={{ color: '#007bff' }}>Success!</h2>
            <p id="success-modal-description">{ActionMessage}</p>
            <Button variant="contained" onClick={handleSuccessModalClose} style={{ backgroundColor: '#007bff', color: 'white' }}>Close</Button>
          </div>
        </Modal>

        <Modal
          open={errorModalOpen}
          onClose={handleErrorModalClose}
          aria-labelledby="error-modal-title"
          aria-describedby="error-modal-description"
        >
          <div style={{ ...modalStyle, borderColor: '#ff0000' }}>
            <h2 id="error-modal-title" style={{ color: '#ff0000' }}>Error!</h2>
            <p id="error-modal-description">{ActionMessage}.</p>
            <Button variant="contained" onClick={handleErrorModalClose} style={{ backgroundColor: '#ff0000', color: 'white' }}>Close</Button>
          </div>
        </Modal>
      </Grid >
    </>
  );
}

export default ProfileSalaryStruc;