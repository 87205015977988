import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import MUIDataTable from "mui-datatables";
import Modal from '@mui/material/Modal';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid'; // Import DataGrid from MUI

function Production_mach_list() {
    const [shiftName, setShiftName] = useState(''); // Updated state variable
    const [inTime, setInTime] = useState(''); // Updated state variable
    const [outTime, setOutTime] = useState(''); // Updated state variable
    const [totalHours, setTotalHours] = useState(''); // Updated state variables
    const [error, setError] = useState('');
    const [data, setdata] = useState([]);
    const [openModal, setOpenModal] = useState(false); // State to control the modal visibility
    const [deleteIndex, setDeleteIndex] = useState(null); // State to store the index of item to be deleted
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [ActionMessage, setActionMessage] = useState('');
    const [searchText, setSearchText] = useState(''); // State for search text
    const [rowsPerPage, setRowsPerPage] = useState(10); // State for rows per page, default 10
    const [currentPage, setCurrentPage] = useState(1); // State for current page, default 1
    const [editID, setEditID] = useState()
    const [editDepart, seteditDepart] = useState(null)
    const [MachOb, setMachOb] = useState([]);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [editMach, seteditMach] = useState('');

    const navigate = useNavigate();

    const handleErrorModalClose = () => {
        setErrorModalOpen(false);
    };

    const handleSuccessModalClose = () => {
        setSuccessModalOpen(false);
    };
    function transformArray(arr) {
        const newArray = [];
        for (let obj of arr) {
            const newObj = {};

            newObj['_id'] = obj['_id'];
            newObj['hours'] = obj['hours'];
            newObj['nos'] = obj['nos'];

            newObj['shift'] = obj.shift._id;
            newObj['category'] = obj.category._id;

            newArray.push(newObj);
        }

        return newArray;
    }

    const handleSave = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');

            const data = {
                _id: editID,
                machgroup: transformArray(MachOb),
                user: userSchema?.usrnm,
                compid: JSON.parse(companyIdString),
                divid: JSON.parse(divIdString),
                masterid: userSchema?.masterid
            };
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/production/MachineMasterUpdate`, data, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            })
                .then(response => {

                    if (response.data.status) {
                        fetchData()
                        setActionMessage(response.data.message)
                        handleCloseEditModal()
                        setSuccessModalOpen(true);
                        // handleCancel();

                    } else {
                        fetchData()
                        setActionMessage(response.data.message)
                        handleCloseEditModal()
                        // handleCancel();
                        setErrorModalOpen(true)
                    }
                })
                .catch(error => {
                    fetchData()
                    console.log(error)
                    setActionMessage(response.data.message)
                    handleCloseEditModal()
                    setErrorModalOpen(true)
                });

        } catch (error) {
            fetchData()
            console.error('Error saving data:', error);
            setActionMessage('Oops... an error occured while saving data.')
            handleCloseEditModal()
            setErrorModalOpen(true)
        }

    }

    const handleCancel = () => {
        setShiftName('');
        setInTime('');
        setOutTime('');
        setTotalHours('');
    };

    const handleDelete = (INFO) => {
        setDeleteIndex(INFO._id);
        console.log(INFO._id)
        setOpenModal(true);
    };

    const handleModalDelete = async () => {
        try {
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/production/MachineMasterDELETE/${deleteIndex}`, {}, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            });
            console.log('Delete API Response:', response.data);
            handleCloseModal();
            fetchData();
        } catch (error) {
            console.error('Error deleting item:', error);
        }
    };

    const handleChange = (event, index) => {
        const { name, value } = event.target;
        const newData = [...MachOb];
        newData[index][name] = value;
        setMachOb(newData);
    };

    const handleOpenEditModal = () => {
        setOpenEditModal(true);
    };

    const handleCloseEditModal = () => {
        fetchData();
        setOpenEditModal(false);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };
    const handleTableChange = (action, tableState) => {
        if (action === 'search') {
            setSearchText(tableState.searchText);
        }
        if (action === 'changeRowsPerPage') {
            setRowsPerPage(tableState.rowsPerPage);
        }
        if (action === 'changePage') {
            setCurrentPage(tableState.page);
        }
    };
    const fetchData = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const masterid = userSchema?.masterid;
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/production/MachineMasterGET`,
                {
                    params: {
                        searchText,
                        rowsPerPage,
                        currentPage,
                        masterid,
                        compid: JSON.parse(companyIdString),
                        divid: JSON.parse(divIdString),
                    },
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            //   setdata(response.data.shiftData)
            setdata(transformData(response.data.shiftData))

            console.log(transformData(response.data.shiftData))
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    function transformData(data) {
        return data.map(item => ({
            ...item,
            id: item?._id,
            departmentWid: item?.department,
            department: item?.department?.discription
        }));
    }


    // function transformData(data) {
    //     return data.flatMap(item =>
    //       item.machgroup.map(group => ({
    //         machine_name: item.machine_name,
    //         department_description: item.department.description,
    //         shift_name: group.shift.shift_name,
    //         hours: group.hours,
    //         nos: group.nos,
    //         _id: group._id
    //       }))
    //     );
    //   }


    useEffect(() => {
        fetchData(); // Call fetchData when component mounts or when searchText, rowsPerPage, or currentPage changes
    }, []);

    const CustomToolbar = () => {
        // if (userRights?.includes("Leave Request List-D") || administrator) {
        return (
            <Button variant='contained' color='success' size='small' onClick={() => { navigate('/HrMaster/production/ProMachMaster') }}>
                Add
            </Button>
        );
        // }
        // else {
        //     return
        // }
    };


    const options = {
        pagination: true,
        download: false,
        customToolbar: () => <CustomToolbar />,
        downloadOptions: {
            filename: 'data',
            separator: ',',
            filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true,
            },
        },
        filter: false,
        selectableRows: false,
        onTableChange: handleTableChange
    };

    const columns = [
        // { name: "serialNumber", label: "S. No." }, // Serial number column
        { name: "machine_name", label: "Machine Name" },
        { name: "department", label: "Department" },
        {
            name: "edit",
            label: "Edit",
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <Button variant="text" size='small' sx={{ padding: '0px' }} color="primary"
                            onClick={() => handleEdit(data[dataIndex])}> <EditIcon fontSize="small" /></Button>
                    );
                }
            }
        },
        {
            name: "delete",
            label: "Delete",
            options: {
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <Button variant="text" size='small' sx={{ padding: '0px' }} color="error" onClick={() => handleDelete(data[dataIndex])}><DeleteIcon fontSize="small" /></Button>
                    );
                }
            }
        }
    ];

    const columnsss = [
        { field: "machine_name", headerName: "Machine Name", flex: 1 },
        { field: "department", headerName: "Department", flex: 1 },
        {
            field: "edit",
            headerName: "Edit",
            flex: 1,
            renderCell: (params) => (
                <Button variant="text" size='small' sx={{ padding: '0px' }} color="primary"
                    onClick={() => handleEdit(params.row)}> <EditIcon fontSize="small" /></Button>
            )
        },
        {
            field: "delete",
            headerName: "Delete",
            flex: 1,
            renderCell: (params) => (
                <Button variant="text" size='small' sx={{ padding: '0px', }} color="error" onClick={() => handleDelete(params.row)}><DeleteIcon fontSize="small" /></Button>
            )
        }
    ];

    const EditDataStructure = (data) => {
        return data.map(item => ({
            shift: item.shift._id,
            shiftName: item.shift,
            hours: item.shift.total_hour,
            category: item.category._id,
            discription: item.category,
            nos: item.nos,
            _id: item._id
        }));
    };

    const handleEdit = async (editdata) => {
        const MacData = await EditDataStructure(editdata?.machgroup);

        const dataToSend = {
            data: {
                EditID: editdata._id,
                MacName: editdata.machine_name,
                department: editdata?.departmentWid,
                machgroup: MacData,
            }
        };

        navigate('/HrMaster/production/ProMachEdit', { state: dataToSend });

        // setEditID(editdata._id);
        // seteditMach(editdata.machine_name)
        // setMachOb(editdata?.machgroup)
        // seteditDepart(editdata?.departmentWid)
        // console.log("GHY",editdata?.machgroup)
        // handleOpenEditModal()
    };
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        border: '2px solid',
        boxShadow: 24,
        padding: 16,
        minWidth: 300,
        maxWidth: 500,
        textAlign: 'center'
    };
    return (
        <>
            <Container maxWidth="xl" style={{ marginTop: '25px', minHeight: '400px' }}>
                {/* <Box sx={{ border: '1px solid black', padding: '20px', marginBottom: '20px' }}> */}
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="h5" sx={{ color: 'black', fontWeight:'bold' }}>
                            Production Machine List
                        </Typography>
                        <Button variant='contained' color='success' onClick={() => navigate('/HrMaster/production/ProMachMaster')} style={{ marginLeft: '8px', color: 'white', borderColor: 'white' }}>Add</Button>
                    </div>
                <DataGrid
                    rows={data}
                    columns={columnsss}
                    initialState={{
                        ...data.initialState,
                        pagination: { paginationModel: { pageSize: 10 } },
                      }}
                    pageSizeOptions={[10, 25, 100]}
                    pagination
                    autoHeight
                    checkboxSelection={false}
                    disableSelectionOnClick
                    density="compact"
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                        },
                    }}
                />
                {/* <MUIDataTable title={<span style={{ fontWeight: 'bold', fontSize: '23px' }}>Production Machine List</span>} data={data} columns={columns} options={options} /> */}
                {/* </Box> */}
            </Container>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, width: 400 }}>
                    <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
                        Confirm Deletion
                    </Typography>
                    <Typography id="modal-description" sx={{ mt: 2 }}>
                        Are you sure you want to delete this item?
                    </Typography>
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={handleCloseModal} color="primary" sx={{ mr: 2 }}>
                            Cancel
                        </Button>
                        <Button onClick={handleModalDelete} variant="contained" color="error">
                            Delete
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={successModalOpen}
                onClose={handleSuccessModalClose}
                aria-labelledby="success-modal-title"
                aria-describedby="success-modal-description"
            >
                <div style={{ ...modalStyle, borderColor: '#007bff' }}>
                    <h2 id="success-modal-title" style={{ color: '#007bff' }}>Success!</h2>
                    <p id="success-modal-description">{ActionMessage}</p>
                    <Button variant="contained" onClick={handleSuccessModalClose} style={{ backgroundColor: '#007bff', color: 'white' }}>Close</Button>
                </div>
            </Modal>

            <Modal
                open={errorModalOpen}
                onClose={handleErrorModalClose}
                aria-labelledby="error-modal-title"
                aria-describedby="error-modal-description"
            >
                <div style={{ ...modalStyle, borderColor: '#ff0000' }}>
                    <h2 id="error-modal-title" style={{ color: '#ff0000' }}>Error!</h2>
                    <p id="error-modal-description">{ActionMessage}.</p>
                    <Button variant="contained" onClick={handleErrorModalClose} style={{ backgroundColor: '#ff0000', color: 'white' }}>Close</Button>
                </div>
            </Modal>
            <Dialog open={openEditModal} onClose={handleCloseEditModal} fullWidth maxWidth="sm">
                <DialogTitle>Edit {editMach}</DialogTitle>
                <DialogContent>
                    {MachOb.map((item, index) => (
                        <div key={item._id} style={{ marginBottom: '20px' }}>
                            <div style={{ marginBottom: '10px', marginTop: '10px' }}>
                                <TextField
                                    label="Shift Name"
                                    value={item.shift.shift_name}
                                    variant="outlined"
                                    size="small"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth
                                />
                                <TextField
                                    label="Category"
                                    value={item.category.discription}
                                    variant="outlined"
                                    size="small"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth
                                    style={{ marginTop: '10px' }}
                                />
                                <TextField
                                    label="Hours"
                                    value={item.hours}
                                    variant="outlined"
                                    size="small"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth
                                    style={{ marginTop: '10px' }}
                                />
                                <TextField
                                    type="number"
                                    name="nos"
                                    label="Nos"
                                    fullWidth
                                    value={item.nos}
                                    onChange={(e) => handleChange(e, index)}
                                    style={{ marginTop: '10px' }}
                                    variant="outlined"
                                    size="small"
                                />
                            </div>
                            <hr style={{ margin: '20px 0' }} />
                        </div>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEditModal} color="primary">Cancel</Button>
                    <Button onClick={handleSave} color="primary">Save</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default Production_mach_list;
