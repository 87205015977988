import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

function Header() {
    // const [UserName, setUserName] = useState('');
    // const [UserID, setUserID] = useState('');

    // useEffect(()=>{
    //     setUserName(localStorage.getItem('UserRightsName'))
    //     setUserID(localStorage.getItem('UserRightsID'))
    // },[])
    
    return (
        <Paper elevation={3} style={{ padding: '20px', marginBottom: '10px', borderRadius: '10px', background: 'linear-gradient(135deg, #FFFFFF,#60a5f2,#FFFFFF)', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* <img src={profileImage} alt="Profile" style={{ width: '70px', height: '70px', borderRadius: '50%', marginRight: '10px',border: '2px solid white' }} /> */}
                {/* <img src={getImageUrlFromPath(`${PP}`)} alt="Profile" style={{ width: '95px', height: '95px', borderRadius: '50%', marginRight: '10px', border: '2px solid white' }} /> */}

                <div>
                    <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: 'black' }}>
                        {"Authorization Setup"}
                    </Typography>
                </div>
            </div>
            <div>
                <Button variant="contained" color="primary" style={{ marginRight: '10px' }}>Active</Button>
                <Button variant="outlined" color="primary">Invite</Button>
            </div>
        </Paper>
    );
}

export default Header;
