import React from 'react';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useLocation, useNavigate } from 'react-router-dom';

function ProfileNav() {

    const location = useLocation();
    const Navigate = useNavigate();

    const navItems = [
        { label: 'Profile', path: '/staffDetail/profile' },
        { label: 'Attendance', path: '/staffDetail/attendance' },
        { label: 'Salary Overview', path: '/staffDetail/salaryOverview' },
        { label: 'Salary Structure', path: '/staffDetail/salary-structure' },
        { label: 'Loans', path: '/staffDetail/loans' },
        { label: 'Leave(s)', path: '/staffDetail/leave' },
        { label: 'Document Center', path: '/staffDetail/document-center' },
        { label: 'Tasks Report', path: '/staffDetail/tasks-report' },

    ];

    const handleItemClick = (path, e) => {
        if (e.metaKey || e.ctrlKey) {
            const url = path;
            window.open(url, '_blank'); 
        } else {
            Navigate(path);
        }
        // Navigate(path);
    };

    return (
        <Paper elevation={3} style={{ padding: '20px', marginBottom: '10px', borderRadius: '10px', position:'fixed' }}>
            <List style={{ maxWidth: '100%', overflowX: 'auto' }}>
                {navItems.map((item) => (
                    <ListItem 
                        key={item.label} 
                        button 
                        onClick={(e) => handleItemClick(item.path, e)}
                        sx={{ 
                            borderRadius: '10px', 
                            '&:hover': { 
                                backgroundColor: location.pathname === item.path ? '#2196F3' : 'inherit',
                                color: location.pathname === item.path ? '#fff' : 'inherit',
                            },
                            backgroundColor: location.pathname === item.path ? '#2196F3' : 'inherit',
                            color: location.pathname === item.path ? '#fff' : 'inherit'
                        }}
                    >
                        <ListItemText primary={item.label} />
                    </ListItem>
                ))}
            </List>
        </Paper>
    );
}

export default ProfileNav;
