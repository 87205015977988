import React, { useState, useEffect } from 'react';
import { Modal, Typography, Grid, Box, Button, Paper, TextField, Divider, CircularProgress, Select, MenuItem, Container } from '@mui/material';
import axios from 'axios';
import dayjs from 'dayjs';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid'; // Import DataGrid from MUI
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useRef, useLayoutEffect } from 'react';

const MachineWiseReq = () => {
    const [selectionRange, setSelectionRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });

    const [tempStartDate, setTempStartDate] = useState(new Date());
    const [tempEndDate, setTempEndDate] = useState(new Date());
    const dateRangePickerRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleSelect = (ranges) => {
        // Update the tempStartDate and tempEndDate independently
        const { startDate, endDate } = ranges.selection;
        if (startDate && endDate) {
            if (startDate <= tempEndDate) {
                setTempStartDate(startDate);
            }
            if (endDate >= tempStartDate) {
                setTempEndDate(endDate);
            }
        }
    };

    const applyDateRange = () => {
        setSelectionRange({
            startDate: tempStartDate,
            endDate: tempEndDate,
            key: 'selection',
        });
        setIsOpen(false);
    };

    const handleClickOutside = (event) => {
        if (dateRangePickerRef.current && !dateRangePickerRef.current.contains(event.target)) {
            setIsOpen(false); // Close the date picker when clicked outside
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDateRangePicker = () => {
        setIsOpen(!isOpen);
    };

    const formatDates = (date) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const [sumdata, setSumData] = useState([]);

    const fetchSummaryData = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const masterid = userSchema?.masterid;
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const token = localStorage.getItem('user-token');
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/attendance/GetattendanceDetailsReport`,
                {
                    compid: JSON.parse(companyIdString),
                    divid: JSON.parse(divIdString),
                    masterid,
                    // startDate,
                    // endDate
                    startDate: selectionRange.startDate,
                    endDate: selectionRange.endDate,
                },
                {
                    params: {

                    },
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            // Finally, set the state
            const NewreqData = response.data.transformedData.map((item, index) => ({
                ...item,
                id: index + 1,
            }));

            setSumData(NewreqData);
            console.log("989", response.data.transformedData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchSummaryData()
    }, [selectionRange])

    // Function to extract unique category keys from the data

    const generateColumns = (data) => {
        if (!data.length) return [];

        const columns = [
            { field: 'Shift', headerName: 'Shift', width: 150 },
            { field: 'Machine', headerName: 'Machine', width: 150 },
            { field: 'Department', headerName: 'Department', width: 150 }
        ];

        const categoryKeys = getCategoryKeys(data);
        categoryKeys.forEach(category => {
            columns.push({ field: `${category}Count`, headerName: `${category} Count`, width: 150 });
            columns.push({ field: `${category}Present`, headerName: `${category} Present`, width: 150 });
            columns.push({ field: `${category}Absent`, headerName: `${category} Absent`, width: 150 });
            columns.push({
                field: `${category}Shortage`,
                headerName: `${category} Shortage`,
                width: 150,
                renderCell: (params) => {
                    const shortage = Number(params.value);
                    const cellStyle = {
                        backgroundColor: shortage > 0 ? '#e3877f' : 'inherit',
                        color: 'black',
                        textAlign: 'center',
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    };
                    return (
                        <div style={cellStyle}>
                            {shortage < 0 ? 0 : shortage} {/* Ensure shortage is not negative */}
                        </div>
                    );
                }
            });
        });

        columns.push({ field: 'totalPresent', headerName: 'Total Present', width: 150 });
        columns.push({ field: 'totalCount', headerName: 'Total Count', width: 150 });
        // columns.push({ field: 'total', headerName: 'Total', width: 150 });

        // Filter out columns where the headerName ends with 'Pr'
        const filteredColumns = columns.filter(item => !item.headerName.split(' ')[0].endsWith('Pr'));

        return filteredColumns;
    };

    const getCategoryKeys = (data) => {
        const categoryKeys = {};
        data.forEach(item => {
            Object.keys(item).forEach(key => {
                if (key.endsWith('Count') || key.endsWith('Present')) {
                    // const category = key.slice(0, -5); // Extract category name
                    const category = key.slice(0, -5); // Extract category name
                    categoryKeys[category] = true;
                }
            });
        });
        return Object.keys(categoryKeys);
    };

    const categoryKeys = getCategoryKeys(sumdata);

    const Newsumdata = sumdata.map((item, index) => {
        const totalPresent = categoryKeys.reduce((sum, key) => {
            const value = parseFloat(item[`${key}Present`] || 0);
            return sum + (isNaN(value) ? 0 : value);
        }, 0);

        const totalCount = categoryKeys.reduce((sum, key) => {
            const value = parseFloat(item[`${key}Count`] || 0);
            return sum + (isNaN(value) ? 0 : value);
        }, 0);

        const total = totalPresent + totalCount;
        const short = totalCount - totalPresent ;

        return {
            ...item,
            id: index,
            totalPresent: totalPresent.toFixed(2),
            totalCount: totalCount.toFixed(2),
            total: total.toFixed(2),
            short: short.toFixed(2)
        };
    });


    const generateFileName = () => {
        const now = new Date();
        const date = now.toLocaleDateString().replace(/\//g, '-'); // Format date as YYYY-MM-DD
        const time = now.toLocaleTimeString().replace(/:/g, '-');  // Format time as HH-MM-SS

        const prefix = 'SHORTAGE REPORT';
        return `${prefix}_${date}_${time}`;

        // return `AbsentReport_${date}_${time}`;
    };

    const Sumcolumns = [
        { field: 'Shift', headerName: 'Shift', width: 150 },
        { field: 'Machine', headerName: 'Machine', width: 150 },
        { field: 'Department', headerName: 'Department', width: 150 },
        { field: 'totalPresent', headerName: 'Present', width: 150 },
        { field: 'totalCount', headerName: 'Count', width: 150 },
        { field: 'short', headerName: 'Short', width: 150 },
        // { field: 'total', headerName: 'Total', width: 150 },
    ];
    return (
        <>
            <Container maxWidth="xl" style={{ minHeight: '400px' }}>

                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
                    <Typography variant="h5" sx={{ color: 'black', fontWeight: 'bold' }}>
                        Machine Requirement Report
                    </Typography>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', position: 'relative', padding: '0px' }}>
                                <TextField
                                    type="text"
                                    size="small"
                                    label="Select Range"
                                    value={`${formatDates(selectionRange.startDate)} - ${formatDates(selectionRange.endDate)}`}
                                    onFocus={toggleDateRangePicker}
                                    readOnly
                                    fullWidth
                                    InputProps={{
                                        disableUnderline: true, // Remove default underline
                                        style: { cursor: 'pointer', textAlign: 'center' } // Center-align text and change cursor to pointer when hovering
                                    }}
                                    InputLabelProps={{
                                        shrink: true, // Ensure the label doesn't float above the input when value is present
                                    }}
                                    onClick={toggleDateRangePicker} // Open DateRangePicker on click
                                />
                                {isOpen && (
                                    <div style={{ position: 'absolute', zIndex: 999, marginRight: '400px' }} ref={dateRangePickerRef}>
                                        <DateRangePicker
                                            ranges={[{ startDate: tempStartDate, endDate: tempEndDate, key: 'selection' }]}
                                            onChange={handleSelect}
                                        />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={applyDateRange}
                                            fullWidth
                                            style={{ marginTop: '10px' }}
                                        >
                                            Apply
                                        </Button>
                                    </div>
                                )}

                            </div>
                    </div>
                </div>

                <DataGrid
                    rows={Newsumdata}
                    columns={generateColumns(sumdata)}
                    initialState={{
                        ...Newsumdata.initialState,
                        pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[10, 25, 100]}
                    pagination
                    autoHeight
                    checkboxSelection={false}
                    disableSelectionOnClick
                    density="compact"
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            csvOptions: { fileName: generateFileName() },
                        },
                    }}
                />

                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
                    <Typography variant="h5" sx={{ color: 'black', fontWeight: 'bold' }}>
                       Production Summary Report
                    </Typography>
                </div>
            <DataGrid
                rows={Newsumdata}
                columns={Sumcolumns}
                initialState={{
                    ...Newsumdata.initialState,
                    pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[10, 25, 100]}
                pagination
                autoHeight
                checkboxSelection={false}
                disableSelectionOnClick
                density="compact"
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        csvOptions: { fileName: generateFileName() },
                    },
                }}
            />
        </Container >
        </>
    );
};

export default MachineWiseReq;
