import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ProfileNav from './navbar';
import ProfileHeader from './header';
import Modal from '@mui/material/Modal';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Divider from '@mui/material/Divider';
import { Button, Grid, Paper, Typography, TextField, Select, MenuItem, InputLabel } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { Container, Box } from '@mui/material';
import { useRef, useLayoutEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';

function AuthorizationSetup() {
    const location = useLocation();

    
    const [user, setUser] = useState('');
    const [userList, setuserList] = useState([]);
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [ActionMessage, setActionMessage] = useState('');

    const [idname, setidname] = useState('');
    const [rightname, setrightname] = useState('');

    const [dropdownValues, setDropdownValues] = useState([{ user: "", role: "" }]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        setidname(searchParams.get('_id'));
        setrightname(searchParams.get('right_name'));
        autho_setupGET()
    }, [location]);

    // Function to handle changes in dropdown values
    const handleChange = (index, field, value) => {
        const newDropdownValues = [...dropdownValues];
        newDropdownValues[index][field] = value;
        setDropdownValues(newDropdownValues);
    };

    // Function to add one more dropdown
    const handleAddMore = () => {
        setDropdownValues([...dropdownValues, { user: "", role: "" }]);
    };

    // Function to delete a dropdown item
    const handleDelete = (index) => {
        const newDropdownValues = [...dropdownValues];
        newDropdownValues.splice(index, 1);
        setDropdownValues(newDropdownValues);
    };

    const user_rights_securityGET = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');

            const data = {
                user: userSchema?.usrnm,
                compid: JSON.parse(companyIdString),
                divid: JSON.parse(divIdString),
                masterid: userSchema?.masterid
            }
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/user_rights/user_rights_userGET`, data,
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );
            setuserList(response.data.lastEntryNo.map(item => item.usrnm));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const autho_setupGET = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const searchParams = new URLSearchParams(location.search);
    
            const data = {
                user: userSchema?.usrnm,
                compid: JSON.parse(companyIdString),
                divid: JSON.parse(divIdString),
                masterid: userSchema?.masterid,
                modalID: searchParams.get('_id')
            }
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/user_rights/autho_setupGET`, data,
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );
            if(response?.data?.lastEntryNo?.rolesetup?.length>0){
                setDropdownValues(response?.data?.lastEntryNo?.rolesetup);
            }
            else{
                setDropdownValues([{ user: "", role: "" }])
            }
            // setuserList(response.data.lastEntryNo.map(item => item.usrnm));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handlesave = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const masterid = userSchema?.masterid;
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            // const extractedData = dropdownValues.map(item => ({
            //     user: item.user.usrnm,
            //     role: item.role.value
            // }));

            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/user_rights/Addautho_setup`,
                {
                    rolesetup: dropdownValues,
                    module_id: idname,
                    module_name: rightname,
                    user: userSchema?.usrnm,
                    compid: JSON.parse(companyIdString),
                    divid: JSON.parse(divIdString),
                    masterid: userSchema?.masterid
                },
                {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );
            if (response.data.status) {
                setActionMessage(response.data.message)
                setSuccessModalOpen(true)
            }
            else {
                setActionMessage(response.data.message)
                setErrorModalOpen(true)

            }

        } catch (error) {
            setActionMessage('Some error occured.')
            setErrorModalOpen(true)
            console.error('Error fetching data:', error);
        }
    }

    const handlecancel = () => {
        autho_setupGET();
    }

    useEffect(() => {
        user_rights_securityGET()
    }, []);

    const lastContentRef = useRef(null);
    useLayoutEffect(() => {
        if (lastContentRef.current) {
            lastContentRef.current.style.marginBottom = '75px';
        }
    }, []);

    // const handleCancel = () => {
    //     const fetchData = async () => {
    //         try {
    //             const allrightsData = await AllrightsGET();
    //             setAllrights(allrightsData);
    //             await UserData(allrightsData);
    //         } catch (error) {
    //             console.error('Error fetching Allrights:', error);
    //         }
    //     };
    //     fetchData();    
    // }

    const handleErrorModalClose = () => {
        setErrorModalOpen(false);
    };

    const handleSuccessModalClose = () => {
        setSuccessModalOpen(false);
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        border: '2px solid',
        boxShadow: 24,
        padding: 16,
        minWidth: 300,
        maxWidth: 500,
        textAlign: 'center'
    };

    return (
        <>
            <Grid container spacing={2} ref={lastContentRef}>
                <Grid item xs={12}>
                    <ProfileHeader />
                </Grid>
                <Grid item xs={2} sm={3} md={2}>
                    <ProfileNav />
                </Grid>
                <Grid item xs={10}>
                    <Grid item xs={12}>
                        {dropdownValues.map((item, index) => (
                            <div key={index}>
                                <Paper elevation={0} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '10px', marginTop: '10px' }}>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={5}>
                                            <FormControl style={{ width: '100%' }}>
                                                <Autocomplete
                                                    value={item?.user}
                                                    onChange={(event, newValue) => {
                                                        handleChange(index, 'user', newValue);
                                                    }}
                                                    options={userList}
                                                    // getOptionLabel={(option) => option?.usrnm || ""}
                                                    // getOptionSelected={(option, value) => option._id === value}
                                                    style={{ height: '38px', fontSize: '14px' }}
                                                    renderInput={(params) => <TextField {...params} label="User"
                                                        inputProps={{ ...params.inputProps, 'aria-label': 'Select Department Type', style: { paddingTop: '0px' } }}
                                                        // sx={{ "& .MuiInputBase-root": { height: '30px', marginTop: "10px" } }}    
                                                        variant="outlined"
                                                        size="small" />}
                                                    fullWidth
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={5}>
                                            {/* <select
                                            value={item.role}
                                            onChange={(e) => handleChange(index, 'role', e.target.value)}
                                        >
                                            <option value="">Select Role</option>
                                            <option value="role1">Role 1</option>
                                            <option value="role2">Role 2</option>
                                        </select> */}
                                            <FormControl style={{ width: '100%' }}>
                                                <Autocomplete
                                                    value={item.role}
                                                    onChange={(event, newValue) => handleChange(index, 'role', newValue)}
                                                    options={[
                                                        "Super User",
                                                        "Authorizer",
                                                        "Checker",
                                                        "Viewer"]}
                                                    // getOptionLabel={(option) => option?.label || ""}
                                                    // getOptionSelected={(option, value) => option.value === value}
                                                    style={{ height: '38px', fontSize: '14px' }}
                                                    renderInput={(params) => <TextField {...params} label="Role"
                                                        inputProps={{ ...params.inputProps, 'aria-label': 'Select Department Type', style: { paddingTop: '0px' } }}
                                                        variant="outlined"
                                                        size="small" />}
                                                    fullWidth
                                                />
                                                {/* <Autocomplete
                                                value={admin}
                                                onChange={(e, newValue) => setAdmin(newValue)}
                                                options={['Yes', 'No']}
                                                renderInput={(params) => <TextField {...params} label="Admin"
                                                    inputProps={{ ...params.inputProps, 'aria-label': 'Select Department Type', style: { paddingTop: '0px' } }}
                                                    // sx={{ "& .MuiInputBase-root": { height: '30px', marginTop: "10px" } }}    
                                                    variant="outlined" size="small" />}
                                                fullWidth
                                            /> */}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Button onClick={() => handleDelete(index)}><DeleteIcon /></Button>
                                        </Grid>
                                        {/* <Grid item xs={12}>
                                            <pre>{JSON.stringify(dropdownValues, null, 2)}</pre>
                                        </Grid> */}
                                    </Grid>
                                </Paper>
                            </div>
                        ))}
                    </Grid>
                    <Grid item xs={12}>
                        <Button onClick={handleAddMore}>Add More</Button>
                    </Grid>
                </Grid>
                <Paper style={{ padding: '16px', position: 'fixed', bottom: '16px', zIndex: 1000, width: '60%', left: '0', right: '0', margin: '0 auto' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }}>
                        <Button style={{ marginRight: '8px' }} variant="contained" color="error" onClick={()=>{handlecancel()}}>
                            Cancel
                        </Button>
                        <Button style={{ marginLeft: '8px' }} variant="contained" color="primary" onClick={() => { handlesave() }}>
                            Save
                        </Button>
                    </div>
                </Paper>
                <Modal
                    open={successModalOpen}
                    onClose={handleSuccessModalClose}
                    aria-labelledby="success-modal-title"
                    aria-describedby="success-modal-description"
                >
                    <div style={{ ...modalStyle, borderColor: '#007bff' }}>
                        <h2 id="success-modal-title" style={{ color: '#007bff' }}>Success!</h2>
                        <p id="success-modal-description">{ActionMessage}</p>
                        <Button variant="contained" onClick={handleSuccessModalClose} style={{ backgroundColor: '#007bff', color: 'white' }}>Close</Button>
                    </div>
                </Modal>

                <Modal
                    open={errorModalOpen}
                    onClose={handleErrorModalClose}
                    aria-labelledby="error-modal-title"
                    aria-describedby="error-modal-description"
                >
                    <div style={{ ...modalStyle, borderColor: '#ff0000' }}>
                        <h2 id="error-modal-title" style={{ color: '#ff0000' }}>Error!</h2>
                        <p id="error-modal-description">{ActionMessage}.</p>
                        <Button variant="contained" onClick={handleErrorModalClose} style={{ backgroundColor: '#ff0000', color: 'white' }}>Close</Button>
                    </div>
                </Modal>
            </Grid>
        </>
    );
}

export default AuthorizationSetup;
