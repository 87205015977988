import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import MUIDataTable from "mui-datatables";
import Modal from '@mui/material/Modal';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid'; // Import DataGrid from MUI

function LeaveReqList() {

    const [data, setdata] = useState([]);
    const [openModal, setOpenModal] = useState(false); // State to control the modal visibility
    const [deleteIndex, setDeleteIndex] = useState(null); // State to store the index of item to be deleted
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [ActionMessage, setActionMessage] = useState('');
    const [searchText, setSearchText] = useState(''); // State for search text
    const [rowsPerPage, setRowsPerPage] = useState(10); // State for rows per page, default 10
    const [currentPage, setCurrentPage] = useState(1); // State for current page, default 1
    const [editID, setEditID] = useState();
    const [userRights, setUserRights] = useState(null); // Replaced state name
    const [FromDate, setFromDate] = useState();
    const [ToDate, setToDate] = useState();
    const [status, setstatus] = useState();
    const [administrator, setadministrator] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const userSchemaString = localStorage.getItem('userSchema');
        const userSchema = JSON.parse(userSchemaString);
        // setUserDetail(userSchema);
        setadministrator(userSchema?.administrator === "Yes" ? true : false);
        setUserRights(userSchema?.admin ? userSchema.admin.split(/,\s*/) : [])
    }, []);


    const handleErrorModalClose = () => {
        setErrorModalOpen(false);
    };

    const handleSuccessModalClose = () => {
        setSuccessModalOpen(false);
    };

    const handleDelete = (params) => {

        const rowIndex = params.api.getAllRowIds().indexOf(params.id); // Using getAllRowIds to get the index of the row by id
        const INFO = data[rowIndex];
        if (INFO?.status === "Approve") {
            setActionMessage("Approved leaves can't be deleted!")
            setErrorModalOpen(true)
            return;
        }
        setDeleteIndex(INFO._id);
        setFromDate(INFO?.request_fromdate);
        setToDate(INFO?.request_todate);
        setstatus(INFO?.status);
        setEditID(INFO?._id);
        setOpenModal(true);
    };

    const handleModalDelete = async () => {
        try {
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/leave_reqsRouter/LeaveReqDELETE/${deleteIndex}`, {
                FromDate,
                ToDate,
                status,
                editID
            }, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            });
            console.log('Delete API Response:', response.data);
            handleCloseModal();
            fetchData();
        } catch (error) {
            console.error('Error deleting item:', error);
        }
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };
    const handleTableChange = (action, tableState) => {
        if (action === 'search') {
            setSearchText(tableState.searchText);
        }
        if (action === 'changeRowsPerPage') {
            setRowsPerPage(tableState.rowsPerPage);
        }
        if (action === 'changePage') {
            setCurrentPage(tableState.page);
        }
    };
    const fetchData = async () => {
        try {
            const userSchemaString = localStorage.getItem('userSchema');
            const userSchema = JSON.parse(userSchemaString);
            const masterid = userSchema?.masterid;
            const companyIdString = localStorage.getItem('companyId');
            const divIdString = localStorage.getItem('divId');
            const token = localStorage.getItem('user-token'); // Get JWT token from localStorage
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/leave_reqsRouter/LeaveReqListGET`,
                {
                    params: {
                        searchText,
                        rowsPerPage,
                        currentPage,
                        masterid,
                        compid: JSON.parse(companyIdString),
                        divid: JSON.parse(divIdString),
                        emp: JSON.parse(localStorage.getItem('EmployeeIDD')) || null
                    },
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                }
            );

            console.log('API Response:', response.data.lastEntryNo);
            setdata(response.data.lastEntryNo)

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
        // }, [searchText, rowsPerPage, currentPage]);
    }, []);

    function convertToIST(dateString) {
        const utcDate = new Date(dateString);

        utcDate.setHours(utcDate.getHours() + 5); // Add 5 hours for IST
        utcDate.setMinutes(utcDate.getMinutes() + 30); // Add 30 minutes for IST

        const formattedDate = utcDate.toLocaleDateString('en-IN', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });

        return formattedDate;
    }

    const NewreqData = data.map(item => ({
        ...item,
        id: item._id,
        request_fromdate: convertToIST(item.request_fromdate),
        request_todate: convertToIST(item.request_todate),
        request_date: convertToIST(item.request_date),
        employee_name: item.employee_name?.full_name,
        leave_reason: item.leave_reason?.discription,
        request_typ: item.request_typ?.discription
    }));

    const columns = [
        { field: "request_number", headerName: "Request No.", flex: 1, },
        { field: "employee_name", headerName: "Employee", flex: 1, },
        { field: "request_typ", headerName: "Request Type", flex: 1, },
        { field: "request_date", headerName: "Request Date", flex: 1, },
        { field: "request_fromdate", headerName: "From", flex: 1, },
        { field: "request_todate", headerName: "To", flex: 1, },
        { field: "request_nos", headerName: "Nos", flex: 1, },
        { field: "request_remarks", headerName: "Remarks", flex: 1, },
        { field: "leave_reason", headerName: "Leave Reason", flex: 1, },
        { field: "status", headerName: "Status", flex: 1, }
    ];

    if (userRights?.includes("Leave Request List-E") || administrator) {
        columns.push({
            field: "edit",
            headerName: "Edit",
            flex: 1,
            renderCell: (params) => {
                const isApproved = params.row.status === "Approve";
                return (
                    <Button
                        variant="text"
                        color="primary"
                        size="small"
                        sx={{ padding: '0px' }}
                        onClick={(e) => handleEdit(params, e)}
                        disabled={isApproved}  // Disable the button if status is "Approve"
                    >
                        <EditIcon fontSize="small" />
                    </Button>
                );
            }
        });
    }

    if (userRights?.includes("Leave Request List-D") || administrator) {
        columns.push({
            field: "delete",
            headerName: "Delete",
            flex: 1,
            renderCell: (params) => {
                const isApproved = params.row.status === "Approve";
                return (
                    <Button
                        variant="text"
                        color="error"
                        size="small"
                        sx={{ padding: '0px' }}
                        onClick={() => handleDelete(params)}
                        disabled={isApproved}  // Disable the button if status is "Approve"
                    >
                        <DeleteIcon fontSize="small" />
                    </Button>
                )
            }
        });
    }

    const handleEdit = (params, e) => {
        const rowIndex = params.api.getAllRowIds().indexOf(params.id); // Using getAllRowIds to get the index of the row by id
        const dataToSend = { data: data[rowIndex] };
        if (e.metaKey || e.ctrlKey) {
            const dataString = encodeURIComponent(JSON.stringify(dataToSend));
            const url = `/transaction/LeaveReqEdit?data=${dataString}`;
            window.open(url, '_blank'); 
        } else {
            navigate('/transaction/LeaveReqEdit', { state: dataToSend });
        }
        // navigate('/transaction/LeaveReqEdit', { state: dataToSend });
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        border: '2px solid',
        boxShadow: 24,
        padding: 16,
        minWidth: 300,
        maxWidth: 500,
        textAlign: 'center'
    };
    return (
        <>
            <Container maxWidth="xl" style={{ minHeight: '400px' }}>
                <Box sx={{ padding: '10px', marginBottom: '20px' }}>
                    {/* <MUIDataTable title={<span style={{ fontWeight: 'bold', fontSize: '23px' }}>Leave Requests</span>} data={NewreqData} columns={columns} options={options} /> */}
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="h5" sx={{ color: 'black', fontWeight: 'bold' }}>
                            Leave Requests
                        </Typography>
                        {(userRights?.includes("Leave Request List-D") || administrator) && (
                            <Button variant='contained' color='success' size='small'
                                // onClick={() => { navigate('/transaction/leave_req') }}
                                onClick={(e) => {
                                    if (e.metaKey || e.ctrlKey) {
                                        const url = `/transaction/leave_req`;
                                        window.open(url, '_blank');
                                    } else {
                                        navigate('/transaction/leave_req')
                                    }
                                }}
                            >
                                Add Leave Request
                            </Button>
                        )}
                    </div>

                    <DataGrid
                        rows={NewreqData}
                        columns={columns}
                        initialState={{
                            ...NewreqData.initialState,
                            pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        pageSizeOptions={[10, 25, 100]}
                        pagination
                        autoHeight
                        checkboxSelection={false}
                        disableSelectionOnClick
                        density="compact"
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                            },
                        }}
                    />
                </Box>
            </Container>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4, width: 400 }}>
                    <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
                        Confirm Deletion
                    </Typography>
                    <Typography id="modal-description" sx={{ mt: 2 }}>
                        Are you sure you want to delete this item?
                    </Typography>
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={handleCloseModal} color="primary" sx={{ mr: 2 }}>
                            Cancel
                        </Button>
                        <Button onClick={handleModalDelete} variant="contained" color="error">
                            Delete
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={successModalOpen}
                onClose={handleSuccessModalClose}
                aria-labelledby="success-modal-title"
                aria-describedby="success-modal-description"
            >
                <div style={{ ...modalStyle, borderColor: '#007bff' }}>
                    <h2 id="success-modal-title" style={{ color: '#007bff' }}>Success!</h2>
                    <p id="success-modal-description">{ActionMessage}</p>
                    <Button variant="contained" onClick={handleSuccessModalClose} style={{ backgroundColor: '#007bff', color: 'white' }}>Close</Button>
                </div>
            </Modal>

            <Modal
                open={errorModalOpen}
                onClose={handleErrorModalClose}
                aria-labelledby="error-modal-title"
                aria-describedby="error-modal-description"
            >
                <div style={{ ...modalStyle, borderColor: '#ff0000' }}>
                    <h2 id="error-modal-title" style={{ color: '#ff0000' }}>Error!</h2>
                    <p id="error-modal-description">{ActionMessage}.</p>
                    <Button variant="contained" onClick={handleErrorModalClose} style={{ backgroundColor: '#ff0000', color: 'white' }}>Close</Button>
                </div>
            </Modal>
        </>
    );
}

export default LeaveReqList;
